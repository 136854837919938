import { useHistory } from 'react-router-dom';
import { MoreOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import {
	Pagination,
	Space,
	Table,
	Switch,
	Dropdown,
	Menu,
	message,
	Input,
	Select,
} from 'antd';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import { TagProps } from '../../types/tag.type';
import { ContainerFilter } from '../dashboard';
import useFetchList from '../../hooks/useFetchList';
import React from 'react';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<TagProps, 'createdAt' | 'updatedAt'>;
}

const Tag = () => {
	const history = useHistory();

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<TagProps>({
		apiRequest: httpRequest,
		endpoint: 'tags',
		initialQuery: {
			limit: 10,
			offset: 0,
		},
		limit: 10,
	});

	const handleStatusChange = async (record: TagProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.tagId === record.tagId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/tags/' + record.tagId,
				{
					tagId: record.tagId,
					isPublished: !record.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.tagId === res.data.payload.tagId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.name + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleCreateUser = () => {
		history.push({ pathname: '/tag/add' });
	};

	const handleClickDetail = (e: TagProps) => {
		history.push({
			pathname: `/tag/${e.tagId}/detail`,
		});
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
		}
	};

	const handleClickEdit = (e: TagProps) => {
		history.push({
			pathname: `/tag/${e.tagId}/edit`,
		});
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: TagProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'Status',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: TagProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: TagProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: TagProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'detail') {
					handleClickDetail(record);
				} else if (key === 'edit') {
					handleClickEdit(record);
				}
			}}
		>
			<Menu.Item key="detail">Detail Tag</Menu.Item>
			<Menu.Item key="edit">Edit Tag</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<TagOutlined />}
				title="Tags"
				subtitle="Manage your tag data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateUser}>
							Add Tag
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Tag name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Inactive</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 && (
				<div>
					<Pagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]}-${range[1]} of ${total} items`
						}
						onChange={changePage}
					/>
				</div>
			)}
		</div>
	);
};

export default Tag;
