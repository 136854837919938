import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal, Result, Row, Typography } from 'antd';
import { DoctorSpecialistsProps } from '../types/doctor.type';
import { SpecialistProps } from '../types/specialist.type';

const { Text } = Typography;

interface Props {
	showModal: any;
	setShowModal: any;
	specializaionList: SpecialistProps[];
	data: DoctorSpecialistsProps[];
	onConfirm: (data: SpecialistProps[]) => void;
}

const ModalSpecialization = (props: Props) => {
	const [showAddSpecializationModal, setShowAddSpecializationModal] =
		useState<boolean>(false);

	const [search, setSearch] = useState<string>('');
	const [addSpecialization, setAddSpecialization] = useState<string>('');

	const [selectedSpecialization, setSelectedSpecialization] = useState<
		SpecialistProps[]
	>([]);
	const [listSpecialization, setListSpecialization] = useState<
		SpecialistProps[]
	>([]);

	useEffect(() => {
		setListSpecialization(props.specializaionList);
	}, [props.specializaionList]);

	const handleSelectItem = (data: SpecialistProps) => {
		const selectedData = selectedSpecialization?.filter(
			(item) => item.specialistName === data.specialistName,
		);
		if (selectedData.length > 0) {
			const newSelectedData = selectedSpecialization.filter(
				(item) => item.specialistName !== data.specialistName,
			);
			setSelectedSpecialization(newSelectedData);
		} else {
			setSelectedSpecialization((oldValue) => [...oldValue, data]);
		}
	};

	const handleAddSpecialization = () => {
		const item = {
			specialistName: addSpecialization,
		};
		setSelectedSpecialization((oldValue) => [...oldValue, item]);
		setListSpecialization((oldValue) => [item, ...oldValue]);
		setSearch('');
		setAddSpecialization('');
		setShowAddSpecializationModal(false);
	};

	return (
		<Modal
			centered
			closable={true}
			visible={props.showModal}
			onCancel={() => {
				props.setShowModal(false);
			}}
			footer={[
				<Button
					key="back"
					onClick={() => {
						props.setShowModal(false);
					}}
				>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					onClick={() => {
						props.onConfirm(selectedSpecialization);
						props.setShowModal(false);
					}}
				>
					Save
				</Button>,
			]}
		>
			<Row>
				<Text strong>Specialization</Text>
			</Row>
			<Row>
				<Text type="secondary">
					Choose type of consultation that you want to participate
				</Text>
			</Row>
			<Input
				style={{ margin: '5px 0' }}
				placeholder="Search Specialization Name"
				onChange={(e: any) => setSearch(e.target.value)}
			/>
			<div style={{ height: 400, overflowY: 'scroll' }}>
				{listSpecialization?.filter((item) =>
					item.specialistName.toLowerCase().includes(search.toLowerCase()),
				)?.length > 0 ? (
					listSpecialization
						?.filter((item) =>
							item.specialistName.toLowerCase().includes(search.toLowerCase()),
						)
						.map((item: SpecialistProps) => {
							return (
								<Row style={{ margin: '5px 0' }}>
									<Checkbox
										onChange={(e) => handleSelectItem(e.target.value)}
										value={item}
										disabled={
											props.data.find(
												(selectedItem) =>
													selectedItem.subSpecialistName ===
														item.specialistName ||
													selectedItem.specialistName === item.specialistName,
											)
												? true
												: false
										}
										checked={
											selectedSpecialization.find(
												(selectedItem) =>
													selectedItem.specialistName === item.specialistName,
											) ||
											props.data.find(
												(selectedItem) =>
													selectedItem.subSpecialistName ===
														item.specialistName ||
													selectedItem.specialistName === item.specialistName,
											)
												? true
												: false
										}
									>
										{item.specialistName}
									</Checkbox>
								</Row>
							);
						})
				) : (
					<Result
						title="Specialization Not Found"
						subTitle="Are you want to add new specialization?"
						extra={
							<Button
								type="primary"
								ghost
								onClick={() => {
									setShowAddSpecializationModal(true);
								}}
							>
								Add New Specialization
							</Button>
						}
					></Result>
				)}
			</div>
			<Modal
				centered
				closable={true}
				visible={showAddSpecializationModal}
				onCancel={() => {
					setShowAddSpecializationModal(false);
				}}
				footer={[
					<Button
						key="back"
						onClick={() => {
							setShowAddSpecializationModal(false);
						}}
					>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							handleAddSpecialization();
							setShowAddSpecializationModal(false);
						}}
					>
						Add Sub Specialization
					</Button>,
				]}
			>
				<Row>
					<Text strong>Add New Sub Specialization</Text>
				</Row>
				<Input
					onChange={(e: any) => setAddSpecialization(e.target.value)}
					placeholder="New Specialization Name"
					value={addSpecialization}
				/>
			</Modal>
		</Modal>
	);
};

export default ModalSpecialization;
