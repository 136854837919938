import { DeleteOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Row,
	Select,
	Space,
	Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import {
	initialPackageService,
	PackageServiceProps,
} from '../../types/packageService.type';

interface IParams {
	healthServiceId: string;
}

interface ILocation {
	healthServiceId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PackageServiceProps, 'createdAt' | 'updatedAt'>;
}

interface ResponsePartnerProps extends BaseResponseProps {
	payload: Omit<PackageServiceProps, 'createdAt' | 'updatedAt'>;
}

const { Option } = Select;
const { Text } = Typography;

const PackageEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { healthServiceId } = useParams<IParams>();
	const formRef =
		React.useRef<
			FormInstance<Omit<PackageServiceProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [healthServiceName, setHealthServiceName] = React.useState<string>('');
	const [showWarning, setShowWarning] = React.useState<boolean>(false);

	const [packageService, setPackageService] =
		React.useState<PackageServiceProps>(initialPackageService);

	const [groups, setGroups] = React.useState<Array<String>>([
		'Package',
		'Specialized Package',
		'ECU',
		'OFW',
	]);

	const [items, setItems] = React.useState<Array<String>>([]);

	const createService = async (
		props: Omit<
			PackageServiceProps,
			'createdAt' | 'updatedAt' | 'healthServiceId' | 'statusLoading'
		>,
	) => {
		try {
			if (packageService.group.length == 0) {
				message.error('Please select group');
				return false;
			}
			setIsLoadingAction(true);
			const res: any = await httpRequest.post(
				'/health-service/packages',
				{
					healthServiceName: packageService.healthServiceName,
					isPublished: packageService.isPublished,
					description: packageService.description,
					group: packageService.group,
					items: items,
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			message.success('Success create ' + props.healthServiceName);
			history.push(
				'/medical-service-package/' +
					res.data.payload.healthServiceId +
					'/detail',
			);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateService = async (
		props: Omit<
			PackageServiceProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			const dataToBeSent = {
				...props,
				group: packageService.group,
				items: items,
			};
			await httpRequest.patch(
				`/health-service/packages/${healthServiceId}`,
				dataToBeSent,
			);

			message.success('Success update ' + props.healthServiceName + ' data');
			history.push('/medical-service-package');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			PackageServiceProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		if (healthServiceId) {
			updateService(values);
		} else {
			createService(values);
		}
	};

	const handleChangeGroup = (value: any) => {
		setPackageService((oldVal) => {
			return {
				...oldVal,
				group: value,
			};
		});
	};

	const handleInputItem = (value: string, index: number) => {
		//change value of the index value
		const newItems = items;
		newItems[index] = value;
		setItems(newItems);
	};

	React.useEffect(() => {
		if (healthServiceId) {
			const fetchServiceDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/health-service/packages/' + healthServiceId,
					);
					setPackageService(res.data.payload);

					if (res.data.payload.items) {
						setItems(res.data.payload.items);
					}

					const bcDetails = [
						{
							field: 'healthServiceId',
							value: healthServiceId,
							label: res.data.payload.healthServiceName,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchServiceDetail();
		}
	}, [healthServiceId, location]);

	const handleHealthNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setHealthServiceName(value);
		setShowWarning(value.length > 100);
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(healthServiceId ? 'Edit' : 'Add') + ' Package'}
				subtitle=""
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="packageServiceForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={
						healthServiceId ? packageService : initialPackageService
					}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Medical Update Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Item Name"
							name="healthServiceName"
							rules={generateFormRules('Item Name', ['required'])}
						>
							<Input
								onChange={handleHealthNameChange}
								value={healthServiceName}
							/>
						</Form.Item>
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Item Name should not exceed 100 characters!
							</Text>
						)}

						<Form.Item
							label="Description"
							name="description"
							rules={generateFormRules('Description', ['required'])}
						>
							<TextArea
								placeholder="Input description"
								value={packageService.description}
								onChange={(e) => {
									setPackageService({
										...packageService,
										description: e.target.value,
									});
								}}
							></TextArea>
						</Form.Item>

						<h4>Group</h4>
						<Select
							allowClear
							style={{ width: '100%' }}
							placeholder="Please select"
							onChange={handleChangeGroup}
							key={'groupSelect'}
							value={packageService.group ? packageService.group : ''}
						>
							{groups.map((group, index) => {
								return (
									<Option key={`group${index}`} value={String(group)}>
										{group}
									</Option>
								);
							})}
						</Select>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Items"
							name="items"
						>
							{items.map((item, index) => {
								return (
									<div style={{ paddingBottom: '2%' }}>
										<Row justify="space-between" gutter={[8, 8]}>
											<Col flex={'auto'}>
												<Input
													style={{ width: '100%' }}
													defaultValue={item.toString()}
													onChange={
														(e) => handleInputItem(e.target.value, index) //change value of the index position
													}
												/>
											</Col>
											<Col>
												<Button
													disabled={items.length <= 1}
													onClick={() => {
														if (items.length >= 1) {
															items.splice(index, 1);
															setItems([...items]);
														}
													}} //remove pos index
												>
													<DeleteOutlined color="#D81F64" />
												</Button>
											</Col>
										</Row>
									</div>
								);
							})}
							<Button
								style={{
									color: '#D81F64',
									borderColor: '#D81F64',
									width: '100%',
								}}
								onClick={() => {
									setItems([...items, '']);
								}} //push empty array
							>
								Add New Item
							</Button>
						</Form.Item>
					</SectionContent>

					<SectionContent
						groupTitle="Settings"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Is Published', ['required'])}
						>
							<Radio.Group value={packageService.isPublished}>
								<Radio value={true}>Published</Radio>
								<Radio value={false}>Unpublished</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default PackageEdit;
