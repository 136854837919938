import React, { useState } from 'react';
import { Button, Image, Modal, Row, Col, Typography, message } from 'antd';
import { PatientInRelationProps } from '../types/patientInRelation.type';
import { formatDate } from '../helpers/constant';
import { CustomerProps } from '../types/customer.type';
import { capitalizeFirstLetter } from '../helpers/text';
import TextArea from 'antd/lib/input/TextArea';
import { httpRequest } from '../helpers/api';
import { EPWIdStatus } from '../types/patient.type';

type IProps = {
	isVisible: boolean;
	setIsVisible: (visible: boolean) => any;
	data: CustomerProps | PatientInRelationProps | undefined;
	setSelectedData: (
		data: CustomerProps | PatientInRelationProps | undefined,
	) => any;
	fetchList: any;
};

const { Text } = Typography;

export default function ModalApprovalPwId(props: IProps) {
	const [isShowModalConfirmation, setIsShowModalConfirmation] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [action, setAction] = useState<string>('');
	const [reason, setReason] = useState<string>('');

	const DATA_LIST = [
		{
			title: 'First Name',
			value:
				(props.data as CustomerProps)?.firstName ||
				(props.data as PatientInRelationProps)?.firstName ||
				'-',
		},
		{
			title: 'Middle Name',
			value:
				(props.data as CustomerProps)?.middleName ||
				(props.data as PatientInRelationProps)?.middleName ||
				'-',
		},
		{
			title: 'Last Name',
			value:
				(props.data as CustomerProps)?.lastName ||
				(props.data as PatientInRelationProps)?.lastName ||
				'-',
		},
		{
			title: 'Gender',
			value:
				capitalizeFirstLetter(
					(props.data as CustomerProps)?.gender ||
						(props.data as PatientInRelationProps)?.gender ||
						'',
				) || '-',
		},
		{
			title: 'Birth Date',
			value:
				formatDate(
					(props.data as CustomerProps)?.birthdate ||
						(props.data as PatientInRelationProps)?.birthdate,
				) || '-',
		},
	];

	const onClose = () => {
		props.setIsVisible(false);
		props.setSelectedData(undefined);
	};

	const onRejectedOrApprove = async () => {
		setIsLoading(true);
		try {
			let res;
			if ((props.data as CustomerProps)?.userId) {
				if (action === 'Reject') {
					res = await httpRequest.patch(
						`/patients/${(props.data as CustomerProps).userId}`,
						{
							pwIdStatus: EPWIdStatus.REJECTED,
							reason: reason,
						},
					);
				} else {
					res = await httpRequest.patch(
						`/patients/${(props.data as CustomerProps).userId}`,
						{
							pwIdStatus: EPWIdStatus.APPROVED,
							reason: '',
						},
					);
				}
			} else {
				if (action === 'Reject') {
					res = await httpRequest.patch(
						`/patient-in-relations/${
							(props.data as PatientInRelationProps).patientInRelationId
						}`,
						{
							pwIdStatus: EPWIdStatus.REJECTED,
							reason: reason,
						},
					);
				} else {
					res = await httpRequest.patch(
						`/patient-in-relations/${
							(props.data as PatientInRelationProps).patientInRelationId
						}`,
						{
							pwIdStatus: EPWIdStatus.APPROVED,
							reason: '',
						},
					);
				}
			}

			console.log(res);
			if (res) {
				message.success(
					`success to ${action === 'Reject' ? 'reject' : 'approve'} PWD`,
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
			props.setIsVisible(false);
			props.fetchList();
		}
	};

	return (
		<Modal
			visible={props.isVisible}
			width="60%"
			centered
			onCancel={onClose}
			footer={null}
		>
			<Row gutter={[20, 0]}>
				<Col span={12}>
					{DATA_LIST.map((item) => (
						<div
							key={item.title}
							style={{
								padding: '16px 0',
								flex: 1,
								borderBottomWidth: 1,
								borderBottomStyle: 'solid',
								borderColor: '#ebebeb',
							}}
						>
							<Row>
								<Text
									style={{
										fontSize: 14,
										fontWeight: '400',
										color: '#929292',
										marginBottom: 8,
									}}
								>
									{item.title}
								</Text>
							</Row>
							<Row>
								<Text style={{ fontSize: 16, fontWeight: '400' }}>
									{item.value}
								</Text>
							</Row>
						</div>
					))}
				</Col>
				<Col span={12}>
					<Row>
						<Text
							style={{
								fontSize: 16,
								fontWeight: '400',
								color: '#929292',
								marginBottom: 8,
							}}
						>
							PWD ID
						</Text>
					</Row>
					<Row>
						<div
							style={{
								flex: 1,
								borderWidth: 2,
								borderStyle: 'solid',
								padding: 16,
								borderColor: '#D5DCE1',
								width: 400,
							}}
						>
							<Image
								preview={false}
								width={'100%'}
								height={280}
								src={
									(props.data as CustomerProps)?.userId
										? (props.data as CustomerProps)?.patient?.pwIdUrl
										: (props.data as PatientInRelationProps)?.pwIdUrl
								}
								fallback={'/images/blur-image.jpeg'}
								style={{ objectFit: 'contain' }}
								placeholder={
									<Image
										preview={false}
										src="/images/blur-image.jpeg"
										width="100%"
										height={220}
										style={{ objectFit: 'contain' }}
									/>
								}
							/>
						</div>
					</Row>
				</Col>
			</Row>
			<Row
				gutter={[14, 0]}
				style={{ marginTop: 20, justifyContent: 'flex-end' }}
			>
				<Col>
					<Button
						onClick={onClose}
						size="large"
						style={{ border: 'none', fontSize: 14, width: 100 }}
					>
						Cancel
					</Button>
				</Col>
				<Col>
					<Button
						loading={isLoading}
						onClick={() => {
							setIsShowModalConfirmation(true);
							setAction('Reject');
						}}
						size="large"
						style={{
							border: 'none',
							backgroundColor: '#F23459',
							color: 'white',
							fontSize: 14,
							width: 100,
						}}
					>
						Reject
					</Button>
				</Col>
				<Col>
					<Button
						loading={isLoading}
						onClick={() => {
							setIsShowModalConfirmation(true);
							setAction('Approved');
						}}
						size="large"
						style={{
							border: 'none',
							backgroundColor: '#39AC6E',
							color: 'white',
							fontSize: 14,
							width: 100,
						}}
					>
						Approve
					</Button>
				</Col>
			</Row>
			<Modal
				title={
					<Text style={{ fontWeight: '500', fontSize: 18 }}>Confirmation</Text>
				}
				visible={isShowModalConfirmation}
				width="40%"
				centered
				onCancel={() => setIsShowModalConfirmation(false)}
				footer={null}
			>
				<Row style={{ justifyContent: 'center', marginBottom: 20 }}>
					<Row style={{ marginBottom: 12 }}>
						<Text style={{ fontWeight: '500', fontSize: 20 }}>
							{`	Are you sure want to ${
								action === 'Reject' ? 'reject' : 'approve'
							} PWD ID?`}
						</Text>
					</Row>
					<Row>
						<Text style={{ fontWeight: '400', fontSize: 14 }}>
							Double-check before saving, make sure the data is accurate with
							PWD/Senior ID
						</Text>
					</Row>
				</Row>
				{action === 'Reject' && (
					<Row>
						<Row>
							<Text
								style={{
									fontSize: 14,
									fontWeight: '400',
									color: '#556575',
									marginBottom: 8,
								}}
							>
								Reason
							</Text>
						</Row>
						<TextArea
							value={reason}
							onChange={(e) => setReason(e.target.value)}
							rows={4}
							placeholder="Input the reason of rejection"
						/>
					</Row>
				)}
				<Row
					gutter={[14, 0]}
					style={{ marginTop: 20, justifyContent: 'flex-end' }}
				>
					<Col span={12}>
						<Button
							onClick={() => setIsShowModalConfirmation(false)}
							size="large"
							style={{
								width: '100%',
								borderStyle: 'solid',
								borderColor: '#D81F64',
								color: '#D81F64',
								fontSize: 14,
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col span={12}>
						<Button
							disabled={action === 'Reject' && !reason}
							loading={isLoading}
							onClick={() => {
								setIsShowModalConfirmation(false);
								onRejectedOrApprove();
							}}
							size="large"
							style={{
								width: '100%',
								border: 'none',
								backgroundColor:
									action === 'Reject' && !reason ? '#93A1B0' : '#D81F64',
								color: 'white',
								fontSize: 14,
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>
		</Modal>
	);
}
