import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppVersion from '../../components/AppVersion';
import AuthHeaderCard from '../../components/AuthHeaderCard';
import useAuth from '../../hooks/useAuth';
import AuthLayout from '../layout/AuthLayout';
import { isPublisherMode } from '../../helpers/cmsMode';

const Login = () => {
	const history = useHistory();
	const { isLoading, login, isLoggedIn } = useAuth();
	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	React.useEffect(() => {
		if (!isLoading && isLoggedIn) {
			history.push('/dashboard');
		}
	}, [isLoggedIn, isLoading]);

	return (
		<AuthLayout>
			<Card style={{ width: 400 }}>
				<AuthHeaderCard
					subtitle={`Welcome! Please fill the Username and Password to Sign in into Medeasy ${
						isPublisherMode() ? 'for Publisher' : 'System'
					}`}
				/>
				<Form
					layout="vertical"
					name="basic"
					initialValues={{
						email: '',
						password: '',
					}}
					onFinish={login}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Form.Item name="email" label="Email address">
						<Input
							size="large"
							type="email"
							prefix={<MailOutlined style={{ color: '#A5B2BD' }} />}
							placeholder="Email address"
						/>
					</Form.Item>

					<Form.Item name="password" label="Password">
						<Input.Password
							size="large"
							prefix={<LockOutlined style={{ color: '#A5B2BD' }} />}
							placeholder="Password"
						/>
					</Form.Item>

					<Form.Item>
						<AppButton
							loading={isLoading}
							size="large"
							type="primary"
							htmlType="submit"
							style={{ width: '100%' }}
						>
							Sign In
						</AppButton>
					</Form.Item>

					<Link
						style={{
							display: 'block',
							textAlign: 'center',
							textDecoration: 'underline',
						}}
						to="/forgot-password"
					>
						Forgot Password?
					</Link>
				</Form>
				<AppVersion />
			</Card>
		</AuthLayout>
	);
};

export default Login;
