import { BaseResponseProps } from './config.type';

export interface MedicalCalculatorProps {
	MEDICAL_CALCULATOR_INFORMATION: string;
}

export interface FetchAllMedicalCalculatorResponse extends BaseResponseProps {
	payload: MedicalCalculatorProps;
}

export const initialMedicalCalculator: MedicalCalculatorProps = {
	MEDICAL_CALCULATOR_INFORMATION: '',
};
