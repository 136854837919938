import { BaseResponseProps } from './config.type';
import { initialTopic, TopicProps } from './topic.type';
import { IUser } from './user.type';

export enum EPWIdStatus {
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	WAITING_APPROVAL = 'WAITING_APPROVAL',
}

export interface PatientProps {
	patientId: string;
	pwId?: string;
	pwIdUrl?: string;
	pwIdStatus?: EPWIdStatus;
	updatedBy?: any;
	reason?: string;
	createdAt?: Date;
	updatedAt?: Date;
	user?: IUser;
}

export interface FetchAllArticleResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: PatientProps[];
	};
}

export const initialArticle: PatientProps = {
	patientId: '',
	pwId: '',
	pwIdUrl: '',
	pwIdStatus: EPWIdStatus.WAITING_APPROVAL,
};
