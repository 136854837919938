import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface IProps {
	exact?: boolean;
	path: string;
}

const PrivateRoute: React.FC<IProps> = ({ children, ...rest }) => {
	const { isLoggedIn } = useAuth();

	return (
		<Route
			{...rest}
			render={({ location, match }: any) => {
				if (!isLoggedIn) {
					return (
						<Redirect
							to={{
								pathname: '/',
								state: { from: location },
							}}
						/>
					);
				} else {
					return children;
				}
			}}
		/>
	);
};

export default PrivateRoute;
