import { useHistory } from 'react-router-dom';
import { MoreOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import {
	Pagination,
	Table,
	Dropdown,
	Menu,
	Input,
	Select,
	Switch,
	message,
	Modal,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { ContainerFilter } from '../dashboard';
import useFetchList from '../../hooks/useFetchList';
import { DoctorProps } from '../../types/doctor.type';
import moment from 'moment';
import KycTag from '../../components/KycTag';
import { getFullName, getNickName } from '../../helpers/name';
import { useState } from 'react';
import { initialDoctor } from '../../types/doctor.type';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<DoctorProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const Doctor = () => {
	const history = useHistory();

	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [tmpData, setTmpData] = useState<DoctorProps>(initialDoctor);
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		useState<boolean>(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		fetchList,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<DoctorProps>({
		endpoint: '/doctors',
		limit: 10,
	});

	//   console.log(pagination, "data doctor");

	const handleClickDetail = (doctorId: string) => {
		history.push({
			pathname: `/doctor/${doctorId}`,
		});
	};

	const handleClickDelete = async () => {
		try {
			await httpRequest.delete<DeleteResponseProps>(
				'/doctors/' + tmpData.doctorId,
			);
			fetchList();
			message.success('Success delete doctor ' + tmpData.user?.name);
			setIsModalDeleteVisible(false);
			setTmpData(initialDoctor);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialDoctor);
		}
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, kycStatus: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, kycStatus: null }));
		}
	};

	const handleFilterGenderChange = (gender: string) => {
		if (gender !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, gender }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, gender: null }));
		}
	};

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdate(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.doctorId === tmpData.doctorId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});

			const newStatus =
				tmpData.user?.status === 'active' ? 'inactive' : 'active';

			const res = await httpRequest.patch<ResponseProps>(
				'/user/' + tmpData.doctorId,
				{
					doctorId: tmpData.doctorId,
					status: newStatus,
				},
			);
			fetchList();

			newData = data.map((item) => {
				if (item.doctorId === res.data.payload.doctorId) {
					return {
						...item,
						status: res.data.payload.user?.status,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);
			message.success('Success change ' + tmpData.user?.name + ' status.');

			setIsModalVisible(false);
			setTmpData(initialDoctor);
			setIsLoadingUpdate(false);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialDoctor);
			setIsLoadingUpdate(false);
		}
	};

	const columns = [
		{
			title: 'FULL NAME',
			dataIndex: 'doctorName',
			key: 'doctorName',
			render: (text: string, record: DoctorProps) => (
				<div
					className="table-link"
					onClick={() => handleClickDetail(record.doctorId)}
				>
					{record.user ? getFullName(record.user) : ' '}
					{record.user && getNickName(record.user)
						? ` ( ${getNickName(record.user)} )`
						: null}
				</div>
			),
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phone',
			key: 'phone',
			render: (text: string, record: DoctorProps) => '+' + record.user?.phone,
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			key: 'gender',
			render: (text: string, record: DoctorProps) =>
				`${record.user?.gender
					?.charAt(0)
					.toUpperCase()}${record.user?.gender?.slice(1)}`,
		},
		{
			title: 'BIRTHDATE',
			dataIndex: 'birthdate',
			key: 'birtdate',
			render: (text: string, record: DoctorProps) =>
				record.user?.birthdate ? (
					<div>{moment(record.user?.birthdate).format('DD MMM YYYY')}</div>
				) : (
					<div>-</div>
				),
		},
		{
			title: 'KYC STATUS',
			key: 'kycStatus',
			dataIndex: 'kycStatus',
			render: (text: string) => <KycTag>{text}</KycTag>,
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: DoctorProps) => (
				<>
					<Switch
						checked={record.user?.status == 'active' ? true : false}
						onChange={() => {
							setIsModalVisible(true);
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: string) => (
				<span>{moment(text).format('MMMM DD, YYYY, HH:mm')}</span>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: DoctorProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: DoctorProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'detail') {
					handleClickDetail(record.doctorId);
				} else if (key === 'delete') {
					setTmpData(record);
					setIsModalDeleteVisible(true);
				}
			}}
		>
			<Menu.Item key="detail">Detail Doctor</Menu.Item>
			<Menu.Item key="delete">Delete Doctor</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<UserOutlined width={20} height={20} />}
				title="Doctor"
				subtitle="Manage your doctors data"
			/>
			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search name, phone number, and email address"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterGenderChange}
					placeholder="Gender"
				>
					<Option value="all">All</Option>
					<Option value="male">Male</Option>
					<Option value="female">Female</Option>
				</Select>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="WAITING_APPROVAL">Waiting Approval</Option>
					<Option value="APPROVED">Approved</Option>
					<Option value="REJECTED">Rejected</Option>
					<Option value="BLOCKED">Blocked</Option>
				</Select>
			</ContainerFilter>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
			{data.length !== 0 && (
				<div>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</div>
			)}

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialDoctor);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdate}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change{' '}
					<b>
						"{tmpData.user?.firstName} {tmpData.user?.middleName}{' '}
						{tmpData.user?.lastName}"
					</b>{' '}
					status to{' '}
					<b>"{tmpData.user?.status === 'active' ? 'Inactive' : 'Active'}"</b>
					?.{' '}
					{tmpData.user?.status === 'active'
						? 'If this doctor status is changed to "Inactive", then this doctor cannot login to the MedEasy'
						: 'If this doctor status is changed to "Active", then this doctor can Sign In to MedEasy'}
				</p>
			</Modal>

			<Modal
				title="Delete partner confirmation"
				visible={isModalDeleteVisible}
				onOk={handleClickDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialDoctor);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdate}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete doctor <b>{tmpData.user?.name}</b>?
				</p>
			</Modal>
		</div>
	);
};

export default Doctor;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
