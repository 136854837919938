import { Divider, Space, Tabs, Typography, Switch, Modal, Table } from 'antd';
import React from 'react';
import moment from 'moment';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import { ColumnsType } from 'antd/lib/table';
import SectionContent from '../../components/SectionContent';
import { initialVoucher, VoucherProps } from '../../types/voucher.type';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import HTMLPreview from '../../components/HTMLPreview';
import useFetchList from '../../hooks/useFetchList';
import { EbookProps } from '../../types/ebook.type';
interface IParams {
	voucherId: string;
	voucherEventId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: VoucherProps;
}

interface FetchAllVoucherResponse extends BaseResponseProps {
	payload: {
		results: VoucherProps[];
	};
}

interface fetchAllEBookResponse extends BaseResponseProps {
	payload: EbookProps;
}

const { Text } = Typography;
const { TabPane } = Tabs;

const GenerateVoucherDetail = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { voucherId, voucherEventId } = useParams<IParams>();
	const [tabs, setTabs] = React.useState<string>('1');
	const [isLoadingAction, setIsLoadingAction] = React.useState(false);
	const [tmpDataUpdateActiveVoucher, setTmpDataUpdateActiveVoucher] =
		React.useState<VoucherProps>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [voucher, setVoucher] = React.useState<VoucherProps>(initialVoucher);
	const [generateVoucher, setGenerateVoucher] = React.useState<VoucherProps[]>(
		[],
	);
	const [ebooks, setEbooks] = React.useState<EbookProps[]>([]);

	const dateOptions = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	} as const;

	const timeOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	} as const;
	const fromDate =
		voucher && voucher.validStartAt
			? new Date(voucher.validStartAt).toLocaleDateString(
					'en-US',
					dateOptions,
			  ) +
			  ', ' +
			  new Date(voucher.validStartAt).toLocaleTimeString(
					navigator.language,
					timeOptions,
			  )
			: '';

	const toDate =
		voucher && voucher.validEndAt
			? new Date(voucher.validEndAt).toLocaleDateString('en-US', dateOptions) +
			  ', ' +
			  new Date(voucher.validEndAt).toLocaleTimeString(
					navigator.language,
					timeOptions,
			  )
			: '';

	const handleClickEdit = () => {
		history.push(`/voucher-generate/${voucher.voucherEventId}/edit`);
	};

	const handleClickDetail = (e: VoucherProps) => {
		history.push(`/vouchers-generate/${e.voucherEventId}`);
	};

	const handleClickUpdateActiveVoucher = async () => {
		if (tmpDataUpdateActiveVoucher) {
			const updatedVoucher = {
				...tmpDataUpdateActiveVoucher,
				isPublished: !tmpDataUpdateActiveVoucher.isPublished,
			};
			setIsLoadingAction(true);
			setVoucher((prevVoucher) => ({
				...prevVoucher,
				isPublished: updatedVoucher.isPublished,
			}));

			try {
				await httpRequest.patch(
					'/voucher-generate/' + updatedVoucher.voucherEventId,
					{
						isPublished: updatedVoucher.isPublished,
					},
				);
				setGenerateVoucher((prevVouchers) =>
					prevVouchers.map((voucher) =>
						voucher.voucherEventId === updatedVoucher.voucherEventId
							? { ...voucher, isPublished: updatedVoucher.isPublished }
							: voucher,
					),
				);
			} catch (error) {
				setVoucher((prevVoucher) => ({
					...prevVoucher,
					isPublished: !updatedVoucher.isPublished,
				}));
			} finally {
				setTmpDataUpdateActiveVoucher(undefined);
				setIsLoadingAction(false);
			}
		}
	};

	React.useEffect(() => {
		const fetchVoucherList = async () => {
			try {
				const res = await httpRequest.get<FetchAllVoucherResponse>(
					`/vouchers/generate?voucherEventId=${voucherEventId}`,
				);

				const voucherDetails = res.data.payload.results;
				setGenerateVoucher(voucherDetails);

				const voucherItems = voucherDetails.flatMap(
					(voucher) => voucher.voucherItems || [],
				);

				const ebookItemIds = Array.from(
					new Set(
						voucherItems
							.filter((item) => item.itemType === 'EBOOK')
							.map((item) => item.itemId),
					),
				);

				if (ebookItemIds.length > 0) {
					const ebookRequests = ebookItemIds.map((itemId) =>
						httpRequest.get<fetchAllEBookResponse>(`/ebooks/${itemId}`),
					);
					const ebookResponses = await Promise.all(ebookRequests);
					setEbooks(ebookResponses.map((res) => res.data.payload));
				}
			} catch (error) {
				console.error('Failed to fetch voucher list or eBook data', error);
			}
		};

		fetchVoucherList();
	}, [voucherEventId]);

	React.useEffect(() => {
		const fetchDetailVoucher = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<{
					payload: { results: VoucherProps[] };
				}>(`/voucher-generate?voucherEventId=${voucherEventId}`);

				const voucherDetails = res.data.payload.results[0];

				setVoucher(voucherDetails);

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'voucherCode',
						value: voucherDetails.voucherCode,
						label: voucherDetails.voucherName || '',
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailVoucher();
	}, [voucherEventId]);

	const columns: ColumnsType<VoucherProps> = [
		{
			title: 'VOUCHER NAME',
			key: 'voucherName',
			dataIndex: 'voucherName',
			render: (text: string, record: VoucherProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.voucherName}
					</div>
				);
			},
		},
		{
			title: 'VOUCHER CODE',
			key: 'voucherCode',
			dataIndex: 'voucherCode',
			render: (text: string, record: VoucherProps) => {
				return <div>{record.voucherCode}</div>;
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isActive: any, record: VoucherProps) => (
				<>
					<Switch
						loading={isLoadingAction}
						checked={isActive}
						onChange={() => {
							setTmpDataUpdateActiveVoucher(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'VOUCHER STATUS',
			key: 'voucherStatus',
			dataIndex: 'voucherStatus',
			render: (text: string, record: VoucherProps) => {
				const style: React.CSSProperties = {
					padding: '3px 10px',
					borderRadius: '7px',
					border: '1px solid',
					display: 'inline-block',
					width: '160px',
					textAlign: 'center',
				};

				if (text == 'Available' || text == 'available') {
					return (
						<div
							style={{
								...style,
								color: '#56C288',
								backgroundColor: '#F5FFF9',
								borderColor: '#56C288',
							}}
						>
							{text}
						</div>
					);
				} else if (text == 'Pending' || text == 'pending') {
					return (
						<div
							style={{
								...style,
								color: '#FAB347',
								backgroundColor: '#FFF9EF',
								borderColor: '#FAB347',
							}}
						>
							{text}
						</div>
					);
				} else if (text == 'Expired' || text == 'expired') {
					return (
						<div
							style={{
								...style,
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								borderColor: '#F23459',
							}}
						>
							{text}
						</div>
					);
				} else if (text == 'Used' || text == 'used') {
					return (
						<div
							style={{
								...style,
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								borderColor: '#F23459',
							}}
						>
							{text}
						</div>
					);
				}
			},
		},
		{
			title: 'USED BY',
			key: 'usedBy',
			dataIndex: 'voucherGenerates',
			render: (text: any, record: VoucherProps) => {
				const usedByName =
					record.voucherGenerates?.[0]?.createdByMetadata?.userDetails
						?.metaBuyerDetail?.name || 'N/A';
				return <div>{usedByName}</div>;
			},
		},
		{
			title: 'TRANSACTION CODE',
			key: 'invoiceId',
			dataIndex: 'voucherGenerates',
			render: (text: any, record: VoucherProps) => {
				const invoiceId = record.voucherGenerates?.[0]?.invoiceId || 'N/A';
				return <div>{invoiceId}</div>;
			},
		},
		{
			title: 'CREATED',
			dataIndex: 'validStartAt',
			key: 'validStartAt',
			width: 180,
			render: (val) => <div>{moment(val).format('MMMM DD, YYYY, HH:mm')}</div>,
		},
	];

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Vouchers"
				subtitle="Manage your Vouchers data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<Modal
				title="Confirmation"
				visible={!!tmpDataUpdateActiveVoucher}
				onOk={handleClickUpdateActiveVoucher}
				onCancel={() => {
					setTmpDataUpdateActiveVoucher(undefined);
				}}
				okText="Yes"
				confirmLoading={isLoadingAction}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change voucher status to{' '}
					<b>
						{tmpDataUpdateActiveVoucher?.isPublished
							? 'Unpublished'
							: 'Published'}
					</b>
					?
				</p>
			</Modal>

			<AppCard loading={isLoading}>
				<Tabs
					type="line"
					activeKey={tabs}
					centered
					size="large"
					onChange={(activeKey) => setTabs(activeKey)}
				>
					<TabPane tab="Detail" key="1">
						<SectionContent
							groupTitle="Information"
							helpers={[
								{
									title: 'Information',
									content: 'Enter all required data for the voucher',
								},
							]}
						>
							<DetailItem
								label="Headline"
								content={voucher.voucherName || ''}
							/>
							<DetailItem
								label="Detail Vouchers"
								content={voucher.description || ''}
							/>
							<DetailItem
								label="Status"
								content={voucher.isPublished ? 'Published' : 'Unpublished'}
							/>
							{ebooks.length > 0 && (
								<DetailItem
									label="E-Books"
									content={
										ebooks.map((ebook) => ebook.title).join(', ') || 'No title'
									}
								/>
							)}
						</SectionContent>
						<Divider style={{ width: '100%' }} />
						<SectionContent
							groupTitle="Discount Setting"
							helpers={[
								{
									title: 'Information',
									content:
										'Enter all required details for the voucher code, Make sure the information is accurate to ensure the voucher works',
								},
							]}
						>
							<DetailItem
								label="Discount Code"
								content={voucher.prefix || ''}
							/>
							<DetailItem
								label="Discount Type"
								content={voucher.discountType || ''}
							/>
							<DetailItem
								label="Discount Value"
								content={
									voucher.discountType == 'percentage'
										? voucher.discountValue + ' %' || ''
										: '₱ ' + voucher.discountValue || ''
								}
							/>
							{voucher.discountType == 'percentage' ? (
								<DetailItem
									label="Max Discount"
									content={'₱ ' + voucher.maxDiscountValue || ''}
								/>
							) : (
								''
							)}
							<DetailItem
								label="Usage Limit Per User"
								content={voucher.limitMaxUsePerPerson + ' Usage' || ''}
							/>
							<DetailItem
								label="Minimum Transaction"
								content={
									'₱ ' +
									(voucher.requirement.minimumTransaction !== undefined
										? voucher.requirement.minimumTransaction
										: voucher.requirement || '0')
								}
							/>
							<DetailItem
								label="From"
								content={fromDate.replace('AM', '').replace('PM', '') || ''}
							/>
							<DetailItem
								label="To"
								content={toDate.replace('AM', '').replace('PM', '') || ''}
							/>
							<DetailItem
								label="Quantity"
								content={voucher.totalGenerateVoucher}
							/>
						</SectionContent>
					</TabPane>
					<TabPane tab="Voucher List" key="2">
						<Table
							loading={isLoading}
							columns={columns}
							dataSource={generateVoucher}
							pagination={false}
						/>
					</TabPane>
				</Tabs>
			</AppCard>
		</div>
	);
};

export default GenerateVoucherDetail;
