import {
	Button,
	Card,
	Col,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Space,
	Typography,
	Table,
	Pagination,
} from 'antd';
import React from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import useFetchList from '../../hooks/useFetchList';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import {
	BaseResponsePaginationProps,
	BaseResponseProps,
} from '../../types/config.type';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import {
	FeesHistoryProps,
	FeesProps,
	ReferralFeesProps,
	initialFees,
	initialReferralFees,
} from '../../types/fees.type';
import { replaceUnderscoreWithSpace } from '../../helpers/replaceUnderscoreWithSpace';
import { DeleteOutlined } from '@ant-design/icons';
import { DistanceProps } from '.';
import { generateQueryString } from '../../helpers/generateQueryString';
import { feesDescriptions } from '../../helpers/feesDescription';

interface IParams {
	key: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<FeesProps, 'createdAt' | 'updatedAt'>;
}

const { Text } = Typography;

const FeesDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { key } = useParams<IParams>();
	let tmpKey = key.replace('MEDICALSERVICE', 'MEDICAL_SERVICE');

	const keyArr = key.split('&');

	const configKey =
		key === 'REFERRAL_POINT_DOCTOR'
			? 'REWARD_POINT_REFERRAL_UPLINE_DOCTOR,REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR'
			: key === 'REFERRAL_POINT_PATIENT'
			? 'REWARD_POINT_REFERRAL_UPLINE_PATIENT,REWARD_POINT_REFERRAL_DOWNLINE_PATIENT'
			: key;
	const {
		data: historyData,
		setData: setHistoryData,
		pagination,
		changePage,
		fetchList: historyFetchList,
	} = useFetchList<FeesHistoryProps>({
		endpoint: `app-configs-history`,
		initialQuery: {
			keys: configKey,
		},
	});

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [fees, setFees] = React.useState<FeesProps>(initialFees);
	const [feesReferral, setFeesReferral] = React.useState<FeesProps[]>([]);
	const [selectedReferralFee, setSelectedReferralFee] =
		React.useState<ReferralFeesProps>(initialReferralFees);

	const [isShowModalEdit, setIsShowModalEdit] = React.useState<boolean>(false);
	const [isShowModalEditConsultNow, setIsShowModalEditConsultNow] =
		React.useState<boolean>(false);
	const [isShowModalEditTransportFees, setIsShowModalEditTransportFees] =
		React.useState<boolean>(false);
	const [isShowModalEditReferral, setIsShowModalEditReferral] =
		React.useState<boolean>(false);

	const [transportDistance, setTransportDistance] = React.useState<
		Array<DistanceProps>
	>([
		{
			startAt: 0,
			endAt: 1,
			price: 0,
		},
	]);

	React.useEffect(() => {
		fetchFeesDetail();
		historyFetchList();
	}, [key]);

	const fetchFeesDetail = async () => {
		try {
			setIsLoading(true);

			historyFetchList();

			const res = await httpRequest.get<ResponseProps>(
				'/app-configs/' + configKey,
			);

			setFees(res.data.payload);
			setIsLoading(false);

			if (key === 'REFERRAL_POINT_DOCTOR' || key === 'REFERRAL_POINT_PATIENT') {
				const resReferral = await httpRequest.get<
					BaseResponsePaginationProps<FeesProps>
				>(`/app-configs${generateQueryString({ keys: configKey })}`);

				setFeesReferral(resReferral.data.payload.results);
			}

			if (key === 'MEDICALSERVICE_HOME_TRANSPORT_FEE') {
				const splitter = res.data.payload.value.split('},');
				const transportDist = [];
				for (var i = 0; i < splitter.length; i++) {
					if (i === splitter.length - 1) {
						transportDist.push(JSON.parse(splitter[i]));
					} else {
						const stringBuilder = splitter[i] + '}';
						transportDist.push(JSON.parse(stringBuilder));
					}
				}
				setTransportDistance(transportDist);
			}

			const bcDetails = [
				{
					field: 'key',
					value: replaceUnderscoreWithSpace(tmpKey),
					label: replaceUnderscoreWithSpace(
						res.data.payload.key.replace('MEDICALSERVICE', 'MEDICAL_SERVICE'),
					),
				},
			];
			setBreadcrumbDetails(bcDetails);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const updateFees = async (
		props: Omit<FeesProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoading(true);

			await httpRequest.patch('/app-configs/' + props.key, props);
			message.success(
				'Success update ' +
					replaceUnderscoreWithSpace(
						props.key.replace('MEDICALSERVICE', 'MEDICAL_SERVICE'),
					),
			);

			setIsLoading(false);

			fetchFeesDetail();
			historyFetchList();
		} catch (error) {
			setIsLoading(false);
		}
	};

	const updateReferral = async (props: ReferralFeesProps) => {
		try {
			setIsLoading(true);
			let arrKey =
				props.type === 'DOCTOR'
					? [
							'REWARD_POINT_REFERRAL_UPLINE_DOCTOR',
							'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR',
					  ]
					: [
							'REWARD_POINT_REFERRAL_UPLINE_PATIENT',
							'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT',
					  ];

			for (const key of arrKey) {
				await httpRequest.patch('/app-configs/' + key, {
					value:
						key === `REWARD_POINT_REFERRAL_UPLINE_${props.type}`
							? props.uplinePoint
							: props.downlinePoint,
				});
			}

			message.success('Success update referral ' + props.type.toLowerCase());
			setIsLoading(false);
			fetchFeesDetail();
			setSelectedReferralFee(initialReferralFees);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleClickEdit = async (key: string) => {
		if (key === 'CONSULT_NOW_PRICE') {
			setIsShowModalEditConsultNow(true);
		} else if (key === 'MEDICALSERVICE_HOME_TRANSPORT_FEE') {
			setIsShowModalEditTransportFees(true);
		} else if (key.includes('REFERRAL_POINT')) {
			setIsShowModalEditReferral(true);
			setSelectedReferralFee({
				uplinePoint: feesReferral.find(
					(ref) =>
						ref.key ===
						(key === 'REFERRAL_POINT_DOCTOR'
							? 'REWARD_POINT_REFERRAL_UPLINE_DOCTOR'
							: 'REWARD_POINT_REFERRAL_UPLINE_PATIENT'),
				)?.value!,
				downlinePoint: feesReferral.find(
					(ref) =>
						ref.key ===
						(key === 'REFERRAL_POINT_DOCTOR'
							? 'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR'
							: 'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT'),
				)?.value!,
				type: key === 'REFERRAL_POINT_DOCTOR' ? 'DOCTOR' : 'PATIENT',
			});
		} else {
			setIsShowModalEdit(true);
		}
	};

	const setColumnTitle = (key: string) => {
		if (key === 'CONSULT_NOW_PRICE') {
			return 'PRICE';
		} else if (key === 'MEDICALSERVICE_HOME_TRANSPORT_FEE') {
			return 'FEE PER DISTANCE';
		} else if (key === 'PATIENT_PWD_DISCOUNT') {
			return 'DISCOUNT';
		} else {
			return 'FEE';
		}
	};

	const reformatServiceHomeTransportFee = (distance: string) => {
		const splitter = distance.split('},');
		const transportDist = [];
		for (var i = 0; i < splitter.length; i++) {
			if (i === splitter.length - 1) {
				transportDist.push(JSON.parse(splitter[i]));
			} else {
				const stringBuilder = splitter[i] + '}';
				transportDist.push(JSON.parse(stringBuilder.replace(/^.*?(\{)/, '{')));
			}
		}
		return transportDist as DistanceProps[];
	};

	const columns = [
		{
			title: 'UPDATE DATE',
			dataIndex: 'updateDate',
			key: 'updateDate',
			render: (text: string, record: FeesHistoryProps) => {
				return <Text>{moment(record.updatedAt).format('MMMM DD, YYYY')}</Text>;
			},
		},
		{
			title: setColumnTitle(key),
			dataIndex: 'price',
			key: 'price',
			render: (text: string, record: FeesHistoryProps) => {
				return key !== 'MEDICALSERVICE_HOME_TRANSPORT_FEE' ? (
					// <Text>{record.newData.value}</Text>
					key === 'CONSULT_NOW_PRICE' ? (
						<Text>₱{record.newData.value}</Text>
					) : key.includes('REFERRAL_POINT_') ? (
						<Text>
							{record.newData.key.includes('UPLINE') ? 'Upline' : 'Downline'}{' '}
							{record.newData.value}
						</Text>
					) : (
						<Text>{record.newData.value}</Text>
					)
				) : (
					reformatServiceHomeTransportFee(record.newData.value).map(
						(distance: DistanceProps) => {
							return (
								<Row justify="space-between">
									<Col>
										<Text style={{ fontSize: '12px' }}>
											{`More than ${distance?.startAt || 0} km to ${
												distance.endAt
											} km`}
										</Text>
									</Col>
									<Col style={{ paddingLeft: '10px' }}>
										<Text style={{ fontSize: '12px' }}>₱{distance.price}</Text>
									</Col>
								</Row>
							);
						},
					)
				);
			},
		},
		{
			title: 'UPDATED BY',
			dataIndex: 'updatedBy',
			key: 'updatedBy',
			render: (text: string, record: FeesHistoryProps) => {
				return <Text>{record.metaCreatedByUser.name}</Text>;
			},
		},
		{
			title: 'ROLE',
			dataIndex: 'role',
			key: 'role',
			render: (text: string, record: FeesHistoryProps) => {
				return <Text>Admin</Text>;
			},
		},
	];

	return (
		<div>
			<HeaderSection
				icon="back"
				title={replaceUnderscoreWithSpace(tmpKey)}
				subtitle="Fees data with history"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => {
								handleClickEdit(key);
							}}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<Card
				loading={isLoading}
				style={{
					width: '627px',
				}}
			>
				<Row align="middle">
					<Col span={10}>
						<Row>
							<Text
								strong
								style={{
									fontSize: '16px',
									lineHeight: '150%',
								}}
							>
								{replaceUnderscoreWithSpace(tmpKey)}
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: '12px', color: '#93A1B0' }}>
								{feesDescriptions[key]
									? feesDescriptions[key]
									: 'Eget nunc scelerisque viverra mauris in aliquam sem fringilla. Accumsan lacus vel facilisis volutpat est velit egestas dui id.'}
							</Text>
						</Row>
					</Col>
					{key === 'MEDICALSERVICE_HOME_TRANSPORT_FEE' ? (
						<Col offset={3}>
							{transportDistance.map((distance) => {
								return (
									<Row justify="space-between">
										<Col>
											<Text style={{ fontSize: '12px' }}>
												{`More than ${distance.startAt} km to ${distance.endAt} km`}
											</Text>
										</Col>
										<Col style={{ paddingLeft: '10px' }}>
											<Text style={{ fontSize: '12px' }}>
												₱{distance.price}
											</Text>
										</Col>
									</Row>
								);
							})}
						</Col>
					) : key.includes('REFERRAL_POINT_') ? (
						<Col offset={5}>
							<Text
								style={{
									display: 'block',
								}}
							>
								Upline Point{' '}
								{
									feesReferral.find(
										(ref) =>
											ref.key ===
											(key === 'REFERRAL_POINT_DOCTOR'
												? 'REWARD_POINT_REFERRAL_UPLINE_DOCTOR'
												: 'REWARD_POINT_REFERRAL_UPLINE_PATIENT'),
									)?.value
								}
							</Text>
							<Text
								style={{
									display: 'block',
								}}
							>
								Downline Point{' '}
								{
									feesReferral.find(
										(ref) =>
											ref.key ===
											(key === 'REFERRAL_POINT_DOCTOR'
												? 'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR'
												: 'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT'),
									)?.value
								}
							</Text>
						</Col>
					) : (
						<Col offset={5}>
							<Text
								style={{
									fontWeight: '400',
									fontSize: '36px',
									lineHeight: '42px',
								}}
							>
								{key === 'CONSULT_NOW_PRICE'
									? `₱${fees.value}`
									: `${fees.value}`}
							</Text>
						</Col>
					)}
				</Row>
			</Card>

			<Text strong style={{ fontSize: '16px' }}>
				Change Log
			</Text>

			<Table
				bordered
				loading={isLoading}
				columns={columns}
				dataSource={historyData}
				pagination={false}
				style={{ marginTop: 10 }}
			/>

			{historyData.length !== 0 ? (
				<Pagination
					current={pagination.page}
					total={pagination.totalData}
					defaultPageSize={pagination.perPage}
					pageSize={pagination.perPage}
					pageSizeOptions={['10', '20', '30']}
					showSizeChanger={true}
					showTotal={(total, range) =>
						`${range[0]}-${range[1]} of ${total} items`
					}
					onChange={changePage}
				/>
			) : null}

			{/* ----- modal consult now ----- */}
			<Modal
				title="Consult Now Price"
				visible={isShowModalEditConsultNow}
				footer={null}
				onCancel={() => {
					setIsShowModalEditConsultNow(false);
				}}
			>
				<Text
					style={{
						fontWeight: '400px',
						fontSize: '12px',
						lineHeight: '150%',
						color: '#556575',
					}}
				>
					Consult Now
				</Text>
				<Row>
					<Input
						prefix="₱"
						onChange={(e) => {
							setFees({
								...fees,
								value: e.target.value,
							});
						}}
						defaultValue={fees.value}
					/>
				</Row>
				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEditConsultNow(false);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								updateFees(fees);
								setIsShowModalEditConsultNow(false);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>

			{/* ----- modal edit ----- */}
			<Modal
				title={replaceUnderscoreWithSpace(
					fees.key.replace('MEDICALSERVICE', 'MEDICAL_SERVICE'),
				)}
				visible={isShowModalEdit}
				footer={null}
				onCancel={() => {
					setIsShowModalEdit(false);
				}}
			>
				<Text
					style={{
						fontWeight: '400px',
						fontSize: '12px',
						lineHeight: '150%',
						color: '#556575',
					}}
				>
					Fee Nominal
				</Text>
				<Row>
					<Input
						onChange={(e) => {
							setFees({
								...fees,
								value: e.target.value,
							});
						}}
						defaultValue={fees.value}
					/>
				</Row>
				<Row>
					<Text
						style={{
							fontWeight: '400px',
							fontSize: '10px',
							lineHeight: '150%',
							color: '#556575',
						}}
					>
						{`eg. 10%+50 (10% + 50 peso)`}
					</Text>
				</Row>
				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEdit(false);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								updateFees(fees);
								setIsShowModalEdit(false);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>

			{/* ----- modal transport fees now ----- */}
			<Modal
				title={'Home Service Transport Fees'}
				visible={isShowModalEditTransportFees}
				footer={null}
				onCancel={() => {
					setIsShowModalEditTransportFees(false);
				}}
			>
				<Row justify="space-between" gutter={[8, 8]}>
					<Col span={16}>
						<Text
							style={{
								fontWeight: '400px',
								fontSize: '12px',
								lineHeight: '150%',
								color: '#556575',
							}}
						>
							Distance
						</Text>
					</Col>
					<Col span={5}>
						<Text
							style={{
								fontWeight: '400px',
								fontSize: '12px',
								lineHeight: '150%',
								color: '#556575',
							}}
						>
							Transport Fees
						</Text>
					</Col>
					<Col>
						<Text
							style={{
								fontWeight: '400px',
								fontSize: '12px',
								lineHeight: '150%',
								color: '#556575',
							}}
						>
							Action
						</Text>
					</Col>
				</Row>
				{transportDistance.map((distance, index) => {
					return (
						<Row justify="space-between" style={{ paddingTop: '2%' }}>
							<Col span={8}>
								<Text
									style={{ fontSize: '12px' }}
								>{`More Than ${distance.startAt} KM to`}</Text>
							</Col>

							<Col offset={1}>
								<InputNumber
									min={distance.startAt + 1}
									style={{
										width: '150px',
										height: '36px',
									}}
									disabled={index !== transportDistance.length - 1}
									onChange={(e) => {
										const newDistance = transportDistance;
										newDistance[index].endAt = Number(e);
										setTransportDistance(newDistance);
									}}
									defaultValue={distance.endAt}
								/>
							</Col>

							<Col span={5}>
								<InputNumber
									prefix="₱"
									style={{
										width: '90px',
										height: '36px',
									}}
									onChange={(e) => {
										const newDistance = transportDistance;
										newDistance[index].price = Number(e);
										setTransportDistance(newDistance);
									}}
									defaultValue={distance.price}
								/>
							</Col>

							<Col>
								<Button
									icon={<DeleteOutlined style={{ color: '#D81F64' }} />}
									disabled={transportDistance.length - 1 !== index}
									style={{
										marginLeft: '5px',
									}}
									onClick={() => {
										const newDistance = transportDistance.splice(-1);
										setTransportDistance(newDistance);
									}}
								/>
							</Col>
						</Row>
					);
				})}

				<Row>
					<Col span={24}>
						<Button
							type="primary"
							block
							style={{
								margin: '1rem 0 20px 0',
								borderColor: '#D81F64',
								backgroundColor: 'white',
								color: '#D81F64',
							}}
							onClick={function () {
								setTransportDistance((current) => [
									...current,
									{
										startAt:
											transportDistance[transportDistance.length - 1].endAt,
										endAt:
											transportDistance[transportDistance.length - 1].endAt + 1,
										price: 0,
									},
								]);
							}}
						>
							Add New Distance
						</Button>
					</Col>
				</Row>

				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEditTransportFees(false);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								const transportdistanceString = transportDistance.map(
									(distance) => JSON.stringify(distance),
								);
								const transportfees = {
									...fees,
									value: transportdistanceString.toString(),
								};
								updateFees(transportfees);
								setIsShowModalEditTransportFees(false);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>

			{/* ----- modal referral ----- */}
			<Modal
				title={`Referral ${
					selectedReferralFee.type === 'DOCTOR' ? 'Doctor' : 'Patient'
				}`}
				visible={isShowModalEditReferral}
				footer={null}
				onCancel={() => {
					setIsShowModalEditReferral(false);
					setSelectedReferralFee(initialReferralFees);
				}}
				confirmLoading={isLoading}
			>
				<div>
					<Text
						style={{
							fontWeight: '400px',
							fontSize: '12px',
							lineHeight: '150%',
							color: '#556575',
						}}
					>
						Upline Point
					</Text>
					<Row>
						<Input
							onChange={(e) => {
								setSelectedReferralFee({
									...selectedReferralFee,
									uplinePoint: e.target.value,
								});
							}}
							value={selectedReferralFee.uplinePoint}
						/>{' '}
					</Row>
				</div>

				<div style={{ marginTop: 10 }}>
					<Text
						style={{
							fontWeight: '400px',
							fontSize: '12px',
							lineHeight: '150%',
							color: '#556575',
							marginTop: 12,
						}}
					>
						Downline Point
					</Text>
					<Row>
						<Input
							onChange={(e) => {
								setSelectedReferralFee({
									...selectedReferralFee,
									downlinePoint: e.target.value,
								});
							}}
							value={selectedReferralFee.downlinePoint}
						/>
					</Row>
				</div>

				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEditReferral(false);
								setSelectedReferralFee(initialReferralFees);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								updateReferral(selectedReferralFee);
								setIsShowModalEditReferral(false);
								setSelectedReferralFee(initialReferralFees);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>
		</div>
	);
};

export default FeesDetail;
