export interface NotificationProps {
	notificationId: string;
	title: string;
	message: string;
	senderId: string;
	metaSender?: any;
	isRead: boolean;
	toId: string;
	meta?: any;
	feature: string; //FORUM
	createdAt?: Date;
	updatedAt?: Date;
}

export interface Meta {
	totalSendUser?: number;
	userIds?: string[];
}

export interface NotificationHistoriesProps {
	notificationHistoryId?: string;
	title: string;
	message: string;
	target?: string;
	metaCreatedByUser?: any;
	meta?: Meta;
	createdAt?: Date;
	updatedAt?: Date;
}

export const initialNotificationHistories: NotificationHistoriesProps = {
	title: '',
	message: '',
	target: 'All',
};
