export const medicalServiceIconList = [
	{
		name: 'Allergology',
		label: 'Allergology',
		src: '/specialist-icons/allergology.svg',
	},
	{
		name: 'anesthesiology',
		label: 'Anesthesiology',
		src: '/specialist-icons/anesthesiology.svg',
	},
	{
		name: 'basic_chemistry',
		label: 'Basic Chemistry',
		src: '/medical-service-icons/basic_chemistry.svg',
	},
	{
		name: 'cardiopulmo',
		label: 'Cardiopulmo',
		src: '/medical-service-icons/cardiopulmo.svg',
	},
	{
		name: 'cardiovascular_thoracic_surgery',
		label: 'Cardiovascular Thoracic Surgery',
		src: '/specialist-icons/cardiovascular_thoracic_surgery.svg',
	},
	{
		name: 'clinical_microscopy',
		label: 'Clinical Microscopy',
		src: '/medical-service-icons/clinical_microscopy.svg',
	},
	{
		name: 'covid_19_test',
		label: 'Covid-19 Test',
		src: '/medical-service-icons/covid_19_test.svg',
	},
	{
		name: 'critical_care_intensivist',
		label: 'Critical Care Intensivist',
		src: '/specialist-icons/critical_care_intensivist.svg',
	},
	{
		name: 'drugs_and_pregnant',
		label: 'Drugs And Pregnant',
		src: '/medical-service-icons/drugs_and_pregnant.svg',
	},
	{
		name: 'dental',
		label: 'Dental',
		src: '/specialist-icons/dental.svg',
	},
	{
		name: 'dermatology',
		label: 'Dermatology',
		src: '/specialist-icons/dermatology.svg',
	},
	{
		name: 'diabetology',
		label: 'Diabetology',
		src: '/specialist-icons/diabetology.svg',
	},
	{
		name: 'ear_nose_throat',
		label: 'Ear Nose Throat',
		src: '/specialist-icons/ear_nose_throat.svg',
	},
	{
		name: 'electrolytes',
		label: 'Electrolytes',
		src: '/medical-service-icons/electrolytes.svg',
	},
	{
		name: 'emergency_medicine',
		label: 'Emergency Medicine',
		src: '/specialist-icons/emergency_medicine.svg',
	},
	{
		name: 'family_medicine',
		label: 'Family Medicine',
		src: '/specialist-icons/family_medicine.svg',
	},
	{
		name: 'functional_medicine',
		label: 'Functional Medicine',
		src: '/specialist-icons/functional_medicine.svg',
	},
	{
		name: 'general_clinical_pathology',
		label: 'General Clinical Pathology',
		src: '/specialist-icons/general_clinical_pathology.svg',
	},
	{
		name: 'general_practitioner',
		label: 'General Practitioner',
		src: '/specialist-icons/general_practitioner.svg',
	},
	{
		name: 'general_surgeryr',
		label: 'General Surgery',
		src: '/specialist-icons/general_surgeryr.svg',
	},
	{
		name: 'geriatric_medicine',
		label: 'Geriatric Medicine',
		src: '/specialist-icons/geriatric_medicine.svg',
	},
	{
		name: 'hematology',
		label: 'Hematology',
		src: '/medical-service-icons/hematology.svg',
	},
	{
		name: 'hepatitis',
		label: 'Hepatitis',
		src: '/medical-service-icons/hepatitis.svg',
	},
	{
		name: 'hepatobiliary_surgery',
		label: 'Hepatobiliary Surgery',
		src: '/specialist-icons/hepatobiliary_surgery.svg',
	},
	{
		name: 'histopath',
		label: 'Histopath',
		src: '/medical-service-icons/histopath.svg',
	},
	{
		name: 'hormones',
		label: 'Hormones',
		src: '/medical-service-icons/hormones.svg',
	},
	{
		name: 'infectious_disease',
		label: 'Infectious Disease',
		src: '/specialist-icons/infectious_disease.svg',
	},
	{
		name: 'internal_medicine',
		label: 'Internal Medicine',
		src: '/specialist-icons/internal_medicine.svg',
	},
	{
		name: 'laparoscopic_surgery',
		label: 'Laparoscopic Surgery',
		src: '/specialist-icons/laparoscopic_surgery.svg',
	},
	{
		name: 'microbiology',
		label: 'Microbiology',
		src: '/medical-service-icons/microbiology.svg',
	},
	{
		name: 'neonatology',
		label: 'Neonatology',
		src: '/specialist-icons/neonatology.svg',
	},
	{
		name: 'neurosurgery',
		label: 'Neurosurgery',
		src: '/specialist-icons/neurosurgery.svg',
	},
	{
		name: 'nuclear_medicine',
		label: 'Nuclear Medicine',
		src: '/specialist-icons/nuclear_medicine.svg',
	},
	{
		name: 'nutrition_2',
		label: 'Nutrition 2',
		src: '/specialist-icons/nutrition_2.svg',
	},
	{
		name: 'nutrition',
		label: 'Nutrition',
		src: '/specialist-icons/nutrition.svg',
	},
	{
		name: 'obstetric_gynecology',
		label: 'Obstetric Gynecology',
		src: '/specialist-icons/obstetric_gynecology.svg',
	},
	{
		name: 'occupational_medicine',
		label: 'Occupational Medicine',
		src: '/specialist-icons/occupational_medicine.svg',
	},
	{
		name: 'opthalmology',
		label: 'Opthalmology',
		src: '/specialist-icons/opthalmology.svg',
	},
	{
		name: 'others',
		label: 'Others',
		src: '/medical-service-icons/others.svg',
	},
	{
		name: 'orthopedic_surgery',
		label: 'Orthopedic Surgery',
		src: '/specialist-icons/orthopedic_surgery.svg',
	},
	{
		name: 'pediatrician',
		label: 'Pediatrician',
		src: '/specialist-icons/pediatrician.svg',
	},
	{
		name: 'pediacardio',
		label: 'Pediacardio',
		src: '/medical-service-icons/pediacardio.svg',
	},
	{
		name: 'perinatology',
		label: 'Perinatology',
		src: '/specialist-icons/perinatology.svg',
	},
	{
		name: 'physiotherapist',
		label: 'Physiotherapist',
		src: '/specialist-icons/physiotherapist.svg',
	},
	{
		name: 'plastic_and_reconstructive_surgery',
		label: 'Plastic And Reconstructive Surgery',
		src: '/specialist-icons/plastic_and_reconstructive_surgery.svg',
	},
	{
		name: 'preventive_medicine',
		label: 'Preventive Medicine',
		src: '/specialist-icons/preventive_medicine.svg',
	},
	{
		name: 'psychiatry',
		label: 'Psychiatry',
		src: '/specialist-icons/psychiatry.svg',
	},
	{
		name: 'radiology',
		label: 'Radiology',
		src: '/specialist-icons/radiology.svg',
	},
	{
		name: 'rheumatology',
		label: 'Rheumatology',
		src: '/specialist-icons/rheumatology.svg',
	},
	{
		name: 'serology',
		label: 'Serology',
		src: '/medical-service-icons/serology.svg',
	},
	{
		name: 'sleep_disorder',
		label: 'Sleep Disorder',
		src: '/specialist-icons/sleep_disorder.svg',
	},
	{
		name: 'tumor_markers',
		label: 'Tumor Markers',
		src: '/medical-service-icons/tumor_markers.svg',
	},
	{
		name: 'tyroid_function_test',
		label: 'Tyroid Function Test',
		src: '/medical-service-icons/tyroid_function_test.svg',
	},
	{
		name: 'ultrasound',
		label: 'Ultrasound',
		src: '/medical-service-icons/ultrasound.svg',
	},
	{
		name: 'urology',
		label: 'Urology',
		src: '/specialist-icons/urology.svg',
	},
	{
		name: 'urine_chemistry',
		label: 'Urine Chemistry',
		src: '/medical-service-icons/urine_chemistry.svg',
	},
	{
		name: 'vaccination',
		label: 'Vaccination',
		src: '/specialist-icons/vaccination.svg',
	},
	{
		name: 'vascular',
		label: 'Vascular',
		src: '/medical-service-icons/vascular.svg',
	},
	{
		name: 'xray',
		label: 'Xray',
		src: '/medical-service-icons/xray.svg',
	},
];
