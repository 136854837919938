import React from 'react';
import styled from 'styled-components';

interface Props {
	title?: string;
	subtitle: string;
	status?: 'email' | 'password';
}

const AuthHeaderCard: React.FC<Props> = ({ title, subtitle, status }) => {
	return (
		<AuthWrapperHeader>
			{!status ? (
				<img
					height="40"
					src="/images/logo-app.svg"
					alt="bg-login-qbit-frontend"
				/>
			) : status === 'email' ? (
				<img
					src="/images/send-reset-password-success.svg"
					alt="icon-send-reset-password-success"
				/>
			) : status === 'password' ? (
				<img
					src="/images/reset-password-success.svg"
					alt="icon-reset-password-success"
				/>
			) : (
				false
			)}
			{title && <AuthTitleCard>{title}</AuthTitleCard>}
			<AuthSubTitleCard>{subtitle}</AuthSubTitleCard>
		</AuthWrapperHeader>
	);
};

export const AuthWrapperHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
	margin-bottom: 20px;
`;

export const AuthTitleCard = styled.div`
	font-size: ${({ theme }) => theme.fontSize.title};
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	color: ${({ theme }) => theme.colors.black};
	text-align: center;
	margin-top: 20px;
`;

export const AuthSubTitleCard = styled.div`
	font-size: ${({ theme }) => theme.fontSize.body};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	color: ${({ theme }) => theme.colors.charcoal400};
	text-align: center;
	margin-top: 20px;
`;

export default AuthHeaderCard;
