import { BaseResponseProps } from './config.type';

export interface BannerProps {
	bannerId: string;
	bannerType: string;
	bannerImageUrl?: string;
	title?: string;
	content?: string;
	createdByUserId: string;
	metaCreatedByUser: UserMetadata;
	relatedContentType?: string;
	relatedContentId?: string;
	relatedContentUrl?: string;
	isPublished: boolean;
	order: number;
	target: string[];
	updatedAt: Date;
	createdAt: Date;
}

export interface FetchAllBannerResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: BannerProps[];
	};
}

export const initialBanner: BannerProps = {
	bannerId: '',
	bannerType: '',
	bannerImageUrl: '',
	title: '',
	content: '',
	createdByUserId: '',
	metaCreatedByUser: {
		userId: '',
		userType: '',
		name: '',
	},
	relatedContentType: '',
	relatedContentId: '',
	relatedContentUrl: '',
	isPublished: false,
	order: -1,
	target: [],
	updatedAt: new Date(),
	createdAt: new Date(),
};

export type UserMetadata = {
	userId: string;
	userType: string;
	name: string;
};
