import { AppState } from '../store/index';
import { useDispatch, useSelector } from 'react-redux';
import {
	hideSidebar,
	showSidebar,
	setActiveSubMenu,
} from '../store/actions/config.action';

export default function useConfigApp() {
	const { counterBatch, sidebarCollapsed, activeSubMenu } = useSelector(
		(state: AppState) => state.configApp,
	);
	const dispatch = useDispatch();

	const toggleSidebar = () => {
		if (sidebarCollapsed) {
			dispatch(hideSidebar());
		} else {
			dispatch(setActiveSubMenu(['']));
			dispatch(showSidebar());
		}
	};

	const toggleActiveSideMenu = (data: any | boolean) => {
		if (typeof data === 'boolean' && data === false) {
			dispatch(setActiveSubMenu(['']));
		} else if (typeof data === 'boolean' && data === true) {
			dispatch(setActiveSubMenu(['sub1']));
		} else if (data.length === 2) {
			dispatch(setActiveSubMenu(data || ['sub1']));
		} else {
			dispatch(setActiveSubMenu(data));
		}
	};

	return {
		sidebarCollapsed,
		counterBatch,
		activeSubMenu,
		toggleSidebar,
		toggleActiveSideMenu,
	};
}
