import { Space, Image, Radio } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import {
	HealthServiceCategoryProps,
	initialHealthServiceCategory,
} from '../../types/healthServiceCategory.type';
import { IconProps } from '../../components/IconList';
import { medicalServiceIconList } from '../../components/MedicalServiceIconList';

interface IParams {
	categoryId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<HealthServiceCategoryProps, 'createdAt' | 'updatedAt'>;
}

const HealthServiceCategoryDetail = () => {
	const history = useHistory();
	const { categoryId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [healthServiceCategory, setCategory] =
		React.useState<HealthServiceCategoryProps>(initialHealthServiceCategory);
	const [selectedIcon, setSelectedIcon] = React.useState<IconProps | undefined>(
		undefined,
	);

	const handleClickEdit = () => {
		history.push({
			pathname: `/medical-service-category/${healthServiceCategory.categoryId}/edit`,
			state: {
				categoryId: healthServiceCategory.categoryId,
			},
		});
	};

	const fetchIcon = (iconName: string) => {
		const findIcon = medicalServiceIconList.find(
			(icon) => icon.name === iconName,
		);
		if (findIcon) {
			// const url = '../../' + findIcon.src
			// setImageUrl(url)
			setSelectedIcon(findIcon);
		}
	};

	React.useEffect(() => {
		const fetchCategory = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/health-service/categories/' + categoryId,
				);

				setCategory(res.data.payload);
				if (res.data.payload.icon) {
					fetchIcon(res.data.payload.icon);
				}

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchCategory();
	}, [categoryId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Category Detail"
				subtitle="Manage your category data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Category Information">
					<DetailItem
						label="Name"
						content={healthServiceCategory.categoryName}
					/>
					<DetailItem
						label="Icon"
						content={
							<Space>
								<Image
									width={150}
									height={100}
									src={selectedIcon?.src || '/images/select-image.jpg'}
									fallback={'/images/blur-image.jpeg'}
								/>
							</Space>
						}
					/>
					<DetailItem
						label="Description"
						content={healthServiceCategory.description}
					/>

					<Radio.Group value={healthServiceCategory.isPublished} disabled>
						<Radio value={true}>Active</Radio>
						<Radio value={false}>Non-Active</Radio>
					</Radio.Group>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default HealthServiceCategoryDetail;
