import { MoreOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import {
	Button,
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Switch,
	Table,
	Tabs,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../components/HeaderSection';
import ModalApprovalPwId from '../../components/ModalApprovalPwId';
import PwIdTag from '../../components/PwIdTag';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import { getErrorMessage } from '../../helpers/errorHandler';
import { getFullName } from '../../helpers/name';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import { CustomerProps, initialCustomer } from '../../types/customer.type';
import { EPWIdStatus } from '../../types/patient.type';
import { PatientInRelationProps } from '../../types/patientInRelation.type';
import { capitalizeFirstLetter } from '../../helpers/text';
import useApproval from '../../hooks/useApproval';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const { TabPane } = Tabs;
const { Option } = Select;

const Customer = () => {
	const history = useHistory();

	const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);

	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [isModalApprovalPwIdVisible, setIsModalApprovalPwIdVisible] =
		React.useState<boolean>(false);

	const [selectedData, setSelectedData] = React.useState<
		CustomerProps | PatientInRelationProps
	>();
	const [tmpData, setTmpData] = React.useState<CustomerProps>(initialCustomer);

	const [activeKey, setActiveKey] = React.useState<string>('1');
	const { patientlength, setPatientLength } = useApproval();

	const {
		isLoading: isLoadingPatient,
		data: dataPatient,
		pagination: paginationPatient,
		fetchList: fetchListPatient,
		setData: setDataPatient,
		setSearch: setSearchPatient,
		setQuery: setQueryPatient,
		changePage: changePagePatient,
	} = useFetchList<CustomerProps>({
		endpoint: 'user',
		initialQuery: {
			filterUserType: 'customer',
			patientOnly: true,
		},
		limit: 10,
	});

	const {
		isLoading: isLoadingNeedApprovalPatient,
		data: dataNeedApprovalPatient,
		pagination: paginationNeedApprovalPatient,
		fetchList: fetchListNeedApprovalPatient,
		setData: setDataNeedApprovalPatient,
		setSearch: setSearchNeedApprovalPatient,
		setQuery: setQueryNeedApprovalPatient,
		changePage: changePageNeedApprovalPatient,
	} = useFetchList<CustomerProps>({
		endpoint: 'user',
		initialQuery: {
			filterUserType: 'customer',
			patientOnly: true,
			pwIdStatus: EPWIdStatus.WAITING_APPROVAL,
		},
		limit: 10,
	});

	useEffect(() => {
		setPatientLength(dataNeedApprovalPatient.length);
	}, [dataNeedApprovalPatient]);

	const handleChangeTab = async (key: string) => {
		setActiveKey(key);
	};

	React.useEffect(() => {
		switch (activeKey) {
			case '1':
				fetchListPatient();
				break;
			case '2':
				fetchListNeedApprovalPatient();
				break;
		}
	}, [activeKey]);

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdate(true);

			const newStatus = tmpData.status === 'active' ? 'inactive' : 'active';

			const res = await httpRequest.patch<ResponseProps>(
				'/user/' + tmpData.userId,
				{
					userId: tmpData.userId,
					status: newStatus,
				},
			);

			let newData = activeKey === '1' ? dataPatient : dataNeedApprovalPatient;
			newData = newData?.map((item) => {
				if (item.userId === res.data.payload.userId) {
					return {
						...item,
						status: res.data.payload.status,
						statusLoading: false,
					};
				}
				return item;
			});

			activeKey === '1'
				? setDataPatient(newData)
				: setDataNeedApprovalPatient(newData);

			message.success('Success change ' + tmpData.name + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		} finally {
			setIsModalVisible(false);
			setTmpData(initialCustomer);
			setIsLoadingUpdate(false);
		}
	};

	const handleChangePwIdStatus = (status: string) => {
		if (status !== 'all') {
			setQueryPatient((oldVal) => ({ ...oldVal, pwIdStatus: status }));
		} else {
			setQueryPatient((oldVal) => ({ ...oldVal, pwIdStatus: '' }));
		}
	};

	const handleChangeGender = (status: string) => {
		if (status !== 'all') {
			setQueryPatient((oldVal) => ({ ...oldVal, filterGender: status }));
			setQueryNeedApprovalPatient((oldVal) => ({
				...oldVal,
				filterGender: status,
			}));
		} else {
			setQueryPatient((oldVal) => ({ ...oldVal, filterGender: '' }));
			setQueryNeedApprovalPatient((oldVal) => ({
				...oldVal,
				filterGender: '',
			}));
		}
	};

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQueryPatient((oldVal) => ({ ...oldVal, filterStatus: status }));
			setQueryNeedApprovalPatient((oldVal) => ({
				...oldVal,
				filterStatus: status,
			}));
		} else {
			setQueryPatient((oldVal) => ({ ...oldVal, filterStatus: '' }));
			setQueryNeedApprovalPatient((oldVal) => ({
				...oldVal,
				filterStatus: '',
			}));
		}
	};

	const handleClickDetail = (e: CustomerProps) => {
		history.push(`/patients/${e.userId}`);
	};

	const columns = [
		{
			title: 'FULL NAME',
			render: (record: CustomerProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{getFullName(record) || (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</div>
				);
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phone',
			key: 'phone',
			render: (phone: string) => <Text>+{phone}</Text>,
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			key: 'gender',
			render: (gender: string) => <Text>{capitalizeFirstLetter(gender)}</Text>,
		},
		{
			title: 'BIRTHDATE',
			dataIndex: 'birthdate',
			key: 'birthdate',
			render: (birthdate: Date) =>
				birthdate ? (
					<div>{formatDate(birthdate)}</div>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				),
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: CustomerProps) => (
				<>
					<Switch
						loading={record.statusLoading}
						checked={status === 'active'}
						onChange={() => {
							setIsModalVisible(true);
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: activeKey === '1' ? 'PWD/SENIOR ID STATUS' : 'ACTION',
			render: (record: CustomerProps) =>
				activeKey === '1' ? (
					record.patient?.pwIdUrl && record.patient?.pwIdStatus ? (
						<PwIdTag>{record.patient.pwIdStatus}</PwIdTag>
					) : (
						<Text italic type="secondary">
							Not set
						</Text>
					)
				) : (
					<Button
						onClick={() => {
							setIsModalApprovalPwIdVisible(true);
							setSelectedData(record);
						}}
						style={{
							border: 'none',
							backgroundColor: '#D81F64',
							color: 'white',
						}}
					>
						Approval
					</Button>
				),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: CustomerProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const handleClick = (key: string, record: CustomerProps) => {
		if (key === 'detail') {
			history.push({
				pathname: `/patients/${record.userId}`,
			});
		} else if (key === 'send-email-verification-link') {
			sendEmailVerificationLink(record.email);
		}
	};

	const sendEmailVerificationLink = (email: string) => {
		setIsLoadingUpdate(true);
		httpRequest
			.post('/auth/send-email-verification', {
				platform: 'web',
				email,
			})
			.then((res) => {
				setIsLoadingUpdate(false);
				message.success('Email verification link has been sent successfully');
			})
			.catch((err) => {
				message.success(
					'Failed to send email verification link. ' + getErrorMessage(err),
				);
				setIsLoadingUpdate(false);
			});
	};

	const menu = (record: CustomerProps) => (
		<Menu onClick={(e) => handleClick(e.key, record)}>
			<Menu.Item key="detail">Detail Patient</Menu.Item>

			<Menu.Item
				key="send-email-verification-link"
				disabled={record.isEmailVerified}
			>
				Send Email Verification
			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<UserOutlined />}
				title={'Patient'}
				subtitle={'Manage your patient'}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder={'Search by Name, Phone number or Email'}
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => {
						setSearchPatient(e.target.value);
						setSearchNeedApprovalPatient(e.target.value);
					}}
				/>

				<Select
					size="large"
					disabled={activeKey === '2'}
					style={{ width: 160 }}
					onChange={handleChangePwIdStatus}
					placeholder="PWD Status"
				>
					<Option value="all">All</Option>
					<Option value={EPWIdStatus.APPROVED}>Approved</Option>
					<Option value={EPWIdStatus.REJECTED}>Rejected</Option>
				</Select>

				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeGender}
					placeholder="Gender"
				>
					<Option value="all">All</Option>
					<Option value="male">Male</Option>
					<Option value="female">Female</Option>
				</Select>

				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Non Active</Option>
				</Select>
			</ContainerFilter>

			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					onChange={(e) => handleChangeTab(e)}
				>
					<TabPane tab="All Patient" key={'1'}>
						<Table
							loading={isLoadingPatient}
							columns={columns}
							dataSource={dataPatient}
							pagination={false}
						/>
						{dataPatient.length !== 0 && (
							<>
								<CustomPagination
									current={paginationPatient.page}
									total={paginationPatient.totalData}
									defaultPageSize={paginationPatient.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total}`
									}
									onChange={changePagePatient}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						)}
					</TabPane>

					<TabPane
						tab={
							<Text>
								Patient Need Approval PWD{' '}
								<span
									style={{
										backgroundColor: '#FAB347',
										padding: '5px 10px',
										borderRadius: 50,
										fontWeight: 'bold',
									}}
								>
									{dataNeedApprovalPatient.length}
								</span>
							</Text>
						}
						key={'2'}
					>
						<Table
							loading={isLoadingNeedApprovalPatient}
							columns={columns}
							dataSource={dataNeedApprovalPatient}
							pagination={false}
						/>
						{dataNeedApprovalPatient.length !== 0 && (
							<>
								<CustomPagination
									current={paginationNeedApprovalPatient.page}
									total={paginationNeedApprovalPatient.totalData}
									defaultPageSize={paginationNeedApprovalPatient.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total}`
									}
									onChange={changePageNeedApprovalPatient}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						)}
					</TabPane>
				</Tabs>
			</div>

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialCustomer);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdate}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.name}"</b> status to{' '}
					<b>"{tmpData.status === 'active' ? 'Inactive' : 'Active'}"</b>?.{' '}
					{tmpData.status === 'active'
						? 'If this user status is changed to "Inactive", then this user cannot login to the MedEasy'
						: 'If this user status is changed to "Active", then this user can Sign In to MedEasy'}
				</p>
			</Modal>

			<ModalApprovalPwId
				isVisible={isModalApprovalPwIdVisible}
				setIsVisible={setIsModalApprovalPwIdVisible}
				data={selectedData}
				setSelectedData={setSelectedData}
				fetchList={fetchListNeedApprovalPatient}
			/>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Customer;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
