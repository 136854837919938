import {
	Col,
	Form,
	FormInstance,
	message,
	Radio,
	Row,
	Select,
	Space,
	Image,
	Typography,
	InputNumber,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import SectionContent from '../../components/SectionContent';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { EnvironmentOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
	initialPartnerPackageHealthServicesProps,
	PackageServiceProps,
} from '../../types/packageService.type';
import { initialPartnerIndividualHealthServicesProps } from '../../types/individualService.type';
import DetailItem from '../../components/DetailItem';

interface IParams {
	healthServiceId: string;
	partnerId: string;
	type: string;
}

const { Option } = Select;
const { Text } = Typography;

const PartnerServiceEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { healthServiceId, partnerId, type } = useParams<IParams>();
	console.log(healthServiceId, partnerId, type);
	const formRef =
		React.useRef<
			FormInstance<Omit<PackageServiceProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const [serviceList, setServiceList] = React.useState<any>([]);
	const [serviceDetail, setServiceDetail] = React.useState<any>({});

	const createService = async (
		props: Omit<
			PackageServiceProps,
			'createdAt' | 'updatedAt' | 'healthServiceId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			if (type === 'Individual') {
				const data = {
					healthServiceId: serviceDetail.healthServiceId,
					partnerId: partnerId,
					price: serviceDetail.price,
					setting: serviceDetail.setting,
				};
				const res = await httpRequest.post(
					`/partner-individual-health-services`,
					data,
				);
				if (res) {
					message.success('Success add individual service');
					history.push('/clinic/' + partnerId);
				}
			} else {
				const data = {
					packageHealthServiceId: serviceDetail.healthServiceId,
					partnerId: partnerId,
					price: serviceDetail.price,
					setting: serviceDetail.setting,
				};
				const res = await httpRequest.post(
					`/partner-package-health-services`,
					data,
				);
				if (res) {
					message.success('Success add package service');
					history.push('/clinic/' + partnerId);
				}
			}
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateService = async () => {
		try {
			setIsLoadingAction(true);
			if (type === 'Individual') {
				const res = await httpRequest.patch(
					`/partner-individual-health-services/${healthServiceId}/${partnerId}`,
					serviceDetail,
				);
				if (res) {
					message.success(
						'Success update ' +
							serviceDetail.individualHealthService.healthServiceName +
							' data',
					);
					history.push('/clinic/' + partnerId);
				}
			} else {
				const res = await httpRequest.patch(
					`/partner-package-health-services/${healthServiceId}/${partnerId}`,
					serviceDetail,
				);
				if (res) {
					message.success(
						'Success update ' +
							serviceDetail.packageHealthServices.healthServiceName +
							' data',
					);
					history.push('/clinic/' + partnerId);
				}
			}
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			PackageServiceProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		if (healthServiceId) {
			updateService();
		} else {
			createService(values);
		}
	};

	React.useEffect(() => {
		if (!healthServiceId) {
			const fetchServiceList = async () => {
				try {
					setIsLoading(true);

					let res;
					if (type === 'Individual') {
						res = await httpRequest.get<any>('/health-service/individuals', {
							params: {
								isPublished: '1',
							},
						});
					} else {
						res = await httpRequest.get<any>('/health-service/packages', {
							params: {
								isPublished: '1',
							},
						});
					}

					setServiceList(res.data.payload.results);
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			const fetchPartner = async () => {
				const res = await httpRequest.get<any>('/partners/' + partnerId);
				if (res.data.payload) {
					setServiceDetail({
						...serviceDetail,
						partner: res.data.payload,
					});
				}
			};
			fetchServiceList();
			fetchPartner();
		}
	}, []);

	React.useEffect(() => {
		if (healthServiceId) {
			const fetchServiceDetail = async () => {
				try {
					setIsLoading(true);

					let res;
					if (type === 'Individual') {
						res = await httpRequest.get<any>(
							'/partner-individual-health-services',
							{
								params: {
									healthServiceId: healthServiceId,
									partnerId: partnerId,
								},
							},
						);
					} else {
						res = await httpRequest.get<any>(
							'/partner-package-health-services',
							{
								params: {
									healthServiceId: healthServiceId,
									partnerId: partnerId,
								},
							},
						);
					}

					setServiceDetail(res.data.payload.results?.[0]);

					const bcDetails = [
						{
							field: 'healthServiceId',
							value: healthServiceId,
							label:
								type === 'Individual'
									? res.data.payload.results?.[0]?.individualHealthService
											?.healthServiceName
									: res.data.payload.results?.[0]?.packageHealthServices
											?.healthServiceName,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchServiceDetail();
		}
	}, [healthServiceId]);

	const handleChangeCategories = (value: string) => {
		setServiceDetail((oldVal: any) => {
			return {
				...oldVal,
				healthServiceId: value,
			};
		});
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(healthServiceId ? 'Edit ' : 'Add ') + type + ' Service'}
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="serviceForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={
						healthServiceId
							? serviceDetail
							: type === 'Individual'
							? initialPartnerIndividualHealthServicesProps
							: initialPartnerPackageHealthServicesProps
					}
					autoComplete="off"
				>
					<SectionContent groupTitle={`${type} Service Information`}>
						{healthServiceId ? (
							<>
								<DetailItem
									label="Item Name"
									content={
										type === 'Individual'
											? serviceDetail.individualHealthService?.healthServiceName
											: serviceDetail.packageHealthServices?.healthServiceName
									}
								/>
								<DetailItem
									label="Item Category"
									content={
										type === 'Individual'
											? serviceDetail.individualHealthService?.category
													?.categoryName
											: serviceDetail.packageHealthServices?.group
									}
								/>
								{type === 'Package' && (
									<>
										<DetailItem
											label="Item Description"
											content={serviceDetail.packageHealthServices?.description}
										/>
										<DetailItem
											label="Items"
											content={
												<ul style={{ paddingLeft: 12 }}>
													{serviceDetail.packageHealthServices?.items?.map(
														(item: any) => <li>{item}</li>,
													)}
												</ul>
											}
										/>
									</>
								)}
							</>
						) : (
							<>
								<Row>
									<Text
										style={{ color: '#748394', marginTop: 10, marginBottom: 5 }}
									>
										Home Service Status
									</Text>
								</Row>
								<Row>
									<Select
										allowClear
										style={{ width: '100%' }}
										placeholder="Please select"
										onChange={handleChangeCategories}
										key={'categorySelect'}
										defaultValue={
											serviceList.healthServiceId
												? serviceList.healthServiceId
												: ''
										}
									>
										{serviceList.map((service: any) => {
											return (
												<Option
													key={healthServiceId}
													value={String(service.healthServiceId)}
												>
													{service.healthServiceName}
												</Option>
											);
										})}
									</Select>
								</Row>
							</>
						)}
					</SectionContent>

					<SectionContent groupTitle="Settings">
						<Form.Item label="Price" name="price">
							<InputNumber
								style={{
									width: '100%',
								}}
								prefix="₱"
								min={0}
								defaultValue={serviceDetail.price ? serviceDetail.price : 0}
								onChange={(value) =>
									setServiceDetail({
										...serviceDetail,
										price: Number(value),
									})
								}
							/>
						</Form.Item>

						<Row>
							<Text
								style={{ color: '#748394', marginTop: 10, marginBottom: 5 }}
							>
								Home Service Status
							</Text>
						</Row>
						<Row>
							<Radio.Group
								value={serviceDetail.setting?.availableForHomeService}
								onChange={(e) =>
									setServiceDetail({
										...serviceDetail,
										setting: {
											availableForHomeService: e.target.value,
										},
									})
								}
							>
								<Radio value={true}>Available</Radio>
								<Radio value={false}>Unavailable</Radio>
							</Radio.Group>
						</Row>
					</SectionContent>

					<SectionContent groupTitle="Clinic Information">
						<Row gutter={[16, 16]}>
							<Col>
								<Image
									width={140}
									height={140}
									style={{
										borderRadius: '4px',
									}}
									src={
										serviceDetail.partner?.imageCoverURL ||
										'/images/select-image.jpg'
									}
									fallback={'/images/blur-image.jpeg'}
								/>
							</Col>

							<Col
								style={{
									display: 'flex',
									flex: 1,
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Row>
									<Text
										style={{
											fontWeight: 'bold',
											fontSize: '14px',
											lineHeight: '150%',
										}}
									>
										{serviceDetail.partner?.partnerName}
									</Text>
								</Row>
								<Row style={{ paddingTop: '1%', alignItems: 'center' }}>
									<EnvironmentOutlined style={{ fontSize: '12px' }} />
									<Text
										style={{
											paddingLeft: '1%',
											fontSize: '12px',
											lineHeight: '150%',
										}}
									>
										{serviceDetail.partner?.address}
									</Text>
								</Row>
							</Col>
						</Row>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default PartnerServiceEdit;
