import { Button } from 'antd';
import { AnchorButtonProps, NativeButtonProps } from 'antd/lib/button/button';
import React from 'react';
import styled from 'styled-components';

interface Props extends Partial<AnchorButtonProps & NativeButtonProps> {
	onClick?: () => void;
}

const AppButton: React.FC<Props> = ({ onClick, ...rest }) => {
	const handleOnClick = () => {
		onClick && onClick();
	};

	if (rest.type === 'primary') {
		return <ButtonPrimary onClick={handleOnClick} {...rest} />;
	}

	return <Button {...rest} onClick={handleOnClick} />;
};

export default AppButton;

const ButtonPrimary = styled(Button)`
	background: ${({ theme }) => theme.colors.primary};
	border-color: ${({ theme }) => theme.colors.primary};

	&:hover,
	&:focus {
		background: ${({ theme }) => theme.colors.primary};
		border-color: ${({ theme }) => theme.colors.primary};
		opacity: 0.7;
	}
`;
