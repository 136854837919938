import {
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Space,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import {
	initialAdmin as InitialAdmin,
	CustomerProps,
} from '../../types/customer.type';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { getErrorMessage } from '../../helpers/errorHandler';
import styled from 'styled-components';
import { getFullName } from '../../helpers/name';

interface IParams {
	userId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const AdminEdit = () => {
	const history = useHistory();
	const { userId } = useParams<IParams>();

	const formRef =
		React.useRef<FormInstance<Omit<CustomerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const [customer, setCustomer] = React.useState<CustomerProps>(InitialAdmin);

	const createAdmin = async (
		props: Omit<CustomerProps, 'createdAt' | 'updatedAt' | 'adminId'>,
	) => {
		try {
			setIsLoadingAction(true);

			let name = getFullName(props);

			const formData = {
				name: name,
				email: props.email,
				phone: props.phone,
				userType: 'admin',
				status: props.status,
				isEmailVerified: true,
				isPhoneVerified: true,

				firstName: props.firstName,
				middleName: props.middleName,
				lastName: props.lastName,
				password: props.password,
			};

			await httpRequest.post('/user', formData);

			message.success('Success create ' + getFullName(formData));

			history.push('/admins');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateAdmin = async (
		props: Omit<CustomerProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			let name = getFullName(props);

			const formData = {
				name: name,
				email: props.email,
				phone: props.phone,
				userType: props.userType,
				status: props.status,
				isEmailVerified: true,
				isPhoneVerified: true,

				firstName: props.firstName,
				middleName: props.middleName,
				lastName: props.lastName,
			};

			await httpRequest.patch('/user/' + userId, formData);

			message.success('Success update ' + getFullName(formData));

			history.push('/admins');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<CustomerProps, 'createdAt' | 'updatedAt'>,
	) => {
		if (userId) {
			updateAdmin(values);
		} else {
			createAdmin(values);
		}
	};

	React.useEffect(() => {
		if (userId) {
			const fetchAdmin = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>('/user/' + userId);
					setCustomer(res.data.payload);
				} catch (error) {
					console.log(error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchAdmin();
		}
	}, [userId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(userId ? 'Edit' : 'Add') + ' Admin'}
				subtitle={userId ? 'Manage your admin data' : 'Create new admin'}
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={userId ? customer : InitialAdmin}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="User Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name.',
							},
						]}
					>
						<Form.Item
							label="First Name"
							name="firstName"
							rules={generateFormRules('FirstName', ['required', 'letter-and-space'])}
						>
							<Input placeholder="First Name" />
						</Form.Item>
						<Form.Item
							label="Middle Name"
							name="middleName"
							rules={generateFormRules('MiddleName', ['letter-and-space'])}
						>
							<Input placeholder="Middle Name" />
						</Form.Item>
						<Form.Item
							label="Last Name"
							name="lastName"
							rules={generateFormRules('LastName', ['letter-and-space'])}
						>
							<Input placeholder="Last Name" />
						</Form.Item>

						<Form.Item
							label="Phone Number"
							name="phone"
							rules={generateFormRules('Phone number', [
								// 'required',
								'phoneNumber',
							])}
						>
							<Input
								onChange={(event) =>
									setCustomer({ ...customer, phone: event.target.value })
								}
								placeholder="Input number"
							/>
						</Form.Item>

						<Form.Item
							label="Email"
							name="email"
							rules={generateFormRules('Email', ['required', 'email'])}
						>
							<Input
								onChange={(event) =>
									setCustomer({ ...customer, email: event.target.value })
								}
								placeholder="Input email address"
							/>
						</Form.Item>

						{!userId && (
							<Form.Item
								label="Password"
								name="password"
								rules={generateFormRules('Password', ['required', 'password'])}
							>
								<Input.Password
									onChange={(event) =>
										setCustomer({ ...customer, password: event.target.value })
									}
									placeholder="Input password"
								/>
							</Form.Item>
						)}
					</SectionContent>

					<Divider />

					<SectionContent
						groupTitle="User Settings"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name.',
							},
						]}
					>
						<Form.Item
							label="Status"
							name="status"
							rules={[
								{
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={customer.status || 'active'}>
								<CustomRadio value="active">Active</CustomRadio>
								<CustomRadio value="inactive">Non Active</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default AdminEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
