import { PaymentProps } from './payment.type';
import { TransactionConsultationProps } from './transactionConsultation.type';

export enum ERefundStatus {
	APPROVED = 'APPROVED',
	REJECT = 'REJECT',
	PENDING = 'PENDING',
}

export interface TransactionConsultRefundProps {
	refundId: string;
	transactionConsultationId: string;
	paymentId: string;
	refundPercentage: string;
	refundAmount: number;
	status: ERefundStatus;
	reason?: string;
	receiptImage?: string;
	createdAt: Date;
	updatedAt: Date;
	payment?: PaymentProps;
	transactionConsultation?: TransactionConsultationProps;
}

export const REFUND_STATUS_SETTINGS: any = {
	[ERefundStatus.PENDING]: {
		label: 'Pending',
		color: '#E89635',
	},
	[ERefundStatus.APPROVED]: {
		label: 'Approved',
		color: '#39AC6E',
	},
	[ERefundStatus.REJECT]: {
		label: 'Rejected',
		color: '#D81F64',
	},
};
