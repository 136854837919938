import { BaseResponseProps } from './config.type';
import { TopicProps } from './topic.type';

export interface ArticleProps {
	articleId?: string;
	images?: string[];
	title: string;
	contentText: string;
	targetReaders: string[];
	isFromRSS: boolean;
	rssDetail?: any;
	createdByUserId: string;
	metaCreatedByUser: any;
	isPublished: boolean;
	publishedAt?: Date;
	createdAt?: Date;
	updatedAt?: Date;
	topics: TopicProps[];
	statusLoading?: boolean;
}

export interface FetchAllArticleResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: ArticleProps[];
	};
}

export const initialArticle: ArticleProps = {
	articleId: '',
	images: [],
	title: '',
	contentText: '',
	targetReaders: [],
	isFromRSS: false,
	createdByUserId: '',
	metaCreatedByUser: {},
	isPublished: true,
	topics: [],
};
