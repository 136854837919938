import {
	AppstoreAddOutlined,
	CloudUploadOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
	Switch,
	Table,
	Tag,
	Tooltip,
	Typography,
	Upload,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { read, utils } from 'xlsx';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { getToken } from '../../helpers/auth';
import { IPagination, IQuery } from '../../helpers/pagination';
import useFetchList from '../../hooks/useFetchList';
import { CategoryProps } from '../../types/category.type';
import { BaseResponseProps } from '../../types/config.type';
import { DrugMedicineDosesProps } from '../../types/doses.type';
import { DrugMedicineProps, initialMedicine } from '../../types/medicine.type';
import {
	ERewardPointPatientDoctor,
	RewardProps,
} from '../../types/rewardPointSettings.type';

const { Text, Paragraph } = Typography;
const { Dragger } = Upload;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<DrugMedicineProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

interface ILocation {
	pagination: IPagination;
}

const Medicine = () => {
	const history = useHistory();
	const location = useLocation<ILocation>();

	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] =
		React.useState<DrugMedicineProps>(initialMedicine);
	const [isShowModalImportXlsx, setIsShowModalImportXlsx] =
		React.useState<boolean>(false);
	const [isShowModalPreviewXlsx, setIsShowModalPreviewXlsx] =
		React.useState<boolean>(false);
	const [fileName, setFileName] = React.useState<string>('');
	const [searchBy, setSearchBy] = React.useState<string>('name');
	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);
	const [isError, setIsError] = React.useState<boolean>(false);
	const [fileSize, setFileSize] = React.useState<string>('');
	const [importedData, setImportedData] = React.useState<
		Array<DrugMedicineProps>
	>([]);

	const [search, setSearch] = React.useState<string>();
	const [searchValue] = useDebounce(search, 500);
	const [genericPoint, setGenericPoint] = React.useState(0);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setQuery,
		changePage,
		setIsLoading,
	} = useFetchList<DrugMedicineProps>({
		endpoint: 'drug-medicines',
		limit: 10,
	});

	const { data: allMedicinesList } = useFetchList<DrugMedicineProps>({
		endpoint: 'drug-medicines',
		limit: 100000,
	});

	const { data: dataReward } = useFetchList<RewardProps>({
		endpoint: 'app-configs',
		limit: Object.keys(ERewardPointPatientDoctor).length,
		initialQuery: { keys: Object.keys(ERewardPointPatientDoctor).join(',') },
	});

	React.useEffect(() => {
		dataReward.map((item) => {
			if (
				item.configId ===
				'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_DOCTOR'
			) {
				setGenericPoint(Number(item.value));
			}
		});
	}, [dataReward]);

	React.useEffect(() => {
		if (location.state?.pagination) {
			changePage(
				location.state.pagination.page,
				location.state.pagination.size,
			);
		}
	}, [location]);

	React.useEffect(() => {
		setQuery((e: IQuery) => {
			return {
				...e,
				search: searchValue as string,
				isSearchByCategories: searchBy === 'medicine tag' ? true : false,
				isSearchByPartnerName: searchBy === 'pharmacy name' ? true : false,
			};
		});
	}, [searchValue]);

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.drugMedicineId === tmpData.drugMedicineId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/drug-medicines/' + tmpData.drugMedicineId,
				{
					drugMedicineId: tmpData.drugMedicineId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.drugMedicineId === res.data.payload.drugMedicineId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success(
				'Success change ' + tmpData.drugMedicineName + ' status.',
			);

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialMedicine);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialMedicine);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleCreateMedicine = () => {
		history.push('/medicine/add');
	};

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleClickDetail = (e: DrugMedicineProps) => {
		history.push({
			pathname: `/medicine/${e.drugMedicineId}/detail`,
			state: { pagination: pagination },
		});
	};

	const handleClickAction = (props: DrugMedicineProps, key: string) => {
		if (key === 'detail') {
			history.push({
				pathname: `/medicine/${props.drugMedicineId}/detail`,
				state: { pagination: pagination },
			});
		} else if (key === 'edit') {
			history.push({
				pathname: `/medicine/${props.drugMedicineId}/edit`,
				state: { pagination: pagination },
			});
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		}
	};

	const handleDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.drugMedicineId === tmpData.drugMedicineId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.delete<DeleteResponseProps>(
				'/drug-medicines/' + tmpData.drugMedicineId,
			);

			newData = data.filter(
				(item) => item.drugMedicineId !== tmpData.drugMedicineId,
			);
			setData(newData);

			message.success(
				'Success change ' + tmpData.drugMedicineName + ' status.',
			);

			setIsLoadingDelete(false);
			setIsModalDeleteVisible(false);
			setTmpData(initialMedicine);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialMedicine);
			setIsLoadingDelete(false);
		}
	};

	const handleFileXlsx = async (file: UploadFile<unknown>) => {
		if (file.status === 'uploading') {
			return;
		}

		const newDataImported: DrugMedicineProps[] = [];
		setIsLoadingUpdateStatus(true);
		setIsError(false);

		if (file.originFileObj) {
			const data = await file.originFileObj.arrayBuffer();
			const workbook = read(data);

			if (file.name) {
				setFileSize(`${convertToKb(file.size)}`);
				setFileName(file.name);
			}

			for (const name of workbook.SheetNames) {
				const sheet = workbook.Sheets[name];
				const jsonHeaderData: any[] = utils.sheet_to_json(sheet, { header: 1 });

				if (
					jsonHeaderData?.length !== 14 &&
					![
						'Name',
						'Description',
						'Formulary',
						'Indications',
						'Contraindications',
						'Dosage',
						'Dose',
						'DoseAdjustments',
						'AdverseDrugReactions',
						'Administration',
						'PharmacyName',
						'Points',
						'PregnancyCategory',
						'Categories',
					].every((value) => jsonHeaderData?.[0]?.includes(value))
				) {
					return message.error(
						'The template used does not match to medeasy format',
					);
				}

				const jsonData = utils.sheet_to_json(sheet);

				for (const row of jsonData) {
					const item: any = row;
					const medicineCategories: Array<CategoryProps> = [];

					if (item.Categories) {
						const categoriesXlsx = item.Categories.split(',');
						for (const cat of categoriesXlsx) {
							medicineCategories.push({
								categoryName: cat,
								isPublished: true,
							});
						}
					}

					let newDosage: Array<DrugMedicineDosesProps> = [];
					if (item.Dosage) {
						const splitter = item.Dosage.split(',');
						splitter.map((dosage: string) =>
							newDosage.push({
								drugMedicineId: '',
								dose: dosage.trim(),
								description: '',
							}),
						);
					}

					newDataImported.push({
						drugMedicineName: item.Name?.trim(),
						description: item.Description,
						compositions: item.Formulary,
						indications: item.Indications,
						contraIndications: item.Contraindications,
						dose: item.Dose,
						doseNote: item.DoseAdjustments,
						adverseDrugReactions: item.AdverseDrugReactions,
						administration: item.Administration,
						isPublished: true,
						partnerName: item.PharmacyName?.trim(),
						points: item.Points,
						categories: medicineCategories,
						pregnancyCategory: item.PregnancyCategory,
						doses: newDosage,
					});
				}
			}
			setImportedData(newDataImported);
		}
		setIsLoadingUpdateStatus(false);
		setIsShowModalImportXlsx(false);
		setIsShowModalPreviewXlsx(true);
	};

	const handleDragXlsx = async (fileList: FileList) => {
		const newDataImported: DrugMedicineProps[] = [];
		setIsLoadingUpdateStatus(true);
		setIsError(false);

		if (fileList && fileList.length > 0) {
			const file = fileList[0];
			const fileData = await file.arrayBuffer();
			const workbook = read(fileData);

			if (file.name) {
				setFileSize(`${convertToKb(file.size)}`);
				setFileName(file.name);
			}

			for (const name of workbook.SheetNames) {
				const sheet = workbook.Sheets[name];

				const jsonHeaderData: any[] = utils.sheet_to_json(sheet, { header: 1 });

				if (
					jsonHeaderData?.length !== 14 &&
					![
						'Name',
						'Description',
						'Formulary',
						'Indications',
						'Contraindications',
						'Dosage',
						'Dose',
						'DoseAdjustments',
						'AdverseDrugReactions',
						'Administration',
						'PharmacyName',
						'Points',
						'PregnancyCategory',
						'Categories',
					].every((value) => jsonHeaderData?.[0]?.includes(value))
				) {
					return message.error(
						'The template used does not match to medeasy format',
					);
				}

				const jsonData = utils.sheet_to_json(sheet);

				for (const row of jsonData) {
					const item: any = row;
					const medicineCategories: Array<CategoryProps> = [];

					if (item.Categories) {
						const categoriesXlsx = item.Categories.split(',');
						for (const cat of categoriesXlsx) {
							medicineCategories.push({
								categoryName: cat,
								isPublished: true,
							});
						}
					}

					let newDosage: Array<DrugMedicineDosesProps> = [];
					if (item.Dosage) {
						const splitter = item.Dosage.split(',');
						splitter.map((dosage: string) =>
							newDosage.push({
								drugMedicineId: '',
								dose: dosage.trim(),
								description: '',
							}),
						);
					}

					newDataImported.push({
						drugMedicineName: item.Name?.trim(),
						description: item.Description,
						compositions: item.Formulary,
						indications: item.Indications,
						contraIndications: item.Contraindications,
						dose: item.Dose,
						doseNote: item.DoseAdjustments,
						adverseDrugReactions: item.AdverseDrugReactions,
						administration: item.Administration,
						isPublished: true,
						partnerName: item.PharmacyName?.trim(),
						points: item.Points,
						categories: medicineCategories,
						pregnancyCategory: item.PregnancyCategory,
						doses: newDosage,
					});
				}
			}

			setImportedData(newDataImported);
		}
		setIsLoadingUpdateStatus(false);
		setIsShowModalImportXlsx(false);
		setIsShowModalPreviewXlsx(true);
	};

	const handeSubmitXlsx = async () => {
		await handleUploadImportedData(importedData);
		setIsShowModalPreviewXlsx(false);
		window.location.reload();
	};

	const handleCancelXlsx = async () => {
		setIsShowModalPreviewXlsx(false);
		setImportedData([]);
		setFileName('');
		setFileSize(``);
		setIsShowModalImportXlsx(true);
	};

	function convertToKb(byte: number | undefined) {
		if (byte) {
			return byte / 1000;
		} else {
			return 0;
		}
	}
	const onClickExport = async () => {
		axios({
			url: `drug-medicines/export/xlsx`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + (await getToken()),
			},
			responseType: 'blob',
		})
			.then((res: any) => {
				//          // 1. Create blob link to download
				//   const url = window.URL.createObjectURL(result);
				//   const link = document.createElement("a");
				//   link.href = url;
				//   link.setAttribute("download", `BPOM Register Primer.xlsx`);
				//   // 2. Append to html page
				//   document.body.appendChild(link);
				//   // 3. Force download
				//   link.click();
				//   // 4. Clean up and remove the link
				//   link.parentNode?.removeChild(link);
				if (res && res.data) {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `medeasy-medicine-template.xlsx`);
					document.body.appendChild(link);
					link.click();
					link.parentNode?.removeChild(link);
				}
			})
			.catch((err) => {
				console.error('Error Export xlsx', err);
			});
	};

	const handleUploadImportedData = async (data: DrugMedicineProps[]) => {
		await httpRequest.post('/drug-medicines/bulk', { bulk: data });
	};

	const calculateTotalPoints = (data: any[]) => {
		const pointsMap: { [name: string]: number } = {};

		data.forEach((item) => {
			const { drugMedicineName, medicine } = item;
			const { totalPrescribed } = medicine;
			const trimmedDrugName = drugMedicineName.trim();
			if (pointsMap.hasOwnProperty(trimmedDrugName)) {
				pointsMap[trimmedDrugName] += totalPrescribed;
			} else {
				pointsMap[trimmedDrugName] = totalPrescribed;
			}
		});

		return pointsMap;
	};

	const prescribedMap = calculateTotalPoints(data);

	const columns = [
		{
			title: 'NAME',
			dataIndex: 'drugMedicineName',
			width:'250px',
			key: 'drugMedicineName',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}
					style={{
						wordBreak: 'break-word',  
						whiteSpace: 'normal',    
					}} >
						{record.drugMedicineName}
					</div>
				);
			},
		},
		{
			title: 'DOSAGE',
			dataIndex: 'doses',
			key: 'doses',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.doses && record.doses.length > 0 ? (
							record.doses?.map((item, index) => {
								{
									if (index !== record.doses!.length - 1) {
										return <Text>{`${item.dose}, `}</Text>;
									} else {
										return <Text>{item.dose}</Text>;
									}
								}
							})
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'MEDICINE TAG',
			dataIndex: 'categories',
			width: '120px',
			key: 'categories',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<div style={{ wordBreak: 'break-word', whiteSpace: 'normal', maxWidth: '110px' }}>
						{record.categories.map((item, index) => {
							if (index !== record.categories.length - 1) {
								return <Text key={index}>{`${item.categoryName}, `}</Text>;
							} else {
								return <Text key={index}>{item.categoryName}</Text>;
							}
						})}
					</div>
				);
			},
		},
		{
			title: 'TOTAL PRESCRIBED',
			dataIndex: 'points',
			key: 'points',
			render: (text: string, record: any) => {
				return (
					<Text>
						{record.medicine ? (
							<div>
								{Object.entries(prescribedMap).map(
									([name, totalPrescribed]) =>
										record.drugMedicineName.trim() == name && (
											<p>{totalPrescribed}</p>
										),
								)}
							</div>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'PHARMACY NAME',
			dataIndex: 'pharmacyName',
			width: '100px',
			key: 'pharmacyName',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.partner ? (
							<Text>{record.partner.partnerName}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'POINTS',
			dataIndex: 'points',
			key: 'points',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<Text>
						{record.partner ? (
							record.points ? (
								record.points
							) : (
								<Text italic type="secondary">
									Not set
								</Text>
							)
						) : (
							genericPoint
						)}
					</Text>
				);
			},
		},
		{
			title: 'PUBLISHED',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (status: any, record: DrugMedicineProps) => (
				<>
					{
						<Tooltip
							title={
								record.partner && !record.partner?.isPublished
									? 'Pharmacy status is unpublished'
									: ''
							}
						>
							<Switch
								loading={record.statusLoading}
								checked={record.isPublished}
								onChange={() => {
									setIsModalVisible(true);
									setTmpData(record);
								}}
								disabled={record.partner && !record.partner?.isPublished}
							/>
						</Tooltip>
					}
				</>
			),
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: DrugMedicineProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const previewColumns = [
		{
			title: 'NOTE',
			dataIndex: '',
			key: '',
			width: 130,
			render: (text: string, record: DrugMedicineProps) => {
				const isExist = allMedicinesList.filter(
					(item) =>
						item.drugMedicineName?.trim().toLowerCase() ===
							record.drugMedicineName?.trim().toLowerCase() &&
						item.partner?.partnerName?.trim().toLowerCase() ===
							record.partnerName?.trim().toLowerCase(),
				);
				isExist.length > 0 || (!record.drugMedicineName && setIsError(true));
				return (
					<>
						{isExist.length > 0 && (
							<Tag color="red" style={{ marginBottom: 10 }}>
								Duplicate Data
							</Tag>
						)}
						{!record.drugMedicineName && <Tag color="red">Name Empty</Tag>}
					</>
				);
			},
		},
		{
			title: 'NAME',
			dataIndex: 'drugMedicineName',
			key: 'drugMedicineName',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<Text>
						{record.drugMedicineName ? (
							<Text>{record.drugMedicineName}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'DESCRIPTION',
			dataIndex: 'description',
			key: 'description',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.description ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.description}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'FORMULARY',
			dataIndex: 'compositions',
			key: 'compositions',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.compositions ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.compositions}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'INDICATIONS',
			dataIndex: 'indications',
			key: 'description',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.indications ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.indications}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'CONTRAINDICATIONS',
			dataIndex: 'contraIndications',
			key: 'contraIndications',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.contraIndications ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.contraIndications}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'DOSAGE',
			dataIndex: 'doses',
			key: 'doses',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.doses && record.doses.length > 0 ? (
							<ul style={{ padding: 12 }}>
								{record.doses?.map((item, index) => {
									{
										if (index !== record.doses!.length - 1) {
											return <li>{`${item.dose}, `}</li>;
										} else {
											return <li>{item.dose}</li>;
										}
									}
								})}
							</ul>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'DOSE',
			dataIndex: 'dose',
			key: 'dose',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.dose ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.dose}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'DOSE ADJUSTMENT',
			dataIndex: 'doseNote',
			key: 'doseNote',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.doseNote ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.doseNote}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'ADVERSE DRUG REACTIONS',
			dataIndex: 'adverseDrugReactions',
			key: 'adverseDrugReactions',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.adverseDrugReactions ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.adverseDrugReactions}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'ADMINISTRATION',
			dataIndex: 'administration',
			key: 'administration',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<>
						{record.administration ? (
							<Paragraph
								ellipsis={{ rows: 5, expandable: false, tooltip: true }}
							>
								{record.administration}
							</Paragraph>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'PHARMACY NAME',
			dataIndex: 'partnerName',
			key: 'partnerName',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<Text>
						{record.partnerName ? (
							<Text>{record.partnerName}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'POINTS',
			dataIndex: 'points',
			key: 'points',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<Text>
						{record.points ? (
							record.points
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'PREGNANCY CATEGORY',
			dataIndex: 'pregnancyCategory',
			key: 'pregnancyCategory',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<Text>
						{record.pregnancyCategory ? (
							record.pregnancyCategory
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'CATEGORIES',
			dataIndex: 'categories',
			key: 'categories',
			render: (text: string, record: DrugMedicineProps) => {
				return (
					<Text>
						{record.categories.length > 0 ? (
							record.categories.map((item, index) => {
								{
									if (index !== record.categories.length - 1) {
										return <Text>{`${item.categoryName},`}</Text>;
									} else {
										return <Text>{item.categoryName}</Text>;
									}
								}
							})
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
	];

	const menu = (record: DrugMedicineProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Medicine</Menu.Item>
			<Menu.Item key="detail">Detail Medicine</Menu.Item>
			<Menu.Item key="delete">Delete Medicine</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						icon={<AppstoreAddOutlined />}
						title="Medicine"
						subtitle="Manage your Medicines"
					/>
				</Col>
				<Col flex={1}>
					<Space>
						<AppButton type="primary" onClick={handleCreateMedicine}>
							Add Medicine
						</AppButton>
					</Space>
					<Space
						style={{
							paddingLeft: '2%',
						}}
					>
						<AppButton
							type="primary"
							onClick={() =>
								fileName
									? setIsShowModalPreviewXlsx(true)
									: setIsShowModalImportXlsx(true)
							}
						>
							Import Medicine
						</AppButton>
					</Space>
				</Col>
			</Row>

			<ContainerFilter>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={(value) => setSearchBy(value)}
					placeholder={'Search by'}
				>
					<Option value="name">Name</Option>
					<Option value="medicine tag">Medicine Tag</Option>
					<Option value="pharmacy name">Pharmacy Name</Option>
				</Select>
				<Input
					size="large"
					placeholder={`Search ${searchBy}`}
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Published</Option>
					<Option value="inactive">Unpublished</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialMedicine);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.drugMedicineName}"</b> status
					to <b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
					{!tmpData.isPublished}
				</p>
			</Modal>

			<Modal
				width={580}
				title="Import"
				visible={isShowModalImportXlsx}
				onCancel={() => {
					setIsShowModalImportXlsx(false);
				}}
				footer={null}
			>
				<div>
					<Dragger
						multiple={false}
						onDrop={async (e) => await handleDragXlsx(e.dataTransfer.files)}
						onChange={async (e) => await handleFileXlsx(e.file)}
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						showUploadList={false}
					>
						<p className="ant-upload-drag-icon">
							<CloudUploadOutlined />
						</p>
						<p className="ant-upload-hint">
							Only file with template structure and .xlsx format are supported
						</p>
						<p className="ant-upload-hint">Maximum upload file size is 5 MB</p>
					</Dragger>
					<Row
						justify="space-between"
						align="middle"
						style={{ paddingTop: '2%' }}
					>
						<Col>
							<Button
								onClick={onClickExport}
								style={{
									color: '#D81F64',
									borderColor: '#D81F64',
								}}
							>
								Download Template
							</Button>
						</Col>
						<Col>
							<AppButton type="primary">
								<Upload
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
									showUploadList={false}
									onChange={async (e) => await handleFileXlsx(e.file)}
								>
									<Text
										style={{
											color: 'white',
										}}
									>
										Browse File
									</Text>
								</Upload>
							</AppButton>
						</Col>
					</Row>
				</div>
			</Modal>
			<Modal
				width={'95%'}
				style={{ marginTop: -50 }}
				title="Preview"
				visible={isShowModalPreviewXlsx}
				onCancel={() => {
					setIsShowModalPreviewXlsx(false);
				}}
				footer={null}
			>
				<div>
					<Table
						loading={isLoading}
						columns={previewColumns}
						dataSource={importedData}
						pagination={false}
						sticky={true}
						scroll={{ y: 550 }}
						size={'middle'}
					/>
					<Row
						justify="space-between"
						align="middle"
						style={{ paddingTop: '2%' }}
					>
						<Col>
							<Button
								onClick={handleCancelXlsx}
								style={{
									color: '#D81F64',
									borderColor: '#D81F64',
									width: '225px',
									height: '36px',
								}}
							>
								Cancel
							</Button>
						</Col>
						<Col>
							<AppButton
								disabled={isError}
								type="primary"
								onClick={handeSubmitXlsx}
								style={{
									width: '225px',
									height: '36px',
								}}
							>
								Submit
							</AppButton>
						</Col>
					</Row>
				</div>
			</Modal>

			<Modal
				title="Delete medicine confirmation"
				visible={isModalDeleteVisible}
				onOk={handleDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialMedicine);
				}}
				okText="Yes"
				confirmLoading={isLoadingDelete}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete medicine <b>{tmpData.drugMedicineName}</b>
					?
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Medicine;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
