export const feesDescriptions: { [key: string]: string } = {
	CONSULT_NOW_PRICE:
		'Consult with a doctor in _ minutes or less for your urgent needs, by chat or telemedicine.',
	TELEMEDICINE_EXTEND:
		'Extension time fees for online consultations that exceed the initial _ minute duration. A flat fee will be applicable for additional _ minute beyond the allotted time. Kindly be aware of the time constraints to optimize your consultation experience.',
	DOCTOR_COMMISSION_FEE:
		'There will be a [percentage fee] commission on consultations and services provided through our platform. This fees is in line with our commitment to enhancing the platform and ensuring its sustainability.',
	DOCTOR_TRANSACTION_FEE:
		'For every payment we receive on behalf of the doctor, a percentage of that goes to the payment providers. We also incur costs in transferring these payouts to your bank account.',
	DOCTOR_DOCTOR_DOCUMENT_FEE:
		'In the event that the patient requests more service, the doctor has the option to ask for an extra fee or provide it free of charge. If there is a transaction fee, the cost will be shouldered by the doctor.',
	PATIENT_TRANSACTION_FEE:
		'For every payment we receive on behalf of the doctor, a percentage of that goes to the payment providers.',
	MEDICALSERVICE_PATIENT_TRANSACTION_FEE:
		'For every payment we receive on behalf of the partner, a percentage of that goes to the payment providers. We also incur costs in transferring these payouts to partner bank account.',
	MEDICALSERVICE_LAB_TRANSACTION_FEE:
		'For every payment we receive on behalf of the partner, a percentage of that goes to the payment providers. We also incur costs in transferring these payouts to your bank account.',
	MEDICALSERVICE_LAB_COMISSION_FEE:
		'There will be a [percentage fee] commission on consultations and services provided through our platform. This fees is in line with our commitment to enhancing the platform and ensuring its sustainability.',
	MEDICALSERVICE_HOME_TRANSPORT_FEE:
		'In the event of home service, we incur an additional transportation fee based on the distance.',
	PATIENT_PWD_DISCOUNT:
		'As per the law, we are required to provide discounts to senior citizens and persons with disabilities (PWD) on our products/services.',
	REFERRAL_POINT_DOCTOR:
		'Referral points serve as a monitoring and rewarding system for individuals who refer our app to others. This encourages our existing customers or users to actively promote our offerings.',
	REFERRAL_POINT_PATIENT:
		'Referral points serve as a monitoring and rewarding system for individuals who refer our app to others. This encourages our existing customers or users to actively promote our offerings.',
};
