import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	AppstoreAddOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Pagination,
	Space,
	Table,
	Switch,
	Dropdown,
	Menu,
	Modal,
	message,
	Input,
	Select,
	Image,
	Tag,
} from 'antd';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import { formatDate } from '../../helpers/constant';
import { initialProduct, ProductProps } from '../../types/product.type';
import { CategoryProps } from '../../types/category.type';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<ProductProps, 'createdAt' | 'updatedAt'>;
}

const Product = () => {
	const history = useHistory();

	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<ProductProps>(initialProduct);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<ProductProps>({
		endpoint: 'products',
		limit: 10,
	});

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.productId === tmpData.productId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/products/' + tmpData.productId,
				{
					productId: tmpData.productId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.productId === res.data.payload.productId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.productName + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialProduct);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialProduct);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleCreateMenu = () => {
		history.push('/product/add');
	};

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleClickDetail = (e: ProductProps) => {
		history.push(`/product/${e.productId}/detail`);
	};

	const handleClickAction = (props: ProductProps, key: string) => {
		if (key === 'detail') {
			history.push(`/product/${props.productId}/detail`);
		} else if (key === 'edit') {
			history.push(`/product/${props.productId}/edit`);
		}
	};

	const columns = [
		{
			title: 'Image',
			dataIndex: 'imageUrls',
			key: 'imageUrls',
			render: (images: ProductProps['imageUrls']) => {
				return (
					<Image
						width={120}
						height={100}
						src={images ? images[0] : '/images/blur-image.jpeg'}
						// images && images.length > 0
						//   ? images[0] || '/images/blur-image.jpeg'
						//   : '/images/blur-image.jpeg'
						// }
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				);
			},
		},
		{
			title: 'Name',
			dataIndex: 'productName',
			key: 'productName',
			render: (text: string, record: ProductProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'Categories',
			key: 'categories',
			dataIndex: 'categories',
			render: (status: any, record: ProductProps) => (
				<>
					{record.categories
						? sortCategories(record.categories).map((element) => {
								return <Tag>{element.categoryName}</Tag>;
						  })
						: []}
				</>
			),
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: ProductProps) => (
				<>
					{
						<Switch
							loading={record.statusLoading}
							checked={record.isPublished}
							onChange={() => {
								setIsModalVisible(true);
								setTmpData(record);
							}}
						/>
					}
				</>
			),
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: ProductProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: ProductProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Product</Menu.Item>
			<Menu.Item key="detail">Detail Product</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Product"
				subtitle="Manage your Products"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateMenu}>
							Add Product
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Product Name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Inactive</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			<Pagination
				current={pagination.page}
				total={pagination.totalData}
				defaultPageSize={pagination.perPage}
				pageSizeOptions={['10', '20', '30']}
				showSizeChanger={true}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				onChange={changePage}
			/>

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialProduct);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.productName}"</b> status to{' '}
					<b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
					{!tmpData.isPublished}
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Product;

function sortCategories(categories: CategoryProps[]) {
	categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
	return categories;
}
