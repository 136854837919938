import { AxiosInstance } from 'axios';
import React from 'react';
import { useDebounce } from 'use-debounce';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import {
	IHttpResponse,
	INITIAL_PAGINATION,
	INITIAL_QUERY,
	IPagination,
	IPayloadPagination,
	IQuery,
} from '../helpers/pagination';

type Props = {
	endpoint: string;
	initialQuery?: Object;
	limit?: number;
	pushData?: boolean;
	apiRequest?: AxiosInstance;
};

const DEFAULT_LIMIT = 25;

export default function useFetchList<DataType>(props: Props) {
	const apiRequest: AxiosInstance = props.apiRequest || httpRequest;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [data, setData] = React.useState<Array<DataType>>([]);
	const [pagination, setPagination] = React.useState<IPagination>({
		...INITIAL_PAGINATION,
	});
	const [query, setQuery] = React.useState<IQuery>({
		...INITIAL_QUERY,
		limit: props.limit || DEFAULT_LIMIT,
		...props.initialQuery,
	});

	const [search, setSearch] = React.useState<string>();
	const [searchValue] = useDebounce(search, 500);

	const fetchList = async (newQuery?: any) => {
		try {
			// console.log('query : ', query);
			setIsLoading(true);

			let objectQuery = {
				...query,
			};

			if (newQuery) {
				objectQuery = {
					...objectQuery,
					...newQuery,
				};
			}

			if (objectQuery?.search) {
				objectQuery.search = encodeURIComponent(
					(objectQuery.search as string).trim(),
				).replace(/(_|%|\\)/g, '\\$1');
			}

			const res = await apiRequest.get<
				IHttpResponse<IPayloadPagination<DataType>>
			>(`${props.endpoint}${generateQueryString(objectQuery)}`);

			if (res?.data?.payload) {
				setPagination((oldVal) => {
					return {
						...oldVal,
						perPage: props.limit || DEFAULT_LIMIT,
						prev: res.data.payload.prev,
						next: res.data.payload.next,
						totalData: res.data.payload.count,
						countPage: Math.ceil(
							res.data.payload.count / (props.limit || DEFAULT_LIMIT),
						),
					};
				});

				if (props.pushData) {
					setData((value) => [...value, ...res.data.payload.results]);
				} else {
					setData(res.data.payload.results);
				}
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchList();
		console.log('ini useEffect fetchlist');
	}, [query]);

	React.useEffect(() => {
		setQuery((e: IQuery) => {
			return { ...e, search: searchValue as string };
		});
	}, [searchValue]);

	const changePage = (page: any, size?: number) => {
		setPagination((oldVal) => {
			return {
				...oldVal,
				page,
				size,
			};
		});
		setQuery((oldVal) => {
			return {
				...oldVal,
				offset: (page - 1) * (size || DEFAULT_LIMIT),
				limit: size,
			};
		});
	};

	const changeLimit = (current: number, perPage: number) => {
		console.log('change limit per page : ' + perPage);
		setPagination((oldVal) => {
			return {
				...oldVal,
				perPage,
			};
		});

		setQuery((oldVal) => {
			return {
				...oldVal,
				limit: perPage,
				offset: 0,
			};
		});
	};

	return {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		query,
		setData,
		setPagination,
		setQuery,
		setSearch,
		changePage,
		fetchList,
		setIsLoading,
		changeLimit,
	};
}
