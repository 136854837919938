import { MoreOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import { CustomerProps, initialCustomer } from '../../types/customer.type';
import { getFullName } from '../../helpers/name';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const Admin = () => {
	const history = useHistory();

	const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

	const [tmpData, setTmpData] = React.useState<CustomerProps>(initialCustomer);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<CustomerProps>({
		endpoint: 'user',
		initialQuery: {
			filterUserType: 'admin',
		},
		limit: 10,
	});

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdate(true);
			let newData = [];

			const newStatus = tmpData.status === 'active' ? 'inactive' : 'active';

			const res = await httpRequest.patch<ResponseProps>(
				'/user/' + tmpData.userId,
				{
					userId: tmpData.userId,
					status: newStatus,
				},
			);

			newData = data.map((item) => {
				if (item.userId === res.data.payload.userId) {
					return {
						...item,
						status: res.data.payload.status,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.name + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		} finally {
			setIsModalVisible(false);
			setTmpData(initialCustomer);
			setIsLoadingUpdate(false);
		}
	};

	const handleClickDetail = (e: CustomerProps) => {
		history.push(`/admins/${e.userId}`);
	};

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, filterStatus: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterStatus: '' }));
		}
	};

	const handleCreateUser = () => {
		history.push({ pathname: '/admins/add' });
	};

	const columns = [
		{
			title: 'FULL NAME',
			render: (record: CustomerProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{getFullName(record)}
					</div>
				);
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phone',
			key: 'phone',
			render: (phone: string) =>
				phone ? (
					<Text>+{phone}</Text>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				),
		},
		{
			title: 'EMAIL ADDRESS',
			dataIndex: 'email',
			key: 'email',
			render: (email: string) => <Text>{email}</Text>,
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: CustomerProps) => (
				<>
					<Switch
						loading={isLoadingUpdate}
						checked={status === 'active'}
						onChange={() => {
							setIsModalVisible(true);
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: CustomerProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const handleClick = (key: string, record: CustomerProps) => {
		if (key === 'edit') {
			history.push({
				pathname: `/admins/${record.userId}/edit`,
			});
		} else if (key === 'change-password') {
			history.push({
				pathname: `/admins/${record.userId}/change-password`,
			});
		} else if (key === 'send-email-forgot-password') {
			//
			sendEmailForgotPasswordLink(record.email);
		}
	};

	const sendEmailForgotPasswordLink = (email: string) => {
		setIsLoadingUpdate(true);
		httpRequest
			.post('/auth/forgot-password/confirmation-link', {
				email,
			})
			.then((res) => {
				message.success('Link reset password has been sent successfully');
			})
			.catch((err) => {
				message.error(
					'Failed to send link reset password. ' + getErrorMessage(err),
				);
			})
			.finally(() => {
				setIsLoadingUpdate(false);
			});
	};

	const menu = (record: CustomerProps) => (
		<Menu onClick={(e) => handleClick(e.key, record)}>
			<Menu.Item key="edit">Edit Admin</Menu.Item>
			<Menu.Item key="change-password">Change Password</Menu.Item>

			<Menu.Item key="send-email-forgot-password">
				Send Link Reset Password
			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<UserOutlined />}
				title={'Admin'}
				subtitle={'Manage your admin'}
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateUser}>
							Add Admin
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Name, Phone number or Email"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>

				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Non Active</Option>
				</Select>
			</ContainerFilter>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
			{data.length !== 0 ? (
				<CustomPagination
					current={pagination.page}
					total={pagination.totalData}
					defaultPageSize={pagination.perPage}
					pageSizeOptions={['25', '50', '100']}
					showSizeChanger={true}
					showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total}`}
					onChange={changePage}
					locale={{ items_per_page: '' }}
					responsive={true}
				/>
			) : null}

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialCustomer);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdate}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.name}"</b> status to{' '}
					<b>"{tmpData.status === 'active' ? 'Inactive' : 'Active'}"</b>?.{' '}
					{tmpData.status === 'active'
						? 'If this user status is changed to "Inactive", then this user cannot login to the MedEasy'
						: 'If this user status is changed to "Active", then this user can Sign In to MedEasy'}
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Admin;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;
