import React from 'react';
import { Col, Image, Row } from 'antd';
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';

interface DetailItemProps {
	type?: 'image' | string;
	label: string | any;
	content: string | any;
	disabled?: boolean;
}

const DetailItem: React.FC<DetailItemProps> = ({ type, label, content, disabled }) => {
	return (
		<ContentWrapper>
			{Array.isArray(label) ? (
				<Row>
					<Col span={12}>
						<Label>{label[0]}</Label>
					</Col>
					<Col span={12}>
						<Label>{label[1]}</Label>

					</Col>
				</Row>
			):(
				<Label>{label}</Label>
			)}
			<Content disabled={disabled}>
				{type === 'image' ? (
					<Image
						preview={false}
						height={200}
						src={content || '/images/select-image.jpg'}
						fallback={'/images/blur-image.jpeg'}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={137}
								height={132}
							/>
						}
					/>
				) : content ? (
					content
				) : (
					<Text
						style={{ color: 'darkgrey', fontStyle: 'italic', fontSize: 12 }}
					>
						Not set
					</Text>
				)}
			</Content>
		</ContentWrapper>
	);
};

const ContentWrapper = styled.div`
	margin-bottom: 15px;
`;

const Label = styled.div`
	font-size: ${({ theme }) => theme.fontSize.body};
	font-family: 'Roboto', sans-serif;
	color: ${({ theme }) => theme.colors.charcoal300};
`;

const Content = styled.div<{ disabled?: boolean }>`
	font-size: ${({ theme }) => theme.fontSize.body};
	font-family: 'Roboto', sans-serif;
	color: ${({ theme }) => theme.colors.black};
	${({ disabled }) => disabled && `
		color: darkgrey;
		font-style: italic;
	`}
`;

export default DetailItem;
