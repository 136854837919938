import React from 'react';
import styled from 'styled-components';
import { isPublisherMode } from '../../helpers/cmsMode';
import { Card } from 'antd';

const AuthLayout: React.FC = ({ children }) => {
	return (
		<Container isPublisherMode={isPublisherMode()}>
			{isPublisherMode() ? (
				<>
					<ContentRight>{children}</ContentRight>
					<StyledCard>
						<CardContent>
							<img src="/images/logo-app.svg" alt="MedEasy Logo" />
							<TextWrapper>
								<Title>MedEasy for publisher E-Book</Title>
								<Subtitle>
									Empower yourself with the information in reading e-book to
									ensure a brighter future.
								</Subtitle>
							</TextWrapper>
						</CardContent>
					</StyledCard>
				</>
			) : (
				<>
					<ContentLeft />
					<ContentRight>{children}</ContentRight>
				</>
			)}
		</Container>
	);
};

const Container = styled.div<{ isPublisherMode: boolean }>`
	width: 100%;
	height: 100vh;
	display: grid;
	grid-template-columns: ${({ isPublisherMode }) =>
		isPublisherMode ? '1fr 35%' : '35% 1fr'};
`;

const ContentLeft = styled.div`
	background-image: url('/images/bg-login.jpg');
	background-repeat: no-repeat;
	background-size: cover;
`;

const ContentRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.ash400};
`;

const StyledCard = styled(Card)`
	background-color: #1d2b36;
	border-radius: 12px;
`;

const CardContent = styled.div`
	padding: 20px;
	color: ${({ theme }) => theme.colors.white};
`;

const TextWrapper = styled.div`
	position: absolute;
	bottom: 33px;
	left: 12%;
	right: 15%;
`;

const Title = styled.div`
	font-size: 33px;
	font-weight: bold;
	line-height: normal;
	white-space: normal;
	margin-bottom: 10px;
`;

const Subtitle = styled.div`
	font-size: 14px;
`;

export default AuthLayout;
