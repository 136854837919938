import { Divider, Space, Tabs, Typography, Switch, Table } from 'antd';
import React from 'react';
import moment from 'moment';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import { ColumnsType } from 'antd/lib/table';
import SectionContent from '../../components/SectionContent';
import { initialVoucher, VoucherProps } from '../../types/voucher.type';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import HTMLPreview from '../../components/HTMLPreview';
import { EbookProps } from '../../types/ebook.type';
interface IParams {
	voucherId: string;
	voucherEventId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: VoucherProps;
}

interface FetchAllVoucherResponse extends BaseResponseProps {
	payload: {
		results: VoucherProps[];
	};
}
interface fetchAllEBookResponse extends BaseResponseProps {
	payload: EbookProps;
}

const { Text } = Typography;
const { TabPane } = Tabs;

const GenerateVouchersDetail = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { voucherId, voucherEventId } = useParams<IParams>();
	const [tabs, setTabs] = React.useState<string>('1');
	const [isLoadingAction, setIsLoadingAction] = React.useState(false);
	const [tmpDataUpdateActiveVoucher, setTmpDataUpdateActiveVoucher] =
		React.useState<VoucherProps>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [voucher, setVoucher] = React.useState<VoucherProps>(initialVoucher);
	const [generateVoucher, setGenerateVoucher] = React.useState<VoucherProps[]>(
		[],
	);
	const [ebooks, setEbooks] = React.useState<EbookProps[]>([]);

	const dateOptions = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	} as const;

	const timeOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	} as const;
	const fromDate =
		voucher && voucher.validStartAt
			? new Date(voucher.validStartAt).toLocaleDateString(
					'en-US',
					dateOptions,
			  ) +
			  ', ' +
			  new Date(voucher.validStartAt).toLocaleTimeString(
					navigator.language,
					timeOptions,
			  )
			: '';

	const toDate =
		voucher && voucher.validEndAt
			? new Date(voucher.validEndAt).toLocaleDateString('en-US', dateOptions) +
			  ', ' +
			  new Date(voucher.validEndAt).toLocaleTimeString(
					navigator.language,
					timeOptions,
			  )
			: '';

	const handleClickEdit = () => {
		history.push(`/voucher-generate/${voucher.voucherEventId}/edit`);
	};

	const handleClickUpdateActiveVoucher = async () => {
		if (tmpDataUpdateActiveVoucher) {
			setIsLoadingAction(true);
			await httpRequest.patch(
				'/voucher-generate/' + tmpDataUpdateActiveVoucher.voucherId,
				{
					isPublished: tmpDataUpdateActiveVoucher.isPublished ? false : true,
				},
			);
			setTmpDataUpdateActiveVoucher(undefined);
			setIsLoadingAction(false);

			// setQuery((prevQuery) => ({
			// 	...prevQuery,
			// 	page: pagination.page,
			// }));
		}
	};
	React.useEffect(() => {
		const fetchVoucherList = async () => {
			try {
				const res = await httpRequest.get<FetchAllVoucherResponse>(
					`/vouchers?voucherEventId=${voucherEventId}`,
				);

				const voucherDetails = res.data.payload.results;
				setGenerateVoucher(voucherDetails);

				const voucherItems = voucherDetails.flatMap(
					(voucher) => voucher.voucherItems || [],
				);

				const ebookItemIds = Array.from(
					new Set(
						voucherItems
							.filter((item) => item.itemType === 'EBOOK')
							.map((item) => item.itemId),
					),
				);

				if (ebookItemIds.length > 0) {
					const ebookRequests = ebookItemIds.map((itemId) =>
						httpRequest.get<fetchAllEBookResponse>(`/ebooks/${itemId}`),
					);
					const ebookResponses = await Promise.all(ebookRequests);
					setEbooks(ebookResponses.map((res) => res.data.payload));
				}
			} catch (error) {
				console.error('Failed to fetch voucher list or eBook data', error);
			}
		};

		fetchVoucherList();
	}, [voucherEventId]);

	React.useEffect(() => {
		const fetchDetailVoucher = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<{
					payload: { results: VoucherProps[] };
				}>(`/voucher-generate?voucherEventId=${voucherEventId}`);

				const voucherDetails = res.data.payload.results[0];

				setVoucher(voucherDetails);

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'voucherCode',
						value: voucherDetails.voucherCode,
						label: voucherDetails.voucherName || '',
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailVoucher();
	}, [voucherEventId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Vouchers"
				subtitle="Manage your Vouchers data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="Information"
					helpers={[
						{
							title: 'Information',
							content: 'Enter all required data for the voucher',
						},
					]}
				>
					<DetailItem label="Headline" content={voucher.voucherName || ''} />
					<DetailItem
						label="Detail Vouchers"
						content={voucher.description || ''}
					/>
					<DetailItem
						label="Status"
						content={voucher.isPublished ? 'Published' : 'Unpublished'}
					/>
					{ebooks.length > 0 && (
						<DetailItem
							label="E-Books"
							content={
								ebooks.map((ebook) => ebook.title).join(', ') || 'No title'
							}
						/>
					)}
				</SectionContent>
				<Divider style={{ width: '100%' }} />
				<SectionContent
					groupTitle="Discount Setting"
					helpers={[
						{
							title: 'Information',
							content:
								'Enter all required details for the voucher code, Make sure the information is accurate to ensure the voucher works',
						},
					]}
				>
					<DetailItem label="Discount Code" content={voucher.prefix || ''} />
					<DetailItem
						label="Discount Type"
						content={voucher.discountType || ''}
					/>
					<DetailItem
						label="Discount Value"
						content={
							voucher.discountType == 'percentage'
								? voucher.discountValue + ' %' || ''
								: '₱ ' + voucher.discountValue || ''
						}
					/>
					{voucher.discountType == 'percentage' ? (
						<DetailItem
							label="Max Discount"
							content={'₱ ' + voucher.maxDiscountValue || ''}
						/>
					) : (
						''
					)}
					<DetailItem
						label="Usage Limit Per User"
						content={voucher.limitMaxUsePerPerson + ' Usage' || ''}
					/>
					<DetailItem
						label="Minimum Transaction"
						content={
							'₱ ' +
							(voucher.requirement.minimumTransaction !== undefined
								? voucher.requirement.minimumTransaction
								: voucher.requirement || '0')
						}
					/>
					<DetailItem
						label="From"
						content={fromDate.replace('AM', '').replace('PM', '') || ''}
					/>
					<DetailItem
						label="To"
						content={toDate.replace('AM', '').replace('PM', '') || ''}
					/>
					<DetailItem label="Quantity" content={voucher.totalGenerateVoucher} />
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default GenerateVouchersDetail;
