import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { EventTagProps, initialEventTag } from '../../types/eventTag.type';
import { useDebounce } from 'use-debounce';
import useFetchList from '../../hooks/useFetchList';

interface IParams {
	tagId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EventTagProps, 'createdAt' | 'updatedAt'>;
}

const EventTagEdit: React.FC = () => {
	const { Text } = Typography;
	const history = useHistory();
	const { tagId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<EventTagProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [eventTag, setEventTag] =
		React.useState<EventTagProps>(initialEventTag);
	const [tagName, setTagName] = React.useState<string>('');
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(tagName, 500);

	const { data } = useFetchList<EventTagProps>({
		endpoint: 'event-tags',
		initialQuery: {
			limit: 10,
			offset: 0,
		},
		limit: 10,
	});

	React.useEffect(() => {
		const res: any = data.filter(
			(item) => item.tagId !== tagId && searchValue === item.tagName,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	const createEventTag = async (
		props: Omit<
			EventTagProps,
			'createdAt' | 'updatedAt' | 'tagId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			await httpRequest.post('/event-tags', { ...props });
			message.success('Success create ' + props.tagName);
			history.push('/medical-event-tag');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateEventTag = async (
		props: Omit<EventTagProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/event-tags/' + tagId, props);
			message.success('Success update ' + props.tagName + ' data');
			history.push('/medical-event-tag');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<EventTagProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (tagId) {
			updateEventTag(values);
		} else {
			createEventTag(values);
		}
	};

	React.useEffect(() => {
		if (tagId) {
			const fetchTag = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/event-tags/' + tagId,
					);
					setEventTag(res.data.payload);
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchTag();
		}
	}, [tagId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(tagId ? 'Edit' : 'Add') + ' Event Tag'}
				subtitle="Manage your tag data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							disabled={isDuplicate}
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={tagId ? eventTag : initialEventTag}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Tag Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Tag Name"
							name="tagName"
							rules={generateFormRules('Tag name', ['required'])}
						>
							<Input onChange={(e) => setTagName(e.target.value)} />
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Category Name Already Exist!
							</Text>
						)}

						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={eventTag.isPublished}>
								<Radio value={true}>Published</Radio>
								<Radio value={false}>Unpublished</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default EventTagEdit;
