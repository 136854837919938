import { Reducer } from 'redux';
import { AuthState, AuthTypes } from '../../types/auth.type';

const initialState: AuthState = {
	user: {
		userId: '',
		name: '',
		email: '',
		birthdate: '',
		phone: '',
		gender: '',
		province: '',
		city: '',
		status: 'inactive',
		userType: 'customer',
	},
	isLoading: false,
	isLoadingUser: false,
	isLoadingUserAction: false,
	isLoggedIn: false,
};

export const auth: Reducer<AuthState> = (state = initialState, action) => {
	switch (action.type) {
		case AuthTypes.AUTH_SIGNIN:
			return {
				...state,
				isLoggedIn: true,
				isLoading: false,
			};
		case AuthTypes.AUTH_SIGNOUT:
			return {
				...state,
				isLoggedIn: false,
				isLoading: false,
			};
		case AuthTypes.AUTH_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case AuthTypes.USER_LOADING:
			return {
				...state,
				isLoadingUser: action.payload,
			};
		case AuthTypes.USER_LOADING_ACTION:
			return {
				...state,
				isLoadingUserAction: action.payload,
			};
		case AuthTypes.SET_USER_DATA:
			return {
				...state,
				isLoading: false,
				isLoggedIn: true,
				isLoadingUser: false,
				isLoadingUserAction: false,
				user: action.payload,
			};

		default:
			return state;
	}
};
