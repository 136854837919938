import { Row, Col, Divider, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import DetailItem from '../../../components/DetailItem';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import {
	initialTransactionHealthService,
	TransactionHealthServiceItemProps,
	TransactionHealthServiceProps,
} from '../../../types/transactionHealthService.type';
import moment from 'moment';
import { IndividualServiceProps } from '../../../types/individualService.type';
import { PackageServiceProps } from '../../../types/packageService.type';
import { replaceUnderscoreWithSpace } from '../../../helpers/replaceUnderscoreWithSpace';
import { PaymentProps } from '../../../types/payment.type';
import {
	EPaymentDetailType,
	EPaymentType,
} from '../../../types/paymentDetail.type';
import { getFullName } from '../../../helpers/name';
import { getAge } from '../../../helpers/getAge';
import { formatDate, formatTime } from '../../../helpers/constant';

const { Text } = Typography;

interface ItemsProps {
	name: string;
	items: Array<String>;
}

interface IParams {
	transactionHealthServiceId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<TransactionHealthServiceProps, 'createdAt' | 'updatedAt'>;
}

let formatPrice = Intl.NumberFormat('en-US');

const TransactionHealthServiceDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();

	const { transactionHealthServiceId } = useParams<IParams>();

	const [items, setItems] = React.useState<Array<ItemsProps>>([]);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [transactionHealthService, setTransactionHealthService] =
		React.useState<TransactionHealthServiceProps>(
			initialTransactionHealthService,
		);
	const [payment, setPayment] = React.useState<PaymentProps>();

	React.useEffect(() => {
		const fetchTransactionHealthServiceDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/transaction-health-services/' + transactionHealthServiceId,
				);
				setTransactionHealthService(res.data.payload);
				setPayment(res.data.payload.payment);

				handleItems(res.data.payload.transactionHealthServiceItems);

				const bcDetails = [
					{
						field: 'transactionHealthServiceId',
						value: transactionHealthServiceId,
						label: res.data.payload.transactionHealthServiceId,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchTransactionHealthServiceDetail();
	}, [transactionHealthServiceId]);
	console.log(transactionHealthService);

	const handleItems = (items: TransactionHealthServiceItemProps[]) => {
		const newItems: Array<ItemsProps> = items.map((item) => {
			const metaIndividual = item.metaHealthService as IndividualServiceProps;
			const metaPackage = item.metaHealthService as PackageServiceProps;
			let serviceName = '';
			let hsItems: string[] = [];

			if (metaIndividual.categoryId) {
				serviceName = metaIndividual.category
					? metaIndividual.category.categoryName
					: '';
				hsItems.push(
					metaIndividual.healthServiceName
						? metaIndividual.healthServiceName
						: '',
				);
			} else {
				serviceName = metaPackage.healthServiceName;
				metaPackage.items?.map((packageItem) => {
					hsItems.push(packageItem);
				});
			}

			return {
				name: serviceName,
				items: hsItems,
			};
		});

		setItems(newItems);
	};

	const statusWaitStyle = {
		backgroundColor: '#FFF9EF',
		border: '2px solid #E89635',
		borderRadius: '0',
		color: '#E89635',
		padding: '0 1%',
		display: 'inline',
	};

	const statusApprovedStyle = {
		backgroundColor: '#F5FFF9',
		border: '2px solid #39AC6E',
		color: '#39AC6E',
		borderRadius: '0',
		padding: '0 1%',
		display: 'inline',
	};

	const statusCancelStyle = {
		backgroundColor: '#FFF9FB;',
		border: '2px solid #F23459',
		color: '#F23459',
		borderRadius: '0',
		padding: '0 1%',
		display: 'inline',
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Medical Service"
				subtitle="Detail of your transaction"
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Status">
					<div
						style={
							transactionHealthService.transactionStatus == 'APPROVED'
								? statusApprovedStyle
								: transactionHealthService.transactionStatus ===
								  'WAITING_FOR_PAYMENT'
								? statusWaitStyle
								: statusCancelStyle
						}
					>
						{replaceUnderscoreWithSpace(
							transactionHealthService.transactionStatus,
						)}
					</div>
				</SectionContent>

				<Divider />

				<SectionContent groupTitle="Information">
					<Row gutter={[16, 16]}>
						<Col>
							<DetailItem
								label="Service Type"
								content={replaceUnderscoreWithSpace(
									transactionHealthService.serviceType,
								)}
							/>
						</Col>
						<Col offset={4}>
							<DetailItem
								label="Schedule"
								content={
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<h4>
											{formatDate(transactionHealthService.scheduledStartAt)}
										</h4>
										<div
											style={{
												width: '5px',
												height: '5px',
												borderRadius: '50px',
												backgroundColor: 'grey',
												margin: '8px 8px 0',
											}}
										></div>
										<h4>
											{formatTime(transactionHealthService.scheduledStartAt)} -{' '}
											{formatTime(transactionHealthService.scheduledEndAt)}
										</h4>
									</div>
								}
							/>
						</Col>
					</Row>
					<Row>
						<DetailItem
							label="Clinic"
							content={transactionHealthService.metaPartner?.address}
						/>
					</Row>
					<Text
						style={{
							fontFamily: 'Roboto',
							fontWeight: 'bold',
							fontSize: '12px',
							lineHeight: '150%',
							letterSpacing: '0.02em',
							color: '#556575',
						}}
					>
						Medical Service
					</Text>
					{items.map((item) => {
						return (
							<Row>
								<DetailItem
									label={item.name}
									content={
										<div
											style={{
												display: 'grid',
												flexWrap: 'wrap',
												gridTemplateColumns: '1fr 1fr 1fr',
												alignItems: 'flex-start',
												gap: 15,
												// width: 700
											}}
										>
											{item.items.map((hsItem) => {
												return (
													<div>
														<Text
															style={{
																fontWeight: 'bold',
																fontSize: '14px',
																lineHeight: '150%',
															}}
														>
															· {hsItem}
														</Text>
													</div>
												);
											})}
										</div>
									}
								/>
							</Row>
						);
					})}
				</SectionContent>

				<Divider />

				<SectionContent groupTitle="Patient">
					<Row>
						<Col span={8}>
							<DetailItem
								label="Name"
								content={getFullName(transactionHealthService.metaPatient)}
							/>
						</Col>
						<Col span={8}>
							<DetailItem
								label="Age"
								content={
									transactionHealthService.metaPatient?.birthdate
										? getAge(
												transactionHealthService.metaPatient?.birthdate.toString(),
										  ) + ' y.o'
										: undefined
								}
							/>
						</Col>
						<Col span={8}>
							<DetailItem
								label="Gender"
								content={`${transactionHealthService.metaPatient?.gender
									?.charAt(0)
									.toUpperCase()}${transactionHealthService.metaPatient?.gender?.slice(
									1,
								)}`}
							/>
						</Col>
					</Row>
				</SectionContent>

				<Divider />

				<SectionContent groupTitle="Payment Detail">
					{payment &&
						payment.paymentDetails &&
						payment.paymentDetails
							.filter(
								(item) => item.paymentType !== EPaymentType.PAYMENT_GATEWAY,
							)
							.map((item) => {
								return (
									<Row>
										<Col span={16}>
											<Text>
												{replaceUnderscoreWithSpace(item.paymentType)}
											</Text>
										</Col>
										<Col span={8} style={{ textAlign: 'right' }}>
											{item.type === EPaymentDetailType.PAYMENT ? (
												<Text>₱{formatPrice.format(item.totalPay)}</Text>
											) : (
												<Text style={{ color: 'red' }}>
													-₱{formatPrice.format(item.totalPay)}
												</Text>
											)}
										</Col>
									</Row>
								);
							})}

					<Divider />

					<Row>
						<Col span={16}>
							<Text>Total</Text>
						</Col>
						<Col span={8} style={{ textAlign: 'right' }}>
							{payment ? (
								<Text>₱{formatPrice.format(payment.totalNeedToPay)}</Text>
							) : (
								<Text>No Payment Has Been Made</Text>
							)}
						</Col>
					</Row>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default TransactionHealthServiceDetail;
