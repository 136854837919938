import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Input, message, Modal, Pagination, Select, Switch, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import useFetchList from '../../hooks/useFetchList';
import { IUser } from '../../types/user.type';

const Secretary = () => {
	const history = useHistory();
	const {
		isLoading,
		data,
		pagination,
		setSearch,
		setData,
		setQuery,
		changePage,
	} = useFetchList<IUser>({
		endpoint: 'user',
		initialQuery: {
			secretaryOnly: 1,
		},
		limit: 10,
	});

	const handleClickDetail = (userId: string) => {
		history.push(`/secretary/${userId}`);
	};
	const [tmpData, setTmpData] = React.useState<any>({});
	const [isModalUserStatusVisible, setIsModalUserStatusVisible] =
		React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, filterStatus: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterStatus: '' }));
		}
	};

	const handleDoctorStatusChange = async () => {
		try {
			setIsLoadingAction(true);
			const newStatus = tmpData.status === 'active' ? 'inactive' : 'active';

			const res = await httpRequest.patch<any>('/user/' + tmpData.userId, {
				...tmpData,
				status: newStatus,
			});

			const newData = data.map((item) => {
				if (item.userId === res.data.payload.userId) {
					return {
						...item,
						status: res.data.payload.status,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.name + ' status.');
			setIsModalUserStatusVisible(false);
			setTmpData({});
			setIsLoadingAction(false);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalUserStatusVisible(false);
			setTmpData({});
			setIsLoadingAction(false);
		}
	};

	const columns = [
		{
			title: 'FULL NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: IUser) => {
				return (
					<div
						className="table-link"
						onClick={() => handleClickDetail(record.userId || '')}
					>
						{record.name}
					</div>
				);
			},
		},
		{
			title: 'EMAIL',
			dataIndex: 'email',
			key: 'email',
			render: (phone: string, record: IUser) => {
				return (
					<>
						{record.email ? (
							<Text>{record.email}</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phone',
			key: 'phone',
			render: (phone: string, record: IUser) => {
				return (
					<>
						{record.phone ? (
							<Text>{record.phone}</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			key: 'gender',
			render: (gender: string, record: IUser) => {
				return (
					<>
						{record.gender ? (
							<Text>
								{record.gender.charAt(0).toUpperCase() + record.gender.slice(1)}
							</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'BIRTHDATE',
			dataIndex: 'birthdate',
			key: 'birthdate',
			render: (birthdate: any, record: IUser) => {
				return (
					<>
						{record.birthdate ? (
							<Text>{formatDate(record.birthdate)}</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: IUser) => (
				<>
					<Switch
						checked={record.status == 'active' ? true : false}
						onChange={() => {
							setIsModalUserStatusVisible(true);
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
	];

	return (
		<div>
			<HeaderSection
				icon={<UserOutlined />}
				title={'Secretary'}
				subtitle={'Manage your secretary data'}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Name, Phone number or Email"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					//value={(query as any).filterUserType}
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Non Active</Option>
				</Select>
			</ContainerFilter>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
			{data.length !== 0 && (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			)}
			<Modal
				title="Update secretary status confirmation"
				visible={isModalUserStatusVisible}
				onOk={handleDoctorStatusChange}
				onCancel={() => {
					setIsModalUserStatusVisible(false);
					setTmpData({});
				}}
				okText="Yes"
				confirmLoading={isLoadingAction}
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to change user status?</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Secretary;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
