import { Form, FormInstance, Input, message, Space } from 'antd';
import React from 'react';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { FAQProps } from '../../types/faq.type';
import { httpRequest } from '../../helpers/api';
import SectionContent from '../../components/SectionContent';
import ReactQuill from 'react-quill';
import { generateFormRules } from '../../helpers/formRules';

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const ChatCustomerServiceEdit: React.FC = () => {
	const formRef =
		React.useRef<FormInstance<Omit<FAQProps, 'createdAt' | 'updatedAt'>>>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [data, setData] = React.useState<any>({});

	const updateData = async (
		props: Omit<FAQProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.put('/app-configs/custom/as-object', {
				...data,
				...props,
			});
			message.success('Updated successfully');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<FAQProps, 'createdAt' | 'updatedAt'>,
	) => {
		updateData(values);
	};

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<any>('/app-configs/custom/as-object');
				setData(res.data.payload);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Chat Customer Service"
				subtitle={`Manage your chat customer service data`}
				rightAction={
					<Space>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="form"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={data}
					autoComplete="off"
				>
					<SectionContent groupTitle={'Information'}>
						<Form.Item
							label="Contact Us Information"
							name="CUSTOMER_SERVICE_DESCRIPTION"
							rules={generateFormRules('Contact Us Information', ['required'])}
						>
							<ReactQuill
								theme="snow"
								value={data.CUSTOMER_SERVICE_DESCRIPTION}
								onChange={(content) =>
									setData({
										...data,
										CUSTOMER_SERVICE_DESCRIPTION: content,
									})
								}
								modules={quillModules}
								formats={quillFormats}
							/>
						</Form.Item>

						<Form.Item
							label="Customer Service Email"
							name="CUSTOMER_SERVICE_EMAIL"
							rules={[
								{
									required: true,
									message: 'Email is required.',
								},
							]}
						>
							<Input />
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default ChatCustomerServiceEdit;
