import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	SendOutlined,
	SearchOutlined,
	CloudUploadOutlined,
} from '@ant-design/icons';
import {
	Pagination,
	Table,
	Menu,
	Input,
	Select,
	Typography,
	Col,
	Row,
	Upload,
	Modal,
	message,
	Form,
	FormInstance,
	Image,
} from 'antd';
import {
	BalanceWithdrawalProperties,
	EBalanceWithdrawStatus,
} from '../../types/withdrawal.type';
import { replaceUnderscoreWithSpace } from '../../helpers/replaceUnderscoreWithSpace';
import AppButton from '../../components/AppButton';
import type { UploadProps } from 'antd';
import type { MenuProps } from 'antd';
import HeaderSection from '../../components/HeaderSection';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import moment from 'moment';
import { httpRequest } from '../../helpers/api';

const { Text } = Typography;
const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

const Withdrawal = () => {
	const history = useHistory();
	const [current, setCurrent] = React.useState('needAction');

	const [isModalApprove, setIsModalApprove] = React.useState(false);
	const [isModalReject, setIsModalReject] = React.useState(false);
	const [isModalReason, setIsModalReason] = React.useState(false);

	const [needAction, setNeedAction] =
		React.useState<BalanceWithdrawalProperties[]>();
	const [approved, setApproved] =
		React.useState<BalanceWithdrawalProperties[]>();
	const [rejected, setRejected] =
		React.useState<BalanceWithdrawalProperties[]>();

	const [tmpData, setTmpData] = React.useState<BalanceWithdrawalProperties>();
	const [uploadedImage, setUploadedImage] = React.useState<any>('');
	const [preview, setPreview] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>('');

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
		setIsLoading,
		fetchList,
	} = useFetchList<BalanceWithdrawalProperties>({
		endpoint: 'balance-withdrawal',
	});

	const formRefReject =
		React.useRef<FormInstance<BalanceWithdrawalProperties>>(null);

	const sortDataBasedOnStatus = (data: BalanceWithdrawalProperties[]) => {
		const statusNeedAction = [];
		const statusApproved = [];
		const statusRejected = [];

		for (let obj of data) {
			if (obj.status === EBalanceWithdrawStatus.WAITING_APPROVAL) {
				statusNeedAction.push(obj);
			} else if (obj.status === EBalanceWithdrawStatus.APPROVED) {
				statusApproved.push(obj);
			} else {
				statusRejected.push(obj);
			}
		}

		setNeedAction(statusNeedAction);
		setApproved(statusApproved);
		setRejected(statusRejected);
	};

	React.useEffect(() => {
		sortDataBasedOnStatus(data);
	}, [data, current]);

	// Modal
	const showModalApprove = () => {
		setIsModalApprove(true);
	};

	const showModalReject = () => {
		setIsModalReject(true);
	};

	const showModalReason = () => {
		setIsModalReason(true);
	};

	const handleSubmitReject = async (values: any) => {
		setIsModalReject(false);
		try {
			setIsLoading(true);
			const res = await httpRequest.patch(
				`/balance-withdrawal/${tmpData!.balanceWithdrawalId}/reject`,
				{ ...tmpData, reason: values.reason },
			);
			fetchList();
			setIsLoading(false);
		} catch (e) {
			message.error('ERROR: cannot reject, please try again!');
		}
	};

	const handleSubmitApprove = async (values: any) => {
		setIsModalApprove(false);
		try {
			setIsLoading(true);
			const formData = new FormData();

			if (uploadedImage !== '') {
				formData.append('image', uploadedImage);
			}
			const res = await httpRequest.patch(
				`/balance-withdrawal/${tmpData!.balanceWithdrawalId}/approve`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
			fetchList();
			setIsLoading(false);
		} catch (e) {
			message.error('ERROR: cannot approve, please try again!');
			setIsLoading(false);
		}
	};

	const handleOkReject = async () => {
		setIsModalReject(false);
		await formRefReject?.current?.submit();
	};

	const handleOkApprove = () => {
		setIsModalApprove(false);
		handleSubmitApprove(tmpData);
	};

	const handleCancel = () => {
		setIsModalApprove(false);
		setIsModalReject(false);
		setIsModalReason(false);
	};

	const uploadProps: UploadProps = {
		name: 'file',
		accept: '.jpg,.jpeg,.png,.pdf',
		multiple: false,
		maxCount: 1,
		customRequest({ file, onSuccess }) {
			setTimeout(() => {
				onSuccess!('ok');
			}, 0);
		},
		onChange(info) {
			setUploadedImage(info.file.originFileObj);
		},
		onDrop(e) {
			setUploadedImage('');
		},
	};

	// Horizontal Menu
	const handleChangeSelect = (status: string) => {
		setCurrent(status);
	};

	const onMenuClick: MenuProps['onClick'] = (e) => {
		setCurrent(e.key);
	};

	// Table
	const items: MenuProps['items'] = [
		{
			label: 'Need Action',
			key: 'needAction',
		},
		{
			label: 'Approved',
			key: 'approved',
		},
		{
			label: 'Rejected',
			key: 'rejected',
		},
	];

	const needActionColumns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaUser.name}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaUser.phoneNumber}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankCode',
			key: 'bankCode',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return (
					<Text>
						{record.metaBankAccount.bankCode
							? replaceUnderscoreWithSpace(record.metaBankAccount.bankCode)
							: replaceUnderscoreWithSpace(record.metaBankAccount.bankName)}
					</Text>
				);
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'accountNumber',
			key: 'accountNumber',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaBankAccount.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'nameInBankAccount',
			key: 'nameInBankAccount',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaBankAccount.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			key: 'amount',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>₱{record.amount}</Text>;
			},
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			key: 'action',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<AppButton
							type="primary"
							onClick={() => {
								setIsModalApprove(true);
								setTmpData(record);
							}}
						>
							Approve
						</AppButton>
						<AppButton
							onClick={() => {
								setIsModalReject(true);
								setTmpData(record);
							}}
						>
							Reject
						</AppButton>
					</div>
				);
			},
		},
		{
			title: 'REQUESTED AT',
			dataIndex: 'requestedAt',
			key: 'requestedAt',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{moment(record.createdAt).format('MMMM d, YYYY')}</Text>;
			},
		},
	];

	const approvedColumns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaUser.name}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaUser.phoneNumber}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankCode',
			key: 'bankCode',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return (
					<Text>
						{record.metaBankAccount.bankCode
							? replaceUnderscoreWithSpace(record.metaBankAccount.bankCode)
							: replaceUnderscoreWithSpace(record.metaBankAccount.bankName)}
					</Text>
				);
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'accountNumber',
			key: 'accountNumber',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaBankAccount.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'nameInBankAccount',
			key: 'nameInBankAccount',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaBankAccount.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			key: 'amount',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>₱{record.amount}</Text>;
			},
		},
		{
			title: 'ATTACHMENT',
			dataIndex: 'attachment',
			key: 'attachment',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return (
					<>
						<div
							style={{
								cursor: 'pointer',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => setPreview(true)}
						>
							<Text underline style={{ color: '#D81F64' }}>
								View
							</Text>
						</div>
						<Image
							style={{ display: 'none' }}
							src={
								record.imageLink
									? record.imageLink || '/images/blur-image.jpeg'
									: '/images/blur-image.jpeg'
							}
							preview={{
								visible: preview,
								onVisibleChange: (value) => {
									setPreview(value);
								},
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'REQUESTED AT',
			dataIndex: 'requestedAt',
			key: 'requestedAt',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{moment(record.createdAt).format('MMMM d, YYYY')}</Text>;
			},
		},
	];

	const rejectedColumns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaUser.name}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaUser.phoneNumber}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankCode',
			key: 'bankCode',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return (
					<Text>
						{record.metaBankAccount.bankCode
							? replaceUnderscoreWithSpace(record.metaBankAccount.bankCode)
							: replaceUnderscoreWithSpace(record.metaBankAccount.bankName)}
					</Text>
				);
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'accountNumber',
			key: 'accountNumber',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaBankAccount.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'nameInBankAccount',
			key: 'nameInBankAccount',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{record.metaBankAccount.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			key: 'amount',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>₱{record.amount}</Text>;
			},
		},
		{
			title: 'REJECTED BY',
			dataIndex: 'rejectedBy',
			key: 'rejectedBy',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>Admin</Text>;
			},
		},
		{
			title: 'REJECTED AT',
			dataIndex: 'rejectedAt',
			key: 'rejectedAt',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return <Text>{moment(record.updatedAt).format('MMMM d, YYYY')}</Text>;
			},
		},
		{
			title: 'REASON',
			dataIndex: 'reason',
			key: 'reason',
			render: (text: string, record: BalanceWithdrawalProperties) => {
				return (
					<>
						<div
							style={{
								cursor: 'pointer',
								display: 'flex',
								justifyContent: 'center',
							}}
							onClick={() => {
								setIsModalReason(true);
								setReason(record!.reason!);
							}}
						>
							<Text underline style={{ color: '#D81F64' }}>
								View
							</Text>
						</div>
						<Modal
							title="Reason"
							visible={isModalReason}
							footer={[
								<AppButton
									type="primary"
									onClick={() => {
										handleCancel();
										setReason('');
									}}
								>
									Ok
								</AppButton>,
							]}
						>
							<p>{reason}</p>
						</Modal>
					</>
				);
			},
		},
	];

	return (
		<div>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						icon={
							<SendOutlined
								rotate={315}
								style={{ fontSize: 17, color: '#A5B2BD' }}
							/>
						}
						title="Withdrawal"
						subtitle="Manage your withdrawal"
					/>
				</Col>
			</Row>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search name, phone number, or address"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeSelect}
					placeholder="Verification"
				>
					<Option value="needAction">Need Action</Option>
					<Option value="approved">Approved</Option>
					<Option value="Rejected">Rejected</Option>
				</Select>
			</ContainerFilter>

			<CustomMenu
				onClick={onMenuClick!}
				selectedKeys={[current]}
				mode="horizontal"
				items={items}
			/>

			<Table
				loading={isLoading}
				columns={
					current === 'needAction'
						? needActionColumns
						: current === 'approved'
						? approvedColumns
						: rejectedColumns
				}
				dataSource={
					current === 'needAction'
						? needAction
						: current === 'approved'
						? approved
						: rejected
				}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}

			{/* Modals */}
			<CustomModal
				title="Approve"
				visible={isModalApprove}
				onOk={() => handleOkApprove()}
				onCancel={handleCancel}
				footer={[
					<AppButton onClick={handleCancel}>Cancel</AppButton>,
					<AppButton
						type="primary"
						key="back"
						onClick={() => handleOkApprove()}
					>
						Approve
					</AppButton>,
				]}
			>
				<Dragger {...uploadProps}>
					<p className="ant-upload-drag-icon">
						<CloudUploadOutlined />
					</p>
					<p className="ant-upload-text">
						Drag and drop file here or browse files
					</p>
					<p className="ant-upload-hint">
						Supported file are .png .jpg .pdf. Maximum upload file size is 5 MB.
					</p>
				</Dragger>
			</CustomModal>
			<CustomModal
				title="Reject"
				visible={isModalReject}
				onOk={() => handleOkReject()}
				onCancel={handleCancel}
				footer={[
					<AppButton key="back" onClick={handleCancel}>
						Cancel
					</AppButton>,
					<AppButton type="primary" key="back" onClick={() => handleOkReject()}>
						Reject
					</AppButton>,
				]}
			>
				<Form
					ref={formRefReject}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={{ remember: true }}
					onFinish={handleSubmitReject}
					autoComplete="off"
				>
					<Form.Item
						label="Reason"
						name="reason"
						rules={[
							{ required: true, message: 'Input the reason of rejection' },
						]}
					>
						<TextArea />
					</Form.Item>
				</Form>
			</CustomModal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Withdrawal;

const CustomMenu = styled(Menu)`
	background-color: white !important;

	.ant-menu-item-selected {
		color: black !important;
	}

	.ant-menu-item::hover {
		backgroundcolor: white !important;
	}

	.ant-menu-item-active {
		color: #d81f64 !important;
	}

	.ant-menu-item-selected::after {
		border-bottom: 2px solid #d81f64 !important;
	}
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

const CustomModal = styled(Modal)`
	.ant-modal,
	.ant-modal-content {
		min-width: 630px;
	}
	.ant-modal-title {
		font-size: 20px;
		font-weight: 600;
		color: #556575;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
	}
	.ant-typography {
		font-weight: 400;
		letter-spacing: 0.02em;
		line-height: 150%;
	}
	span.ant-typography {
		font-size: 12px;
		color: #556575;
		text-transform: capitalize;
	}
	div.ant-typography {
		font-size: 14px;
		color: #1d2b36;
		margin-bottom: 0;
		/* font-weight: 600; */
	}
`;
