import { BaseResponseProps } from './config.type';
import { EventTagProps } from './eventTag.type';
import { SpecialistProps } from './specialist.type';

export interface EventParticipantProps {
	eventParticipantId?: string;
	eventId: string;
	participantId: string;
	metaParticipant: any;
	status: string;
	paidAt?: Date | string;
	createdAt?: string;
	updatedAt?: string;
}

export interface EventProps {
	eventId?: string;
	imageUrls?: string[];
	title: string;
	subtitle?: string;
	description: string;
	eventOrganizer: string;
	price: number;
	maxParticipant?: number;
	eventStartAt: Date;
	eventEndAt: Date;
	registerStartAt?: Date;
	registerEndAt?: Date;
	registerUrl?: string;
	isPublished?: boolean;
	isCPDAvailable?: boolean;
	createdAt?: Date;
	updatedAt?: Date;
	tags: EventTagProps[];
	specialists?: SpecialistProps[];
	// participant: EventParticipantProps[];
	statusLoading?: boolean;
}

export interface FetchAllEventResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: EventProps[];
	};
}

export const initialEvent: EventProps = {
	eventId: '',
	title: '',
	subtitle: '',
	description: '',
	eventOrganizer: '',
	price: 0,
	maxParticipant: 0,
	imageUrls: [],
	eventStartAt: new Date(),
	eventEndAt: new Date(),
	registerStartAt: new Date(),
	registerEndAt: new Date(),
	registerUrl: '',
	isPublished: true,
	isCPDAvailable: true,
	tags: [],
	specialists: [],
	// participant: [],
	createdAt: new Date(),
	updatedAt: new Date(),
	statusLoading: false,
};
