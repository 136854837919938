import { BaseResponseProps } from './config.type';
import { IndividualServiceProps } from './individualService.type';
import { PackageServiceProps } from './packageService.type';
import { PartnerProps } from './partner.type';
import { PatientProps } from './patient.type';
import { PaymentProps } from './payment.type';

export enum EAvailableServiceTypes {
	HOME_SERVICE = 'HOME_SERVICE',
	IN_CLINIC = 'IN_CLINIC',
}

export type TransactionHealthServiceProps = {
	transactionHealthServiceId: string;
	patientId: string;
	patientInRelationId?: string;
	partnerId: string;
	serviceType: EAvailableServiceTypes;
	noteFromPatient?: string;
	noteFromLab?: string;
	transactionStatus: string;
	lastUpdatedByUser?: any;
	scheduledStartAt?: Date;
	scheduledEndAt?: Date;
	realStartAt?: Date;
	realEndAt?: Date;

	metaPartner?: PartnerProps;
	metaPatient?: {
		city?: string;
		name: string;
		email?: string;
		phone?: string;
		doctor?: any;
		gender: 'male' | 'female';
		status: string;
		userId: string;
		patient: {
			pwId: null;
			pwIdUrl: null;
			createdAt: Date | string;
			patientId: string;
			updatedAt: string;
			pwIdStatus: string;
		};
		lastName: string;
		nickName: string;
		province?: string;
		userType: string;
		birthdate: Date | string;
		createdAt: Date | string;
		firstName: string;
		updatedAt: Date | string;
		middleName?: string;
		profilePic?: string;
		isEmailVerified: boolean;
		isPhoneVerified: boolean;
	};

	metaPatientInRelation?: {
		patientInRelationId: string;
		patientId: string;
		firstName?: string;
		middleName?: string;
		lastName?: string;
		nickName?: string;
		relation?: string;
		gender?: string;
		email?: string;
		phone?: string;
		pwId?: string;
		pwIdStatus?: string; //ePWIDstatus,
	};

	createdAt?: Date;
	updatedAt?: Date;
	transactionHealthServiceItems: TransactionHealthServiceItemProps[];
	payment?: PaymentProps;
};

export interface TransactionHealthServiceItemProps {
	id?: string;
	transactionHealthServiceId: string;
	healthServiceId?: string;
	noteFromPatient?: string;
	noteFromLab?: string;
	metaHealthService?: IndividualServiceProps | PackageServiceProps;
	createdAt?: string;
	updatedAt?: string;
}

export interface FetchAllTransactionHealthServiceResponse
	extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: TransactionHealthServiceProps[];
	};
}

export const initialTransactionHealthService: TransactionHealthServiceProps = {
	transactionHealthServiceId: '',
	patientId: '',
	patientInRelationId: '',
	partnerId: '',
	serviceType: EAvailableServiceTypes.HOME_SERVICE,
	noteFromPatient: '',
	noteFromLab: '',
	transactionStatus: '',
	lastUpdatedByUser: '',
	scheduledStartAt: new Date(),
	scheduledEndAt: new Date(),
	realStartAt: new Date(),
	realEndAt: new Date(),
	createdAt: new Date(),
	updatedAt: new Date(),
	transactionHealthServiceItems: [],
};
