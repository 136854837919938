import { Col, Image, Row, Tag, Typography } from 'antd';
import React from 'react';
import { EventTagProps } from '../../types/eventTag.type';

export default function SelectedMedicalUpdate({ content }: any) {
	const { Text } = Typography;

	function sortTags(tags: EventTagProps[]) {
		tags.sort((a, b) => a.tagName.localeCompare(b.tagName));
		return tags;
	}

	return (
		<div
			style={{
				height: '120px',
			}}
		>
			<Row gutter={[16, 16]}>
				<Col>
					<Image
						width={120}
						height={100}
						src={
							content.imageUrls && content.imageUrls.length > 0
								? content.imageUrls[0] || '/images/blur-image.jpeg'
								: '/images/blur-image.jpeg'
						}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				</Col>

				<Col span={10}>
					<Row>
						<Text
							style={{
								fontWeight: 'bold',
								fontSize: '14px',
							}}
						>
							{content.title}
						</Text>
					</Row>
					<Row>
						{content.specialists
							? content.specialists.length <= 3
								? content.specialists
										.slice()
										.sort((a: any, b: any) => a.level - b.level)
										?.map((el: any) => (
											<Tag
												style={{
													border: '2px solid #D81F64',
													color: '#D81F64',
													marginBottom: '10px',
												}}
												key={el.specialistId}
											>
												{el.specialistName}
											</Tag>
										))
								: content.specialists
										.slice(0, 3)
										.sort((a: any, b: any) => a.level - b.level)
										?.map((el: any) => (
											<Tag
												style={{
													border: '2px solid #D81F64',
													color: '#D81F64',
													marginBottom: '10px',
												}}
												key={el.specialistId}
											>
												{el.specialistName}
											</Tag>
										))
							: []}
						{content.specialists
							? content.specialists.length > 3 && (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
									>
										+{content.specialists.slice(3).length} More
									</Tag>
							  )
							: []}
					</Row>
					<Row
						style={{
							fontSize: '12px',
						}}
					>
						{content?.tags
							? content?.tags?.length <= 4
								? sortTags(content?.tags)?.map((element, idx) => {
										return (
											<Tag key={idx}>
												{element.tagName}
												{idx < content.tags.length - 1 && ', '}
											</Tag>
										);
								  })
								: sortTags(content?.tags)
										?.slice(0, 4)
										?.map((element, idx) => {
											return (
												<Tag key={idx}>
													{element.tagName}
													{idx < content?.tags?.length && ', '}
												</Tag>
											);
										})
							: []}
						{content?.tags?.length > 4 &&
							`+${content?.tags?.slice(4)?.length} More`}
					</Row>
				</Col>
			</Row>
		</div>
	);
}
