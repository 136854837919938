import {
	Checkbox,
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
} from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { CustomerProps, initialCustomer } from '../../types/customer.type';

interface ILocation {
	userId: string;
}

type Props = {
	userType?: 'admin' | 'customer';
};

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const CustomerEdit = (props: Props) => {
	console.info('RENDER: CustomerEdit');
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { userId } = useParams<ILocation>();
	const formRef =
		React.useRef<FormInstance<Omit<CustomerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [customer, setCustomer] =
		React.useState<CustomerProps>(initialCustomer);
	const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(false);
	const [isPhoneVerified, setIsPhoneVerified] = React.useState<boolean>(false);

	const [image, setImage] = React.useState<File>();
	const [imageUrl, setImageUrl] = React.useState(customer.profilePic);

	const createCustomer = async (
		props: Omit<CustomerProps, 'createdAt' | 'updatedAt' | 'adminId'>,
	) => {
		try {
			setIsLoadingAction(true);

			let name = props.firstName;
			if (
				props.middleName !== '' &&
				props.middleName !== null &&
				props.middleName !== undefined
			) {
				name += ' ' + props.middleName;
			}
			if (
				props.lastName !== '' &&
				props.lastName !== null &&
				props.lastName !== undefined
			) {
				name += ' ' + props.lastName;
			}

			const formData = {
				name: name,
				email: props.email,
				birthdate: props.birthdate,
				phone: props.phone,
				gender: props.gender,
				province: props.province,
				city: props.city,
				userType: props.userType,
				status: props.status,
				isEmailVerified,
				isPhoneVerified,

				firstName: props.firstName,
				middleName: props.middleName,
				lastName: props.lastName,
			};

			await httpRequest.post('/user', formData);

			message.success(
				'Success create ' + props.firstName + props.middleName + props.lastName,
			);

			history.push('/patients');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateCustomer = async (
		props: Omit<CustomerProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			let name = props.firstName;
			if (
				props.middleName !== '' &&
				props.middleName !== null &&
				props.middleName !== undefined
			) {
				name += ' ' + props.middleName;
			}
			if (
				props.lastName !== '' &&
				props.lastName !== null &&
				props.lastName !== undefined
			) {
				name += ' ' + props.lastName;
			}

			const formData = {
				name: name,
				email: props.email,
				birthdate: props.birthdate,
				phone: props.phone,
				gender: props.gender,
				province: props.province,
				city: props.city,
				userType: props.userType,
				status: props.status,
				isEmailVerified,
				isPhoneVerified,

				firstName: props.firstName,
				middleName: props.middleName,
				lastName: props.lastName,
			};

			const imageData = new FormData();
			if (image) imageData.append('image', image);

			await httpRequest.patch('/user/' + userId, formData);
			if (image) {
				await httpRequest.put('/user/' + userId + '/photo', imageData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
			}

			message.success(
				'Success update ' +
					props.firstName +
					props.middleName +
					props.lastName +
					' data',
			);
			history.push('/patients');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<CustomerProps, 'createdAt' | 'updatedAt'>,
	) => {
		if (userId) {
			updateCustomer(values);
		} else {
			createCustomer(values);
		}
	};

	React.useEffect(() => {
		if (userId) {
			const fetchCustomer = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>('/user/' + userId);
					setCustomer(res.data.payload);
					setImageUrl(res.data.payload.profilePic);
					setIsLoading(false);

					console.log(res);

					const bcDetails = [
						{
							field: 'userId',
							value: userId,
							label: res.data.payload.name,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsEmailVerified(res.data.payload.isEmailVerified ? true : false);
					setIsPhoneVerified(res.data.payload.isPhoneVerified ? true : false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchCustomer();
		}
	}, [userId, location]);

	React.useEffect(() => {
		if (!customer.email) {
			setIsEmailVerified(false);
		}
	}, [customer.email]);

	React.useEffect(() => {
		if (!customer.phone) {
			setIsPhoneVerified(false);
		}
	}, [customer.phone]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(userId ? 'Edit' : 'Add') + ' Patient'}
				subtitle={userId ? 'Manage your patient data' : 'Create new patient'}
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={userId ? customer : initialCustomer}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="User Account"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name.',
							},
						]}
					>
						<Form.Item
							label="First Name"
							name="firstName"
							rules={generateFormRules('FirstName', ['required'])}
						>
							<Input placeholder="First Name" />
						</Form.Item>
						<Form.Item label="Middle Name" name="middleName">
							<Input placeholder="Middle Name" />
						</Form.Item>
						<Form.Item label="Last Name" name="lastName">
							<Input placeholder="Last Name" />
						</Form.Item>

						<Form.Item
							label="Phone Number"
							name="phone"
							rules={generateFormRules('Phone number', [
								// 'required',
								'phoneNumber',
							])}
						>
							<Input
								onChange={(event) =>
									setCustomer({ ...customer, phone: event.target.value })
								}
								placeholder="Input phone number"
							/>
						</Form.Item>

						<Form.Item>
							<Checkbox
								checked={isPhoneVerified}
								disabled={!customer.phone}
								onChange={() => setIsPhoneVerified(!isPhoneVerified)}
								style={{ fontSize: 12 }}
							>
								Mark phone as verified
							</Checkbox>
						</Form.Item>

						<Form.Item label="Gender" name="gender">
							<Select style={{ width: '100%' }}>
								<Select.Option value="male">Male</Select.Option>
								<Select.Option value="female">Female</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item label="Birthday" name="birthdate">
							<Input type="date" />
						</Form.Item>

						<Form.Item
							label="Email Address"
							name="email"
							rules={generateFormRules('Email', [
								// 'required',
								'email',
							])}
						>
							<Input
								onChange={(event) =>
									setCustomer({ ...customer, email: event.target.value })
								}
								placeholder="Input email address"
							/>
						</Form.Item>

						<Form.Item>
							<Checkbox
								checked={isEmailVerified}
								disabled={!customer.email}
								onChange={() => setIsEmailVerified(!isEmailVerified)}
								style={{ fontSize: 12 }}
							>
								Mark email as verified
							</Checkbox>
						</Form.Item>
					</SectionContent>

					<Divider />

					<SectionContent
						groupTitle="User Settings"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name.',
							},
						]}
					>
						<Form.Item
							label="Status"
							name="status"
							rules={[
								{
									required: false,
									// message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={customer.status || 'active'}>
								<CustomRadio value="active">Active</CustomRadio>
								<CustomRadio value="inactive">Non Active</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default CustomerEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
