import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import HeaderSection from '../../components/HeaderSection';

const Dashboard = () => {
	return (
		<div>
			<HeaderSection
				icon={<UserOutlined />}
				title="Dashboard"
				subtitle="Lorem ipsum dashboard"
			/>

			<div>Dashboard</div>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Dashboard;
