import { Redirect, Route } from 'react-router';
import useAuth from '../hooks/useAuth';

export interface IProps {
	exact?: boolean;
	path: string;
	component?: React.ComponentType<any>;
	restricted?: boolean;
}

const PublicRoute: React.FC<IProps> = ({ children, restricted, ...rest }) => {
	const { isLoggedIn } = useAuth();

	return (
		<Route
			{...rest}
			render={() =>
				isLoggedIn && restricted ? <Redirect to="/dashboard" /> : children
			}
		/>
	);
};

export default PublicRoute;
