import { createGlobalState } from 'react-hooks-global-state';

type State = {
	patientNeedApprovalLength: number;
	refundNeedApprovalLength: number;
};

const initialState: State = {
	patientNeedApprovalLength: 0,
	refundNeedApprovalLength: 0,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useApproval() {
	const [patientlength, setPatientLength] = useGlobalState(
		'patientNeedApprovalLength',
	);
	const [refundLength, setRefundLength] = useGlobalState(
		'refundNeedApprovalLength',
	);

	return { patientlength, setPatientLength, refundLength, setRefundLength };
}
