import { BaseResponseProps } from './config.type';

export enum EStatus {
	WAITING_FOR_RESPONSE = 'Waiting for Response',
	RESPONDED = 'Responded',
}

export interface CustomerFeedbackProps {
	customerFeedbackId: string;
	userId?: string;
	name: string;
	email?: string;
	phone?: string;
	feedback: string;
	status?: EStatus;
	meta?: any;
	createdAt?: Date;
	updatedAt?: Date;
	statusLoading?: boolean;
}

export interface FetchAllCustomerResponseResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: CustomerFeedbackProps[];
	};
}

export const initialCustomerFeedback: CustomerFeedbackProps = {
	customerFeedbackId: '',
	userId: '',
	name: '',
	email: '',
	phone: '',
	feedback: '',
	meta: '',
};
