import { BaseResponseProps } from './config.type';
import { CustomerProps, initialCustomer } from './customer.type';
import { PartnerProps, initialPartner } from './partner.type';

export enum UserTypes {
	SET_USER = 'SET_USER',
	SET_LOADING_USER = 'SET_LOADING_USER',
	SET_LOADING_ACTION_USER = 'SET_LOADING_ACTION_USER',
}

export interface PublisherState {
	Publisher: PublisherProps;
	userFetchLoading: boolean;
	userActionLoading: boolean;
}

export interface PublisherProps {
	userId: string;
	partnerId: string;
	role: string;
	kycStatus: string;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	partners: PartnerProps;
	user: CustomerProps;
}

export interface FetchAllPublisherResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: PublisherProps[];
	};
}

export const initialPublisher: PublisherProps = {
	userId: '',
	partnerId: '',
	role: '',
	kycStatus: '',
	createdAt: '',
	updatedAt: '',
	partners: initialPartner,
	user: initialCustomer,
};

export const initialAdminPublisherFetchAll: FetchAllPublisherResponse = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialPublisher],
	},
};
