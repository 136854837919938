import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import useFetchList from '../../hooks/useFetchList';
import {
	BankAccountProps,
	BankListProps,
	EBankStatus,
	initialBankAccount,
} from '../../types/bank.type';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

interface IParams {
	userBankAccountId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<BankAccountProps, 'createdAt' | 'updatedAt'>;
}

const BankApprovalEdit: React.FC = () => {
	const { Text } = Typography;
	const history = useHistory();
	const { userBankAccountId } = useParams<IParams>();
	const formRef =
		React.useRef<
			FormInstance<Omit<BankAccountProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [bankAccount, setBankAccount] =
		React.useState<BankAccountProps>(initialBankAccount);
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(bankAccount.bankAccountNumber, 500);

	const { data: bankList } = useFetchList<BankListProps>({
		endpoint: 'banks',
	});

	const { data: userBankAccountList } = useFetchList<BankAccountProps>({
		endpoint: 'user-bank-accounts',
	});

	React.useEffect(() => {
		const res: any = userBankAccountList.filter(
			(item) =>
				bankAccount.userBankAccountId !== item.userBankAccountId &&
				searchValue === item.bankAccountNumber,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	const updateBankApproval = async (
		props: Omit<BankAccountProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);
			await httpRequest.patch(
				`/user-bank-accounts/${userBankAccountId}`,
				props,
			);
			console.log(props);
			message.success('Success update ' + props.userBankAccountId + ' data');
			history.push('/bank-approval');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<BankAccountProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (userBankAccountId) {
			updateBankApproval(values);
		} else {
			// createPharmacy(values);
		}
	};

	const fetchBankApproval = async () => {
		try {
			setIsLoading(true);
			const res = await httpRequest.get<ResponseProps>(
				'/user-bank-accounts/' + userBankAccountId,
			);
			setBankAccount(res.data.payload);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchBankApproval();
	}, []);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(userBankAccountId ? 'Edit' : 'Add') + ' Bank Approval'}
				subtitle="Manage your bank apporoval data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							disabled={isDuplicate}
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={userBankAccountId ? bankAccount : initialBankAccount}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Bank Approval Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Bank name and bank account number must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Bank Name"
							name="bankCode"
							rules={generateFormRules('Bank Name', ['required'])}
						>
							<Select
								showSearch
								allowClear
								style={{
									width: '100%',
								}}
								placeholder="Please select"
								onChange={(e) =>
									setBankAccount((prev) => ({ ...prev, bankCode: e }))
								}
								key={'iconSelect'}
								value={bankAccount?.bankCode ? bankAccount.bankCode : ''}
							>
								{bankList.map((item) => {
									return (
										<Option key={item.bankId} value={item.bankId}>
											{item.bankName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							label="Bank Account Number"
							name="bankAccountNumber"
							rules={generateFormRules('Bank Account Number', ['required'])}
						>
							<Input
								onChange={(e) =>
									setBankAccount((prev) => ({
										...prev,
										bankAccountNumber: e.target.value,
									}))
								}
							/>
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Bank Account Number Has Been Used!
							</Text>
						)}

						<Form.Item
							label="Bank Account Owner"
							name="bankAccountOwner"
							rules={generateFormRules('Bank Account Owner', ['required'])}
						>
							<Input
								onChange={(e) =>
									setBankAccount((prev) => ({
										...prev,
										bankAccountOwner: e.target.value,
									}))
								}
							/>
						</Form.Item>

						<Form.Item
							label="Status"
							name="status"
							rules={[
								{
									required: true,
									message: 'The Default Status is required.',
								},
							]}
						>
							<Radio.Group
								onChange={(e) =>
									setBankAccount((prev) => ({
										...prev,
										status: e.target.value,
									}))
								}
								value={bankAccount.status}
							>
								<Radio value={EBankStatus.WAITING_FOR_APPROVAL}>
									Waiting Approval
								</Radio>
								<Radio value={EBankStatus.APPROVED}>Approved</Radio>
								<Radio value={EBankStatus.REJECTED}>Rejected</Radio>
							</Radio.Group>
						</Form.Item>

						{bankAccount.status === EBankStatus.REJECTED && (
							<Form.Item
								label="Reject Reason"
								name="rejectReason"
								rules={generateFormRules('Reject Reason', ['required'])}
							>
								<TextArea
									rows={4}
									onChange={(e) =>
										setBankAccount((prev) => ({
											...prev,
											rejectReason: e.target.value,
										}))
									}
								/>
							</Form.Item>
						)}

						<Form.Item
							label="Default Status"
							name="isDefault"
							rules={[
								{
									required: true,
									message: 'The Default Status is required.',
								},
							]}
						>
							<Radio.Group
								onChange={(e) =>
									setBankAccount((prev) => ({
										...prev,
										isDefault: e.target.value,
									}))
								}
								value={bankAccount.isDefault || false}
							>
								<Radio value={true}>Default</Radio>
								<Radio value={false}>Non-Default</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default BankApprovalEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
