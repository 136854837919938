import { BaseResponseProps } from './config.type';
import { CustomerProps,initialCustomer } from './customer.type';
import { DoctorProps } from './doctor.type';
import { PartnerDoctorProps } from './partnerDoctor.type';
import { PartnerOpenHoursProps } from './partnerOpenHours.type';

export enum EPartnerType {
	HOSPITAL = 'HOSPITAL',
	CLINIC = 'CLINIC',
	LABORATORY = 'LABORATORY',
	ONLINE_CLINIC = 'ONLINE_CLINIC',
	CONSULT_NOW = 'CONSULT_NOW',
	DRUGSTORE = 'DRUGSTORE',
	PHARMACY = 'PHARMACY',
	PUBLISHER ='PUBLISHER',
}

export interface PartnerProps {
	partnerId: string;
	partnerType?: EPartnerType;
	partnerName: string;
	address?: string;
	geolocation?: {
		latitude: number;
		longitude: number;
	};
	doctors?: PartnerDoctorProps[];
	isPublished: boolean;
	isConfirmedPartner: boolean;
	phone?: string;
	email?: string;

	imageCoverURL?: string;
	imageLogoURL?: string;
	// doctors?: PartnerDoctorProps[];

	statusLoading?: boolean;
	openHours?: PartnerOpenHoursProps[];
	user: CustomerProps;
}

export interface PharmacyProps extends PartnerProps {
	medicine?: {
		totalMedicine: number;
		totalPrescribed: number;
		totalPrescribedPoints: number;
		totalPrescribedXQuantity: number;
		totalPrescribedPointsXQuantity: number;
		totalPrescribeCurrentMedicine: number;
		totalPointCurrentMedicine: number;
		totalPointXQuantity: number;
	};
}


export interface FetchAllPartnerResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: PartnerProps[];
	};
}

export interface FetchOnePartnerResponse extends BaseResponseProps {
	payload: PartnerProps;
}

export const initialPartner: PartnerProps = {
	partnerId: '',
	partnerType: EPartnerType.CLINIC,
	partnerName: '',
	address: '',
	geolocation: {
		latitude: 0,
		longitude: 0,
	},
	isPublished: false,
	isConfirmedPartner: false,
	phone: '',
	email: '',

	statusLoading: false,
	user: initialCustomer,
};

export const initialPharmacy: PartnerProps = {
	partnerId: '',
	partnerType: EPartnerType.PHARMACY,
	partnerName: '',
	address: '',
	geolocation: {
		latitude: 0,
		longitude: 0,
	},
	isPublished: false,
	isConfirmedPartner: false,
	phone: '',
	email: '',

	statusLoading: false,
	user: initialCustomer,
};


export const initialPublisher: PartnerProps = {
	partnerId: '',
	partnerType: EPartnerType.PUBLISHER,
	partnerName: '',
	address: '',
	isPublished: false,
	isConfirmedPartner: false,
	phone: '',
	email: '',

	statusLoading: false,
	user: initialCustomer,
};