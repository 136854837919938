import { CategoryProps, initialCategory } from './category.type';
import { BaseResponseProps } from './config.type';
import { initialPartner, PartnerProps } from './partner.type';

export interface IndividualServiceProps {
	healthServiceId: string;
	healthServiceName: string;
	categoryId: string;
	description?: string;
	isPublished: boolean;
	updatedAt?: Date;
	createdAt?: Date;
	category?: CategoryProps;
	partnerIndividualHealthServices: PartnerIndividualHealthServicesProps[];
	statusLoading?: boolean;
}

export interface FetchAllIndividualService extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: IndividualServiceProps[];
	};
}

export const initialIndividualService: IndividualServiceProps = {
	healthServiceId: '',
	healthServiceName: '',
	categoryId: '',
	description: '',
	isPublished: true,
	partnerIndividualHealthServices: [],
	statusLoading: false,
};

export const initialIndividualFetchAll: FetchAllIndividualService = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialIndividualService],
	},
};

export interface PartnerIndividualHealthServicesProps {
	id: string;
	partnerId: string;
	healthServiceId: string;
	price?: number;
	setting?: any;
	updatedAt?: Date;
	createdAt?: Date;
	individualHealthService: IndividualServiceProps;
	partner: PartnerProps;
}

export const initialPartnerIndividualHealthServicesProps: PartnerIndividualHealthServicesProps =
	{
		id: '',
		partnerId: '',
		healthServiceId: '',
		price: 0,
		updatedAt: new Date(),
		createdAt: new Date(),
		individualHealthService: initialIndividualService,
		partner: initialPartner,
	};
