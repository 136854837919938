import { Space } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import HTMLPreview from '../../components/HTMLPreview';
import { BannerProps, initialBanner } from '../../types/banner.type';

interface IParams {
	bannerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: BannerProps;
}

const PromoDealsDetail = () => {
	const history = useHistory();
	const { bannerId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [banner, setBanner] = React.useState<BannerProps>(initialBanner);

	const handleClickEdit = () => {
		history.push(`/promodeals/${banner.bannerId}/edit`);
	};

	React.useEffect(() => {
		const fetchDetailBanner = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/banners/' + bannerId,
				);

				setBanner(res.data.payload);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailBanner();
	}, [bannerId]);
	console.log(banner);
	const renderRelatedContentType = (bannerType: string) => {
		switch (bannerType) {
			case 'ARTICLE':
			case 'VOUCHER':
			case 'MEDICAL_EVENT':
				return (
					<DetailItem
						label="Related Content ID"
						content={banner.relatedContentId || ''}
					/>
				);
			case 'URL':
				return (
					<DetailItem
						label="Related Content URL"
						content={banner.relatedContentUrl || ''}
					/>
				);
			case 'OWN_CONTENT':
				return (
					<DetailItem
						label="Content"
						content={<HTMLPreview html={banner.content || ''} />}
					/>
				);
			case 'ORDER_CONSULTATION':
			case 'ORDER_MEDICAL_SERVICE':
			case 'REFER_A_FRIEND':
				return (
					<DetailItem
						label="Related Content Type"
						content={banner.relatedContentType || ''}
					/>
				);
		}
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Promo Deals"
				subtitle="Manage your promo deals data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				{/* <SectionContent groupTitle="Banner Preview">
          <HeaderBannerItem rightImageUrl={banner.bannerImageUrl || ''} title={banner.title || 'title'} content={banner.content || ''} />
        </SectionContent> */}
				<SectionContent
					groupTitle="Information"
					helpers={[
						{
							title: 'Information',
							content:
								'Accepted image type are jpg, jpeg and png. Max file size is 1 Mb.',
						},
					]}
				>
					<DetailItem
						type="image"
						label="Promo Deals Image"
						content={banner?.bannerImageUrl || ''}
					/>
					<DetailItem label="Title" content={banner?.title || ''} />
					<DetailItem
						label="Promo Deals Type"
						content={banner?.bannerType.replace(/_/g, ' ') || ''}
					/>
					{renderRelatedContentType(banner?.bannerType)}
					<DetailItem
						label="Target"
						content={
							banner?.isPublished && banner?.target !== null
								? banner.target?.length < 2
									? banner.target.map((element) => {
											return <h4>{element}</h4>;
									  })
									: 'ALL'
								: '-'
						}
					/>
					<DetailItem
						label="Status"
						content={banner?.isPublished ? 'Published' : 'Unpublished' || ''}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default PromoDealsDetail;
