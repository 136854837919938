import { Image, Space } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import HTMLPreview from '../../components/HTMLPreview';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { EventProps, initialEvent } from '../../types/event.type';
import { EventTagProps } from '../../types/eventTag.type';

interface IParams {
	eventId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EventProps, 'createdAt' | 'updatedAt'>;
}

const EventDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { eventId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
		url: '',
		uid: '',
		name: '',
	});
	const [images, setImages] = React.useState<
		UploadChangeParam<UploadFile<any>>['fileList']
	>([]);
	const [event, setEvent] = React.useState<EventProps>(initialEvent);

	React.useEffect(() => {
		const fetchEventDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>('/events/' + eventId);
				setEvent(res.data.payload);

				const dataImages = (res.data.payload.imageUrls || []).map((item) => ({
					url: item || '',
					uid: '',
					name: '',
				}));

				setImages(dataImages as any[]);

				if (dataImages.length > 0) {
					setSelectedImage(dataImages[dataImages.length - 1]);
				}

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'eventId',
						value: eventId,
						label: res.data.payload.title,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetchEventDetail();
	}, [eventId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Medical Event Detail"
				subtitle="Manage your event data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() =>
								history.push('/medical-event/' + eventId + '/edit')
							}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="Medical Event Information"
					helpers={[
						{
							title: 'Information',
							content:
								'Accepted image type are jpg, jpeg and png. Max file size is 1 Mb.',
						},
					]}
				>
					<Space
						style={{
							width: '100%',
							background: 'grey',
							justifyContent: 'center',
							marginBottom: '20px',
						}}
					>
						<Image
							preview={false}
							width="100%"
							height={200}
							src={selectedImage.url || '/images/select-image.jpg'}
							fallback={'/images/blur-image.jpeg'}
							style={{ objectFit: 'cover' }}
							placeholder={
								<Image
									preview={false}
									src="/images/blur-image.jpeg"
									width="100%"
									height={200}
									style={{ objectFit: 'cover' }}
								/>
							}
						/>
					</Space>

					<DetailItem label="Headline" content={event.title} />

					<DetailItem
						label="Description"
						content={<HTMLPreview html={event.description} />}
					/>

					<DetailItem label="Event Organizer" content={event.eventOrganizer} />

					<DetailItem
						label="is CPD Available"
						content={event.isCPDAvailable ? 'True' : 'False'}
					/>

					<DetailItem
						label="Medical Event Date"
						content={
							formatDate(event.eventStartAt) +
							' ' +
							moment(event.registerStartAt).hour().toString().padStart(2, '0') +
							':' +
							moment(event.registerStartAt)
								.minute()
								.toString()
								.padStart(2, '0') +
							' - ' +
							formatDate(event.eventEndAt) +
							' ' +
							moment(event.registerEndAt).hour().toString().padStart(2, '0') +
							':' +
							moment(event.registerEndAt).minute().toString().padStart(2, '0')
						}
					/>

					<DetailItem
						label="Specialities"
						content={
							<>
								{event.specialists
									? event.specialists
											.slice()
											.map((el) => <HTMLPreview html={el.specialistName} />)
									: []}
							</>
						}
					/>

					<DetailItem
						label="Medical Event Tags"
						content={sortTags(event.tags || [])
							.map((item) => item.tagName)
							.join(', ')}
					/>

					<DetailItem
						label="Webinar Register Link"
						content={event.registerUrl}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default EventDetail;

function sortTags(tags: EventTagProps[]) {
	tags.sort((a, b) => a.tagName.localeCompare(b.tagName));
	return tags;
}
