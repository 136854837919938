import {
	AppstoreAddOutlined,
	CloudUploadOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	DatePicker,
	Divider,
	Input,
	message,
	Modal,
	Pagination,
	Select,
	Table,
	Tabs,
	Typography,
	Form,
	Badge,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../../components/HeaderSection';
import { getFullName } from '../../../helpers/name';
import useFetchList from '../../../hooks/useFetchList';
import type { UploadProps } from 'antd';
import {
	EConsultationType,
	TransactionConsultationProps,
	TRANSACTION_STATUS_SETTINGS,
	ETransactionStatusType,
} from '../../../types/transactionConsultation.type';
import AppButton from '../../../components/AppButton';
import Dragger from 'antd/lib/upload/Dragger';
import { httpRequest } from '../../../helpers/api';
import useApproval from '../../../hooks/useApproval';
import {
	ERefundStatus,
	REFUND_STATUS_SETTINGS,
	TransactionConsultRefundProps,
} from '../../../types/refund.type';

let formatPrice = Intl.NumberFormat('en-US');

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const TransactionConsultation = (props: { isTest?: boolean }) => {
	const history = useHistory();

	const [activeKey, setActiveKey] = useState<string>('1');
	const { refundLength, setRefundLength } = useApproval();

	const [isModalApprove, setIsModalApprove] = useState<boolean>(false);
	const [isModalConfirmApprove, setIsModalConfirmApprove] =
		useState<boolean>(false);
	const [isModalConfirmReject, setIsModalConfirmReject] =
		useState<boolean>(false);

	const [tmpData, setTmpData] =
		useState<TransactionConsultRefundProps | null>();
	const [uploadedImage, setUploadedImage] = useState<any>('');

	const [reason, setReason] = useState<string>('');

	const { isLoading, data, pagination, setSearch, setQuery, changePage } =
		useFetchList<TransactionConsultationProps>({
			endpoint: 'transaction-consults',
			limit: 10,
			initialQuery: {
				isTestAccount: props.isTest,
			},
		});

	const {
		isLoading: isLoadingRefund,
		data: dataRefund,
		pagination: paginationRefund,
		setSearch: setSearchRefund,
		setQuery: setQueryRefund,
		changePage: changePageRefund,
		setIsLoading,
	} = useFetchList<TransactionConsultRefundProps>({
		endpoint: 'transaction-consults/refund/find-all',
		limit: 10,
		initialQuery: {
			status: ERefundStatus.PENDING,
			isTestAccount: props.isTest,
		},
	});

	useEffect(() => {
		setRefundLength(paginationRefund.totalData);
	}, [paginationRefund]);

	const handleChangeTab = async (key: string) => {
		setActiveKey(key);
	};

	const handleFilterByDate = (date: any) => {
		if (date) {
			setQuery((oldVal) => ({ ...oldVal, filterDate: date }));
			setQueryRefund((oldVal) => ({ ...oldVal, filterDate: date }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterDate: undefined }));
			setQueryRefund((oldVal) => ({ ...oldVal, filterDate: undefined }));
		}
	};

	const handleFilterByType = (type: string) => {
		if (type !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, filterConsultationTypes: type }));
			setQueryRefund((oldVal) => ({
				...oldVal,
				filterConsultationTypes: type,
			}));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterConsultationTypes: undefined }));
			setQueryRefund((oldVal) => ({
				...oldVal,
				filterConsultationTypes: undefined,
			}));
		}
	};

	const handleFilterByStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, filterTransactionStatuses: status }));
			setQueryRefund((oldVal) => ({
				...oldVal,
				filterTransactionStatuses: status,
			}));
		} else {
			setQuery((oldVal) => ({
				...oldVal,
				filterTransactionStatuses: undefined,
			}));
		}
	};

	const handleSearch = (e: any) => {
		setSearch(e);
		setSearchRefund(e);
	};

	const handleClickDetail = (e: TransactionConsultationProps) => {
		history.push(`/transaction-consultations/${e.transactionConsultationId}`);
		console.log(e);
	};

	const handleReject = () => {
		setIsModalApprove(false);
		setIsModalConfirmReject(true);
	};

	const handleSendReject = () => {
		handleSubmitApprove('reject', reason);
	};

	const handleOkApprove = () => {
		setIsModalApprove(false);
		setIsModalConfirmApprove(true);
	};

	const handleSendApprove = () => {
		handleSubmitApprove('approve', tmpData);
	};

	const handleCancel = () => {
		setIsModalApprove(false);
		setIsModalConfirmApprove(false);
		setIsModalConfirmReject(false);
		setTmpData(null);
		setUploadedImage('');
	};

	const uploadProps: UploadProps = {
		name: 'file',
		accept: '.jpg,.jpeg,.png,.pdf',
		multiple: false,
		customRequest({ file, onSuccess }) {
			setTimeout(() => {
				onSuccess!('ok');
			}, 0);
		},
		onChange(info) {
			setUploadedImage(info.file.originFileObj);
		},
		onDrop(e) {
			setUploadedImage('');
		},
	};

	const handleSubmitApprove = async (type: string, values: any) => {
		switch (type) {
			case 'approve':
				try {
					setIsLoading(true);
					const formData = new FormData();

					if (uploadedImage !== '') {
						formData.append('image', uploadedImage);
						formData.append('status', 'APPROVED');
					}
					const res = await httpRequest.patch(
						`/transaction-consults/refund/${tmpData?.refundId}`,
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						},
					);
					console.log(res);

					message.success('Update approval success');

					setIsLoading(false);

					window.location.reload();
				} catch (e) {
					console.log(e);

					message.error('ERROR: cannot approve, please try again!');
					setIsLoading(false);
				} finally {
					setIsModalApprove(false);
					setIsModalConfirmApprove(false);
					setIsModalConfirmReject(false);
				}
				break;

			case 'reject':
				try {
					setIsLoading(true);

					const formData = {
						reason: values,
						status: 'REJECT',
					};

					const res = await httpRequest.patch(
						`/transaction-consults/refund/${tmpData?.refundId}`,
						formData,
					);
					console.log(res);

					message.success('Update approval success');

					setIsLoading(false);

					window.location.reload();
				} catch (e) {
					message.error('ERROR: cannot approve, please try again!');
					setIsLoading(false);
				} finally {
					setIsModalApprove(false);
					setIsModalConfirmApprove(false);
					setIsModalConfirmReject(false);
				}
				break;
		}
	};

	const columns = [
		{
			title: 'INVOICE ID',
			key: 'transactionConsultationId',
			width: 100,
			render: (_: string, record: TransactionConsultationProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.payment ? (
							record.payment.invoiceId
						) : (
							<Text italic type="secondary">
								Payment Not Made Yet
							</Text>
						)}
					</div>
				);
			},
		},
		{
			title: 'PARTICIPANTS',
			key: 'participants',
			render: (_: any, record: TransactionConsultationProps) => {
				return (
					<>
						<div>
							{record.metaPatient?.name || getFullName(record.metaPatient)}
						</div>
						<div>{record.metaDoctor?.nameOfSignature || 'No Doctor'}</div>
					</>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'STATUS',
			key: 'transactionStatus',
			dataIndex: 'transactionStatus',
			width: 200,
			render: (transactionStatus: ETransactionStatusType) => (
				<div
					style={
						transactionStatus
							? {
									border:
										'2px solid ' +
										TRANSACTION_STATUS_SETTINGS[transactionStatus]?.color,
									color: TRANSACTION_STATUS_SETTINGS[transactionStatus]?.color,
									borderRadius: '0',
									display: 'inline',
									padding: '0 10px',
									fontSize: 11,
							  }
							: {}
					}
				>
					{transactionStatus
						? TRANSACTION_STATUS_SETTINGS[transactionStatus]?.label
						: '-'}
				</div>
			),
		},
		{
			title: 'TYPE',
			key: 'consultationType',
			dataIndex: 'consultationType',
			render: (consultationType: EConsultationType) => (
				<>
					{consultationType === EConsultationType.CONSULT_NOW_CHAT_CONSULTATION
						? 'Consult Now (Chat Only)'
						: consultationType ===
						  EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
						? 'Consult Now (Video & Chat)'
						: consultationType === EConsultationType.FACE_TO_FACE_CONSULTATION
						? 'In Person'
						: consultationType ===
						  EConsultationType.TELEMEDICINE_CHAT_CONSULTATION
						? 'Telemedicine (Chat Only)'
						: 'Telemedicine (Video & Chat)'}
				</>
			),
		},
		{
			title: 'TOTAL PAY',
			key: 'totalNeedToPay',
			dataIndex: 'totalNeedToPay',
			render: (_: string, record: TransactionConsultationProps) => (
				<>
					₱{' '}
					{record.payment
						? formatPrice.format(record.payment.totalNeedToPay)
						: 0}
				</>
			),
		},
		// {
		// 	title: '',
		// 	key: 'action',
		// 	render: (_: any, record: TransactionConsultationProps) => (
		// 		<Dropdown overlay={() => menu(record)} placement="bottomRight">
		// 			<MoreOutlined style={{ cursor: 'pointer' }} />
		// 		</Dropdown>
		// 	),
		// },
	];

	const columnRefunds = [
		{
			title: 'INVOICE ID',
			key: 'transactionConsultationId',
			width: 100,
			render: (_: string, record: TransactionConsultRefundProps) => {
				return (
					<div
						className="table-link"
						onClick={() =>
							record.transactionConsultation
								? handleClickDetail(record.transactionConsultation)
								: null
						}
					>
						{record.payment ? (
							record.payment.invoiceId
						) : (
							<Text italic type="secondary">
								Payment Not Made Yet
							</Text>
						)}
					</div>
				);
			},
		},
		{
			title: 'PARTICIPANTS',
			key: 'participants',
			render: (_: string, record: TransactionConsultRefundProps) => {
				return (
					<>
						<div>
							{record.transactionConsultation?.metaPatient?.name ||
								getFullName(record.transactionConsultation?.metaPatient)}
						</div>
						<div>
							{record.transactionConsultation?.metaDoctor?.nameOfSignature ||
								'No Doctor'}
						</div>
					</>
				);
			},
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			width: 200,
			render: (status: ERefundStatus) => (
				<div
					style={
						status
							? {
									border: '2px solid ' + REFUND_STATUS_SETTINGS[status]?.color,
									color: REFUND_STATUS_SETTINGS[status]?.color,
									borderRadius: '0',
									display: 'inline',
									padding: '0 10px',
									fontSize: 11,
							  }
							: {}
					}
				>
					{status ? REFUND_STATUS_SETTINGS[status]?.label : '-'}
				</div>
			),
		},
		{
			title: 'TYPE',
			key: 'consultationType',
			dataIndex: 'consultationType',
			render: (_: string, record: TransactionConsultRefundProps) => (
				<>
					{record.transactionConsultation?.consultationType ===
					EConsultationType.CONSULT_NOW_CHAT_CONSULTATION
						? 'Consult Now (Chat Only)'
						: record.transactionConsultation?.consultationType ===
						  EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
						? 'Consult Now (Video & Chat)'
						: record.transactionConsultation?.consultationType ===
						  EConsultationType.FACE_TO_FACE_CONSULTATION
						? 'In Person'
						: record.transactionConsultation?.consultationType ===
						  EConsultationType.TELEMEDICINE_CHAT_CONSULTATION
						? 'Telemedicine (Chat Only)'
						: 'Telemedicine (Video & Chat)'}
				</>
			),
		},
		{
			title: 'REFUND PERCENTAGE',
			key: 'refundPercentage',
			dataIndex: 'refundPercentage',
			render: (refundPercentage: string) => <>{refundPercentage}</>,
		},
		{
			title: 'REFUND AMOUNT',
			key: 'refundAmount',
			dataIndex: 'refundAmount',
			render: (refundAmount: number) => (
				<>₱ {formatPrice.format(refundAmount)}</>
			),
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'ACTION',
			key: 'action',
			render: (_: any, record: TransactionConsultRefundProps) => (
				<AppButton
					type="primary"
					onClick={() => {
						setIsModalApprove(true);
						setTmpData(record);
					}}
				>
					Approve
				</AppButton>
			),
		},
	];

	// const menu = (record: TransactionConsultationProps) => (
	// 	<Menu onClick={(e) => handleClickAction(record, e.key)}>
	// 		<Menu.Item key="detail">Detail Consultation</Menu.Item>
	// 	</Menu>
	// );

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Transaction Consultation"
				subtitle="Manage your transaction consultation"
			/>

			<ContainerFilter>
				<Input
					style={{ width: 550 }}
					size="large"
					placeholder="Search Invoice ID, Patient name, Doctor name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => handleSearch(e.target.value)}
				/>
				<RangePicker
					size="large"
					allowEmpty={[true, true]}
					onCalendarChange={handleFilterByDate}
					showHour={false}
					showMinute={false}
					showSecond={false}
					showTime={false}
				/>
				<Select
					size="large"
					allowClear
					disabled={activeKey !== '1'}
					style={{ width: 240 }}
					onChange={handleFilterByStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value={ETransactionStatusType.WAITING_PAYMENT}>
						Waiting for Payment
					</Option>
					<Option value={ETransactionStatusType.WAITING_APPROVAL}>
						Waiting for Approval
					</Option>
					<Option value={ETransactionStatusType.APPROVED}>Approved</Option>
					<Option value={ETransactionStatusType.REJECTED}>Rejected</Option>
					<Option value={ETransactionStatusType.COMPLETED}>Completed</Option>
					<Option value={ETransactionStatusType.EXPIRED}>Expired</Option>
					<Option
						value={ETransactionStatusType.CANCELED_BY_DOCTOR_OR_SECRETARY}
					>
						Cancel - By Doctor
					</Option>
					<Option value={ETransactionStatusType.CANCELED_BY_USER}>
						Cancel - By User
					</Option>
					<Option value={ETransactionStatusType.CANCELED_BY_SYSTEM}>
						Cancel - By Medeasy
					</Option>
					<Option value={ETransactionStatusType.APPROVED_CHANGE_SCHEDULE}>
						Approved Change Schedule
					</Option>
					<Option value={ETransactionStatusType.PENDING_CHANGE_SCHEDULE}>
						Waiting Approval Change Schedule
					</Option>
				</Select>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterByType}
					placeholder="Type"
				>
					<Option value="all">All</Option>
					<Option
						value={`${EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION},${EConsultationType.TELEMEDICINE_CHAT_CONSULTATION}`}
					>
						Telemedicine
					</Option>
					<Option value={EConsultationType.FACE_TO_FACE_CONSULTATION}>
						In Person
					</Option>
					<Option
						value={`${EConsultationType.CONSULT_NOW_CHAT_CONSULTATION},${EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION}`}
					>
						Consult Now
					</Option>
				</Select>
			</ContainerFilter>
			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					onChange={(e) => handleChangeTab(e)}
				>
					<TabPane tab="All Consultation" key={'1'}>
						<Table
							loading={isLoading}
							columns={columns}
							dataSource={data}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<CustomPagination
								current={pagination.page}
								total={pagination.totalData}
								defaultPageSize={pagination.perPage}
								pageSizeOptions={['25', '50', '100']}
								showSizeChanger={true}
								showTotal={(total, range) =>
									`${range[0]} - ${range[1]} of ${total}`
								}
								onChange={changePage}
								locale={{ items_per_page: '' }}
								responsive={true}
							/>
						) : null}

						<PaginationText>
							<h4>Per Page</h4>
						</PaginationText>
					</TabPane>
					<TabPane
						tab={
							<>
								Pending Refund Approvals
								<Badge
									count={refundLength || 0}
									style={{
										marginLeft: 5,
										backgroundColor: '#FFC555',
										color: '#1D2B36',
										border: 'none',
										boxShadow: 'none',
										fontWeight: 600,
									}}
								/>
							</>
						}
						key={'2'}
					>
						<Table
							loading={isLoadingRefund}
							columns={columnRefunds}
							dataSource={dataRefund}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<CustomPagination
								current={paginationRefund.page}
								total={paginationRefund.totalData}
								defaultPageSize={paginationRefund.perPage}
								pageSizeOptions={['25', '50', '100']}
								showSizeChanger={true}
								showTotal={(total, range) =>
									`${range[0]} - ${range[1]} of ${total}`
								}
								onChange={changePageRefund}
								locale={{ items_per_page: '' }}
								responsive={true}
							/>
						) : null}

						<PaginationText>
							<h4>Per Page</h4>
						</PaginationText>
					</TabPane>
				</Tabs>

				{/* Modals */}
				<CustomModal
					title="Refund Approval"
					visible={isModalApprove}
					onOk={() => handleOkApprove()}
					onCancel={handleCancel}
					footer={[
						<AppButton onClick={handleCancel}>Cancel</AppButton>,
						<AppButton
							onClick={handleReject}
							style={{ borderColor: '#F23459', color: '#F23459' }}
						>
							Reject
						</AppButton>,
						<AppButton
							style={{ backgroundColor: '#39AC6E', color: 'white' }}
							onClick={() => handleOkApprove()}
							disabled={uploadedImage === '' ? true : false}
						>
							Approve
						</AppButton>,
					]}
				>
					<div style={{ fontSize: '1rem' }}>
						<p style={{ color: '#929292' }}>Patient Refund</p>
						<div style={{ marginTop: '-1rem', fontStyle: 'bold' }}>
							₱{tmpData?.refundAmount}
						</div>
					</div>
					<Divider style={{ marginTop: '-0.1rem' }} />
					<div style={{ color: '#929292', marginTop: '-1.5rem' }}>
						Patient Receipt
					</div>
					<Dragger {...uploadProps} style={{ marginTop: '-0.6rem' }}>
						<p className="ant-upload-drag-icon">
							<CloudUploadOutlined />
						</p>
						<p className="ant-upload-text">
							Drag and drop file here or browse files
						</p>
						<p className="ant-upload-hint">
							Supported file are .png .jpg .pdf. Maximum upload file size is 5
							MB.
						</p>
					</Dragger>
				</CustomModal>

				<CustomModal
					title="Confirmation"
					visible={isModalConfirmApprove}
					onOk={() => handleSendApprove()}
					onCancel={handleCancel}
					footer={[
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<AppButton onClick={handleCancel} style={{ width: '50%' }}>
								Cancel
							</AppButton>
							,
							<AppButton
								type="primary"
								onClick={() => handleSendApprove()}
								style={{ width: '50%' }}
							>
								Save
							</AppButton>
						</div>,
					]}
				>
					<div style={{ textAlign: 'center' }}>
						<h3>Are you sure want to approve this refund?</h3>
						<p style={{ fontWeight: 400 }}>
							Double-check before saving, make sure the data is accurate with
							PWD/Senior ID
						</p>
					</div>
				</CustomModal>

				<CustomModal
					title="Confirmation"
					visible={isModalConfirmReject}
					onOk={() => handleSendReject()}
					onCancel={handleCancel}
					footer={[
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<AppButton onClick={handleCancel} style={{ width: '50%' }}>
								Cancel
							</AppButton>
							,
							<AppButton
								type="primary"
								onClick={() => handleSendReject()}
								style={{ width: '50%' }}
							>
								Save
							</AppButton>
						</div>,
					]}
				>
					<div style={{ textAlign: 'center' }}>
						<h3>Are you sure want to reject this refund?</h3>
						<p style={{ fontWeight: 400 }}>
							Double-check before saving to ensure the data is accurate with the
							refund information
						</p>
					</div>
					<div>
						<Form>
							<label>Reason</label>
							<TextArea
								rows={5}
								placeholder="Input the reason of rejection"
								style={{ marginTop: '0.2rem' }}
								onChange={(e) => setReason(e.target.value)}
							/>
						</Form>
					</div>
				</CustomModal>
			</div>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default TransactionConsultation;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const CustomModal = styled(Modal)`
	.ant-modal,
	.ant-modal-content {
		min-width: 630px;
	}
	.ant-modal-title {
		font-size: 20px;
		font-weight: 600;
		color: #556575;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
	}
	.ant-typography {
		font-weight: 400;
		letter-spacing: 0.02em;
		line-height: 150%;
	}
	span.ant-typography {
		font-size: 12px;
		color: #556575;
		text-transform: capitalize;
	}
	div.ant-typography {
		font-size: 14px;
		color: #1d2b36;
		margin-bottom: 0;
		/* font-weight: 600; */
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
