import { BaseResponseProps } from './config.type';

export interface PrivacyPolicyProps {
	content: string;
}

export interface FetchAllPrivacyPolicyResponse extends BaseResponseProps {
	payload: PrivacyPolicyProps;
}

export const initialPrivacyPolicy: PrivacyPolicyProps = {
	content: '',
};
