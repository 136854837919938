import { Tag } from 'antd';
import { theme } from '../assets/theme';

interface IProps {
	children?: string;
}

export default function KycTag(props: IProps) {
	if (!props.children) {
		return <></>;
	}

	let kycStatus = props.children
		.toLowerCase()
		.split('_')
		.map((status) => status[0].toUpperCase() + status.slice(1))
		.join(' ');
	let color = 'inherit';
	let backgroundColor = 'inherit';
	switch (kycStatus) {
		case 'Waiting Approval':
			color = theme.colors.yellow600;
			backgroundColor = theme.colors.yellow100;
			break;
		case 'Rejected':
			color = theme.colors.pink900;
			backgroundColor = theme.colors.pink100;
			break;
		case 'Approved':
			color = theme.colors.green500;
			backgroundColor = theme.colors.green100;
			break;
	}
	return <Tag style={{ color, backgroundColor }}>{kycStatus}</Tag>;
}
