import { Menu, Badge } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useConfigApp from '../hooks/useConfigApp';

export type MenuItemProps = {
	key?: string;
	label: string;
	counter: number;
	icon?: React.ReactNode;
	path?: string;
	permission: boolean | undefined;
	subMenu?: boolean;
	subMenuTitleGroup?: string;
	subMenuItems?: {
		label: string;
		path: string;
		counter: number;
		permission?: boolean;
		icon?: React.ReactNode;
	}[];
};

export type MenuSidebarProps = {
	data: {
		groupTitle: string;
		menus: Array<MenuItemProps>;
	};
};

const AppMenuItem: React.FC<MenuSidebarProps> = ({ data }) => {
	const { sidebarCollapsed } = useConfigApp();
	const location = useLocation();

	return (
		<Menu.ItemGroup key={data.groupTitle} title={data.groupTitle}>
			{data.menus.map((menu) => {
				if (menu.permission) {
					if (menu.subMenu) {
						return (
							<SubMenu
								key={menu.key}
								icon={<span>{menu.icon}</span>}
								title={
									<>
										<span style={{ marginLeft: 10 }}>
											{!sidebarCollapsed && menu.subMenuTitleGroup}
										</span>
										{!sidebarCollapsed && menu.counter > 0 && (
											<Badge
												count={menu.counter || 0}
												style={{
													marginLeft: 5,
													backgroundColor: '#FFC555',
													color: '#1D2B36',
													border: 'none',
													boxShadow: 'none',
													fontWeight: 600,
												}}
											/>
										)}
									</>
								}
								style={{
									// paddingLeft: 25,
									position: 'relative',
								}}
							>
								{(menu.subMenuItems as any).map((subMenuItem: any) => {
									return (
										<Menu.Item key={subMenuItem.path}>
											{subMenuItem.label}
											<span>
												<Badge
													count={subMenuItem.counter || 0}
													style={{
														marginLeft: 10,
														backgroundColor: '#FFC555',
														color: '#1D2B36',
														border: 'none',
														boxShadow: 'none',
														fontWeight: 600,
													}}
												/>
											</span>

											{location.pathname !== subMenuItem.path && (
												<Link to={subMenuItem.path || '/'} />
											)}
										</Menu.Item>
									);
								})}
							</SubMenu>
						);
					} else {
						return (
							<Menu.Item
								key={menu.path}
								className={
									menu.path === location.pathname
										? 'menu-item-collapsed-active'
										: ''
								}
								style={{
									paddingLeft: 25,
									position: 'relative',
								}}
								icon={
									<span>
										{menu.icon}
										{sidebarCollapsed && (
											<Badge
												count={menu.counter || 0}
												offset={menu.counter < 10 ? [-20, -20] : [-25, -21]}
												style={{
													marginLeft: 10,
													backgroundColor: '#FFC555',
													color: '#1D2B36',
													border: 'none',
													boxShadow: 'none',
													fontWeight: 600,
												}}
											/>
										)}
									</span>
								}
							>
								{menu.label}
								{!sidebarCollapsed && (
									<Badge
										count={menu.counter || 0}
										style={{
											marginLeft: 10,
											backgroundColor: '#FFC555',
											color: '#1D2B36',
											border: 'none',
											boxShadow: 'none',
											fontWeight: 600,
										}}
									/>
								)}
								{location.pathname !== menu.path && (
									<Link to={menu.path || '/'} />
								)}
							</Menu.Item>
						);
					}
				} else {
					return false;
				}
			})}
		</Menu.ItemGroup>
	);
};

export default AppMenuItem;
