import { PaymentDetailProps } from './paymentDetail.type';
import { PaymentDetailPartnerProps } from './paymentDetailPartner.type';
import { TransactionConsultRefundProps } from './refund.type';
import { TransactionConsultationProps } from './transactionConsultation.type';
import { TransactionHealthServiceProps } from './transactionHealthService.type';

export enum EPaymentTransactionType {
	TELEMEDICINE = 'TELEMEDICINE',
	HEALTH_SERVICE = 'HEALTH_SERVICE',
	DOCTOR_DOCUMENT = 'DOCTOR_DOCUMENT',
	IN_PERSON = 'IN_PERSON',
	CONSULT_NOW = 'CONSULT_NOW',
	EBOOK = 'EBOOK',
}

export enum EPaymentStatus {
	WAITING_PAYMENT = 'WAITING_PAYMENT',
	PAID_FULL = 'PAID_FULL',
	CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
	CANCELLED_BY_USER = 'CANCELLED_BY_USER',
	EXPIRED = 'EXPIRED',
}

export interface PaymentProps {
	paymentId: string;
	invoiceId: string;
	transactionId: string;
	transactionType: EPaymentTransactionType;
	totalNeedToPay: number;
	paymentStatus: EPaymentStatus;
	buyerId: string;
	metaBuyerDetail: any;
	meta?: any;
	expiredAt: Date;
	updatedAt: Date;
	createdAt: Date;
	paymentDetails?: PaymentDetailProps[];
	paymentDetailsPartners?: PaymentDetailPartnerProps[];
	refund?: TransactionConsultRefundProps;
	transactionConsultation?: TransactionConsultationProps;
	transactionHealthService?: TransactionHealthServiceProps;
}
