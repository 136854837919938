import { IUser } from '../types/user.type';
import { capitalizeFirstLetter } from './text';
export const APP_AUTH_TOKEN = process.env.REACT_APP_ID + '_auth';
export const APP_AUTH_USER = process.env.REACT_APP_ID + '_user';

export async function saveToken(token: string) {
	return localStorage.setItem(APP_AUTH_TOKEN, token);
}

export async function getToken() {
	return localStorage.getItem(APP_AUTH_TOKEN);
}

export async function removeToken() {
	localStorage.removeItem(APP_AUTH_TOKEN);
}

export async function saveUser(user: IUser) {
	return localStorage.setItem(APP_AUTH_USER, JSON.stringify(user));
}

export function getUser() {
	const userString = localStorage.getItem(APP_AUTH_USER);
	if (userString) {
		return JSON.parse(userString);
	}
	return undefined;
}

export const ROLES: { [key: string]: string } = {
	admin: 'Admin',
	customer: 'Customer',
	customer_premium: 'Premium Customer',
	customer_trainer: 'Trainer',
};

export function showRoleName(role: string) {
	if (ROLES[role]) {
		return ROLES[role];
	} else {
		return role
			.split('_')
			.map((item) => capitalizeFirstLetter(item))
			.join(' ');
	}
}
