import { PaymentProps } from './payment.type';

export enum EPaymentType {
	PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
	DISCOUNT_VOUCHER = 'DISCOUNT_VOUCHER',
	POINT = 'POINT',
	BASE_PRICE = 'BASE_PRICE',
	PATIENT_PWD_DISCOUNT = 'PATIENT_PWD_DISCOUNT',
	PATIENT_TRANSACTION_FEE = 'PATIENT_TRANSACTION_FEE',
	PATIENT_HOME_SERVICE_FEE = 'PATIENT_HOME_SERVICE_FEE',
	DOCTOR_COMMISSION_FEE = 'DOCTOR_COMMISSION_FEE',
	DOCTOR_TRANSACTION_FEE = 'DOCTOR_TRANSACTION_FEE',
	DOCTOR_DOCTOR_DOCUMENT_FEE = 'DOCTOR_DOCTOR_DOCUMENT_FEE',
	DOCTOR_INCOME = 'DOCTOR_INCOME',
	MEDICALSERVICE_LAB_COMMISSION_FEE = 'MEDICALSERVICE_LAB_COMMISSION_FEE',
	MEDICALSERVICE_LAB_TRANSACTION_FEE = 'MEDICALSERVICE_LAB_TRANSACTION_FEE',
	TELEMEDICINE_EXTEND = 'TELEMEDICINE_EXTEND',
	FINAL_PAYMENT = 'FINAL_PAYMENT',
	PATIENT_REFUND_RECEIVED = 'PATIENT_REFUND_RECEIVED',
	DOCTOR_REFUND_RECEIVED = 'DOCTOR_REFUND_RECEIVED',
	LAB_REFUND_RECEIVED = 'LAB_REFUND_RECEIVED',
}

export enum EPaymentMethod {
	BANK_TRANSFER = 'BANK_TRANSFER',
	EWALLET = 'EWALLET',
	CREDIT_CARD = 'CREDIT_CARD',
}

export enum EPaymentTypeVendor {
	PAYMONGO = 'PAYMONGO',
}

export enum EPaymentVendorId {
	CARD = 'CARD',
	GCASH = 'GCASH',
	GRABPAY = 'GRABPAY',
	MAYA = 'MAYA',
	BPI = 'BPI',
}

export enum EPaymentDetailType {
	PAYMENT = 'PAYMENT',
	DISCOUNT = 'DISCOUNT',
	PAYMENT_EXTEND = 'PAYMENT_EXTEND',
}

enum EPaymentStatus {
	WAITING_PAYMENT = 'WAITING_PAYMENT',
	PAID_FULL = 'PAID_FULL',
	CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
	CANCELLED_BY_USER = 'CANCELLED_BY_USER',
	EXPIRED = 'EXPIRED',
}

export interface PaymentDetailProps {
	paymentDetailId: string;
	paymentId: string;
	paymentType: EPaymentType;
	paymentMethod?: EPaymentMethod;
	paymentVendorType?: EPaymentTypeVendor;
	paymentVendorId?: EPaymentVendorId;
	externalId?: string;
	totalPay: number;
	status: EPaymentStatus;
	meta: any;
	type: EPaymentDetailType;
	expiredAt: Date;
	updatedAt: Date;
	createdAt: Date;
	payment?: PaymentProps;
}
