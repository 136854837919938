import { CategoryProps } from './category.type';
import { BaseResponseProps } from './config.type';

export interface VariantProps {
	productVariantId?: string;
	productId?: string;
	pricePerItem: number;
	productVariantName: string;
	isDefaultVariant: boolean;
	createdAt?: string;
	updatedAt?: string;
}

export interface ProductProps {
	productId?: string;
	productName: string;
	description: string;
	isPublished: boolean;
	imageUrls?: string[];
	variants: VariantProps[];
	categories?: CategoryProps[];
	updatedAt?: Date | string;
	createdAt?: Date | string;
	statusLoading?: boolean;
}

export interface FetchAllProductResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: ProductProps[];
	};
}

export const initialVariant: VariantProps = {
	pricePerItem: 0,
	productVariantName: 'Default',
	isDefaultVariant: true,
};

export const initialProduct: ProductProps = {
	productId: '',
	productName: '',
	description: '',
	isPublished: true,
	imageUrls: [''],
	variants: [initialVariant],
	categories: [],
	updatedAt: '',
	createdAt: '',
	statusLoading: false,
};
