import React, { useEffect } from 'react';
import {
	SendOutlined,
	SearchOutlined,
	CloudUploadOutlined,
} from '@ant-design/icons';
import {
	Pagination,
	Table,
	Menu,
	Input,
	Typography,
	Col,
	Row,
	Upload,
	Modal,
	message,
	Form,
	Image,
} from 'antd';
import {
	RedeemPointProperties,
	ERedeemPointStatus,
} from '../../types/redeemPoint.type';
import { replaceUnderscoreWithSpace } from '../../helpers/replaceUnderscoreWithSpace';
import AppButton from '../../components/AppButton';
import type { UploadProps } from 'antd';
import type { MenuProps } from 'antd';
import HeaderSection from '../../components/HeaderSection';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import moment from 'moment';
import { httpRequest } from '../../helpers/api';

const { Text } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;

const Redeem = () => {
	const [current, setCurrent] = React.useState('needAction');

	const [isModalApprove, setIsModalApprove] = React.useState(false);
	const [isModalReject, setIsModalReject] = React.useState(false);
	const [isModalReason, setIsModalReason] = React.useState(false);

	const [tmpData, setTmpData] = React.useState<RedeemPointProperties>();
	const [uploadedImage, setUploadedImage] = React.useState<any>('');
	const [preview, setPreview] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>('');

	const {
		isLoading,
		data,
		pagination,
		setSearch,
		changePage,
		setIsLoading,
		fetchList,
		setQuery,
	} = useFetchList<RedeemPointProperties>({
		endpoint: 'redeem-points',
		initialQuery: {
			status: ERedeemPointStatus.WAITING_APPROVAL,
		},
	});

	useEffect(() => {
		if (current === 'needAction') {
			setQuery((prev) => ({
				...prev,
				status: ERedeemPointStatus.WAITING_APPROVAL,
			}));
		} else if (current === 'approved') {
			setQuery((prev) => ({
				...prev,
				status: ERedeemPointStatus.APPROVED,
			}));
		} else if (current === 'rejected') {
			setQuery((prev) => ({
				...prev,
				status: ERedeemPointStatus.REJECTED,
			}));
		}
	}, [current]);

	const handleSubmitReject = async () => {
		try {
			setIsLoading(true);

			await httpRequest.patch(
				`/redeem-points/${tmpData!.redeemPointId}/reject`,
				{
					...tmpData,
					reason: reason,
				},
			);

			fetchList();
		} catch (e) {
			message.error('ERROR: cannot reject, please try again!');
		} finally {
			setIsLoading(false);
			setIsModalReject(false);
		}
	};

	const handleSubmitApprove = async () => {
		try {
			setIsLoading(true);
			const formData = new FormData();

			if (uploadedImage !== '') {
				formData.append('image', uploadedImage);
			}

			await httpRequest.patch(
				`/redeem-points/${tmpData!.redeemPointId}/approve`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			fetchList();
		} catch (e) {
			message.error('ERROR: cannot approve, please try again!');
		} finally {
			setIsLoading(false);
			setIsModalApprove(false);
		}
	};

	const handleOkReject = async () => {
		setIsModalReject(false);
		await handleSubmitReject();
	};

	const handleOkApprove = () => {
		setIsModalApprove(false);
		handleSubmitApprove();
	};

	const handleCancel = () => {
		setIsModalApprove(false);
		setIsModalReject(false);
		setIsModalReason(false);
		setUploadedImage('');
		setReason('');
	};

	const uploadProps: UploadProps = {
		name: 'file',
		accept: '.jpg,.jpeg,.png,.pdf',
		multiple: false,
		maxCount: 1,
		customRequest({ file, onSuccess }) {
			setTimeout(() => {
				onSuccess!('ok');
			}, 0);
		},
		onChange(info) {
			setUploadedImage(info.file.originFileObj);
		},
		onDrop(e) {
			setUploadedImage('');
		},
	};

	const onMenuClick: MenuProps['onClick'] = (e) => {
		setCurrent(e.key);
	};

	// Table
	const items: MenuProps['items'] = [
		{
			label: 'Need Action',
			key: 'needAction',
		},
		{
			label: 'Approved',
			key: 'approved',
		},
		{
			label: 'Rejected',
			key: 'rejected',
		},
	];

	const columns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: RedeemPointProperties) => {
				return <Text>{record.metaUser.name}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: RedeemPointProperties) => {
				return <Text>{record.metaUser.phone}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankCode',
			key: 'bankCode',
			render: (text: string, record: RedeemPointProperties) => {
				return (
					<Text>
						{record.metaBankAccount.bankCode
							? replaceUnderscoreWithSpace(record.metaBankAccount.bankCode)
							: replaceUnderscoreWithSpace(record.metaBankAccount.bankName)}
					</Text>
				);
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'accountNumber',
			key: 'accountNumber',
			render: (text: string, record: RedeemPointProperties) => {
				return <Text>{record.metaBankAccount.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'nameInBankAccount',
			key: 'nameInBankAccount',
			render: (text: string, record: RedeemPointProperties) => {
				return <Text>{record.metaBankAccount.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			key: 'amount',
			render: (text: string, record: RedeemPointProperties) => {
				return <Text>₱{record.amount}</Text>;
			},
		},
	];

	const needActionColumns = [
		...columns,
		{
			title: 'ACTION',
			dataIndex: 'action',
			key: 'action',
			render: (text: string, record: RedeemPointProperties) => {
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<AppButton
							type="primary"
							onClick={() => {
								setIsModalApprove(true);
								setTmpData(record);
							}}
						>
							Approve
						</AppButton>
						<AppButton
							onClick={() => {
								setIsModalReject(true);
								setTmpData(record);
							}}
						>
							Reject
						</AppButton>
					</div>
				);
			},
		},
		{
			title: 'REQUESTED AT',
			dataIndex: 'requestedAt',
			key: 'requestedAt',
			render: (text: string, record: RedeemPointProperties) => {
				return (
					<Text>{moment(record.createdAt).format('MMMM DD, YYYY, HH:mm')}</Text>
				);
			},
		},
	];

	const approvedColumns = [
		...columns,
		{
			title: 'ATTACHMENT',
			dataIndex: 'attachment',
			key: 'attachment',
			render: (text: string, record: RedeemPointProperties) => {
				return (
					<>
						{!preview ? (
							<div
								style={{
									cursor: 'pointer',
								}}
								onClick={() => setPreview(true)}
							>
								<Text underline style={{ color: '#D81F64' }}>
									View
								</Text>
							</div>
						) : (
							<Image
								style={{ display: 'none' }}
								src={
									record.imageLink
										? record.imageLink || '/images/blur-image.jpeg'
										: '/images/blur-image.jpeg'
								}
								preview={{
									visible: preview,
									onVisibleChange: (value) => {
										setPreview(value);
									},
								}}
							/>
						)}
					</>
				);
			},
		},
		{
			title: 'APPROVED AT',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (updatedAt: Date) => {
				return <Text>{moment(updatedAt).format('MMMM DD, YYYY, HH:mm')}</Text>;
			},
		},
	];

	const rejectedColumns = [
		...columns,

		{
			title: 'REASON',
			dataIndex: 'reason',
			key: 'reason',
			render: (reason: string) => {
				return (
					<div
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							setIsModalReason(true);
							setReason(reason);
						}}
					>
						<Text underline style={{ color: '#D81F64' }}>
							View
						</Text>
					</div>
				);
			},
		},
		{
			title: 'REJECTED AT',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (updatedAt: Date) => {
				return <Text>{moment(updatedAt).format('MMMM DD, YYYY, HH:mm')}</Text>;
			},
		},
	];

	return (
		<div>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						icon={
							<SendOutlined
								rotate={315}
								style={{ fontSize: 17, color: '#A5B2BD' }}
							/>
						}
						title="Redeem"
						subtitle="Manage your redeem"
					/>
				</Col>
			</Row>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search name or phone number"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => {
						setSearch(e.target.value);
					}}
				/>
			</ContainerFilter>

			<CustomMenu
				onClick={onMenuClick!}
				selectedKeys={[current]}
				mode="horizontal"
				items={items}
			/>

			<Table
				loading={isLoading}
				columns={
					current === 'needAction'
						? needActionColumns
						: current === 'approved'
						? approvedColumns
						: rejectedColumns
				}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}

			{/* Modals */}
			<CustomModal
				title="Approve"
				visible={isModalApprove}
				onOk={() => handleOkApprove()}
				onCancel={handleCancel}
				footer={[
					<AppButton onClick={handleCancel}>Cancel</AppButton>,
					<AppButton
						type="primary"
						onClick={() => handleOkApprove()}
						disabled={!uploadedImage}
					>
						Approve
					</AppButton>,
				]}
			>
				<Dragger {...uploadProps}>
					<p className="ant-upload-drag-icon">
						<CloudUploadOutlined />
					</p>
					<p className="ant-upload-text">
						Drag and drop file here or browse files
					</p>
					<p className="ant-upload-hint">
						Supported file are .png .jpg .pdf. Maximum upload file size is 5 MB.
					</p>
				</Dragger>
			</CustomModal>
			<CustomModal
				title="Reject"
				visible={isModalReject}
				onOk={() => handleOkReject()}
				onCancel={handleCancel}
				footer={[
					<AppButton onClick={handleCancel}>Cancel</AppButton>,
					<AppButton
						type="primary"
						onClick={() => handleOkReject()}
						disabled={!reason}
					>
						Reject
					</AppButton>,
				]}
			>
				<Form.Item
					label="Reason"
					name="reason"
					rules={[{ required: true, message: 'Input the reason of rejection' }]}
				>
					<TextArea
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</Form.Item>
			</CustomModal>
			<CustomModal
				title="Reason"
				visible={isModalReason}
				footer={[
					<AppButton
						type="primary"
						onClick={() => {
							handleCancel();
						}}
					>
						Ok
					</AppButton>,
				]}
			>
				<p>{reason}</p>
			</CustomModal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Redeem;

const CustomMenu = styled(Menu)`
	background-color: white !important;

	.ant-menu-item-selected {
		color: black !important;
	}

	.ant-menu-item::hover {
		backgroundcolor: white !important;
	}

	.ant-menu-item-active {
		color: #d81f64 !important;
	}

	.ant-menu-item-selected::after {
		border-bottom: 2px solid #d81f64 !important;
	}
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

const CustomModal = styled(Modal)`
	.ant-modal,
	.ant-modal-content {
		min-width: 630px;
	}
	.ant-modal-title {
		font-size: 20px;
		font-weight: 600;
		color: #556575;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
	}
	.ant-typography {
		font-weight: 400;
		letter-spacing: 0.02em;
		line-height: 150%;
	}
	span.ant-typography {
		font-size: 12px;
		color: #556575;
		text-transform: capitalize;
	}
	div.ant-typography {
		font-size: 14px;
		color: #1d2b36;
		margin-bottom: 0;
		/* font-weight: 600; */
	}
`;
