import { DoctorProps } from './doctor.type';
import { MedicalRecordProps } from './medicalRecord.type';
import { PartnerProps } from './partner.type';
import { PatientProps } from './patient.type';
import { PatientInRelationProps } from './patientInRelation.type';
import { PaymentProps } from './payment.type';
import { TransactionConsultRefundProps } from './refund.type';
import { IUser } from './user.type';

export enum EConsultationType {
	FACE_TO_FACE_CONSULTATION = 'FACE_TO_FACE_CONSULTATION',

	TELEMEDICINE_CHAT_CONSULTATION = 'TELEMEDICINE_CHAT_CONSULTATION',
	TELEMEDICINE_VIDEO_CHAT_CONSULTATION = 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',

	CONSULT_NOW_CHAT_CONSULTATION = 'CONSULT_NOW_CHAT_CONSULTATION',
	CONSULT_NOW_VIDEO_CHAT_CONSULTATION = 'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
}

export enum ETransactionStatusType {
	WAITING_PAYMENT = 'WAITING_PAYMENT', // trx created and waiting payment

	WAITING_APPROVAL = 'WAITING_APPROVAL', // after user pay, waiting approval from doctor/secretary

	APPROVED = 'APPROVED', // approved by doctor/secretary
	REJECTED = 'REJECTED', // rejected by doctor/secretary

	// Offering Reschedule = PENDING_CHANGE_SCHEDULE - Approval Rescheduled
	// Approved Reschedule = APPROVED_CHANGE_SCHEDULE
	PENDING_CHANGE_SCHEDULE = 'PENDING_CHANGE_SCHEDULE', // doctor suggest to change schedule
	APPROVED_CHANGE_SCHEDULE = 'APPROVED_CHANGE_SCHEDULE', // doctor suggest to change schedule

	CANCELED_BY_SYSTEM = 'CANCELED_BY_SYSTEM', // doctor not response approval (auto refund 100%)
	CANCELED_BY_USER = 'CANCELED_BY_USER', //
	CANCELED_BY_DOCTOR_OR_SECRETARY = 'CANCELED_BY_DOCTOR_OR_SECRETARY', // if doctor cancel the trx
	COMPLETED = 'COMPLETED', // if consultation has been done
	EXPIRED = 'EXPIRED',
}

export enum ENotifType {
	RESCHEDULED = 'RESCHEDULED',
	WAITING_PAYMENT = 'WAITING_PAYMENT',
	PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
}

export interface TransactionConsultationProps {
	transactionConsultationId: string;
	doctorId: string;
	patientId: string;
	patientInRelationId?: string;
	partnerId?: string;
	consultationType: EConsultationType;
	scheduledStartAt?: Date;
	scheduledEndAt?: Date;
	isCanExtend?: boolean;
	doctorJoinAt?: Date;
	doctorEndAt?: Date;
	patientJoinAt?: Date;
	patientEndAt?: Date;
	transactionStatus: ETransactionStatusType;
	lastUpdatedByUser?: any;
	description?: string;
	place?: string;
	link?: string;
	metaPatient?: IUser;
	metaPatientInRelation?: PatientInRelationProps;
	metaDoctor?: DoctorProps;
	metaPartner?: PartnerProps;
	createdByUserId?: string;
	metaCreatedByUser?: any;
	createdAt: Date;
	updatedAt: Date;
	medicalRecord?: MedicalRecordProps;
	history?: TransactionConsultHistoryProps[];
	refund?: TransactionConsultRefundProps;
	payment?: PaymentProps;
}

export type TransactionConsultHistoryProps = {
	historyId: string;
	transactionConsultationId: string;
	description?: string;
	newData: any;
	createdByUserId: string;
	metaCreatedByUser: any;
	updatedAt: Date;
	createdAt: Date;
	balance?: TransactionConsultationProps;
};

export const TRANSACTION_STATUS_SETTINGS: any = {
	[ETransactionStatusType.WAITING_PAYMENT]: {
		label: 'Waiting Payment',
		color: '#E89635',
	},
	[ETransactionStatusType.WAITING_APPROVAL]: {
		label: 'Waiting Approval',
		color: 'blue',
	},
	[ETransactionStatusType.PENDING_CHANGE_SCHEDULE]: {
		label: 'Waiting Approval Patient',
		color: 'blue',
	},
	[ETransactionStatusType.APPROVED]: {
		label: 'Approved',
		color: '#39AC6E',
	},
	[ETransactionStatusType.APPROVED_CHANGE_SCHEDULE]: {
		label: 'Approved',
		color: '#39AC6E',
	},
	[ETransactionStatusType.COMPLETED]: {
		label: 'Completed',
		color: '#39AC6E',
	},
	[ETransactionStatusType.REJECTED]: {
		label: 'Rejected',
		color: '#D81F64',
	},
	[ETransactionStatusType.CANCELED_BY_SYSTEM]: {
		label: 'Canceled By Medeasy',
		color: '#D81F64',
	},
	[ETransactionStatusType.CANCELED_BY_USER]: {
		label: 'Canceled By User',
		color: '#D81F64',
	},
	[ETransactionStatusType.CANCELED_BY_DOCTOR_OR_SECRETARY]: {
		label: 'Canceled By Doctor',
		color: '#D81F64',
	},
	[ETransactionStatusType.EXPIRED]: {
		label: 'Expired',
		color: '#D81F64',
	},
};
