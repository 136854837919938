import { Space } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import DetailItem from '../../../components/DetailItem';
import HeaderSection from '../../../components/HeaderSection';
import SectionContent from '../../../components/SectionContent';
import { httpRequest } from '../../../helpers/api';
import { formatDate } from '../../../helpers/constant';
import { BaseResponseProps } from '../../../types/config.type';
import { GenreProps,initialGenre } from '../../../types/genre.type';

interface IParams {
	genreId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<GenreProps, 'createdAt' | 'updatedAt'>;
}

const GenreDetail = () => {
	const history = useHistory();
	const { genreId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [genre, setGenre] =
		React.useState<GenreProps>(initialGenre);

	const handleClickEdit = () => {
		history.push({
			pathname: `/genre/${genre.genreId}/edit`,
			state: {
				genreId: genre.genreId,
			},
		});
	};

	React.useEffect(() => {
		const fetchDetailGenre = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/genre/' + genreId,
				);

				setGenre(res.data.payload);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailGenre();
	}, [genreId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Genre Detail"
				subtitle="Manage your genre data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Genre Data"
				helpers={[
					{
						title: 'Information',
						content:
							'Genre must be unique, system will reject if genre has been created with the same name',
					},
				]}>
				{/* <Space
						style={{
							width: '100%',
							background: 'grey',
							justifyContent: 'center',
							marginBottom: '15px',
						}}
					>
						<DetailItem
						type="image"
						label="Cover Genre"
						content={genre.imageUrl || ''}
					/>
					</Space> */}
					<DetailItem label="Name" content={genre.genreName} />
					<DetailItem
						label="Status"
						content={genre.isPublished ? 'Active' : 'Inactive'}
					/>
					<DetailItem
						label="Created at"
						content={formatDate(genre.createdAt)}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default GenreDetail;
