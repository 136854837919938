import {
	CarOutlined,
	DeleteOutlined,
	EditOutlined,
	EnvironmentOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Button,
	Col,
	Image,
	Input,
	InputNumber,
	message,
	Modal,
	Radio,
	Row,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerFilter } from '.';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import PartnerCard from '../../components/PartnerCard';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { generateQueryString } from '../../helpers/generateQueryString';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import {
	initialPackageService,
	initialPartnerPackageHealthServicesProps,
	PackageServiceProps,
	PartnerPackageHealthServicesProps,
} from '../../types/packageService.type';
import {
	EPartnerType,
	FetchAllPartnerResponse,
	PartnerProps,
} from '../../types/partner.type';

const { Text } = Typography;

interface IParams {
	healthServiceId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PackageServiceProps, 'createdAt' | 'updatedAt'>;
}

const PackageDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { healthServiceId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isEdit, setIsEdit] = React.useState<boolean>(false);
	const [isModalSaveVisible, setIsModalSaveVisible] =
		React.useState<boolean>(false);

	const [packageService, setPackageService] =
		React.useState<PackageServiceProps>(initialPackageService);

	const [partners, setPartners] = React.useState<Array<PartnerProps>>([]);
	const [selectedPartner, setSelectedPartner] = React.useState<
		Array<PartnerPackageHealthServicesProps>
	>([]);
	const [tmpSelectedEditPartner, setTmpSelectedEditPartner] =
		React.useState<PartnerPackageHealthServicesProps>(
			initialPartnerPackageHealthServicesProps,
		);

	const [showModalAddPartner, setIsShowModalAddPartner] =
		React.useState<boolean>(false);
	const [showModalEditPartner, setIsShowModalEditPartner] =
		React.useState<boolean>(false);
	const [searchPartner, setSearchPartner] = React.useState<{
		limit: number;
		offset: number;
		search: string;
	}>({
		limit: 10,
		offset: 0,
		search: '',
	});

	const fetchServiceDetail = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<ResponseProps>(
				'/health-service/packages/' + healthServiceId,
			);
			setPackageService(res.data.payload);
			setSelectedPartner(res.data.payload.partnerPackageHealthServices);

			const bcDetails = [
				{
					field: 'healthServiceId',
					value: healthServiceId,
					label: res.data.payload.healthServiceName,
				},
			];
			setBreadcrumbDetails(bcDetails);

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		if (healthServiceId) {
			fetchServiceDetail();
		}
	}, [healthServiceId]);

	React.useEffect(() => {
		const getPartnerData = async () => {
			const partnerRes = await httpRequest.get<FetchAllPartnerResponse>(
				`/partners${generateQueryString({
					search: searchPartner.search,
				})}`,
			);
			const filterPartner = partnerRes.data.payload.results?.filter(
				(item) =>
					item.partnerType !== EPartnerType.CONSULT_NOW &&
					item.partnerType !== EPartnerType.ONLINE_CLINIC,
			);
			setPartners(filterPartner);
		};

		getPartnerData();
	}, [searchPartner]);

	const updateService = async () => {
		try {
			setIsLoading(true);

			if (healthServiceId) {
				if (selectedPartner.length > 0 && healthServiceId) {
					const bulk = [];
					for (const partner of selectedPartner) {
						bulk.push({
							...partner,
							partnerId: partner.partnerId,
							packageHealthServiceId: healthServiceId,
							price: partner.price,
						});
					}

					const resDel = await httpRequest.delete(
						`/partner-package-health-services/${healthServiceId}/by-health-service`,
					);

					if (resDel) {
						await httpRequest.post('/partner-package-health-services/bulk', {
							bulk: bulk,
						});
					}
				}
			}

			message.success(
				'Success update ' + packageService.healthServiceName + ' data',
			);
			setIsModalSaveVisible(false);
			setIsEdit(false);
			fetchServiceDetail();
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleCancelEditPartner = () => {
		setSelectedPartner(packageService.partnerPackageHealthServices);
		setIsEdit(false);
	};

	const handleClickEditPartner = (partnerId: string) => {
		setIsShowModalEditPartner(true);
		const findOne = selectedPartner.find(
			(partner) => partner.partnerId === partnerId,
		);
		if (findOne) {
			setTmpSelectedEditPartner(findOne);
		}
	};

	const handleRemovePartner = (partnerId: string) => {
		const filterPartner = selectedPartner.filter(
			(partner) => partner.partnerId !== partnerId,
		);
		setSelectedPartner(filterPartner);
	};

	const handleConfirmEditPartners = () => {
		const newSelectedPartnerData = selectedPartner.map((item) => {
			if (item.partnerId === tmpSelectedEditPartner.partnerId) {
				return {
					...tmpSelectedEditPartner,
				};
			} else {
				return {
					...item,
				};
			}
		});
		setIsShowModalEditPartner(false);
		setSelectedPartner(newSelectedPartnerData);
	};

	const handleCheckPartner = (partnerData: any) => {
		const isSelected = selectedPartner.find(
			(item) => item.partnerId === partnerData.partnerId,
		);
		if (isSelected) {
			const newPartner = selectedPartner.filter(
				(item) => item.partnerId !== partnerData.partnerId,
			);
			setSelectedPartner(newPartner);
		} else {
			const newPartner = {
				id: '',
				partnerId: partnerData.partnerId,
				price: 0,
				packageHealthServices: initialPackageService,
				packageHealthServiceId: '',
				partner: partnerData,
			};
			setSelectedPartner((prev) => [...prev, newPartner]);
		}
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Package Detail"
				subtitle="Manage your Service Package data"
				rightAction={
					<Space>
						<AppButton
							disabled={isEdit}
							type="primary"
							onClick={() =>
								history.push(
									'/medical-service-package/' + healthServiceId + '/edit',
								)
							}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Medical Update Information">
					<DetailItem
						label="Item Name"
						content={packageService.healthServiceName}
					/>

					<DetailItem label="Category" content={packageService.group} />

					<DetailItem
						label="Description"
						content={packageService.description}
					/>

					<DetailItem
						label="Items"
						content={
							packageService.items && packageService.items.length > 0 ? (
								<ul style={{ paddingLeft: 12 }}>
									{packageService.items.map((item) => (
										<li>{item}</li>
									))}
								</ul>
							) : undefined
						}
					/>
				</SectionContent>
			</AppCard>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Settings">
					<Radio.Group
						value={packageService.isPublished}
						disabled
						style={{ marginTop: 5 }}
					>
						<Radio value={true}>Published</Radio>
						<Radio value={false}>Unpublished</Radio>
					</Radio.Group>
				</SectionContent>
			</AppCard>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Clinic">
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<div style={{ width: '80%' }}>
							{selectedPartner?.map((partnerService) => {
								return (
									<div
										style={{
											height: '143px',
											marginBottom: '2%',
										}}
									>
										<Row gutter={[16, 16]}>
											<Col>
												<Image
													width={140}
													height={140}
													style={{
														borderRadius: '4px',
													}}
													src={
														partnerService.partner.imageCoverURL ||
														'/images/select-image.jpg'
													}
													fallback={'/images/blur-image.jpeg'}
												/>
											</Col>

											<Col
												style={{
													display: 'flex',
													flex: 1,
													flexDirection: 'column',
													justifyContent: 'center',
												}}
											>
												<Row>
													<Text
														style={{
															fontWeight: 'bold',
															fontSize: '14px',
															lineHeight: '150%',
														}}
													>
														{partnerService.partner.partnerName}
													</Text>
												</Row>
												<Row style={{ paddingTop: '1%', alignItems: 'center' }}>
													<EnvironmentOutlined style={{ fontSize: '12px' }} />
													<Text
														style={{
															paddingLeft: '1%',
															fontSize: '12px',
															lineHeight: '150%',
														}}
													>
														{partnerService.partner.address}
													</Text>
												</Row>
												<Row style={{ paddingTop: '1%', alignItems: 'center' }}>
													<CarOutlined style={{ fontSize: '12px' }} />
													{partnerService.setting &&
													partnerService.setting.availableForHomeService ? (
														<Text
															style={{
																paddingLeft: '1%',
																fontSize: '12px',
																lineHeight: '150%',
																color: '#1D2B36',
															}}
														>
															Home Service Available
														</Text>
													) : (
														<Text
															style={{
																paddingLeft: '1%',
																fontSize: '12px',
																lineHeight: '150%',
																color: '#B7C2CC',
															}}
														>
															Home Service Not Available
														</Text>
													)}
												</Row>
												<Row
													style={{
														paddingTop: '1%',
														fontWeight: 'bold',
														fontSize: '12px',
														lineHeight: '150%',
													}}
												>
													Price:{' '}
													{` ₱ ${
														partnerService.price ? partnerService.price : 0
													}`}
												</Row>
											</Col>
											<Col style={{ display: 'flex', alignItems: 'center' }}>
												{isEdit && (
													<>
														<Button
															style={{ marginRight: 10 }}
															onClick={() =>
																handleClickEditPartner(partnerService.partnerId)
															}
														>
															<EditOutlined color="#D81F64" />
														</Button>
														<Button
															onClick={() =>
																handleRemovePartner(partnerService.partnerId)
															}
														>
															<DeleteOutlined color="#D81F64" />
														</Button>
													</>
												)}
											</Col>
										</Row>
									</div>
								);
							})}
							<div>
								{isEdit && (
									<Button
										style={{
											color: '#D81F64',
											borderColor: '#D81F64',
											width: '100%',
											marginTop: '2%',
										}}
										onClick={() => {
											setIsShowModalAddPartner(true);
										}}
									>
										Add New Clinic
									</Button>
								)}
							</div>
						</div>
						<div>
							{isEdit && (
								<AppButton
									onClick={() => handleCancelEditPartner()}
									style={{ marginRight: 10 }}
								>
									Cancel
								</AppButton>
							)}
							<AppButton
								type="primary"
								onClick={() =>
									isEdit ? setIsModalSaveVisible(true) : setIsEdit(true)
								}
							>
								{isEdit ? 'Save' : 'Edit Clinic'}
							</AppButton>
						</div>
					</div>
				</SectionContent>
				<Modal
					width={'850px'}
					title="Add Clinic"
					visible={showModalAddPartner}
					footer={false}
					onCancel={() => {
						setIsShowModalAddPartner(false);
					}}
				>
					<ContainerFilter>
						<Input
							size="large"
							placeholder="Search Clinic Name"
							prefix={<SearchOutlined />}
							allowClear
							onChange={(e) =>
								setSearchPartner({
									...searchPartner,
									search: e.target.value,
								})
							}
						/>
					</ContainerFilter>
					<div>
						{selectedPartner.length > 0 &&
							selectedPartner.map((partner) => (
								<PartnerCard
									partner={partner}
									selectedPartner={selectedPartner}
									handleChange={(partnerData) =>
										handleCheckPartner(partnerData)
									}
								/>
							))}

						{partners.length > 0 &&
							partners
								.filter(
									(item) =>
										!selectedPartner
											.map((item) => item.partnerId)
											.includes(item.partnerId),
								)
								.map((partner) => (
									<PartnerCard
										partner={partner}
										selectedPartner={selectedPartner}
										handleChange={(partnerData) =>
											handleCheckPartner(partnerData)
										}
									/>
								))}
					</div>
				</Modal>

				<Modal
					width={'807px'}
					title="Edit Clinic"
					visible={showModalEditPartner}
					onOk={handleConfirmEditPartners}
					onCancel={() => {
						setIsShowModalEditPartner(false);
						setTmpSelectedEditPartner(initialPartnerPackageHealthServicesProps);
					}}
					okText="Save"
					okButtonProps={{
						type: 'primary',
						style: {
							borderColor: '#D81F64',
							backgroundColor: '#D81F64',
							borderRadius: '5px',
							color: 'white',
						},
					}}
				>
					<div
						style={{
							margin: '2%',
						}}
					>
						<Row
							gutter={[16, 16]}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Col>
								<Image
									width={140}
									height={140}
									style={{
										borderRadius: '4px',
									}}
									src={
										tmpSelectedEditPartner.partner.imageCoverURL ||
										'/images/select-image.jpg'
									}
									fallback={'/images/blur-image.jpeg'}
								/>
							</Col>

							<Col style={{ flex: 1 }}>
								<Row>
									<Text
										style={{
											fontWeight: 'bold',
											fontSize: '20px',
											lineHeight: '150%',
											color: '#1D2B36',
										}}
									>
										{tmpSelectedEditPartner.partner.partnerName}
									</Text>
								</Row>
								<Row style={{ paddingTop: '1%' }}>
									<Text
										style={{
											fontSize: '14px',
											lineHeight: '150%',
											color: '#1D2B36',
										}}
									>
										{tmpSelectedEditPartner.partner.address}
									</Text>
								</Row>
							</Col>
						</Row>

						<Row
							style={{
								marginTop: '4%',
							}}
						>
							<Text>Price</Text>
						</Row>
						<Row>
							<InputNumber
								style={{
									width: '100%',
								}}
								prefix="₱"
								min={0}
								defaultValue={
									tmpSelectedEditPartner.price
										? tmpSelectedEditPartner.price
										: 0
								}
								onChange={(value) =>
									setTmpSelectedEditPartner({
										...tmpSelectedEditPartner,
										price: Number(value),
									})
								}
							/>
						</Row>

						<Row
							style={{
								paddingTop: '4%',
							}}
						>
							<Text>Available For Home Service?</Text>
						</Row>
						<Row>
							<Radio.Group
								value={
									tmpSelectedEditPartner.setting &&
									tmpSelectedEditPartner.setting.availableForHomeService
										? true
										: false
								}
								onChange={(e) => {
									setTmpSelectedEditPartner({
										...tmpSelectedEditPartner,
										setting: {
											availableForHomeService: e.target.value,
										},
									});
								}}
							>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Row>
					</div>
				</Modal>

				<Modal
					title="Edit Clinic Service"
					visible={isModalSaveVisible}
					onOk={updateService}
					onCancel={() => {
						setIsModalSaveVisible(false);
					}}
					okText="Yes"
					confirmLoading={isLoading}
					okButtonProps={{ type: 'primary' }}
				>
					<p>Are you sure want to save ?</p>
				</Modal>
			</AppCard>
		</div>
	);
};

export default PackageDetail;
