export type RedeemPointProperties = {
	redeemPointId: string;
	userId: string;
	metaUser: any;
	userBankAccountId: string;
	metaBankAccount: any;
	amount: number;
	status: ERedeemPointStatus;
	reason?: string;
	lastUpdatedByUser?: string;
	imageLink?: string;
	createdAt?: Date;
	updatedAt?: Date;
};

export enum ERedeemPointStatus {
	WAITING_APPROVAL = 'WAITING_APPROVAL',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export enum EBankStatus {
	WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED',
}
