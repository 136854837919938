import { Space } from 'antd';
import React from 'react';
import HeaderSection from '../../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import AppCard from '../../../components/AppCard';
import DetailItem from '../../../components/DetailItem';
import SectionContent from '../../../components/SectionContent';
import { formatDate } from '../../../helpers/constant';
import { initialTopic, TopicProps } from '../../../types/topic.type';

interface IParams {
	topicId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<TopicProps, 'createdAt' | 'updatedAt'>;
}

const TopicDetail = () => {
	const history = useHistory();
	const { topicId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [topic, setTopic] = React.useState<TopicProps>(initialTopic);

	const handleClickEdit = () => {
		history.push({
			pathname: `/topic/${topic.topicId}/edit`,
			state: {
				topicId: topic.topicId,
			},
		});
	};

	React.useEffect(() => {
		const fetchDetailTopic = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>('/topics/' + topicId);

				setTopic(res.data.payload);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailTopic();
	}, [topicId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Tag Detail"
				subtitle="Manage your tag data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Tag Data">
					<DetailItem label="Name" content={topic.topicName} />
					<DetailItem
						label="Status"
						content={topic.isPublished ? 'Published' : 'Unpublished'}
					/>
					<DetailItem
						label="Created at"
						content={formatDate(topic.createdAt)}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default TopicDetail;
