import React from 'react';
import { Col, Image, Row, Tag, Typography } from 'antd';
import HTMLPreview from '../HTMLPreview';

export default function SelectedArticle({ content }: any) {
	const { Text, Paragraph } = Typography;

	return (
		<div
			style={{
				height: '120px',
			}}
		>
			<Row gutter={[16, 16]}>
				<Col>
					<Image
						width={120}
						height={100}
						src={
							content.images && content.images.length > 0
								? content.images[0] || '/images/blur-image.jpeg'
								: '/images/blur-image.jpeg'
						}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				</Col>
				<Col span={10}>
					<Row>
						<Text
							style={{
								fontWeight: 'bold',
								fontSize: '14px',
							}}
						>
							{content.title}
						</Text>
					</Row>
					<Row style={{ marginBottom: '5px', marginTop: '5px' }}>
						{content.targetReaders?.map((item: any) => <Tag>{item}</Tag>)}
					</Row>
					<Row>
						{content.topics?.map((item: any) => {
							return <Tag>{`#${item.topicName}`}</Tag>;
						})}
					</Row>
				</Col>
			</Row>
		</div>
	);
}
