import { SafetyOutlined } from '@ant-design/icons';
import { Form, FormInstance, message, Space } from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { generateFormRules } from '../../helpers/formRules';
import {
	initialMedicalCalculator,
	MedicalCalculatorProps,
} from '../../types/medicalCalculator.type';

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const PrivacyPolicyEdit: React.FC = () => {
	const formRef =
		React.useRef<
			FormInstance<Omit<MedicalCalculatorProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [medicalCalculator, setMedicalCalculator] =
		React.useState<MedicalCalculatorProps>(initialMedicalCalculator);

	const updateData = async (
		props: Omit<MedicalCalculatorProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.put('/app-configs/custom/as-object', {
				...medicalCalculator,
				...props,
			});
			message.success('Updated successfully');

			setIsLoadingAction(false);

			fetchData();
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			MedicalCalculatorProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		updateData(values);
	};

	const fetchData = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<any>('/app-configs/custom/as-object');
			setMedicalCalculator(res.data.payload);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchData();
	}, []);

	return (
		<div>
			<HeaderSection
				icon={<SafetyOutlined />}
				title={'Edit Medical Calculator Information'}
				subtitle="Manage your Medical Calculator Information data"
				rightAction={
					<Space>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="medicalCalculatorForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={medicalCalculator}
					autoComplete="off"
				>
					<Form.Item
						label="Medical Calculator Information"
						name="MEDICAL_CALCULATOR_INFORMATION"
						rules={generateFormRules('Medical Calculator Information', [
							'required',
						])}
					>
						<ReactQuill
							theme="snow"
							value={medicalCalculator.MEDICAL_CALCULATOR_INFORMATION}
							onChange={(content) =>
								setMedicalCalculator({
									...medicalCalculator,
									MEDICAL_CALCULATOR_INFORMATION: content,
								})
							}
							modules={quillModules}
							formats={quillFormats}
						/>
					</Form.Item>
				</Form>
			</AppCard>
		</div>
	);
};

export default PrivacyPolicyEdit;
