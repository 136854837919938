import { Card } from 'antd';
import React from 'react';
import LoadingCard, { LoadingProps } from './LoadingCard';

interface Props extends LoadingProps {
	loading?: boolean;
}

const AppCard: React.FC<Props> = ({ loading, type, children }) => {
	return <Card>{loading ? <LoadingCard type={type} /> : <>{children}</>}</Card>;
};

export default AppCard;
