import { BaseResponseProps } from './config.type';
import { EbookProps } from './ebook.type';

export type RequirementObject = {
	minimumTransaction: number;
};

export enum EDiscountType {
	NOMINAL = 'nominal',
	PERCENTAGE = 'percentage',
}

export enum EVoucherType {
	GENERATE = 'generate',
	PARTNER = 'partner',
}

export enum EVoucherStatus {
	PENDING = 'pending',
	USED = 'used',
	AVAILABLE = 'available',
	EXPIRED = 'expired',
}

export interface VoucherItem {
    id: string;
    voucherId: string;
    itemId: string;
    itemType: string;
    createdAt: string;
    updatedAt: string;
    ebook: EbookProps;
}

export interface VoucherProps {
	voucherId: string;
	voucherCode: string;
	voucherEventId: string;
	voucherName: string;
	description: string;
	termAndCondition: string;
	discountType: EDiscountType;
	discountValue: number | undefined;
	maxDiscountValue: number;
	requirement: RequirementObject;
	limitMaxUsePerPerson: number| undefined;
	validStartAt: Date | undefined;
	validEndAt: Date | undefined;
	voucherType: EVoucherType;
	voucherStatus: EVoucherStatus;
	imageUrl?: string;
	isPublished: boolean;
	updatedAt: Date;
	createdAt: Date;
	notifSendAt: Date | null;
	totalGenerateVoucher: number;
	prefix: string;
	ebooks: EbookProps[];
	voucherItems: VoucherItem[];
	voucherGenerates:any;
}

export interface FetchAllVoucherResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: VoucherProps[];
	};
}

export const initialVoucher: VoucherProps = {
	voucherId: '',
	voucherCode: '',
	voucherEventId: '',
	voucherName: '',
	description: '',
	termAndCondition: '',
	discountType: EDiscountType.NOMINAL,
	discountValue: undefined,
	maxDiscountValue: 0,
	requirement: {
		minimumTransaction: 0,
	},
	limitMaxUsePerPerson: undefined,
	validStartAt: undefined,
	validEndAt: undefined,
	voucherType: EVoucherType.GENERATE,
	voucherStatus: EVoucherStatus.AVAILABLE,
	imageUrl: '',
	isPublished: true,
	updatedAt: new Date(),
	createdAt: new Date(),
	notifSendAt: null,
	prefix:'',
	totalGenerateVoucher: 1,
	ebooks: [],
	voucherItems: [],
	voucherGenerates: [],
};

