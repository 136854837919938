// export enum ERewardPointPatient {
// 	REWARD_POINT_REGISTERED_NEW_USER_PATIENT = "REWARD_POINT_REGISTERED_NEW_USER_PATIENT",
// 	REWARD_POINT_REFERRAL_UPLINE_PATIENT = "REWARD_POINT_REFERRAL_UPLINE_PATIENT",
// 	REWARD_POINT_REFERRAL_DOWNLINE_PATIENT = "REWARD_POINT_REFERRAL_DOWNLINE_PATIENT",
// 	REWARD_POINT_DAILY_CHECK_IN_PATIENT = "REWARD_POINT_DAILY_CHECK_IN_PATIENT",
// 	REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_PATIENT = "REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_PATIENT",
// 	REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_PATIENT = "REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_PATIENT",
// 	REWARD_POINT_USING_IN_PERSON_CONSULTATION_PATIENT = "REWARD_POINT_USING_IN_PERSON_CONSULTATION_PATIENT",
// 	REWARD_POINT_MEDICAL_SERVICE_LAB_PATIENT = "REWARD_POINT_MEDICAL_SERVICE_LAB_PATIENT",
// 	REWARD_POINT_POST_ARTICLES_PATIENT = "REWARD_POINT_POST_ARTICLES_PATIENT",
// 	REWARD_POINT_READ_ARTICLES_PATIENT = "REWARD_POINT_READ_ARTICLES_PATIENT",
// 	REWARD_POINT_SHARE_ARTICLES_PATIENT = "REWARD_POINT_SHARE_ARTICLES_PATIENT",
// 	REWARD_POINT_REGISTER_WEBINAR_PATIENT = "REWARD_POINT_REGISTER_WEBINAR_PATIENT",
// }

// export enum ERewardPointDoctor {
// 	REWARD_POINT_REGISTERED_NEW_USER_DOCTOR = "REWARD_POINT_REGISTERED_NEW_USER_DOCTOR",
// 	REWARD_POINT_REFERRAL_UPLINE_DOCTOR = "REWARD_POINT_REFERRAL_UPLINE_DOCTOR",
// 	REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR = "REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR",
// 	REWARD_POINT_DAILY_CHECK_IN_DOCTOR = "REWARD_POINT_DAILY_CHECK_IN_DOCTOR",
// 	REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_DOCTOR = "REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_DOCTOR",
// 	REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_DOCTOR = "REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_DOCTOR",
// 	REWARD_POINT_USING_IN_PERSON_CONSULTATION_DOCTOR = "REWARD_POINT_USING_IN_PERSON_CONSULTATION_DOCTOR",
// 	REWARD_POINT_MEDICAL_SERVICE_LAB_DOCTOR = "REWARD_POINT_MEDICAL_SERVICE_LAB_DOCTOR",
// 	REWARD_POINT_POST_ARTICLES_DOCTOR = "REWARD_POINT_POST_ARTICLES_DOCTOR",
// 	REWARD_POINT_READ_ARTICLES_DOCTOR = "REWARD_POINT_READ_ARTICLES_DOCTOR",
// 	REWARD_POINT_SHARE_ARTICLES_DOCTOR = "REWARD_POINT_SHARE_ARTICLES_DOCTOR",
// 	REWARD_POINT_REGISTER_WEBINAR_DOCTOR = "REWARD_POINT_REGISTER_WEBINAR_DOCTOR",
// }

export enum ERewardPointPatientDoctor {
	REWARD_POINT_REGISTERED_NEW_USER_DOCTOR = 'REWARD_POINT_REGISTERED_NEW_USER_DOCTOR',
	REWARD_POINT_REFERRAL_UPLINE_DOCTOR = 'REWARD_POINT_REFERRAL_UPLINE_DOCTOR',
	REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR = 'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR',
	REWARD_POINT_DAILY_CHECK_IN_DOCTOR = 'REWARD_POINT_DAILY_CHECK_IN_DOCTOR',
	REWARD_POINT_USING_CHAT_CONSULTATION_DOCTOR = 'REWARD_POINT_USING_CHAT_CONSULTATION_DOCTOR',
	REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_DOCTOR = 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_DOCTOR',
	REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_DOCTOR = 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_DOCTOR',
	REWARD_POINT_USING_IN_PERSON_CONSULTATION_DOCTOR = 'REWARD_POINT_USING_IN_PERSON_CONSULTATION_DOCTOR',
	REWARD_POINT_MEDICAL_SERVICE_LAB_DOCTOR = 'REWARD_POINT_MEDICAL_SERVICE_LAB_DOCTOR',
	REWARD_POINT_POST_ARTICLES_DOCTOR = 'REWARD_POINT_POST_ARTICLES_DOCTOR',
	REWARD_POINT_READ_ARTICLES_DOCTOR = 'REWARD_POINT_READ_ARTICLES_DOCTOR',
	REWARD_POINT_MAX_READ_ARTICLE_PER_DAY_DOCTOR = 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY_DOCTOR',
	REWARD_POINT_SHARE_ARTICLES_DOCTOR = 'REWARD_POINT_SHARE_ARTICLES_DOCTOR',
	REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY_DOCTOR = 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY_DOCTOR',
	REWARD_POINT_READ_EVENT_DOCTOR = 'REWARD_POINT_READ_EVENT_DOCTOR',
	REWARD_POINT_MAX_READ_EVENT_PER_DAY_DOCTOR = 'REWARD_POINT_MAX_READ_EVENT_PER_DAY_DOCTOR',
	REWARD_POINT_REGISTER_WEBINAR_DOCTOR = 'REWARD_POINT_REGISTER_WEBINAR_DOCTOR',
	REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_DOCTOR = 'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_DOCTOR',
	REWARD_POINT_MAX_POINT_GENERIC_MEDICINE_DOCTOR = 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE_DOCTOR',
	REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE_DOCTOR = 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE_DOCTOR',
	REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP_DOCTOR = 'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISETERED_IN_MEDEASY_APP_DOCTOR',
	REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED_DOCTOR = 'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED_DOCTOR',
	REWARD_POINT_REGISTERED_NEW_USER_PATIENT = 'REWARD_POINT_REGISTERED_NEW_USER_PATIENT',
	REWARD_POINT_REFERRAL_UPLINE_PATIENT = 'REWARD_POINT_REFERRAL_UPLINE_PATIENT',
	REWARD_POINT_REFERRAL_DOWNLINE_PATIENT = 'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT',
	REWARD_POINT_DAILY_CHECK_IN_PATIENT = 'REWARD_POINT_DAILY_CHECK_IN_PATIENT',
	REWARD_POINT_USING_CHAT_CONSULTATION_PATIENT = 'REWARD_POINT_USING_CHAT_CONSULTATION_PATIENT',
	REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_PATIENT = 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION_PATIENT',
	REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_PATIENT = 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION_PATIENT',
	REWARD_POINT_USING_IN_PERSON_CONSULTATION_PATIENT = 'REWARD_POINT_USING_IN_PERSON_CONSULTATION_PATIENT',
	REWARD_POINT_MEDICAL_SERVICE_LAB_PATIENT = 'REWARD_POINT_MEDICAL_SERVICE_LAB_PATIENT',
	REWARD_POINT_POST_ARTICLES_PATIENT = 'REWARD_POINT_POST_ARTICLES_PATIENT',
	REWARD_POINT_READ_ARTICLES_PATIENT = 'REWARD_POINT_READ_ARTICLES_PATIENT',
	REWARD_POINT_MAX_READ_ARTICLE_PER_DAY_PATIENT = 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY_PATIENT',
	REWARD_POINT_SHARE_ARTICLES_PATIENT = 'REWARD_POINT_SHARE_ARTICLES_PATIENT',
	REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY_PATIENT = 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY_PATIENT',
	REWARD_POINT_READ_EVENT_PATIENT = 'REWARD_POINT_READ_EVENT_PATIENT',
	REWARD_POINT_MAX_READ_EVENT_PER_DAY_PATIENT = 'REWARD_POINT_MAX_READ_EVENT_PER_DAY_PATIENT',
	REWARD_POINT_REGISTER_WEBINAR_PATIENT = 'REWARD_POINT_REGISTER_WEBINAR_PATIENT',
	REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_PATIENT = 'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_PATIENT',
	REWARD_POINT_MAX_POINT_GENERIC_MEDICINE_PATIENT = 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE_PATIENT',
	REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE_PATIENT = 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE_PATIENT',
	REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP_PATIENT = 'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP_PATIENT',
	REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED_PATIENT = 'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED_PATIENT',
}

export enum ERewardPoint {
	REWARD_POINT_REGISTERED_NEW_USER = 'REWARD_POINT_REGISTERED_NEW_USER',
	REWARD_POINT_REFERRAL_UPLINE = 'REWARD_POINT_REFERRAL_UPLINE',
	REWARD_POINT_REFERRAL_DOWNLINE = 'REWARD_POINT_REFERRAL_DOWNLINE',
	REWARD_POINT_DAILY_CHECK_IN = 'REWARD_POINT_DAILY_CHECK_IN',
	REWARD_POINT_USING_TELEMEDICINE_CONSULTATION = 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION',
	REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION = 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION',
	REWARD_POINT_USING_IN_PERSON_CONSULTATION = 'REWARD_POINT_USING_IN_PERSON_CONSULTATION',
	REWARD_POINT_MEDICAL_SERVICE_LAB = 'REWARD_POINT_MEDICAL_SERVICE_LAB',
	REWARD_POINT_POST_ARTICLES = 'REWARD_POINT_POST_ARTICLES',
	REWARD_POINT_READ_ARTICLES = 'REWARD_POINT_READ_ARTICLES',
	REWARD_POINT_MAX_READ_ARTICLE_PER_DAY = 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY',
	REWARD_POINT_SHARE_ARTICLES = 'REWARD_POINT_SHARE_ARTICLES',
	REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY = 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY',
	REWARD_POINT_READ_EVENT = 'REWARD_POINT_READ_EVENT',
	REWARD_POINT_MAX_READ_EVENT_PER_DAY = 'REWARD_POINT_MAX_READ_EVENT_PER_DAY',
	REWARD_POINT_REGISTER_WEBINAR = 'REWARD_POINT_REGISTER_WEBINAR',
}

export interface RewardProps {
	configId: string;
	key: string;
	value: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface RewardPoint {
	configId: string;
	key: string;
	value: {
		doctor: string;
		patient: string;
	};
	createdAt?: string;
	updatedAt?: string;
}

export interface RewardHistoryProps {
	historyId: string;
	key: string;
	newData: RewardProps;
	createdByUserId: string;
	metaCreatedByUser: any;
	createdAt?: string;
	updatedAt?: string;
}
