export enum ConfigTypes {
	SHOW_SIDEBAR = 'SHOW_SIDEBAR',
	HIDE_SIDEBAR = 'HIDE_SIDEBAR',
	SET_ACTIVE_SUB_MENU = 'SET_ACTIVE_SUB_MENU',
	APPROVAL = 'APPROVAL',
}

export type ApprovalState = {
	length: number;
};

export type ConfigState = {
	sidebarCollapsed: boolean;
	counterBatch: number;
	activeSubMenu: Array<string>;
};

export interface BaseResponseProps {
	code: string;
	message: string;
}

export interface BaseResponsePaginationProps<T> {
	code: string;
	message: string;
	payload: {
		count: number;
		prev: string;
		next: string;
		results: Array<T>;
	};
}

export interface DefaultQuery {
	limit?: number;
	offset?: number;
}
