import { Form, FormInstance, Input, message, Radio, Space } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { CategoryProps, initialCategory } from '../../types/category.type';
import { httpRequest } from '../../helpers/api';

interface IParams {
	categoryId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CategoryProps, 'createdAt' | 'updatedAt'>;
}

const CategoryEdit: React.FC = () => {
	const history = useHistory();
	const { categoryId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<CategoryProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [category, setCategory] =
		React.useState<CategoryProps>(initialCategory);

	const createCategory = async (
		props: Omit<
			CategoryProps,
			'createdAt' | 'updatedAt' | 'categoryId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			await httpRequest.post('/categories', { ...props });
			message.success('Success create ' + props.categoryName);
			history.push('/category');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateCategory = async (
		props: Omit<CategoryProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/categories/' + categoryId, props);
			message.success('Success update ' + props.categoryName + ' data');
			history.push('/category');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<CategoryProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (categoryId) {
			updateCategory(values);
		} else {
			createCategory(values);
		}
	};

	React.useEffect(() => {
		if (categoryId) {
			const fetchCategory = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/categories/' + categoryId,
					);
					setCategory(res.data.payload);
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchCategory();
		}
	}, [categoryId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(categoryId ? 'Edit' : 'Add') + ' Category'}
				subtitle="Manage your category data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={categoryId ? category : initialCategory}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Category Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, eveniet soluta delectus error tempore iure accusantium explicabo',
							},
						]}
					>
						<Form.Item
							label="Category Name"
							name="categoryName"
							rules={generateFormRules('Category Name', ['required'])}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={category.isPublished}>
								<Radio value={true}>Published</Radio>
								<Radio value={false}>Unpublished</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default CategoryEdit;
