import {
	AppstoreAddOutlined,
	CheckCircleFilled,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
	Tooltip,
	Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { getPartnerTypesForClinicAndHospital } from '../../helpers/partner';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import { initialPartner, PartnerProps } from '../../types/partner.type';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const { Text } = Typography;

const Partner = () => {
	const history = useHistory();

	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [isModalVisibleConfirmed, setIsModalVisibleConfirmed] =
		React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<PartnerProps>(initialPartner);
	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<PartnerProps>({
		endpoint: 'partners',
		limit: 10,
		initialQuery: {
			includePartnerTypes: getPartnerTypesForClinicAndHospital(),
		},
	});

	const handleUpdateIsPublished = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.partnerId === tmpData.partnerId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/partners/' + tmpData.partnerId,
				{
					partnerId: tmpData.partnerId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.partnerId === res.data.payload.partnerId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.partnerName + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialPartner);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialPartner);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleUpdateIsConfirmedPartner = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.partnerId === tmpData.partnerId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/partners/' + tmpData.partnerId,
				{
					partnerId: tmpData.partnerId,
					isConfirmedPartner: !tmpData.isConfirmedPartner,
				},
			);

			newData = data.map((item) => {
				if (item.partnerId === res.data.payload.partnerId) {
					return {
						...item,
						isConfirmedPartner: res.data.payload.isConfirmedPartner,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.partnerName + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisibleConfirmed(false);
			setTmpData(initialPartner);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisibleConfirmed(false);
			setTmpData(initialPartner);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleCreatePartner = () => {
		history.push('/clinic/add');
	};

	const { Option } = Select;

	const handleChangeStatusPublished = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleClickDetail = (e: PartnerProps) => {
		history.push(`/clinic/${e.partnerId}`);
	};

	const handleClickAction = (props: PartnerProps, key: string) => {
		if (key === 'detail') {
			history.push(`/clinic/${props.partnerId}`);
		} else if (key === 'edit') {
			history.push(`/clinic/${props.partnerId}/edit`);
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		}
	};

	const handleDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.partnerId === tmpData.partnerId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			await httpRequest.delete<DeleteResponseProps>(
				'/partners/' + tmpData.partnerId,
			);

			newData = data.filter((item) => item.partnerId !== tmpData.partnerId);
			setData(newData);

			message.success('Success delete ' + tmpData.partnerName);

			setIsLoadingDelete(false);
			setIsModalDeleteVisible(false);
			setTmpData(initialPartner);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialPartner);
			setIsLoadingDelete(false);
		}
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'partnerName',
			key: 'partnerName',
			render: (text: string, record: PartnerProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}{' '}
						{record.isConfirmedPartner && (
							<Tooltip placement="top" title="Verified Clinic">
								<CheckCircleFilled style={{ color: 'green', fontSize: 14 }} />
							</Tooltip>
						)}
					</div>
				);
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: (text: string, record: PartnerProps) => {
				return (
					<Text>
						{record.email ? (
							record.email
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'Phone Number',
			dataIndex: 'phone',
			key: 'phone',
			render: (text: string, record: PartnerProps) => {
				return (
					<Text>
						{record.phone ? (
							record.phone
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			render: (text: string, record: PartnerProps) => {
				return (
					<Text>
						{record.address ? (
							record.address
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},

		{
			title: 'Is Published',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: any, record: PartnerProps) => (
				<>
					{
						<Switch
							loading={record.statusLoading}
							checked={record.isPublished}
							onChange={() => {
								setIsModalVisible(true);
								setTmpData(record);
							}}
						/>
					}
				</>
			),
		},

		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: PartnerProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: PartnerProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Clinic</Menu.Item>
			<Menu.Item key="detail">Detail Clinic</Menu.Item>
			<Menu.Item key="delete">Delete Clinic</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Clinic"
				subtitle="Manage your Clinics"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreatePartner}>
							Add Clinic
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Clinic Name, Address, Phone"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>

				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeStatusPublished}
					placeholder="Status Publish"
				>
					<Option value="all">All</Option>
					<Option value="1">Published</Option>
					<Option value="0">Unpublished</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleUpdateIsPublished}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialPartner);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.partnerName}"</b> status to{' '}
					<b>"{!tmpData.isPublished ? 'Published' : 'Unpublished'}"</b>
					?. {!tmpData.isPublished}
				</p>
			</Modal>

			<Modal
				title="Update status confirmation"
				visible={isModalVisibleConfirmed}
				onOk={handleUpdateIsConfirmedPartner}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialPartner);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.partnerName}"</b> status to{' '}
					<b>"{!tmpData.isConfirmedPartner ? 'Confirmed' : 'Unconfirmed'}"</b>
					?. {!tmpData.isConfirmedPartner}
				</p>
			</Modal>

			<Modal
				title="Delete partner confirmation"
				visible={isModalDeleteVisible}
				onOk={handleDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialPartner);
				}}
				okText="Yes"
				confirmLoading={isLoadingDelete}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete partner <b>{tmpData.partnerName}</b>?
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Partner;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
