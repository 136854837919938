import { Col, Divider, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppCard from '../../../components/AppCard';
import DetailItem from '../../../components/DetailItem';
import HeaderSection from '../../../components/HeaderSection';
import SectionContent from '../../../components/SectionContent';
import { httpRequest } from '../../../helpers/api';
import { formatDate } from '../../../helpers/constant';
import { getAge } from '../../../helpers/getAge';
import { getFullName } from '../../../helpers/name';
import { replaceUnderscoreWithSpace } from '../../../helpers/replaceUnderscoreWithSpace';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../../types/config.type';
import { TransactionConsultationProps } from '../../../types/transactionConsultation.type';
interface IParams {
	id: string;
	transactionId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: TransactionConsultationProps;
}

const PrescriptionLabDetail: React.FC = () => {
	const { transactionId, id } = useParams<IParams>();
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [transactionConsultation, setTransactionConsultation] =
		useState<TransactionConsultationProps>();
	const [groupingSpecialist, setGroupingSpecialist] = useState<any[]>([]);
	const [rx, setRx] = useState<any>();
	const [labRequest, setLabRequest] = useState<any>();
	const [document, setDocument] = useState<any>();
	const { Text } = Typography;

	useEffect(() => {
		const fetchTransactionConsultationDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/transaction-consults/' + transactionId,
				);
				setTransactionConsultation(res.data.payload);

				const bcDetails = [
					{
						field: 'transactionConsultationId',
						value: transactionId,
						label: res.data.payload.transactionConsultationId,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchTransactionConsultationDetail();
	}, [transactionId]);

	useEffect(() => {
		const fetchRxDetail = async () => {
			try {
				const res = await httpRequest.get<ResponseProps>(
					'/rx-prescriptions/' + id,
				);
				setRx(res.data.payload);
			} catch (error) {
				console.log(error);
			}
		};
		fetchRxDetail();
	}, [transactionId]);

	useEffect(() => {
		const fetchLabRequestDetail = async () => {
			try {
				const res = await httpRequest.get<ResponseProps>('/lab-requests/' + id);
				setLabRequest(res.data.payload);
			} catch (error) {
				console.log(error);
			}
		};
		fetchLabRequestDetail();
	}, [transactionId]);

	useEffect(() => {
		const fetchDocumentDetail = async () => {
			try {
				const res = await httpRequest.get<ResponseProps>(
					'/doctor-documents/' + id,
				);
				setDocument(res.data.payload);
			} catch (error) {
				console.log(error);
			}
		};
		fetchDocumentDetail();
	}, [transactionId]);

	useEffect(() => {
		let newGroupSpecialists =
			transactionConsultation?.metaDoctor?.specialists?.reduce(function (
				storage: any,
				item: any,
			) {
				const data = {
					specialistId: item.specialistId || '',
					specialistName: item.specialistName || '',
					subSpecialists:
						item.subSpecialistId || item.subSpecialistName
							? [
									{
										subSpecialistId: item.subSpecialistId || '',
										subSpecialistName: item.subSpecialistName || '',
									},
							  ]
							: [],
				};

				let group = item['specialistId'];
				if (!group) {
					group = item['specialistName'];
				}

				storage[group] = storage[group] || {};
				const storageLength = Object.keys(storage[group]).length;
				if (storageLength > 0) {
					storage[group] = {
						...storage[group],
						subSpecialists: [
							...(storage[group].subSpecialists || []),
							...(data.subSpecialists || []),
						],
					};
				} else {
					storage[group] = data;
				}
				return storage;
			}, {});

		setGroupingSpecialist(newGroupSpecialists);
	}, [transactionConsultation?.metaDoctor?.specialists]);

	function isExpired(validUntil: Date) {
		const currentDate = new Date();
		const validUntilDate = new Date(validUntil);
		return currentDate > validUntilDate;
	}
	return (
		<div>
			<HeaderSection
				icon="back"
				title="Prescription & Lab Request Detail"
				subtitle="Detail of your Prescription & Lab Request"
			/>
			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<AppCard loading={false}>
					{rx && (
						<SectionContent groupTitle="Status">
							{isExpired(rx.validUntil) ? (
								<div
									style={{
										color: '#F23459',
										backgroundColor: '#FFF9FB',
										padding: 2,
										textAlign: 'center',
										width: '30%',
										border: '1px solid',
									}}
								>
									Expired
								</div>
							) : (
								<div
									style={{
										color: '#39AC6E',
										backgroundColor: '#F5FFF9',
										padding: 2,
										textAlign: 'center',
										width: '30%',
										border: '1px solid',
									}}
								>
									Free
								</div>
							)}
						</SectionContent>
					)}
					{labRequest && (
						<SectionContent groupTitle="Status">
							{isExpired(labRequest.validUntil) ? (
								<div
									style={{
										color: '#F23459',
										backgroundColor: '#FFF9FB',
										padding: 2,
										textAlign: 'center',
										width: '30%',
										border: '1px solid',
									}}
								>
									Expired
								</div>
							) : (
								<div
									style={{
										color: '#39AC6E',
										backgroundColor: '#F5FFF9',
										padding: 2,
										textAlign: 'center',
										border: '1px solid',
										width: '30%',
									}}
								>
									Free
								</div>
							)}
						</SectionContent>
					)}
					{document && (
						<SectionContent groupTitle="Status">
							{isExpired(document.validUntil) ? (
								<div
									style={{
										color: '#F23459',
										backgroundColor: '#FFF9FB',
										padding: 2,
										textAlign: 'center',
										width: '30%',
										border: '1px solid',
									}}
								>
									Expired
								</div>
							) : document.payment ? (
								<div
									style={{
										color: '#39AC6E',
										backgroundColor: '#F5FFF9',
										padding: 2,
										textAlign: 'center',
										borderColor: '#C2F0D7',
										width: '30%',
										border: '1px solid',
									}}
								>
									Paid
								</div>
							) : document.price == 0 || document.price == null ? (
								<div
									style={{
										color: '#39AC6E',
										backgroundColor: '#F5FFF9',
										padding: 2,
										textAlign: 'center',
										borderColor: '#C2F0D7',
										width: '30%',
										border: '1px solid',
									}}
								>
									Free
								</div>
							) : (
								<div
									style={{
										color: '#E89635',
										backgroundColor: '#FFF9EF',
										padding: 2,
										textAlign: 'center',
										width: '30%',
										border: '1px solid',
									}}
								>
									Unpaid
								</div>
							)}
						</SectionContent>
					)}

					<SectionContent groupTitle="Transaction Info">
						<Row>
							<Col span={8}>
								<DetailItem
									label="Document Type"
									content={
										rx ? 'Rx' : labRequest ? 'Lab Request' : 'Doctor Document'
									}
								/>
							</Col>
							<Col span={8}>
								<DetailItem
									label="Valid Until"
									content={
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											<h4>
												{rx
													? formatDate(rx.validUntil)
													: labRequest
													? formatDate(labRequest.validUntil)
													: document && formatDate(document.validUntil)}
											</h4>
										</div>
									}
								/>
							</Col>
						</Row>
						{rx && (
							<Row>
								<Col span={16}>
									<DetailItem
										label={['Medicines', 'Quantity']}
										content={rx?.prescriptionItems?.map((item: any) => (
											<Row gutter={[16, 16]}>
												<Col
													span={12}
													key={item.id}
													style={{ display: 'flex' }}
												>
													<h4
														style={{ marginTop: 'auto', marginBottom: 'auto' }}
													>
														{item.drugMedicineDetail.drugMedicineName}
													</h4>
												</Col>
												<Col
													span={12}
													key={item.id}
													style={{ display: 'flex' }}
												>
													<h4
														style={{ marginTop: 'auto', marginBottom: 'auto' }}
													>
														{item.quantity}
													</h4>
												</Col>
											</Row>
										))}
									/>
									{/* <Col span={8}>
										<DetailItem
											label="Medicines"
											content={rx?.prescriptionItems?.map((item: any) => (
												<div key={item.id}>
													<h4>{item.drugMedicineDetail.drugMedicineName}</h4>
												</div>
											))}
										/>
									</Col>
									<Col span={8}>
										<DetailItem
											label="Quantity"
											content={
												<>
													{rx?.prescriptionItems?.map((item: any) => (
														<div key={item.id}>{item.quantity}</div>
													))}
												</>
											}
										/>
									</Col> */}
								</Col>
							</Row>
						)}
						{labRequest && (
							<>
								<Row>
									<Col span={8}>
										<DetailItem
											label="Diagnoses"
											content={labRequest.assessmentDiagnosis}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={8}>
										<DetailItem
											label="Diagnostic Procedure"
											content={labRequest.procedures.map((prod: any) => (
												<div key={prod.id}>
													{prod.metaHealthService.healthServiceName}
												</div>
											))}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={8}>
										<DetailItem
											label="Special Instructions"
											content={labRequest.specialInstructions}
										/>
									</Col>
								</Row>
							</>
						)}
						{document && (
							<>
								<Row>
									<Col span={8}>
										<DetailItem
											label="Document"
											content={replaceUnderscoreWithSpace(document.type)}
										/>
									</Col>
								</Row>
							</>
						)}
					</SectionContent>

					<Divider />

					<SectionContent groupTitle="Patient">
						<Row>
							<Col span={8}>
								<DetailItem
									label="Name"
									content={
										rx
											? getFullName(rx.metaPatient)
											: labRequest
											? getFullName(labRequest.metaPatient)
											: document && getFullName(document.metaPatient)
									}
								/>
							</Col>
							<Col span={8}>
								<DetailItem
									label="Age"
									content={
										rx
											? getAge(rx.metaPatient?.birthdate)
											: labRequest
											? getAge(labRequest.metaPatient?.birthdate)
											: document && getAge(document.metaPatient?.birthdate)
									}
								/>
							</Col>
							<Col span={8}>
								<DetailItem
									label="Gender"
									content={
										rx
											? `${rx.metaPatient?.gender
													?.charAt(0)
													.toUpperCase()}${rx.metaPatient?.gender?.slice(1)}`
											: labRequest
											? `${labRequest.metaPatient?.gender
													?.charAt(0)
													.toUpperCase()}${labRequest.metaPatient?.gender?.slice(
													1,
											  )}`
											: document &&
											  `${document.metaPatient?.gender
													?.charAt(0)
													.toUpperCase()}${document.metaPatient?.gender?.slice(
													1,
											  )}`
									}
								/>
							</Col>
						</Row>
					</SectionContent>

					<Divider />

					<SectionContent groupTitle="Doctor">
						<DetailItem
							label="Name"
							content={
								rx
									? rx.doctor?.nameOfSignature
									: labRequest
									? labRequest.doctor?.nameOfSignature
									: document && document.doctor?.nameOfSignature
							}
						/>
						<Row>
							{(transactionConsultation?.metaDoctor?.specialists || []).length >
							0 ? (
								(
									(groupingSpecialist && Object.keys(groupingSpecialist)) ||
									[]
								)?.map((specialistId: any, index: number) => {
									const specialist = groupingSpecialist?.[specialistId];

									return (
										<Col span={8}>
											<DetailItem
												label={'Specialization'}
												content={
													<>
														<Col>{specialist.specialistName}</Col>
														{specialist.subSpecialists?.map((item: any) => (
															<Col
																style={{
																	marginLeft: 25,
																}}
															>
																{item.subSpecialistName}
															</Col>
														))}
													</>
												}
											/>
										</Col>
									);
								})
							) : (
								<DetailItem label={'Specialization'} content={''} />
							)}
						</Row>
					</SectionContent>

					{document && (
						<>
							<Divider />
							<SectionContent groupTitle="Payment Detail">
								<Row>
									<Col span={16}>Document Fee</Col>
									<Col span={8} style={{ textAlign: 'right' }}>
										<Text style={{ fontWeight: 'bold' }}>
											₱ {document?.price}
										</Text>
									</Col>
									<Divider />
									<Col span={16}>Total</Col>
									<Col span={8} style={{ textAlign: 'right' }}>
										<Text style={{ fontWeight: 'bold' }}>
											₱ {document?.price}
										</Text>
									</Col>
								</Row>
							</SectionContent>
						</>
					)}
				</AppCard>
			</div>
		</div>
	);
};

export default PrescriptionLabDetail;
