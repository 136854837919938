import {
	AppstoreAddOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	DatePicker,
	Dropdown,
	Input,
	Menu,
	Pagination,
	Row,
	Select,
	Table,
	Typography,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../../components/HeaderSection';
import { getFullName } from '../../../helpers/name';
import { replaceUnderscoreWithSpace } from '../../../helpers/replaceUnderscoreWithSpace';
import useFetchList from '../../../hooks/useFetchList';
import { IndividualServiceProps } from '../../../types/individualService.type';
import { PackageServiceProps } from '../../../types/packageService.type';
import {
	EAvailableServiceTypes,
	TransactionHealthServiceItemProps,
	TransactionHealthServiceProps,
} from '../../../types/transactionHealthService.type';

const { Text } = Typography;

interface ItemsProps {
	name: string;
	items: Array<String>;
}

let formatPrice = Intl.NumberFormat('en-US');

const TransactionHealthService = (props: { isTest?: boolean }) => {
	const history = useHistory();
	const { RangePicker } = DatePicker;

	const { isLoading, data, pagination, setSearch, setQuery, changePage } =
		useFetchList<TransactionHealthServiceProps>({
			endpoint: 'transaction-health-services',
			limit: 10,
			initialQuery: {
				isTestAccount: props.isTest,
			},
		});

	const { Option } = Select;

	const handleFilterByDate = (date: any) => {
		if (date) {
			setQuery((oldVal) => ({ ...oldVal, filterDate: date }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterDate: undefined }));
		}
	};

	const handleFilterByType = (type: string) => {
		if (type !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, serviceTypes: type }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, serviceTypes: undefined }));
		}
	};

	const handleFilterByStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, transactionStatus: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, transactionStatus: undefined }));
		}
	};

	const handleItems = (items: TransactionHealthServiceItemProps[]) => {
		const newItems: Array<ItemsProps> = items.map((item) => {
			const metaIndividual = item.metaHealthService as IndividualServiceProps;
			const metaPackage = item.metaHealthService as PackageServiceProps;
			let serviceName = '';
			let hsItems: string[] = [];

			if (metaIndividual && metaIndividual.categoryId) {
				serviceName = metaIndividual.category
					? `${metaIndividual.category.categoryName} (Categories)`
					: '';
				hsItems.push(
					metaIndividual.healthServiceName
						? metaIndividual.healthServiceName
						: '',
				);
			} else if (metaPackage && metaPackage.healthServiceName) {
				serviceName = `${metaPackage.healthServiceName} (Package)`;
				metaPackage.items?.map((packageItem) => {
					hsItems.push(packageItem);
				});
			}
			return {
				name: serviceName,
				items: hsItems,
			};
		});
		return newItems;
	};

	const handleClickDetail = (e: TransactionHealthServiceProps) => {
		history.push(
			`/transaction-medical-services/${e.transactionHealthServiceId}`,
		);
	};

	const handleClickAction = (
		props: TransactionHealthServiceProps,
		key: string,
	) => {
		if (key === 'detail') {
			history.push(
				`/transaction-medical-services/${props.transactionHealthServiceId}`,
			);
		}
	};

	const statusWaitStyle = {
		border: '2px solid #FDD88E',
		color: '#E89635',
		display: 'inline',
		padding: '0 10px',
	};

	const statusApprovedStyle = {
		border: '2px solid #C2F0D7',
		color: '#39AC6E',
		borderRadius: '0',
		display: 'inline',
		padding: '0 10px',
	};

	const statusCancelStyle = {
		border: '2px solid #D81F64',
		color: '#D81F64',
		borderRadius: '0',
		display: 'inline',
		padding: '0 10px',
	};

	const columns = [
		{
			title: 'INVOICE ID',
			dataIndex: 'transactionConsultationId',
			key: 'transactionConsultationId',
			render: (
				transactionHealthServiceId: string,
				record: TransactionHealthServiceProps,
			) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.payment ? record.payment.invoiceId : 'Payment Not Made Yet'}
					</div>
				);
			},
		},
		{
			title: 'PATIENT',
			dataIndex: 'patientId',
			key: 'patientId',
			render: (patient: any, record: TransactionHealthServiceProps) => {
				return (
					<Text>
						{record.metaPatient?.name || getFullName(record.metaPatient)}
					</Text>
				);
			},
		},
		{
			title: 'MEDICAL SERVICE',
			dataIndex: 'medicalServiceItems',
			key: 'medicalServiceItems',
			render: (patient: any, record: TransactionHealthServiceProps) => {
				return (
					<>
						{handleItems(record.transactionHealthServiceItems).map((item) => {
							let items = '';
							item.items.map((item) => (items = item + ', ' + items));
							return (
								<div>
									<Row>
										<Text>{item.name}</Text>
									</Row>
									<Row>
										<Text
											style={{
												color: 'rgba(29, 43, 54, 0.5)',
												fontWeight: 'bold',
												fontSize: '14px',
												lineHeight: '150%',
												letterSpacing: '-0.02em',
											}}
										>
											{items}
										</Text>
									</Row>
								</div>
							);
						})}
					</>
				);
			},
		},
		{
			title: 'TYPE',
			dataIndex: 'type',
			key: 'type',
			render: (patient: any, record: TransactionHealthServiceProps) => {
				return (
					<>{<Text>{replaceUnderscoreWithSpace(record.serviceType)}</Text>}</>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'STATUS',
			key: 'transactionStatus',
			dataIndex: 'transactionStatus',
			width: '20%',
			render: (status: any, record: TransactionHealthServiceProps) => (
				<div
					style={
						record.transactionStatus === 'APPROVED'
							? statusApprovedStyle
							: record.transactionStatus === 'WAITING_FOR_PAYMENT'
							? statusWaitStyle
							: statusCancelStyle
					}
				>
					{replaceUnderscoreWithSpace(record.transactionStatus)}
				</div>
			),
		},
		{
			title: 'TOTAL PAY',
			key: 'totalPay',
			dataIndex: 'totalPay',
			width: '10%',
			render: (status: any, record: TransactionHealthServiceProps) => (
				<>
					₱{' '}
					{record?.payment
						? formatPrice.format(record.payment.totalNeedToPay)
						: 0}
				</>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: TransactionHealthServiceProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: TransactionHealthServiceProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="detail">Detail Services</Menu.Item>
		</Menu>
	);

	if (data.length === 0) {
		return (
			<div>
				<HeaderSection
					icon={<AppstoreAddOutlined />}
					title="Transaction Medical Service"
					subtitle="Manage your transaction medical service"
				/>

				<ContainerFilter>
					<Input
						style={{ width: 550 }}
						size="large"
						placeholder="Search Invoice ID, Patient name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setSearch(e.target.value)}
					/>
					<CustoRangePicker
						size="large"
						allowEmpty={[true, true]}
						onCalendarChange={handleFilterByDate}
						// showTime={{
						//   hideDisabledOptions: true,
						//   defaultValue: [
						//     moment("00:00:00", "HH:mm:ss"),
						//     moment("23:59:59", "HH:mm:ss"),
						//   ],
						// }}
						showHour={false}
						showMinute={false}
						showSecond={false}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 240 }}
						onChange={handleFilterByStatus}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="WAITING_PAYMENT">Waiting for Payment</Option>
						<Option value="WAITING_APPROVAL">Waiting for Approval</Option>
						<Option value="APPROVED">Approved</Option>
						<Option value="CANCELED_BY_SYSTEM">Expired</Option>
						<Option value="REJECTED_REFUND">Refunded</Option>
						<Option value="REJECTED,REJECTED_CHANGE_DOCTOR,REJECTED_REFUND">
							Cancel - By Doctor
						</Option>
						<Option value="CANCELED_BY_USER">Cancel - By User</Option>
						{/* <Option value="COMPLETED">Completed</Option> */}
					</Select>
					<Select
						size="large"
						allowClear
						style={{ width: 160 }}
						onChange={handleFilterByType}
						placeholder="Type"
					>
						<Option value="all">All</Option>
						<Option value={EAvailableServiceTypes.IN_CLINIC}>In Clinic</Option>
						<Option value={EAvailableServiceTypes.HOME_SERVICE}>
							Home Service
						</Option>
					</Select>
				</ContainerFilter>

				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>
			</div>
		);
	} else {
		return (
			<div>
				<HeaderSection
					icon={<AppstoreAddOutlined />}
					title="Transaction Medical Service"
					subtitle="Manage your transaction medical service"
				/>

				<ContainerFilter>
					<Input
						style={{ width: 550 }}
						size="large"
						placeholder="Search Invoice ID, Patient name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setSearch(e.target.value)}
					/>
					<RangePicker
						size="large"
						allowEmpty={[true, true]}
						onCalendarChange={handleFilterByDate}
						showHour={false}
						showMinute={false}
						showSecond={false}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 240 }}
						onChange={handleFilterByStatus}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="WAITING_FOR_PAYMENT">Waiting for Payment</Option>
						<Option value="WAITING_APPROVAL">Waiting for Approval</Option>
						<Option value="APPROVED">Approved</Option>
						<Option value="CANCEL_BY_USER">Cancel - By User</Option>
						<Option value="CANCEL_BY_SYSTEM">Cancel - By System</Option>
						<Option value="REFUNDED">Refunded</Option>
						<Option value="REJECTED">Rejected</Option>
						<Option value="COMPLETED">Completed</Option>
					</Select>
					<Select
						size="large"
						allowClear
						style={{ width: 160 }}
						onChange={handleFilterByType}
						placeholder="Type"
					>
						<Option value="all">All</Option>
						<Option value={EAvailableServiceTypes.IN_CLINIC}>In Clinic</Option>
						<Option value={EAvailableServiceTypes.HOME_SERVICE}>
							Home Service
						</Option>
					</Select>
				</ContainerFilter>

				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>

				{data.length !== 0 ? (
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
				) : null}

				<PaginationText>
					<h4>Per Page</h4>
				</PaginationText>
			</div>
		);
	}
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default TransactionHealthService;

const CustoRangePicker = styled(DatePicker.RangePicker)`
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: 1px;
		margin-right: 10px;
		order: -1;
	}
	.ant-picker.ant-picker-range {
		display: flex;
		flex-direction: row-reverse;
	}
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
