import React, { useEffect, useState } from 'react';
import AppButton from '../../components/AppButton';
import { useHistory, useLocation } from 'react-router-dom';
import { StarOutlined } from '@ant-design/icons';
import {
	Pagination,
	Table,
	Typography,
	Row,
	Col,
	Space,
	message,
	Form,
	FormInstance,
	Modal,
	InputNumber,
	Button,
} from 'antd';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import HeaderSection from '../../components/HeaderSection';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import moment from 'moment';
import {
	RewardProps,
	RewardPoint,
	RewardHistoryProps,
} from '../../types/rewardPointSettings.type';
import { IHttpResponse } from '../../helpers/pagination';
import { IUser } from '../../types/user.type';

const { Text } = Typography;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<RewardProps, 'createdAt' | 'updatedAt'>;
}

const { Title } = Typography;

const RewardPointSettingsDetails = () => {
	const history = useHistory();
	const location = useLocation();
	const receivedData: RewardPoint = location.state as RewardPoint;
	const formRefPatient = React.useRef<FormInstance<RewardProps>>(null);
	const formRefDoctor = React.useRef<FormInstance<RewardProps>>(null);

	const { isLoading, data, setIsLoading, fetchList } =
		useFetchList<RewardProps>({
			endpoint: 'app-configs',
			limit: 2,
			initialQuery: {
				keys: [receivedData.key + '_PATIENT', receivedData.key + '_DOCTOR'],
			},
		});

	const {
		isLoading: historyIsLoading,
		data: historyData,
		setData: setHistoryData,
		setIsLoading: setHistoryIsLoading,
		pagination,
		changePage,
		fetchList: historyFetchList,
	} = useFetchList<RewardHistoryProps>({
		endpoint: 'app-configs-history',
		initialQuery: {
			keys: [receivedData.key + '_PATIENT', receivedData.key + '_DOCTOR'],
		},
	});

	const [isDataSorted, setIsDataSorted] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [modalData, setModalData] = useState<RewardPoint>();
	const [loadingModalData, setLoadingModalData] = useState<boolean>(true);
	const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
	const [patchKey, setPatchKey] = useState<string>('');
	const [isUpdateSuccessful, setIsUpdateSuccessful] = useState<string>('');
	const [isPatientUpdated, setIsPatientUpdated] = useState<boolean>(false);
	const [isDoctorUpdated, setIsDoctorUpdated] = useState<boolean>(false);

	// const getAdminName = async (id: string) => {
	// 	try {
	// 		const resultUser = await httpRequest.get<IHttpResponse<IUser>>(
	// 			`/user/${id}`
	// 		);
	// 		return resultUser.data.payload.name;
	// 	} catch (err) {
	// 		return "";
	// 	}
	// };

	const showModal = (data: RewardPoint) => {
		setLoadingModalData(true);
		setIsModalVisible(true);
		setModalData(data);
		setLoadingModalData(false);
	};

	const closeModal = () => {
		setIsModalVisible(false);
		setLoadingModalData(true);
	};

	const afterEditRewardPoint = () => {
		fetchList();
		historyFetchList();
		closeModal();
		setIsLoadingAction(false);
	};

	const editRewardPoint = async (
		props: Omit<RewardProps, 'createdAt' | 'updatedAt'>,
		type: string,
	) => {
		try {
			setIsLoadingAction(true);
			if (type === 'Doctor') {
				if (isDoctorUpdated === true) {
					const res = await httpRequest.patch<ResponseProps>(
						`/app-configs/${modalData!.key}_DOCTOR`,
						{
							...props,
						},
					);
					setIsUpdateSuccessful('Yes');
					setIsDoctorUpdated(false);
					afterEditRewardPoint();
				}
			} else {
				if (isPatientUpdated === true) {
					const res = await httpRequest.patch<ResponseProps>(
						`/app-configs/${modalData!.key}_PATIENT`,
						{
							...props,
						},
					);
					setIsUpdateSuccessful('Yes');
					setIsPatientUpdated(false);
					afterEditRewardPoint();
				}
			}
		} catch (error) {
			message.error(`ERROR: Point value cannot be updated!`);
			setIsLoadingAction(false);
		}
	};

	const sortData = (data: RewardProps[]) => {
		if (data.length === 0) {
			setIsLoading(true);
			setIsDataSorted(false);
			return;
		}

		setIsLoading(true);
		setIsDataSorted(false);

		let patient: RewardProps;
		let doctor: RewardProps;
		for (let obj of data) {
			if (obj.key === receivedData.key + '_PATIENT') {
				patient = obj;
			} else {
				doctor = obj;
			}
		}

		let createdAt = [patient!.createdAt, doctor!.createdAt];
		let updatedAt = [patient!.updatedAt, doctor!.updatedAt];
		receivedData.value.patient = patient!.value;
		receivedData.value.doctor = doctor!.value;
		receivedData.createdAt = createdAt.sort(
			(a, b) => Date.parse(b!) - Date.parse(a!),
		)[1]!;
		receivedData.updatedAt = updatedAt.sort(
			(a, b) => Date.parse(b!) - Date.parse(a!),
		)[0]!;

		if (isUpdateSuccessful === 'Yes') {
			message.success(`Point value has been updated successfully!`);
			setIsUpdateSuccessful('');
		}

		setIsDataSorted(true);
		setIsLoading(false);
	};

	useEffect(() => {
		sortData(data);
	}, [data, historyData]);

	const handleSubmit = async () => {
		await formRefPatient?.current?.submit();
		await formRefDoctor?.current?.submit();
	};

	const handleSubmitDoctor = async (
		values: Omit<RewardProps, 'createdAt' | 'updatedAt'>,
	) => {
		editRewardPoint(values, 'Doctor');
	};

	const handleSubmitPatient = async (
		values: Omit<RewardProps, 'createdAt' | 'updatedAt'>,
	) => {
		editRewardPoint(values, 'Patient');
	};

	const editKeyName = (keyName: string) => {
		let arr: string[] = keyName.split('_');
		let edited = '';
		for (let word of arr) {
			edited =
				edited +
				' ' +
				word.charAt(0).toUpperCase() +
				word.slice(1).toLowerCase();
		}
		return edited.slice(14);
	};

	const displayPointCard = (type: string) => {
		if (type === 'Doctor') {
			return (
				<Col
					span={11}
					style={{
						backgroundColor: 'white',
						minHeight: '187px',
						display: 'flex',
						flexFlow: 'column wrap',
						padding: 20,
					}}
				>
					<Row
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Text style={{ fontSize: '20px' }}>Doctor</Text>
						<StarOutlined style={{ color: '#A5B2BD', fontSize: '16px' }} />
					</Row>
					<Row>
						<Text style={{ fontSize: '36px' }}>
							{receivedData.value.doctor}
						</Text>
						<Text
							type="secondary"
							style={{ fontSize: '36px', marginLeft: '10px' }}
						>
							Points
						</Text>
					</Row>
				</Col>
			);
		} else {
			return (
				<Col
					span={11}
					style={{
						backgroundColor: 'white',
						minHeight: '187px',
						display: 'flex',
						flexFlow: 'column wrap',
						padding: 20,
					}}
				>
					<Row
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Text style={{ fontSize: '20px' }}>Patient</Text>
						<StarOutlined style={{ color: '#A5B2BD', fontSize: '16px' }} />
					</Row>
					<Row>
						<Text style={{ fontSize: '36px' }}>
							{receivedData.value.patient}
						</Text>
						<Text
							type="secondary"
							style={{ fontSize: '36px', marginLeft: '10px' }}
						>
							Points
						</Text>
					</Row>
				</Col>
			);
		}
	};

	const columns = [
		{
			title: 'UPDATE DATE',
			dataIndex: 'updateDate',
			key: 'updateDate',
			render: (text: string, record: RewardHistoryProps) => {
				return <Text>{moment(record.updatedAt).format('MMMM DD, YYYY')}</Text>;
			},
		},
		{
			title: 'TYPE',
			dataIndex: 'type',
			key: 'type',
			render: (text: string, record: RewardHistoryProps) => {
				return (
					<Text>{record.key.includes('PATIENT') ? 'Patient' : 'Doctor'}</Text>
				);
			},
		},
		{
			title: 'POINTS',
			dataIndex: 'points',
			key: 'points',
			render: (text: string, record: RewardHistoryProps) => {
				return <Text>{record.newData.value}</Text>;
			},
		},
		{
			title: 'UPDATED BY',
			dataIndex: 'updatedBy',
			key: 'updatedBy',
			render: (text: string, record: RewardHistoryProps) => {
				return <Text>{record.metaCreatedByUser.name}</Text>;
			},
		},
		{
			title: 'ROLE',
			dataIndex: 'role',
			key: 'role',
			render: (text: string, record: RewardHistoryProps) => {
				return <Text>Admin</Text>;
			},
		},
	];

	return (
		<div>
			<HeaderSection
				icon={<StarOutlined style={{ color: '#A5B2BD', fontSize: '17px' }} />}
				title={editKeyName(receivedData.key)}
				subtitle="Manage your point data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => {
								showModal(receivedData);
							}}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			{isDataSorted === true && !isLoading && (
				<>
					<Row style={{ gap: 20, marginBottom: 22 }}>
						{receivedData!.key !==
							'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION' &&
							receivedData!.key !==
								'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION' &&
							receivedData!.key !==
								'REWARD_POINT_USING_IN_PERSON_CONSULTATION' &&
							displayPointCard('Doctor')}
						{displayPointCard('Patient')}
					</Row>
					<Text strong style={{ fontSize: '16px' }}>
						Change Log
					</Text>
					<Table
						bordered
						loading={isLoading}
						columns={columns}
						dataSource={historyData}
						pagination={false}
						style={{ marginTop: 10 }}
					/>

					{historyData.length !== 0 ? (
						<Pagination
							current={pagination.page}
							total={pagination.totalData}
							defaultPageSize={pagination.perPage}
							pageSize={pagination.perPage}
							pageSizeOptions={['10', '20', '30']}
							showSizeChanger={true}
							showTotal={(total, range) =>
								`${range[0]}-${range[1]} of ${total} items`
							}
							onChange={changePage}
						/>
					) : null}
				</>
			)}
			{!loadingModalData && (
				<Modal
					title={editKeyName((modalData as RewardPoint).key)}
					visible={isModalVisible}
					onCancel={closeModal}
					keyboard={true}
					maskClosable={true}
					footer={[
						<Button key="back" onClick={closeModal}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={() => {
								handleSubmit();
							}}
							style={{ backgroundColor: '#D81F64', borderColor: '#D81F64' }}
						>
							Save
						</Button>,
					]}
				>
					{modalData!.key !== 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION' &&
						modalData!.key !==
							'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION' &&
						modalData!.key !== 'REWARD_POINT_USING_IN_PERSON_CONSULTATION' && (
							<Form
								ref={formRefDoctor}
								name="rewardPointForm"
								layout="vertical"
								onFinish={handleSubmitDoctor}
								initialValues={modalData}
								autoComplete="off"
								onValuesChange={() => {
									setIsDoctorUpdated(true);
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSubmit();
									}
								}}
							>
								<Form.Item label="Doctor" name="value">
									<InputNumber
										addonAfter="Points"
										min={0}
										defaultValue={+(modalData as RewardPoint).value.doctor}
									/>
								</Form.Item>
							</Form>
						)}
					<Form
						ref={formRefPatient}
						name="rewardPointForm"
						layout="vertical"
						onFinish={handleSubmitPatient}
						initialValues={modalData}
						autoComplete="off"
						onValuesChange={() => {
							setIsPatientUpdated(true);
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								handleSubmit();
							}
						}}
					>
						<Form.Item label="Patient" name="value">
							<InputNumber
								addonAfter="Points"
								min={0}
								defaultValue={+(modalData as RewardPoint).value.patient}
							/>
						</Form.Item>
					</Form>
				</Modal>
			)}
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default RewardPointSettingsDetails;
