import { BaseResponseProps } from './config.type';

export enum EFAQType {
	GENERAL = 'GENERAL',
	BALANCE = 'BALANCE',
	POINT = 'POINT',
}

export interface FAQProps {
	faqId: string;
	type?: string;
	targetReaders: string[];
	question: string;
	answer: string;
	isPublished: boolean;
	updatedAt?: Date | string;
	createdAt?: Date | string;
}

export interface FetchAllFAQResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: FAQProps[];
	};
}

export const initialFAQ: FAQProps = {
	faqId: '',
	type: undefined,
	targetReaders: [],
	question: '',
	answer: '',
	isPublished: true,
	updatedAt: new Date(),
	createdAt: new Date(),
};
