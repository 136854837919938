import { MoreOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	message,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import useFetchList from '../../hooks/useFetchList';
import { CategoryProps } from '../../types/category.type';
import { BaseResponseProps } from '../../types/config.type';
import { ContainerFilter } from '../dashboard';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CategoryProps, 'createdAt' | 'updatedAt'>;
}

const Category = () => {
	const history = useHistory();

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<CategoryProps>({
		endpoint: 'categories',
		limit: 10,
	});

	const handleStatusChange = async (record: CategoryProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.categoryId === record.categoryId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/categories/' + record.categoryId,
				{
					categoryId: record.categoryId,
					isPublished: !record.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.categoryId === res.data.payload.categoryId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.categoryName + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleCreateCategory = () => {
		history.push('/category/add');
	};

	const handleClickDetail = (e: CategoryProps) => {
		history.push(`/category/${e.categoryId}/detail`);
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
		}
	};

	const handleClickEdit = (e: CategoryProps) => {
		history.push(`/category/${e.categoryId}/edit`);
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'categoryName',
			key: 'categoryName',
			render: (text: string, record: CategoryProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'Status',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: CategoryProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: CategoryProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: CategoryProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'detail') {
					handleClickDetail(record);
				} else if (key === 'edit') {
					handleClickEdit(record);
				}
			}}
		>
			<Menu.Item key="detail">Detail Category</Menu.Item>
			<Menu.Item key="edit">Edit Category</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<TagOutlined />}
				title="Categories"
				subtitle="Manage your category data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateCategory}>
							Add Category
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Category name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Inactive</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			<Pagination
				current={pagination.page}
				total={pagination.totalData}
				defaultPageSize={pagination.perPage}
				pageSizeOptions={['25', '50', '100']}
				showSizeChanger={true}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				onChange={changePage}
			/>
		</div>
	);
};

export default Category;
