import { PaymentProps } from './payment.type';
import { TransactionEbookItemProps } from './ebookItemTransaction.type';

export enum ETransactionStatusType {
	WAITING_PAYMENT = 'WAITING_PAYMENT', // trx created and waiting payment

	WAITING_APPROVAL = 'WAITING_APPROVAL',

	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',

	CANCELED_BY_SYSTEM = 'CANCELED_BY_SYSTEM',
	CANCELED_BY_USER = 'CANCELED_BY_USER',
	COMPLETED = 'COMPLETED', // if consultation has been done
	EXPIRED = 'EXPIRED',
}

export interface TransactionEbookProps {
	transactionEbookId: string;
	userId: string;
	sellerId: string;
	partnerId?: string;
	metaUser?: {
		userId?: string;
		name?: string;
		email?: string;
		gender?: 'male' | 'female';
		phone?: string;
		birthdate?: string;
		province?: string;
		city?: string;
		userType?: string;
		firstName?: string;
		middleName?: string;
		lastName?: string;
		nickName?: string;
		profilePic?: string;
		status?: string;
		isEmailVerified: boolean;
		isPhoneVerified: boolean;
	};
	metaSeller: any;
	metaPartner?: any;
	transactionStatus: ETransactionStatusType;
	createdAt: Date;
	updatedAt: Date;
	transactionEbookItems?: TransactionEbookItemProps[];
	payment?: PaymentProps;
	histories?: any[];
	totalBuy: number;
	totalIncome: number;
}

export interface SoldEbookProps {
	bookNumber: string;
	image: string;
	title: string;
	finalPrice: number;
	totalBuy: number;
	totalIncome: number;
	createdAt: Date;
}
export const TRANSACTION_STATUS_SETTINGS: any = {
	[ETransactionStatusType.WAITING_PAYMENT]: {
		label: 'Waiting Payment',
		color: '#E89635',
	},
	[ETransactionStatusType.WAITING_APPROVAL]: {
		label: 'Waiting Approval',
		color: 'blue',
	},
	[ETransactionStatusType.APPROVED]: {
		label: 'Approved',
		color: '#39AC6E',
	},
	[ETransactionStatusType.COMPLETED]: {
		label: 'Completed',
		color: '#39AC6E',
	},
	[ETransactionStatusType.REJECTED]: {
		label: 'Rejected',
		color: '#D81F64',
	},
	[ETransactionStatusType.CANCELED_BY_SYSTEM]: {
		label: 'Canceled By Medeasy',
		color: '#D81F64',
	},
	[ETransactionStatusType.CANCELED_BY_USER]: {
		label: 'Canceled By User',
		color: '#D81F64',
	},
	[ETransactionStatusType.EXPIRED]: {
		label: 'Expired',
		color: '#D81F64',
	},
};
