import { BaseResponseProps } from './config.type';

export interface TnCProps {
	content: string;
}

export interface FetchAllTnCResponse extends BaseResponseProps {
	payload: TnCProps;
}

export const initialTnC: TnCProps = {
	content: '',
};
