import { BaseResponseProps } from './config.type';

export type TagType = 'tag' | 'allergen';

export interface TagProps {
	tagId?: string;
	name: string;
	type: TagType;
	isPublished: boolean;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	statusLoading?: boolean;
}

export interface FetchAllTagResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: TagProps[];
	};
}

export const initialTag: TagProps = {
	tagId: '',
	name: '',
	type: 'tag',
	isPublished: true,
};
