import { BaseResponseProps } from './config.type';
import { initialPartner, PartnerProps } from './partner.type';

export interface PackageServiceProps {
	healthServiceId: string;
	healthServiceName: string;
	group: string;
	description?: string;
	items?: string[];
	isPublished: boolean;
	updatedAt?: Date;
	createdAt?: Date;
	partnerPackageHealthServices: PartnerPackageHealthServicesProps[];
	statusLoading?: boolean;
}

export interface FetchAllPackageService extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: PackageServiceProps[];
	};
}

export const initialPackageService: PackageServiceProps = {
	healthServiceId: '',
	healthServiceName: '',
	description: '',
	isPublished: true,
	partnerPackageHealthServices: [],
	group: '',
	statusLoading: false,
};

export const initialPackageFetchAll: FetchAllPackageService = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialPackageService],
	},
};

export interface PartnerPackageHealthServicesProps {
	id: string;
	partnerId: string;
	packageHealthServiceId: string;
	price?: number;
	setting?: any;
	updatedAt?: Date;
	createdAt?: Date;
	packageHealthServices: PackageServiceProps;
	partner: PartnerProps;
}

export const initialPartnerPackageHealthServicesProps: PartnerPackageHealthServicesProps =
	{
		id: '',
		partnerId: '',
		price: 0,
		packageHealthServices: initialPackageService,
		packageHealthServiceId: '',
		partner: initialPartner,
	};

export interface FetchAllPartnerPackageService extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: PartnerPackageHealthServicesProps[];
	};
}
