import { CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Image, Row, Space } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import HTMLPreview from '../../components/HTMLPreview';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { CategoryProps } from '../../types/category.type';
import { BaseResponseProps } from '../../types/config.type';
import { initialProduct, ProductProps } from '../../types/product.type';

interface IParams {
	productId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<ProductProps, 'createdAt' | 'updatedAt'>;
}

const ProductDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { productId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedImage, setSelectedImage] = React.useState<UploadFile<string>>({
		url: '',
		uid: '',
		name: '',
	});
	const [images, setImages] = React.useState<
		UploadChangeParam<UploadFile<any>>['fileList']
	>([]);
	const [product, setProduct] = React.useState<ProductProps>(initialProduct);

	React.useEffect(() => {
		const fetcProductDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/products/' + productId,
				);
				setProduct(res.data.payload);

				const dataImages = (res.data.payload.imageUrls || []).map(
					(item) => item,
				);

				setImages(dataImages as any[]);

				if (dataImages.length > 0) {
					setSelectedImage({
						...selectedImage,
						url: dataImages[0],
					});
				}

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'productId',
						value: productId,
						label: res.data.payload.productName,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetcProductDetail();
	}, [productId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Product Detail"
				subtitle="Manage your product data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => history.push('/product/' + productId + '/edit')}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="Product Information"
					helpers={[
						{
							title: 'Information',
							content:
								'Image either jpg, png or jpeg; max size 1MB; recommended resolution is 500x300px',
						},
					]}
				>
					<Space
						style={{
							width: '100%',
							background: 'grey',
							justifyContent: 'center',
						}}
					>
						<Image
							preview={false}
							width="100%"
							height={200}
							src={selectedImage.url || '/images/select-image.jpg'}
							fallback={'/images/blur-image.jpeg'}
							style={{ objectFit: 'cover' }}
							placeholder={
								<Image
									preview={false}
									src="/images/blur-image.jpeg"
									width="100%"
									height={200}
									style={{ objectFit: 'cover' }}
								/>
							}
						/>
					</Space>
					<Space direction="horizontal">
						{images.map((img) => (
							<div
								style={
									selectedImage.uid === img.uid
										? {
												border: 5,
												borderStyle: 'solid',
												borderColor: '#D81F64',
												position: 'relative',
										  }
										: { position: 'relative' }
								}
							>
								<Image
									onClick={() => {
										setSelectedImage(img);
									}}
									preview={false}
									width={100}
									height={100}
									src={img.url || img.thumbUrl || '/images/select-image.jpg'}
									fallback={'/images/blur-image.jpeg'}
									style={{ objectFit: 'cover' }}
									placeholder={
										<Image
											preview={false}
											src="/images/blur-image.jpeg"
											width={100}
											height={100}
											style={{ objectFit: 'cover' }}
										/>
									}
								/>
								<div
									onClick={() => {}}
									style={{
										cursor: 'pointer',
										backgroundColor: 'grey',
										position: 'absolute',
										top: 5,
										right: 5,
										paddingRight: 5,
										paddingLeft: 5,
										zIndex: 100,
									}}
								>
									<CloseOutlined width={20} height={20} color="#FFFFFF" />
								</div>
							</div>
						))}
					</Space>

					<DetailItem label="Name" content={product.productName} />

					<DetailItem
						label="Description"
						content={<HTMLPreview html={product.description} />}
					/>

					<DetailItem
						label="Product Variants"
						content={product.variants.map((item) => (
							<>
								<Row>
									<Col span={8}>{item.productVariantName}</Col>
									<Col span={16}>${item.pricePerItem}</Col>
								</Row>
								<Divider />
							</>
						))}
					/>

					<DetailItem
						label="Categories"
						content={sortCategories(product.categories || [])
							.map((item) => item.categoryName)
							.join(', ')}
					/>

					<DetailItem
						label="Status"
						content={product.isPublished ? 'Published' : 'Unpublished'}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default ProductDetail;

function sortCategories(categories: CategoryProps[]) {
	categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
	return categories;
}
