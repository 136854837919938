import { Space } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import { BaseResponseProps } from '../../types/config.type';
import { EventTagProps, initialEventTag } from '../../types/eventTag.type';

interface IParams {
	tagId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EventTagProps, 'createdAt' | 'updatedAt'>;
}

const EventTagDetail = () => {
	const history = useHistory();
	const { tagId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [eventTag, setEventTag] =
		React.useState<EventTagProps>(initialEventTag);

	const handleClickEdit = () => {
		history.push({
			pathname: `/medical-event-tag/${eventTag.tagId}/edit`,
			state: {
				tagId: eventTag.tagId,
			},
		});
	};

	React.useEffect(() => {
		const fetchDetailTag = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/event-tags/' + tagId,
				);

				setEventTag(res.data.payload);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailTag();
	}, [tagId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Event Tag Detail"
				subtitle="Manage your event tag data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Event Tag Data">
					<DetailItem label="Name" content={eventTag.tagName} />
					<DetailItem
						label="Status"
						content={eventTag.isPublished ? 'Active' : 'Inactive'}
					/>
					<DetailItem
						label="Created at"
						content={formatDate(eventTag.createdAt)}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default EventTagDetail;
