import { CategoryProps } from './category.type';
import { DrugMedicineDosesProps } from './doses.type';
import { BaseResponseProps } from './config.type';
import { PartnerProps } from './partner.type';

export interface DrugMedicineProps {
	drugMedicineId?: string;
	drugMedicineName?: string;
	drugMedicineGenericName?: string;
	sku?: string;
	description?: string;
	compositions?: string;
	indications?: string;
	contraIndications?: string;
	dose?: string;
	doseNote?: string;
	note?: string;
	adverseDrugReactions?: string;
	pregnancyCategory?: string;
	administration?: string;
	vendorId?: string;
	vendorName?: string;
	partnerId?: string;
	partnerName?: string;
	points?: number;
	isPublished: boolean;
	createdAt?: Date;
	updatedAt?: Date;

	imageUrls?: string[];
	partner?: PartnerProps;
	categories: CategoryProps[];
	doses?: DrugMedicineDosesProps[];

	statusLoading?: boolean;
}

export interface PharmacyMedicineProps extends DrugMedicineProps {
	medicine?: {
		totalPrescribed: number;
		totalPoints: number;
		totalPrescribedXQuantity: number;
		totalPointsXQuantity: number;
	};
}

export interface FetchAllMedicineResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: DrugMedicineProps[];
	};
}

export const initialMedicine: DrugMedicineProps = {
	drugMedicineId: '',
	drugMedicineName: '',
	drugMedicineGenericName: '',
	sku: '',
	description: '',
	compositions: '',
	indications: '',
	contraIndications: '',
	dose: '',
	doseNote: '',
	note: '',
	adverseDrugReactions: '',
	pregnancyCategory: '',
	administration: '',
	vendorId: '',
	vendorName: '',
	isPublished: true,
	categories: [],
	doses: [],
	statusLoading: false,
};
