import { Col, DatePicker, Pagination, Row, Table, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../components/HeaderSection';
import useFetchList from '../../hooks/useFetchList';
import { PrescriptionProps } from '../../types/prescription.type';
import { PartnerDoctorProps } from '../../types/partnerDoctor.type';
import { getAge } from '../../helpers/getAge';

const { Text } = Typography;

interface IParams {
	drugMedicineId: string;
	drugMedicineName: string;
}

interface PrescriptionHistoryProps {
	prescribedDate: Date;
	prescribedBy: string;
	patient: string;
	patientPhone: string;
	quantity: any;
	points: number;
	pharmacy: string;
	prcNumber?: string;
	specialist?: string;
	clinic?: string;
	age?: number;
	sex?: string;
}

const DetailMedicineHistory = () => {
	const history = useHistory();
	const { drugMedicineId } = useParams<IParams>();
	// const { drugMedicineName } = useParams<IParams>();
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [dmName, setDMName] = React.useState<string>('Medicine');
	const [scroll, setScroll] = React.useState(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
		setIsLoading,
		fetchList,
	} = useFetchList<PrescriptionProps>({
		endpoint: `rx-prescriptions`,
		initialQuery: {
			drugMedicineId: drugMedicineId,
			// drugMedicineName: drugMedicineId,
		},
	});

	const getClinics = (partners?: PartnerDoctorProps[]) => {
		if (partners) {
			return partners
				.filter((item) => item.partner?.partnerName)
				.map((item) => item.partner?.partnerName)
				.join(', ');
		}
		return '-';
	};

	const prescriptionHistories: PrescriptionHistoryProps[] = [];
	for (let prescription of data) {
		const prescriptionItem = prescription.prescriptionItems.find(
			(e) => e.drugMedicineId === drugMedicineId,
		);

		prescriptionHistories.push({
			prescribedDate: prescription.createdAt!,
			prescribedBy: prescription?.doctor?.nameOfSignature!,
			pharmacy: prescriptionItem?.drugMedicineDetail?.partner?.partnerName,
			patient:
				prescription?.metaPatient.name ||
				prescription?.metaPatient?.firstName +
					' ' +
					prescription?.metaPatient?.middleName +
					' ' +
					prescription?.metaPatient?.lastName,
			patientPhone: prescription?.metaPatient.phone,
			quantity: prescriptionItem?.quantity,
			points:
				prescriptionItem?.drugMedicineDetail.points *
				prescriptionItem?.quantity!,
			prcNumber: prescription?.doctor?.prcNumber,
			specialist: prescription?.doctor?.specialists?.[0]?.specialistName,
			clinic: getClinics(prescription?.doctor?.partners),
			age: getAge(prescription?.metaPatient?.birthdate),
			sex: prescription?.metaPatient?.gender?.toUpperCase(),
		});

		if (!scroll) {
			setScroll(true);
		}
	}

	const columns = [
		{
			title: 'PRESCRIBED BY',
			dataIndex: 'prescribedBy',
			key: 'prescribedBy',
			width: 250,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.prescribedBy}</Text>;
			},
		},
		{
			title: 'PRC NUMBER',
			dataIndex: 'prcNumber',
			key: 'prcNumber',
			width: 200,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.prcNumber}</Text>;
			},
		},
		{
			title: 'SPECIALIST',
			dataIndex: 'specialist',
			key: 'specialist',
			width: 200,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.specialist}</Text>;
			},
		},
		{
			title: 'LOCATION/CLINIC',
			dataIndex: 'clinic',
			key: 'clinic',
			width: 200,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.clinic}</Text>;
			},
		},
		{
			title: 'QUANTITY',
			dataIndex: 'quantity',
			key: 'quantity',
			width: 100,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.quantity}</Text>;
			},
		},
		{
			title: 'PATIENT',
			dataIndex: 'patient',
			key: 'patient',
			width: 200,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.patient}</Text>;
			},
		},
		{
			title: 'AGE',
			dataIndex: 'age',
			key: 'age',
			width: 100,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.age}</Text>;
			},
		},
		{
			title: 'SEX',
			dataIndex: 'sex',
			key: 'sex',
			width: 100,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.sex}</Text>;
			},
		},
		{
			title: 'PATIENT PHONE',
			dataIndex: 'phone',
			key: 'phone',
			width: 150,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.patientPhone}</Text>;
			},
		},
		{
			title: 'PHARMACY NAME',
			dataIndex: 'pharmacy',
			key: 'pharmacy',
			width: 150,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.pharmacy}</Text>;
			},
		},
		{
			title: 'PRESCRIBED DATE',
			dataIndex: 'prescribedDate',
			key: 'prescribedDate',
			width: 200,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return (
					<div>
						{moment(record.prescribedDate).format('MMMM DD, YYYY, HH:mm')}
					</div>
				);
			},
		},
		{
			title: 'POINTS',
			dataIndex: 'points',
			key: 'points',
			width: 100,
			render: (text: string, record: PrescriptionHistoryProps) => {
				return <Text>{record.points}</Text>;
			},
		},
	];

	return (
		<div>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						title={dmName}
						subtitle="Manage your medicine data"
						icon="back"
					/>
				</Col>
			</Row>
			<Text strong style={{ fontSize: 20, marginBottom: 8, display: 'block' }}>
				History
			</Text>
			<Table
				loading={isLoading}
				columns={columns}
				dataSource={prescriptionHistories}
				pagination={false}
				scroll={scroll ? { x: 'max-content' } : undefined}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}
		</div>
	);
};

export default DetailMedicineHistory;

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

const CustoRangePicker = styled(DatePicker.RangePicker)`
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: 1px;
		margin-right: 10px;
		order: -1;
	}
	.ant-picker.ant-picker-range {
		display: flex;
		flex-direction: row-reverse;
	}
`;
