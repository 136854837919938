import { BaseResponseProps } from './config.type';

export interface TopicProps {
	topicId?: string;
	topicName: string;
	isPublished: boolean;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	statusLoading?: boolean;
}

export interface FetchAllTopicResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: TopicProps[];
	};
}

export const initialTopic: TopicProps = {
	topicId: '',
	topicName: '',
	isPublished: true,
};
