import { Row, Col, Divider, Typography, Tabs, Space } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import DetailItem from '../../../components/DetailItem';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import { formatDate, formatTime } from '../../../helpers/constant';
import { PaymentProps } from '../../../types/payment.type';
import { getFullName } from '../../../helpers/name';
import { getAge } from '../../../helpers/getAge';
import { replaceUnderscoreWithSpace } from '../../../helpers/replaceUnderscoreWithSpace';
import { EPaymentDetailType } from '../../../types/paymentDetail.type';
import styled from 'styled-components';
import { GenreProps } from '../../../types/genre.type';
import {
	TransactionEbookProps,
	TRANSACTION_STATUS_SETTINGS,
} from '../../../types/ebookTransaction.type';
import { EbookProps, initialEbooks } from '../../../types/ebook.type';
import HTMLPreview from '../../../components/HTMLPreview';

interface IParams {
	transactionEbookId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: TransactionEbookProps;
}

const { Text } = Typography;
const { TabPane } = Tabs;

let formatPrice = Intl.NumberFormat('en-US');

const TransactionEbooksDetail: React.FC = () => {
	const { transactionEbookId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [transactionConsultation, setTransactionConsultation] =
		React.useState<TransactionEbookProps>();
	const [transaction, setTransaction] = React.useState<TransactionEbookProps>();
	const [payment, setPayment] = React.useState<PaymentProps>();

	const [ebook, setEbook] = React.useState<EbookProps>(initialEbooks);

	React.useEffect(() => {
		const fetchTransactionConsultationDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/transaction-ebooks/' + transactionEbookId + '/find-by-status',
				);
				setTransactionConsultation(res.data.payload);
				setPayment(res.data.payload.payment);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchTransactionConsultationDetail();
	}, [transactionEbookId]);

	React.useEffect(() => {
		const fetchTransactionDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/transaction-ebooks/completed',
					{
						params: {
							transactionStatus: 'COMPLETED',
						},
					},
				);
				setTransaction(res.data.payload);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchTransactionDetail();
	}, []);

	const refundPayment =
		payment &&
		payment.paymentDetails &&
		payment?.paymentDetails.find(
			(pay) => pay.paymentType == 'PATIENT_REFUND_RECEIVED',
		);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Transaction Detail"
				subtitle="Detail of your transaction"
			/>
			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<AppCard loading={isLoading}>
					<SectionContent groupTitle="E-Book Information">
						<Row gutter={[16, 16]}>
							<Col span={8}>
								<Space
									style={{
										width: '100%',
										background: 'grey',
										justifyContent: 'center',
										marginBottom: '5px',
									}}
								>
									<DetailItem
										type="image"
										label="Cover Ebook"
										content={
											transactionConsultation?.transactionEbookItems?.[0]?.ebook
												?.imageUrl || ''
										}
									/>
								</Space>
							</Col>
							<Col span={16}>
								<DetailItem
									label="Headline"
									content={
										transactionConsultation?.transactionEbookItems?.[0]?.ebook
											?.title
									}
								/>
								{/* <DetailItem
									label="Genre"
									content={sortTags(
										transactionConsultation?.transactionEbookItems?.[0]?.ebook
											?.genres || [],
									)
										.map((item) => item.genreName)
										.join(', ')}
								/> */}
								<Row gutter={[16, 16]}>
									<Col span={8}>
										<DetailItem
											label="Author"
											content={
												transactionConsultation?.transactionEbookItems?.[0]
													?.ebook?.author
											}
										/>
									</Col>
									<Col span={8}>
										<DetailItem
											label="Published"
											content={formatDate(
												transactionConsultation?.transactionEbookItems?.[0]
													?.ebook?.publicationDate,
											)}
										/>
									</Col>
									<Col span={8}>
										<DetailItem
											label="ISBN"
											content={
												transactionConsultation?.transactionEbookItems?.[0]
													?.ebook?.bookNumber
											}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<DetailItem
							label="Description"
							content={
								<HTMLPreview
									html={
										transactionConsultation?.transactionEbookItems?.[0]?.ebook
											?.description ?? ''
									}
								/>
							}
						/>
					</SectionContent>

					<Divider />
					<SectionContent groupTitle="Transaction Information">
						<Row gutter={[16, 16]}>
							<Col span={8}>
								<DetailItem
									label="Total Buy"
									content={transactionConsultation?.totalBuy}
								/>
							</Col>
							<Col span={8}>
								<DetailItem
									label="Total Income"
									content={`₱ ${transactionConsultation?.totalIncome}`}
								/>
							</Col>
						</Row>
					</SectionContent>
					<Divider />
				</AppCard>
			</div>
		</div>
	);
};
export const CustomTabs = styled(Tabs)`
	.ant-tabs-nav-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.ant-tabs-tab {
		width: 50%;
		justify-content: center;
	}
`;
export default TransactionEbooksDetail;
function sortTags(tags: GenreProps[]) {
	tags.sort((a, b) => a.genreName.localeCompare(b.genreName));
	return tags;
}
