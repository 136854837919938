import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { generateFormRules } from '../../helpers/formRules';
import { generateQueryString } from '../../helpers/generateQueryString';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import {
	FetchAllHealthServiceCategoryResponse,
	HealthServiceCategoryProps,
} from '../../types/healthServiceCategory.type';
import {
	IndividualServiceProps,
	initialIndividualService,
} from '../../types/individualService.type';

interface IParams {
	healthServiceId: string;
}

interface ILocation {
	healthServiceId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<IndividualServiceProps, 'createdAt' | 'updatedAt'>;
}

const { Option } = Select;

const IndividualEdit: React.FC = () => {
	const { Text } = Typography;
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { healthServiceId } = useParams<IParams>();
	const formRef =
		React.useRef<
			FormInstance<Omit<IndividualServiceProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const [individualService, setIndividualService] =
		React.useState<IndividualServiceProps>(initialIndividualService);

	const [healthServiceName, setHealthServiceName] = React.useState<string>('');
	const [showWarning, setShowWarning] = React.useState<boolean>(false);
	const [categories, setCategories] = React.useState<
		Array<HealthServiceCategoryProps>
	>([]);

	const createService = async (
		props: Omit<
			IndividualServiceProps,
			'createdAt' | 'updatedAt' | 'healthServiceId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			const res: any = await httpRequest.post(
				'/health-service/individuals',
				{
					healthServiceName: individualService.healthServiceName,
					isPublished: individualService.isPublished,
					category: individualService.category,
					categoryId: individualService.category?.categoryId,
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			message.success('Success create ' + props.healthServiceName);
			history.replace(
				`/medical-service-individual/` +
					res.data.payload.healthServiceId +
					'/detail',
			);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateService = async (
		props: Omit<
			IndividualServiceProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			const dataToBeSent = {
				healthServiceName: individualService.healthServiceName,
				isPublished: individualService.isPublished,
				category: individualService.category,
				categoryId: individualService.category?.categoryId,
			};
			if (healthServiceId) {
				await httpRequest.patch(
					`/health-service/individuals/${healthServiceId}`,
					dataToBeSent,
				);
			}

			message.success('Success update ' + props.healthServiceName + ' data');
			history.push('/medical-service-individual');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			IndividualServiceProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		if (healthServiceId) {
			console.log('name:', individualService.healthServiceName);
			updateService(values);
		} else {
			createService(values);
		}
	};

	const handleChangeCategories = (value: string) => {
		if (value !== undefined) {
			const foundCategory = categories.find(
				(cat) => cat.categoryId === value.toString(),
			);

			setIndividualService((oldVal) => {
				return {
					...oldVal,
					category: foundCategory,
				};
			});
		}
	};

	const handleChangeStatus = (value: boolean) => {
		setIndividualService((oldVal) => {
			return {
				...oldVal,
				isPublished: value,
			};
		});
	};

	console.log(individualService);

	React.useEffect(() => {
		if (healthServiceId) {
			const fetchServiceDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/health-service/individuals/' + healthServiceId,
					);
					setIndividualService(res.data.payload);

					const bcDetails = [
						{
							field: 'healthServiceId',
							value: healthServiceId,
							label: res.data.payload.healthServiceName,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchServiceDetail();
		}

		const getCategoryData = async () => {
			const catRes =
				await httpRequest.get<FetchAllHealthServiceCategoryResponse>(
					`/health-service/categories${generateQueryString({
						isPublished: true,
					})}`,
				);
			setCategories(catRes.data.payload.results);
		};

		getCategoryData();
	}, [healthServiceId, location]);

	const handleHealthNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHealthServiceName(value);
    setShowWarning(value.length > 100);
    setIndividualService((oldVal) => ({
        ...oldVal,
        healthServiceName: value,
    }));
};


	return (
		<div>
			<HeaderSection
				icon="back"
				title={(healthServiceId ? 'Edit' : 'Add') + ' Individual'}
				subtitle=""
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="individualServiceForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={
						healthServiceId ? individualService : initialIndividualService
					}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Medical Event Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Item Name"
							name="healthServiceName"
							rules={generateFormRules('Item Name', ['required'])}
						>
							<Input
								onChange={handleHealthNameChange}
								value={healthServiceName}
							/>
						</Form.Item>
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Item Name should not exceed 100 characters!
							</Text>
						)}

						<Form.Item
							label="Item Name"
							name="categoryId"
							rules={generateFormRules('Category', ['required'])}
						>
							<Select
								allowClear
								style={{ width: '100%' }}
								placeholder="Please select"
								onChange={handleChangeCategories}
								key={'categorySelect'}
								defaultValue={
									individualService.categoryId
										? individualService.categoryId
										: ''
								}
							>
								{categories.map((category, index) => {
									return (
										<Option
											key={`category${index}`}
											value={String(category.categoryId)}
										>
											{category.categoryName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</SectionContent>

					<SectionContent
						groupTitle="Settings"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Is Published', ['required'])}
						>
							<Radio.Group
								value={individualService.isPublished}
								onChange={(e) => handleChangeStatus(e.target.value)}
							>
								<Radio value={true}>Published</Radio>
								<Radio value={false}>Unpublished</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default IndividualEdit;
