import { BaseResponseProps } from './config.type';
import { GenreProps } from './genre.type';
import { PartnerProps,initialPartner } from './partner.type';
import { EbookVideoProps, initialEbookVideo } from './video.type';

export enum CalculatorType{
    BMI_CALCULATOR = 'BMI_CALCULATOR',
    CARDIO_RISK = 'CARDIO_RISK',
    DIABESTES_RISK = ' DIABESTES_RISK',
    MENSTRUATION_CALCULATOR = 'MENSTRUATION_CALCULATOR',
    PREGNANCY_DUE_DATE = 'PREGNANCY_DUE_DATE',
    GENERAL = 'GENERAL'
}

export interface EbookProps {
    ebookId: string;
    partnerId?: string;
    title: string;
    author: string;
    bookNumber: string;
    targetReaders: string[];
    description: string;
    originalPrice: number;
    finalPrice: number;
    discountText?: string;
    imageUrl?: string;
    isPublished?: boolean;
    fileUrl?: string;
    customOutline?: any;
    publicationDate: Date;
    createdByUserId: string;
    metaCreatedByUser: any;
    metaEbook: any;
    updatedAt: Date;
    createdAt: Date;
    calculator: CalculatorItem[];
    genres: GenreProps[];
    partners: PartnerProps;
    statusLoading?: boolean;
    ebookVideos: EbookVideoProps[];
}

export interface CustomOutlineItem {
    title: string;
    pageNumber: number;
    level: number;
}

export interface CalculatorItem {
    type: string;
    page: number;
}

export interface FetchAllEbookResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: EbookProps[];
	};
}

export const initialEbooks: EbookProps = {
    ebookId: '',
    partnerId:'',
    title: '',
    author: '',
    bookNumber: '',
    description: '',
    originalPrice: 0,
    finalPrice: 0,
    discountText: '',
    imageUrl: '',
    isPublished: true,
    fileUrl: '',
    customOutline: '',
    publicationDate: new Date(),
    createdByUserId: '',
    metaCreatedByUser: '',
    metaEbook: '',
    calculator: [],
    createdAt: new Date(),
	updatedAt: new Date(),
    genres: [],
    partners: initialPartner,
    statusLoading: false,
    ebookVideos: [],
    targetReaders: [],
}