import { Space } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import { formatDate } from '../../helpers/constant';
import { CategoryProps, initialCategory } from '../../types/category.type';
import { httpRequest } from '../../helpers/api';

interface IParams {
	categoryId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CategoryProps, 'createdAt' | 'updatedAt'>;
}

const CategoryDetail = () => {
	const history = useHistory();
	const { categoryId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [category, setCategory] =
		React.useState<CategoryProps>(initialCategory);

	const handleClickEdit = () => {
		history.push({
			pathname: `/category/${category.categoryId}/edit`,
			state: {
				categoryId: category.categoryId,
			},
		});
	};

	React.useEffect(() => {
		const fetchDetailCategory = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/categories/' + categoryId,
				);

				setCategory(res.data.payload);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailCategory();
	}, [categoryId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Category Detail"
				subtitle="Manage your category data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Category Data">
					<DetailItem label="Name" content={category.categoryName} />
					<DetailItem
						label="Status"
						content={category.isPublished ? 'Active' : 'Inactive'}
					/>
					<DetailItem
						label="Created at"
						content={formatDate(category.createdAt)}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default CategoryDetail;
