import { BaseResponseProps } from './config.type';

export interface CategoryProps {
	categoryId?: string;
	categoryName: string;
	isPublished: boolean;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	statusLoading?: boolean;
}

export interface FetchAllCategoryResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: CategoryProps[];
	};
}

export interface FetchCategoryResponse extends BaseResponseProps {
	payload: CategoryProps;
}

export const initialCategory: CategoryProps = {
	categoryId: '',
	categoryName: '',
	isPublished: true,
};
