import { UserOutlined } from '@ant-design/icons';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { useHistory } from 'react-router-dom';
import { Divider, Form, FormInstance, Input, Space } from 'antd';
import React from 'react';
import AppCard from '../../components/AppCard';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { IUser } from '../../types/user.type';
import useAuth from '../../hooks/useAuth';
import { showRoleName } from '../../helpers/auth';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import { capitalizeFirstLetter } from '../../helpers/text';

const EditProfile: React.FC = () => {
	const history = useHistory();
	const formRef =
		React.useRef<FormInstance<Omit<IUser, 'createdAt' | 'updatedAt'>>>(null);

	const { isLoading, user, saveProfile } = useAuth();
	const [image, setImage] = React.useState<File>();
	const [imageUrl, setImageUrl] = React.useState(user.profilePic);

	const handleSave = async (values: IUser) => {
		const name = `${values.firstName} ${values.middleName} ${values.lastName}`;

		await saveProfile({ ...values, image, name });
		history.push('/profile');
	};
	const handleCancel = () => {
		history.push('/profile');
	};

	return (
		<div>
			<HeaderSection
				icon={<UserOutlined />}
				title="Edit Profile"
				subtitle="Manage your profile"
				rightAction={
					<Space>
						<AppButton onClick={handleCancel}>Cancel</AppButton>
						<AppButton
							loading={isLoading}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>

			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSave}
					initialValues={{
						name: user.name,
						email: user.email,
						phone: user.phone,
						role: user.userType,
						status: user.status,
						firstName: user.firstName || user.name,
						middleName: user.middleName,
						lastName: user.lastName,
					}}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Profil Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, eveniet soluta delectus error tempore iure accusantium explicabo',
							},
						]}
					>
						<FormUploadImage
							imageUrl={imageUrl || ''}
							onChange={(file) => setImage(file)}
							onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
						/>

						<Form.Item
							label="First Name"
							name="firstName"
							rules={generateFormRules('First Name', ['required', 'letter-and-space'])}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Middle Name"
							name="middleName"
							rules={generateFormRules('Middle Name', ['letter-and-space'])}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Last Name"
							name="lastName"
							rules={generateFormRules('Last Name', ['letter-and-space'])}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Email"
							name="email"
							rules={generateFormRules('Email', ['required', 'email'])}
						>
							<div>{user.email}</div>
						</Form.Item>
						<Form.Item
							label="Phone number"
							name="phone"
							rules={generateFormRules('Phone number', [
								'required',
								'phoneNumber',
							])}
						>
							<Input />
						</Form.Item>
					</SectionContent>

					<Divider />

					{user.userType === 'admin' && (
						<SectionContent
							groupTitle="Profile Setting"
							helpers={[
								{
									title: 'Information',
									content:
										'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, eveniet soluta delectus error tempore iure accusantium explicabo',
								},
							]}
						>
							<Form.Item
								label="Role"
								name="role"
								rules={[
									{
										required: true,
										message: 'The Role is required.',
									},
								]}
							>
								{showRoleName(user.userType)}
							</Form.Item>
							<Form.Item label="Status" name="status">
								{capitalizeFirstLetter(user.status!)}
							</Form.Item>
						</SectionContent>
					)}
				</Form>
			</AppCard>
		</div>
	);
};

export default EditProfile;
