import { Breadcrumb } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

function isUUID(uuid: string) {
	const rgx =
		/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

	const isMatch = rgx.test(uuid);
	return isMatch;
}

const Breadcrumbs: React.FC = () => {
	const breadcrumbs = useBreadcrumbs();
	const history = useHistory();

	const renderLabel = (label: any) => {
		const labels = label.props.children.split('-');
		if (labels.length > 0) {
			const newLabel = labels.join(' ').toString();
			return newLabel.length > 20 ? newLabel.substr(0, 20) + '...' : newLabel;
		} else {
			return label.props.children;
		}
	};

	const handleBreadcrumb = (e: any, link: string, locationState: any) => {
		e.preventDefault();

		history.push({
			pathname: link,
			state: {
				...locationState,
			},
		});
	};

	return (
		<Breadcrumb
			separator={
				<img
					src="/images/breadcrumb-separator.svg"
					alt="breadcrumb-separator"
					width="5"
				/>
			}
		>
			{breadcrumbs.map(({ breadcrumb, match, location }, index) => {
				if (!isUUID((breadcrumb as any)?.props?.children)) {
					return (
						<Breadcrumb.Item key={match.url}>
							<div
								key={index}
								onClick={(e) => handleBreadcrumb(e, match.url, location.state)}
							>
								{renderLabel(breadcrumb)}
							</div>
						</Breadcrumb.Item>
					);
				}
				return <></>;
			})}
		</Breadcrumb>
	);
};

export default Breadcrumbs;
