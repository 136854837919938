import { Table, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import {
	TransactionEbookProps,
	TRANSACTION_STATUS_SETTINGS,
} from '../../../types/ebookTransaction.type';
import { getFullName } from '../../../helpers/name';
import useFetchList from '../../../hooks/useFetchList';

const { Text } = Typography;

interface historyProps {
	data?: TransactionEbookProps;
	payment: any;
}
interface status {
	newData: {
		transactionStatus: string;
		metaUser: any;
	};
}

const TransactionEbookHistory: React.FC<historyProps> = ({ data, payment }) => {
	let formatPrice = Intl.NumberFormat('en-US');

	const historyColumns = [
		{
			title: 'BUYER',
			key: 'userId',
			dataIndex: 'userId',
			render: (user: any, record: status) => {
				return (
					<Text>
						{record.newData?.metaUser?.name ||
							getFullName(record.newData?.metaUser)}
					</Text>
				);
			},
		},
		{
			title: 'CREATED AT',
			key: 'historyId',
			render: (record: TransactionEbookProps) => (
				<p> {moment(record.updatedAt).format('DD MMM YYYY, HH:mm:ss')}</p>
			),
		},
		{
			title: 'STATUS',

			render: (record: status) => {
				return (
					<div
						style={
							record.newData?.transactionStatus
								? {
										border:
											'2px solid ' +
											TRANSACTION_STATUS_SETTINGS[
												record.newData?.transactionStatus
											]?.color,
										color:
											TRANSACTION_STATUS_SETTINGS[
												record.newData?.transactionStatus
											]?.color,
										borderRadius: '0',
										display: 'inline',
										padding: '0 10px',
								  }
								: {}
						}
					>
						{record.newData?.transactionStatus}
					</div>
				);
			},
		},
		{
			title: 'TOTAL PAY',

			render: () => <p>₱{formatPrice.format(payment.totalNeedToPay)}</p>,
		},
	];

	return (
		<Table
			columns={historyColumns}
			dataSource={data?.histories?.slice().reverse()}
			pagination={false}
		/>
	);
};

export default TransactionEbookHistory;
