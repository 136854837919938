import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoApp } from '../assets/icons/logo.svg';
import { isPublisherMode } from '../helpers/cmsMode';

type AppLogoProps = {
	collapsed: boolean;
};

const AppLogo: React.FC<AppLogoProps> = ({ collapsed }) => {
	return (
		<LogoEtanaContainer collapsed={collapsed}>
			{collapsed ? (
				<LogoApp width={30} height={30} />
			) : (
				<AppTitle>
					{process.env.REACT_APP_WEBSITE_NAME}
				</AppTitle>
			)}
		</LogoEtanaContainer>
	);
};

type LogoEtanaContainerProps = {
	collapsed: boolean;
};

const LogoEtanaContainer = styled.div<LogoEtanaContainerProps>`
	padding: ${({ collapsed }) => (collapsed ? '0' : '0 16px')};
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
	background-color: ${({ theme }) => theme.colors.charcoal800};
`;

const AppTitle = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-size: 18px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
`;


export default AppLogo;
