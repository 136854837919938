import { BaseResponseProps } from './config.type';

export interface SpecialistProps {
	specialistId?: string;
	specialistName: string;
	specialistAliasName?: string | null;
	icon?: string;
	// active?: boolean;
	selected?: boolean;
	createdAt?: string;
	updatedAt?: string;
	// isNoSpecialization?: boolean;
	// childrens?: SpecialistProperties[];
}

export interface FetchAllSpecialistResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: SpecialistProps[];
	};
}

export const initialSpecialist: SpecialistProps = {
	specialistId: '',
	specialistName: '',
	specialistAliasName: '',
	icon: '',
};

export const initialSpecialistFetchAll: FetchAllSpecialistResponse = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialSpecialist],
	},
};
