import { Space } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useParams } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import { CustomerProps, initialCustomer } from '../../types/customer.type';
import AppButton from '../../components/AppButton';
import { useHistory } from 'react-router-dom';

interface IParams {
	userId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const AdminDetail = () => {
	const history = useHistory();
	const { userId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [customer, setCustomer] = React.useState<CustomerProps>({
		...initialCustomer,
	});

	React.useEffect(() => {
		const fetchUser = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>('/user/' + userId);

				setCustomer(res.data.payload);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchUser();
	}, [userId]);

	const handleClickEdit = () => {
		history.push(`/admins/${userId}/edit`);
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={customer.name}
				subtitle="Manage your admin data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() =>
								history.push('/admins/' + userId + '/change-password')
							}
						>
							Change Password
						</AppButton>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="User Data"
					helpers={[
						{
							title: 'Information',
							content:
								'Tag name must be unique, system will reject if tag has been created with the same name.',
						},
					]}
				>
					<DetailItem label="Name" content={customer.name} />
					<DetailItem
						label="Phone Number"
						content={customer.phone ? '+' + customer.phone : customer.phone}
					/>
					<DetailItem label="Email Address" content={customer.email} />
				</SectionContent>

				<SectionContent
					groupTitle="User Settings"
					helpers={[
						{
							title: 'Information',
							content:
								'Tag name must be unique, system will reject if tag has been created with the same name.',
						},
					]}
				>
					<DetailItem
						label="Role"
						content={
							customer.userType.charAt(0).toUpperCase() +
							customer.userType.slice(1)
						}
					/>
					<DetailItem
						label="Status"
						content={
							customer.status.charAt(0).toUpperCase() + customer.status.slice(1)
						}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default AdminDetail;
