import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { EHistoryType, EPointCategory, EStatus } from '../../types/point.type';
import AppButton from '../AppButton';
import { Typography, Modal, Row, Col, Button } from 'antd';
import { CheckSquareFilled, MinusSquareOutlined } from '@ant-design/icons';

type IFilter = { title: string; key: EPointCategory[] };
type IType = { title: string; key: EHistoryType[] };
type IStatus = { title: string; key: EStatus[] };
interface IProps {
	visible: boolean;
	setVisible: any;
	filterList: any;
	setFilterList: (filter: any) => void;
}

const { Text, Title } = Typography;

const ItemOption = ({
	item,
	value,
	field,
	onChangeFilter,
}: {
	item: IFilter;
	value: EPointCategory[];
	field: string;
	onChangeFilter: (field: string, item: IFilter) => void;
}) => (
	<AppButton
		style={{
			marginTop: 10,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			width: '90%',
			borderColor: value.length !== 0 ? '#D81F64' : '#A5B2BD',
		}}
		onClick={() => onChangeFilter(field, item)}
	>
		{value.length !== 0 ? (
			<CheckSquareFilled style={{ color: '#D81F64' }} />
		) : (
			<MinusSquareOutlined />
		)}
		<Text>{item.title}</Text>
	</AppButton>
);
const ModalTransactionFilter = forwardRef<any, IProps>(
	({ visible, setVisible, filterList, setFilterList }) => {
		const [filterType, setFilterType] = useState<any>({});

		const STATUS: IStatus[] = useMemo(
			() => [
				{ title: 'Available', key: [EStatus.AVAILABLE] },
				{ title: 'Pending', key: [EStatus.PENDING] },
				{ title: 'Expired', key: [EStatus.EXPIRED] },
			],
			[],
		);

		const TYPES: IType[] = useMemo(
			() => [
				{ title: 'Income', key: [EHistoryType.INCOME] },
				{ title: 'Outcome', key: [EHistoryType.OUTCOME] },
			],
			[],
		);

		const FILTERS: IFilter[] = useMemo(
			() => [
				{ title: 'Register', key: [EPointCategory.REGISTER] },
				{ title: 'Daily Check In', key: [EPointCategory.DAILY_CHECK_IN] },
				{ title: 'Rx Prescription', key: [EPointCategory.RX_PRESCRIPTION] },
				{ title: 'Medical Service', key: [EPointCategory.MEDICAL_SERVICE] },
				// { title: 'Telemedicine', key: [EPointCategory.TELEMEDICINE] },
				// { title: 'Consult Now', key: [EPointCategory.CONSULT_NOW] },
				// {
				// 	title: 'In Person',
				// 	key: [EPointCategory.IN_PERSON_CONSULTATION],
				// },
				// { title: 'Withdrawal', key: [EPointCategory.WITHDRAWAL] },
				{
					title: 'Referral',
					key: [
						EPointCategory.REFERRAL,
						EPointCategory.REFERRAL_DOWNLINE,
						EPointCategory.REFERRAL_UPLINE,
					],
				},
				// { title: 'Doctor Document', key: [EPointCategory.DOCTOR_DOCUMENT] },
				{ title: 'Read Article', key: [EPointCategory.READ_ARTICLE] },
				{ title: 'Share Article', key: [EPointCategory.SHARE_ARTICLE] },
				{ title: 'Read Event', key: [EPointCategory.READ_EVENT] },
				{ title: 'Register Event', key: [EPointCategory.REGISTER_EVENT] },
				// { title: 'Refund', key: [EPointCategory.REFUND] },
				// {
				// 	title: 'Order Consultation',
				// 	key: [
				// 		EPointCategory.ORDER_CONSULTATION_IN_PERSON,
				// 		EPointCategory.ORDER_CONSULTATION_TELEMEDICINE,
				// 		EPointCategory.EXTEND_CONSULTATION_TELEMEDICINE,
				// 	],
				// },
				{
					title: 'Order Medical Service',
					key: [EPointCategory.ORDER_MEDICAL_SERVICE],
				},
				{
					title: 'Increase Point',
					key: [EPointCategory.INCREASE_POINT],
				},
				{
					title: 'Decrease Point',
					key: [EPointCategory.DECREASE_POINT],
				},
			],
			[],
		);

		const onChangeFilter = (field: string, value: IFilter) => {
			let filter = { ...filterType };
			let fieldArr = [...(filter?.[field] || [])];
			let exist = fieldArr?.find((itm) => value.key.includes(itm));
			if (exist) {
				fieldArr = fieldArr?.filter((itm) => !value.key.includes(itm));
			} else {
				fieldArr = [...fieldArr, ...value.key];
			}
			console.log('changes====>', { ...filter, [field]: fieldArr });
			setFilterType({ ...filter, [field]: fieldArr });
		};

		const confirmHandler = () => {
			if (setFilterList) {
				setFilterList(filterType);
				setVisible(false);
			}
		};

		useEffect(() => {
			if (filterList) {
				setFilterType(filterList);
			}
		}, [filterList]);

		useEffect(() => {
			console.log('open');
			return () => setFilterType({});
		}, []);

		return (
			<Modal visible={visible} footer={null} closable={false}>
				<Title style={{ marginBottom: 20 }} level={4}>
					Filter Point History
				</Title>
				<Row style={{ marginBottom: 30 }}>
					<Col span={12}>
						<Col>
							<Title level={5}>Status</Title>
						</Col>
						<Col>
							{STATUS.map((item: any) => {
								const isActive = filterType?.status?.filter(
									(type: any) => item.key?.includes(type),
								);
								return (
									<ItemOption
										key={item.key}
										item={item}
										value={isActive ? isActive : []}
										field={'status'}
										onChangeFilter={onChangeFilter}
									/>
								);
							})}
						</Col>
					</Col>
					<Col span={12}>
						<Col>
							<Title level={5}>Type</Title>
						</Col>
						<Col>
							{TYPES.map((item: any) => {
								const isActive = filterType?.types?.filter(
									(type: any) => item.key?.includes(type),
								);
								return (
									<ItemOption
										key={item.key}
										item={item}
										value={isActive ? isActive : []}
										field={'types'}
										onChangeFilter={onChangeFilter}
									/>
								);
							})}
						</Col>
					</Col>
				</Row>
				<Col>
					<Title level={5}>Category</Title>
				</Col>
				<Row>
					{FILTERS.map((item: any, index) => {
						const isActive = filterType?.categories?.filter(
							(type: any) => item.key?.includes(type),
						);
						return (
							<>
								{FILTERS.length / 2 > index + 1 ? (
									<Col span={12} key={item.key}>
										<ItemOption
											item={item}
											value={isActive ? isActive : []}
											field={'categories'}
											onChangeFilter={onChangeFilter}
										/>
									</Col>
								) : (
									<Col span={12} key={item.key}>
										<ItemOption
											item={item}
											value={isActive ? isActive : []}
											field={'categories'}
											onChangeFilter={onChangeFilter}
										/>
									</Col>
								)}
							</>
						);
					})}
				</Row>
				<Row
					style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
				>
					<Button type="primary" onClick={confirmHandler}>
						Done
					</Button>
				</Row>
			</Modal>
		);
	},
);

export default ModalTransactionFilter;
