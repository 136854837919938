import { useHistory } from 'react-router-dom';
import {
	MoreOutlined,
	SearchOutlined,
	FileTextOutlined,
} from '@ant-design/icons';
import {
	Pagination,
	Space,
	Table,
	Switch,
	Dropdown,
	Menu,
	message,
	Input,
	Select,
} from 'antd';
import AppButton from '../../../components/AppButton';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { formatDate } from '../../../helpers/constant';
import { BaseResponseProps } from '../../../types/config.type';
import { ContainerFilter } from '../../dashboard';
import useFetchList from '../../../hooks/useFetchList';
import { TopicProps } from '../../../types/topic.type';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<TopicProps, 'createdAt' | 'updatedAt'>;
}

const Topic = () => {
	const history = useHistory();

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<TopicProps>({
		endpoint: 'topics',
		limit: 10,
	});

	const handleStatusChange = async (record: TopicProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.topicId === record.topicId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);
			let status = !record.isPublished;
			const transformStatus = status.toString();

			const res = await httpRequest.patch<ResponseProps>(
				'/topics/' + record.topicId,
				{
					topicId: record.topicId,
					isPublished: transformStatus,
				},
			);

			newData = data.map((item) => {
				if (item.topicId === res.data.payload.topicId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.topicName + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleCreateTopic = () => {
		history.push('/topic/add');
	};

	const handleClickDetail = (e: TopicProps) => {
		history.push(`/topic/${e.topicId}/detail`);
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
		}
	};

	const handleClickEdit = (e: TopicProps) => {
		history.push(`/topic/${e.topicId}/edit`);
	};

	const columns = [
		{
			title: 'TAG NAME',
			dataIndex: 'topicName',
			key: 'topicName',
			render: (text: string, record: TopicProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: TopicProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: TopicProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: TopicProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'detail') {
					handleClickDetail(record);
				} else if (key === 'edit') {
					handleClickEdit(record);
				}
			}}
		>
			<Menu.Item key="detail">Detail Tag</Menu.Item>
			<Menu.Item key="edit">Edit Tag</Menu.Item>
		</Menu>
	);

	if (data.length === 0) {
		return (
			<div>
				<HeaderSection
					icon={
						<FileTextOutlined
							style={{
								fontSize: '1.5rem',
							}}
						/>
					}
					title="Article Tag"
					subtitle="Manage your article tag data"
					rightAction={
						<Space>
							<AppButton type="primary" onClick={handleCreateTopic}>
								Add New Tag
							</AppButton>
						</Space>
					}
				/>

				<ContainerFilter>
					<Input
						size="large"
						placeholder="Search by tag name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setSearch(e.target.value)}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 160 }}
						onChange={handleFilterStatusChange}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="active">Published</Option>
						<Option value="inactive">Unpublished</Option>
					</Select>
				</ContainerFilter>

				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>
			</div>
		);
	} else {
		return (
			<div>
				<HeaderSection
					icon={
						<FileTextOutlined
							style={{
								fontSize: '1.5rem',
							}}
						/>
					}
					title="Article Tag"
					subtitle="Manage your article tag data"
					rightAction={
						<Space>
							<AppButton type="primary" onClick={handleCreateTopic}>
								Add New Tag
							</AppButton>
						</Space>
					}
				/>

				<ContainerFilter>
					<Input
						size="large"
						placeholder="Search by tag name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setSearch(e.target.value)}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 160 }}
						onChange={handleFilterStatusChange}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="active">Published</Option>
						<Option value="inactive">Unpublished</Option>
					</Select>
				</ContainerFilter>

				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>

				{data.length !== 0 ? (
					<>
						<CustomPagination
							current={pagination.page}
							total={pagination.totalData}
							defaultPageSize={pagination.perPage}
							pageSizeOptions={['25', '50', '100']}
							showSizeChanger={true}
							showTotal={(total, range) =>
								`${range[0]} - ${range[1]} of ${total}`
							}
							onChange={changePage}
							locale={{ items_per_page: '' }}
							responsive={true}
						/>
						<PaginationText>
							<h4>Per Page</h4>
						</PaginationText>
					</>
				) : null}
			</div>
		);
	}
};

export default Topic;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
