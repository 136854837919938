import { CertificateProps } from './certificate.type';
import { BaseResponseProps } from './config.type';
import { EducationProps } from './education.type';
import { PartnerDoctorProps } from './partnerDoctor.type';
import { IUser } from './user.type';

export type DoctorProps = {
	doctorId: string;
	title?: string; // S.Kom

	prcNumber?: string;
	ptrNumber?: string;
	tinNumber?: string;

	experience?: number;

	kycStatus?: string;

	nameOfSignature?: string;
	signatureImageUrl?: string;

	specialists?: DoctorSpecialistsProps[];
	otherSpecialist?: string;

	createdAt?: Date;
	updatedAt?: Date;

	certificates?: CertificateProps[];
	educations?: EducationProps[];

	user?: IUser;
	availableConsultationTypes?: AvailabilityConsultationType[];
	schedulesInPartners?: any[];
	partners?: PartnerDoctorProps[];
};

export interface DoctorSpecialistsProps {
	id?: string;
	specialistId: string;
	specialistName: string;
	subSpecialistId?: string;
	subSpecialistName?: string;
	doctorId?: string;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface FetchAllDoctorResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: DoctorProps[];
	};
}

export const initialDoctor: DoctorProps = {
	doctorId: '',
	title: '',
	prcNumber: '',
	ptrNumber: '',
	tinNumber: '',
	kycStatus: '',
	nameOfSignature: '',
	signatureImageUrl: '',
	experience: 0,
	specialists: [],
	certificates: [],
	educations: [],
};

export const initialDoctorFetchAll: FetchAllDoctorResponse = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialDoctor],
	},
};

export enum AvailabilityConsultationType {
	TELEMEDICINE_VIDEO_CHAT_CONSULTATION = 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
	TELEMEDICINE_CHAT_CONSULTATION = 'TELEMEDICINE_CHAT_CONSULTATION',
	FACE_TO_FACE_CONSULTATION = 'FACE_TO_FACE_CONSULTATION',
}
