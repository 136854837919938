import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Table, Tag, Image, Modal } from 'antd';
import { ContainerFilter } from '../../screens/contentArticle/article';
import useFetchList from '../../hooks/useFetchList';
import { ArticleProps } from '../../types/article.type';
import { formatDate } from '../../helpers/constant';
import { TopicProps } from '../../types/topic.type';

type IProps = {
	setIsVisible: (visible: boolean) => any;
	isVisible: boolean;
	onSelect: (article: ArticleProps) => any;
};

export default function ModalSelectArticle(props: IProps) {
	const { isLoading, data, pagination, setSearch, changePage } =
		useFetchList<ArticleProps>({
			endpoint: 'articles',
			initialQuery: {
				isPublished: true,
			},
			limit: 10,
		});

	const columns = [
		{
			title: 'Image',
			dataIndex: 'images',
			key: 'images',
			render: (images: ArticleProps['images']) => {
				return (
					<Image
						width={120}
						height={100}
						src={
							images && images.length > 0
								? images[0] || '/images/blur-image.jpeg'
								: '/images/blur-image.jpeg'
						}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text: string, record: ArticleProps) => {
				return <div>{text}</div>;
			},
		},
		{
			title: 'Topic',
			key: 'topics',
			dataIndex: 'topics',
			render: (status: any, record: ArticleProps) => (
				<>
					{record.topics
						? sortTopics(record.topics).map((element) => {
								return <Tag>{`#${element.topicName}`}</Tag>;
						  })
						: []}
				</>
			),
		},
		{
			title: 'Target Readers',
			key: 'targetReaders',
			dataIndex: 'targetReaders',
			render: (status: any, record: ArticleProps) => (
				<>
					{record.targetReaders
						? record.targetReaders.sort().map((element) => {
								return <Tag>{element}</Tag>;
						  })
						: []}
				</>
			),
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: ArticleProps) => (
				// <Dropdown overlay={() => menu(record)} placement="bottomRight">
				//     <MoreOutlined style={{ cursor: 'pointer' }} />
				// </Dropdown>

				<Button
					onClick={() => {
						props.setIsVisible(false);
						props.onSelect(record);
					}}
				>
					Select
				</Button>
			),
		},
	];

	return (
		<Modal
			title={'Select Article'}
			visible={props.isVisible}
			width="90%"
			onCancel={() => {
				props.setIsVisible(false);
			}}
			footer={null}
		>
			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Title"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			<Pagination
				current={pagination.page}
				total={pagination.totalData}
				defaultPageSize={pagination.perPage}
				pageSize={pagination.perPage}
				pageSizeOptions={['10', '20', '30']}
				showSizeChanger={true}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				onChange={changePage}
			/>
		</Modal>
	);
}

function sortTopics(topics: TopicProps[]) {
	topics.sort((a, b) => a.topicName.localeCompare(b.topicName));
	return topics;
}
