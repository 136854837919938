import React from 'react';
import {
	Button,
	Col,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Row,
	Space,
	Typography,
	Divider,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { initialPartner, PartnerProps } from '../../types/partner.type';
import { getFullName } from '../../helpers/name';
import { useDebounce } from 'use-debounce';
import useFetchList from '../../hooks/useFetchList';
import { EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface IParams {
	partnerId: string;
}

interface ILocation {
	partnerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

const PublisherEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { partnerId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<PartnerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [partner, setPartner] = React.useState<PartnerProps>(initialPartner);
	const [userNumber, setUserNumber] = React.useState<string | null>(null);
	const [form] = Form.useForm();
	const [fieldsDisabled, setFieldsDisabled] = React.useState<boolean>(true);
	const [Password, setPassword] = React.useState<boolean>(true);
	const [publisherName, setPublisherName] = React.useState<string>('');
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [showWarning, setShowWarning] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(publisherName, 500);
	const [emailDisable, setEmailDisabled] = React.useState<boolean>(false);
	const [emailChecked, setEmailChecked] = React.useState<boolean>(false);

	const { data } = useFetchList<PartnerProps>({
		endpoint: 'partners',
		initialQuery: {
			includePartnerTypes: 'PUBLISHER',
		},
	});

	const handleSubmit = async (
		values: Omit<PartnerProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		setIsLoadingAction(true);
		try {
			if (partnerId) {
				await httpRequest.patch(`/partners/${partnerId}`, {
					...values,
					phone: userNumber,
				});
				message.success('Publisher updated successfully');
			} else {
				let name = getFullName(values);

				const formData = {
					name: name,
					email: values?.user?.email.toLowerCase(),
					phone: values?.user?.phone,
					status: 'active',
					isEmailVerified: true,
					isPhoneVerified: true,

					firstName: values?.user?.firstName,
					middleName: values?.user?.middleName,
					lastName: values?.user?.lastName,
					password: values?.user?.password,
				};

				const resultPartner: any = await httpRequest.post('/partners', {
					...values,
					email: values.email,
					phone: values.phone,
					partnerType: 'PUBLISHER',
				});

				await httpRequest.put('/partner-admins', {
					user: formData,
					partnerId: resultPartner.data.payload.partnerId,
					role: 'publisher_owner',
				});
				message.success('Publisher created successfully');
			}
			history.push('/publisher');
		} catch (error) {
			message.error(getErrorMessage(error));
		} finally {
			setIsLoadingAction(false);
		}
	};

	const handleCheckEmail = async () => {
		setIsLoadingAction(true);
		const email = form.getFieldValue(['user', 'email']);
		if (!email) {
			message.warning('Please enter an email address.');
			setIsLoadingAction(false);
			return;
		}
	
		try {
			const res = await httpRequest.post<ResponseProps>('/partner-admins/check-email', {
				email,
			});
			const userData = res.data.payload;
	
			form.setFieldsValue({
				user: {
					...userData,
				},
			});
			setUserNumber(userData.phone?.split('-')[1] || '');
			setFieldsDisabled(true);
			setPassword(false);
			setEmailDisabled(true);
			setEmailChecked(true);
			message.success('User data loaded successfully.');
		} catch (error: any) {
			const currentEmail = form.getFieldValue(['user', 'email']);
			form.resetFields(['user']);
			form.setFieldsValue({
				user: {
					email: currentEmail,
				},
			});
			
			if (error.data.message === 'Email has already been registered as a patient, doctor, or secretary') {
				setFieldsDisabled(true); 
				form.setFieldsValue({ 
					user: {
						email: '',
					},
				});
				message.error(error.data.message)
			} else {
				setFieldsDisabled(false);
				setPassword(true);
				setEmailDisabled(true);
				setEmailChecked(true);
				message.success('The account is not registered yet,please create a new one.');
			}
		} finally {
			setIsLoadingAction(false);
		};
	};
	

	const handleEditEmail = () => {
		setEmailDisabled(false);
		setEmailChecked(false);
	};

	React.useEffect(() => {
		const res: any = data.filter(
			(item) =>
				partnerId !== item.partnerId && searchValue === item.partnerName,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	React.useEffect(() => {
		if (partnerId) {
			const fetchPartnerDetail = async () => {
				setIsLoading(true);
				try {
					const res = await httpRequest.get<ResponseProps>(
						`/partners/${partnerId}`,
					);
					const partnerData = res.data.payload;
					setPartner(partnerData);
					form.setFieldsValue(partnerData);
					const countryNumber = res.data.payload.phone?.split('-');
					setUserNumber(countryNumber![1]);
					setBreadcrumbDetails([
						{
							field: 'partnerId',
							value: partnerId,
							label: res.data.payload.partnerName,
						},
					]);
				} catch (error) {
					message.error(getErrorMessage(error));
				} finally {
					setIsLoading(false);
				}
			};
			fetchPartnerDetail();
		}
	}, [partnerId, setBreadcrumbDetails]);

	const handlePublisherNameChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { value } = e.target;
		setPublisherName(value);
		setShowWarning(value.length > 100);
	};
	return (
		<div>
			<HeaderSection
				icon="back"
				title={`${partnerId ? 'Edit' : 'Add'} Publisher`}
				subtitle="Manage your publisher data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="partnerForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={partnerId ? partner : initialPartner}
					autoComplete="off"
					form={form}
				>
					<SectionContent
						groupTitle="Publisher Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Provide contact information for Publisher, including email and phone number.',
							},
						]}
					>
						<Form.Item
							label="Publisher Name"
							name="partnerName"
							rules={generateFormRules('Publisher Name', ['required'])}
						>
							<Input
								onChange={handlePublisherNameChange}
								value={publisherName}
							/>
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Publisher Name Already Exist!
							</Text>
						)}
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Publisher Name should not exceed 100 characters!
							</Text>
						)}
						<Form.Item label="Contact Email" name="email">
							<Input />
						</Form.Item>
						<Form.Item
							label="Contact Phone"
							name="phone"
							rules={generateFormRules('Phone')}
						>
							<Input
								defaultValue={partnerId && partner.phone ? partner.phone : ''}
								placeholder="Phone Number"
								onChange={(e) => setUserNumber(e.target.value)}
							/>
						</Form.Item>
						<Form.Item
							label="Address"
							name="address"
							rules={generateFormRules('Address')}
						>
							<TextArea rows={4} />
						</Form.Item>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Published Status" name="isPublished">
									<Radio.Group defaultValue={partner.isPublished}>
										<Radio value={true}>Published</Radio>
										<Radio value={false}>Unpublished</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Verified Status" name="isConfirmedPartner">
									<Radio.Group defaultValue={partner.isConfirmedPartner}>
										<Radio value={true}>Verified</Radio>
										<Radio value={false}>Unverified</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
					</SectionContent>

					<Divider />
					{!partnerId && (
						<SectionContent
							groupTitle="Admin Information"
							helpers={[
								{
									title: 'Information',
									content:
										'Provide contact information, including email and phone number for create publisher owner.',
								},
							]}
						>
							<>
								<Form.Item
									label="Email"
									name={['user', 'email']}
									rules={generateFormRules('Email', ['required', 'email'])}
									style={{ marginBottom: 0 }}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '5px',
										}}
									>
										<Input
											placeholder="Input email address"
											style={{ flex: 1 }}
											disabled={emailDisable}
										/>
										{emailChecked ? (
											<AppButton
												icon={<EditOutlined />}
												onClick={handleEditEmail}
												loading={isLoadingAction}
												style={{
													marginLeft: 8,
													backgroundColor: '#d81f64',
													borderColor: '#d81f64',
													color: '#ffffff',
												}}
											></AppButton>
										) : (
											<AppButton
												onClick={handleCheckEmail}
												loading={isLoadingAction}
												style={{
													marginLeft: 8,
													backgroundColor: '#d81f64',
													borderColor: '#d81f64',
													color: '#ffffff',
												}}
											>
												Check Email
											</AppButton>
										)}
									</div>
								</Form.Item>

								<Form.Item
									label="First Name"
									name={['user', 'firstName']}
									rules={generateFormRules('FirstName', [
										'required',
										'letter-and-space',
									])}
								>
									<Input placeholder="First Name" disabled={fieldsDisabled} />
								</Form.Item>
								<Form.Item
									label="Middle Name"
									name={['user', 'middleName']}
									rules={generateFormRules('MiddleName', ['letter-and-space'])}
								>
									<Input placeholder="Middle Name" disabled={fieldsDisabled} />
								</Form.Item>
								<Form.Item
									label="Last Name"
									name={['user', 'lastName']}
									rules={generateFormRules('LastName', ['letter-and-space'])}
								>
									<Input placeholder="Last Name" disabled={fieldsDisabled} />
								</Form.Item>
								{Password && (
									<Form.Item
										label="Password"
										name={['user', 'password']}
										rules={generateFormRules('Password', [
											'required',
											'password',
										])}
									>
										<Input.Password
											placeholder="Input password"
											disabled={fieldsDisabled}
										/>
									</Form.Item>
								)}
							</>
						</SectionContent>
					)}
				</Form>
			</AppCard>
		</div>
	);
};

const CustomSpace = styled(Space)`
	width: 100%;
	.ant-space-item {
		width: 100%;
	}
`;

export default PublisherEdit;
