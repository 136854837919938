import {
	AppstoreAddOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Dropdown,
	Image,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
	Tag,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import { EventProps } from '../../types/event.type';
import { EventTagProps } from '../../types/eventTag.type';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EventProps, 'createdAt' | 'updatedAt'>;
}

const Event = () => {
	const history = useHistory();

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		changePage,
		setQuery,
		fetchList,
	} = useFetchList<EventProps>({
		endpoint: 'events',
		limit: 10,
	});
	const handleCreateEvent = () => {
		history.push('/medical-event/add');
	};

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleClickDetail = (e: EventProps) => {
		history.push(`/medical-event/${e.eventId}/detail`);
	};

	const handleClickAction = (props: EventProps, key: string) => {
		if (key === 'detail') {
			history.push(`/medical-event/${props.eventId}/detail`);
		} else if (key === 'edit') {
			history.push(`/medical-event/${props.eventId}/edit`);
		} else if (key === 'delete') {
			Modal.confirm({
				title: 'Confirmation',
				content: 'Are you sure want to delete this event?',
				onOk: () => {
					if (props.eventId) {
						handleDelete(props.eventId);
					}
				},
			});
		}
	};

	const handleDelete = async (eventId: string) => {
		try {
			await httpRequest.delete('events/' + eventId);
			fetchList();
			message.success('Deleted successfully');
		} catch (err) {
			message.error('Failed to delete. ' + getErrorMessage(err));
		}
	};

	const handleStatusChange = async (record: EventProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.eventId === record.eventId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/events/' + record.eventId,
				{
					eventId: record.eventId,
					isPublished: !record.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.eventId === res.data.payload.eventId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.title + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleCPDPointChange = async (record: EventProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.eventId === record.eventId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/events/' + record.eventId,
				{
					eventId: record.eventId,
					isCPDAvailable: !record.isCPDAvailable,
				},
			);

			newData = data.map((item) => {
				if (item.eventId === res.data.payload.eventId) {
					return {
						...item,
						isCPDAvailable: res.data.payload.isCPDAvailable,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.title + ' CPD Point.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	let numberTagShow: number = 4;
	const columns = [
		{
			title: 'IMAGE',
			dataIndex: 'imageUrls',
			key: 'imageUrls',
			render: (images: EventProps['imageUrls']) => {
				return (
					<Image
						width={196}
						height={100}
						style={{ borderRadius: '10px' }}
						src={
							images && images.length > 0
								? images[images.length - 1] || '/images/blur-image.jpeg'
								: '/images/blur-image.jpeg'
						}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				);
			},
		},
		{
			title: 'HEADLINE',
			dataIndex: 'title',
			key: 'title',
			render: (title: string, record: EventProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{title}
					</div>
				);
			},
		},
		{
			title: 'CPD Point',
			key: 'isCPDAvailable',
			dataIndex: 'isCPDAvailable',
			render: (isCPDAvailable: boolean, record: EventProps) => {
				return (
					<Switch
						loading={record.statusLoading}
						checked={isCPDAvailable}
						onChange={() => {
							handleCPDPointChange(record);
						}}
					/>
				);
			},
		},
		{
			title: 'SPECIALITIES',
			key: 'specialities',
			dataIndex: 'specialities',
			render: (specialities: any, record: EventProps) => (
				<>
					{record.specialists
						? record.specialists.length <= 3
							? record.specialists.slice().map((el, index) => (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
										key={el.specialistId}
									>
										{el.specialistName}
									</Tag>
							  ))
							: record.specialists.slice(0, 3).map((el, index) => (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
										key={el.specialistId}
									>
										{el.specialistName}
									</Tag>
							  ))
						: []}
					{record.specialists
						? record.specialists.length > 3 && (
								<Tag
									style={{
										border: '2px solid #D81F64',
										color: '#D81F64',
										marginBottom: '10px',
									}}
								>
									+{record.specialists.slice(3).length} More
								</Tag>
						  )
						: []}
				</>
			),
		},
		{
			title: 'TAGS',
			key: 'tags',
			dataIndex: 'tags',
			render: (tags: any, record: EventProps) => (
				<>
					{record.tags
						? record.tags.length <= numberTagShow
							? sortTags(record.tags).map((element, idx) => {
									return (
										<Tag>
											{element.tagName}
											{idx < record.tags.length - 1 && ', '}
										</Tag>
									);
							  })
							: sortTags(record.tags)
									.slice(0, numberTagShow)
									.map((element, idx) => {
										return (
											<Tag>
												{element.tagName}
												{idx < record.tags.length && ', '}
											</Tag>
										);
									})
						: []}
					{record.tags.length > numberTagShow &&
						`+${record.tags.slice(numberTagShow).length} More`}
				</>
			),
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: EventProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: EventProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: EventProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Medical Event</Menu.Item>
			<Menu.Item key="detail">Detail Medical Event</Menu.Item>
			<Menu.Item key="delete">Delete Medical Event</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Medical Event"
				subtitle="Manage your Medical Event"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateEvent}>
							Add Medical Event
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search medical event headline"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status - All"
				>
					<Select.Option value="all">All</Select.Option>
					<Select.Option value="active">Published</Select.Option>
					<Select.Option value="inactive">Unpublished</Select.Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : (
				false
			)}
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Event;

function sortTags(tags: EventTagProps[]) {
	tags.sort((a, b) => a.tagName.localeCompare(b.tagName));
	return tags;
}

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
