import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Select, Table, Tabs } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import HeaderSection from '../../components/HeaderSection';
import { formatDate } from '../../helpers/constant';
import { getFullName } from '../../helpers/name';
import useFetchList from '../../hooks/useFetchList';
import { PatientInRelationProps } from '../../types/patientInRelation.type';
import Text from 'antd/lib/typography/Text';
import PwIdTag from '../../components/PwIdTag';
import { CustomerProps } from '../../types/customer.type';
import { EPWIdStatus } from '../../types/patient.type';
import ModalApprovalPwId from '../../components/ModalApprovalPwId';
import { capitalizeFirstLetter } from '../../helpers/text';
import moment from 'moment';

const { TabPane } = Tabs;
const { Option } = Select;

const Relative = () => {
	const [activeKey, setActiveKey] = useState<string>('1');
	const [isModalApprovalPwIdVisible, setIsModalApprovalPwIdVisible] =
		useState<boolean>(false);
	const [selectedData, setSelectedData] = useState<
		CustomerProps | PatientInRelationProps
	>();

	const {
		isLoading: isLoadingPatientRelative,
		data: dataPatientRelative,
		pagination: paginationPatientRelative,
		setSearch: setSearchPatientRelative,
		setQuery: setQueryPatientRelative,
		changePage: changePagePatientRelative,
	} = useFetchList<PatientInRelationProps>({
		endpoint: 'patient-in-relations',
		initialQuery: {
			isRelationPatient: true,
		},
		limit: 10,
	});

	const {
		isLoading: isLoadingDoctorRelative,
		data: dataDoctorRelative,
		pagination: paginationDoctorRelative,
		setSearch: setSearchDoctorRelative,
		setQuery: setQueryDoctorRelative,
		changePage: changePageDoctorRelative,
	} = useFetchList<PatientInRelationProps>({
		endpoint: 'patient-in-relations',
		initialQuery: {
			isRelationDoctor: true,
		},
		limit: 10,
	});

	const {
		isLoading: isLoadingNeedApprovalPatientRelative,
		data: dataNeedApprovalPatientRelative,
		pagination: paginationNeedApprovalPatientRelative,
		fetchList: fetchListNeedApprovalPatientRelative,
		setSearch: setSearchNeedApprovalPatientRelative,
		setQuery: setQueryNeedApprovalPatientRelative,
		changePage: changePageNeedApprovalPatientRelative,
	} = useFetchList<PatientInRelationProps>({
		endpoint: 'patient-in-relations',
		initialQuery: {
			isRelationPatient: true,
			pwIdStatus: EPWIdStatus.WAITING_APPROVAL,
		},
		limit: 10,
	});

	const {
		isLoading: isLoadingNeedApprovalDoctorRelative,
		data: dataNeedApprovalDoctorRelative,
		pagination: paginationNeedApprovalDoctorRelative,
		fetchList: fetchListNeedApprovalDoctorRelative,
		setSearch: setSearchNeedApprovalDoctorRelative,
		setQuery: setQueryNeedApprovalDoctorRelative,
		changePage: changePageNeedApprovalDoctorRelative,
	} = useFetchList<PatientInRelationProps>({
		endpoint: 'patient-in-relations',
		initialQuery: {
			isRelationDoctor: true,
			pwIdStatus: EPWIdStatus.WAITING_APPROVAL,
		},
		limit: 10,
	});

	const handleChangeTab = async (key: string) => {
		setActiveKey(key);
	};

	const handleChangePwIdStatus = (status: string) => {
		if (status !== 'all') {
			setQueryPatientRelative((oldVal) => ({ ...oldVal, pwIdStatus: status }));
			setQueryDoctorRelative((oldVal) => ({ ...oldVal, pwIdStatus: status }));
		} else {
			setQueryPatientRelative((oldVal) => ({ ...oldVal, pwIdStatus: '' }));
			setQueryDoctorRelative((oldVal) => ({ ...oldVal, pwIdStatus: '' }));
		}
	};

	const handleChangeGender = (status: string) => {
		if (status !== 'all') {
			setQueryPatientRelative((oldVal) => ({
				...oldVal,
				filterGender: status,
			}));
			setQueryDoctorRelative((oldVal) => ({ ...oldVal, filterGender: status }));
			setQueryNeedApprovalPatientRelative((oldVal) => ({
				...oldVal,
				filterGender: status,
			}));
			setQueryNeedApprovalDoctorRelative((oldVal) => ({
				...oldVal,
				filterGender: status,
			}));
		} else {
			setQueryPatientRelative((oldVal) => ({ ...oldVal, filterGender: '' }));
			setQueryDoctorRelative((oldVal) => ({ ...oldVal, filterGender: '' }));
			setQueryNeedApprovalPatientRelative((oldVal) => ({
				...oldVal,
				filterGender: '',
			}));
			setQueryNeedApprovalDoctorRelative((oldVal) => ({
				...oldVal,
				filterGender: '',
			}));
		}
	};

	const relativeColumns = [
		{
			title: 'FULL NAME',
			render: (record: PatientInRelationProps) => {
				return (
					<div>
						{getFullName(record) || (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</div>
				);
			},
		},

		{
			title: 'GENDER',
			dataIndex: 'gender',
			key: 'gender',
			render: (gender: string) => <Text>{capitalizeFirstLetter(gender)}</Text>,
		},
		{
			title: 'BIRTHDATE',
			dataIndex: 'birthdate',
			key: 'birthdate',
			render: (birthdate: Date) =>
				birthdate ? (
					<div>{formatDate(birthdate)}</div>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				),
		},
		{
			title: 'MAIN ACCOUNT',
			render: (record: PatientInRelationProps) => (
				<div>{getFullName(record.patient?.user)}</div>
			),
		},
		{
			title: 'RELATIONSHIP',
			key: 'relation',
			dataIndex: 'relation',
			render: (relation: any) => <div>{relation}</div>,
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title:
				activeKey === '1' || activeKey === '3'
					? 'PWD/SENIOR ID STATUS'
					: 'ACTION',
			dataIndex: 'pwIdStatus',
			key: 'pwIdStatus',
			render: (pwIdStatus: string, record: PatientInRelationProps) =>
				activeKey === '1' || activeKey === '3' ? (
					record.pwIdUrl && pwIdStatus ? (
						<PwIdTag>{pwIdStatus}</PwIdTag>
					) : (
						<Text italic type="secondary">
							Not set
						</Text>
					)
				) : (
					<Button
						onClick={() => {
							setIsModalApprovalPwIdVisible(true);
							setSelectedData(record);
						}}
						style={{
							border: 'none',
							backgroundColor: '#D81F64',
							color: 'white',
						}}
					>
						Approval
					</Button>
				),
		},
	];

	return (
		<div>
			<HeaderSection
				icon={<UserOutlined />}
				title={'Relative'}
				subtitle={'Manage your relative'}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder={'Search by Name'}
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => {
						setSearchPatientRelative(e.target.value);
						setSearchDoctorRelative(e.target.value);
						setSearchNeedApprovalPatientRelative(e.target.value);
						setSearchNeedApprovalDoctorRelative(e.target.value);
					}}
				/>

				<Select
					size="large"
					disabled={activeKey === '2' || activeKey === '4'}
					style={{ width: 160 }}
					onChange={handleChangePwIdStatus}
					placeholder="PWD Status"
				>
					<Option value="all">All</Option>
					<Option value={EPWIdStatus.APPROVED}>Approved</Option>
					<Option value={EPWIdStatus.REJECTED}>Rejected</Option>
					<Option value={EPWIdStatus.WAITING_APPROVAL}>Waiting Approval</Option>
				</Select>

				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeGender}
					placeholder="Gender"
				>
					<Option value="all">All</Option>
					<Option value="male">Male</Option>
					<Option value="female">Female</Option>
				</Select>
			</ContainerFilter>

			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					onChange={(e) => handleChangeTab(e)}
				>
					<TabPane tab="Patient Relatives" key={'1'}>
						<Table
							loading={isLoadingPatientRelative}
							columns={relativeColumns}
							dataSource={dataPatientRelative}
							pagination={false}
						/>
						{dataPatientRelative.length !== 0 && (
							<>
								<CustomPagination
									current={paginationPatientRelative.page}
									total={paginationPatientRelative.totalData}
									defaultPageSize={paginationPatientRelative.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total}`
									}
									onChange={changePagePatientRelative}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						)}
					</TabPane>

					<TabPane
						tab={
							<Text>
								Patient Relatives Need Approval PWD{' '}
								<span
									style={{
										backgroundColor: '#FAB347',
										padding: '5px 10px',
										borderRadius: 50,
										fontWeight: 'bold',
									}}
								>
									{dataNeedApprovalPatientRelative.length}
								</span>
							</Text>
						}
						key={'2'}
					>
						<Table
							loading={isLoadingNeedApprovalPatientRelative}
							columns={relativeColumns}
							dataSource={dataNeedApprovalPatientRelative}
							pagination={false}
						/>
						{dataNeedApprovalPatientRelative.length !== 0 && (
							<>
								<CustomPagination
									current={paginationNeedApprovalPatientRelative.page}
									total={paginationNeedApprovalPatientRelative.totalData}
									defaultPageSize={
										paginationNeedApprovalPatientRelative.perPage
									}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total}`
									}
									onChange={changePageNeedApprovalPatientRelative}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						)}
					</TabPane>

					<TabPane tab="Doctor Relatives" key={'3'}>
						<Table
							loading={isLoadingDoctorRelative}
							columns={relativeColumns}
							dataSource={dataDoctorRelative}
							pagination={false}
						/>
						{dataDoctorRelative.length !== 0 && (
							<>
								<CustomPagination
									current={paginationDoctorRelative.page}
									total={paginationDoctorRelative.totalData}
									defaultPageSize={paginationDoctorRelative.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total}`
									}
									onChange={changePageDoctorRelative}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						)}
					</TabPane>

					<TabPane
						tab={
							<Text>
								Doctor Relatives Need Approval PWD{' '}
								<span
									style={{
										backgroundColor: '#FAB347',
										padding: '5px 10px',
										borderRadius: 50,
										fontWeight: 'bold',
									}}
								>
									{dataNeedApprovalDoctorRelative.length}
								</span>
							</Text>
						}
						key={'4'}
					>
						<Table
							loading={isLoadingNeedApprovalDoctorRelative}
							columns={relativeColumns}
							dataSource={dataNeedApprovalDoctorRelative}
							pagination={false}
						/>
						{dataNeedApprovalDoctorRelative.length !== 0 && (
							<>
								<CustomPagination
									current={paginationNeedApprovalDoctorRelative.page}
									total={paginationNeedApprovalDoctorRelative.totalData}
									defaultPageSize={paginationNeedApprovalDoctorRelative.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]}-${range[1]} of ${total}`
									}
									onChange={changePageNeedApprovalDoctorRelative}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						)}
					</TabPane>
				</Tabs>
			</div>

			<ModalApprovalPwId
				isVisible={isModalApprovalPwIdVisible}
				setIsVisible={setIsModalApprovalPwIdVisible}
				data={selectedData}
				setSelectedData={setSelectedData}
				fetchList={
					activeKey === '2'
						? fetchListNeedApprovalPatientRelative
						: fetchListNeedApprovalDoctorRelative
				}
			/>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

export default Relative;
