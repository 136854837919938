import { UploadOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Form,
	FormInstance,
	Image,
	Input,
	message,
	Radio,
	Row,
	Select,
	Space,
	TimePicker,
	Typography,
	Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { initialPartner, PartnerProps } from '../../types/partner.type';

const { Option } = Select;
const { Text } = Typography;

interface IParams {
	partnerId: string;
}

interface ILocation {
	partnerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface SchedulesProps {
	id: string;
	weekday: number;
	day: string;
	times: Array<{
		startAt?: Date;
		endAt?: Date;
	}>;
}

const PartnerEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { partnerId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<PartnerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [partner, setPartner] = React.useState<PartnerProps>(initialPartner);

	const [image, setImage] = React.useState<UploadFile<unknown>>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();

	const [selectedCountry, setSelectedCountry] = React.useState<string>('63');
	const [userNumber, setUserNumber] = React.useState<any>(null);

	const [dates, setDates] = React.useState<Array<SchedulesProps>>([
		{
			id: '',
			weekday: 0,
			day: 'Sunday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 1,
			day: 'Monday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 2,
			day: 'Tuesday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 3,
			day: 'Wednesday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 4,
			day: 'Thursday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 5,
			day: 'Friday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 6,
			day: 'Saturday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
	]);

	const createPartner = async (
		props: Omit<
			PartnerProps,
			'createdAt' | 'updatedAt' | 'partnerId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			const res: any = await httpRequest.post('/partners', {
				...props,
				partnerType: 'CLINIC',

				phone: userNumber,
				geolocation: partner.geolocation,
			});

			if (res && res.data) {
				const promiseSchedule = [];
				for (const date of dates) {
					for (const time of date.times) {
						if (time.startAt !== undefined || time.endAt !== undefined) {
							promiseSchedule.push(
								httpRequest.post('/partner-open-hours', {
									partnerId: res.data.payload.partnerId,
									weekday: date.weekday,
									startAt: time.startAt,
									endAt: time.endAt,
								}),
							);
						}
					}
				}

				console.info('res.data', res.data);
				await Promise.all([
					uploadNewImage(res?.data?.payload.partnerId),
					promiseSchedule,
				]);

				message.success('Success create ' + props.partnerName);
				history.push('/clinic');
			}
			setIsLoadingAction(false);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updatePartner = async (
		props: Omit<PartnerProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/partners/' + partnerId, {
				...props,
				// phone: userNumber ? selectedCountry + '-' + userNumber : undefined,
				phone: userNumber,
				geolocation: partner.geolocation,
			});

			const newOpenHoursData = [];

			for (const date of dates) {
				for (const time of date.times) {
					if (time.startAt !== undefined || time.endAt !== undefined) {
						newOpenHoursData.push({
							partnerId: partnerId,
							weekday: date.weekday,
							startAt: time.startAt,
							endAt: time.endAt,
						});
					}
				}
			}

			await Promise.all([
				uploadNewImage(partnerId),
				httpRequest.patch('/partner-open-hours/' + partnerId, {
					times: newOpenHoursData,
				}),
			]);

			message.success('Success update ' + props.partnerName + ' data');
			history.push('/clinic');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<PartnerProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (partnerId) {
			updatePartner(values);
		} else {
			createPartner(values);
		}
	};

	const getCountryCode = (values: string, type: string) => {
		const countryCode = values.split('-');
		if (type === 'code') {
			return countryCode[0];
		} else if (type === 'phone') {
			return countryCode[1];
		}
	};

	const uploadNewImage = async (partnerId: string) => {
		if (image) {
			setIsLoadingAction(true);
			let formData = new FormData();
			formData.append('image', image.originFileObj as Blob);
			console.log(formData);
			await httpRequest.put(
				'/partners/' + partnerId + '/upload-cover',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
			setIsLoadingAction(false);
		}
	};

	const readFile = (file: UploadFile<any>): Promise<string | undefined> => {
		return new Promise((resolve, reject) => {
			if (file.originFileObj) {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onloadend = function (event) {
					if (event.target && event.target.result) {
						const url =
							event && event.target && event.target.result
								? (event.target.result as any)
								: undefined;
						resolve(url);
					} else {
						resolve(undefined);
					}
				};
			} else {
				resolve(undefined);
			}
		});
	};

	React.useEffect(() => {
		if (partnerId) {
			const fetchPartnerDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/partners/' + partnerId,
					);
					setPartner({
						...res.data.payload,
					});

					setImageUrl(res.data.payload.imageCoverURL);

					const countryNumber = res.data.payload.phone?.split('-');
					setUserNumber(countryNumber![1]);
					const bcDetails = [
						{
							field: 'partnerId',
							value: partnerId,
							label: res.data.payload.partnerName,
						},
					];
					setBreadcrumbDetails(bcDetails);

					const partnerOpenHoursData = dates;
					if (res.data.payload.openHours) {
						for (const date of partnerOpenHoursData) {
							for (const openHours of res.data.payload.openHours) {
								if (date.weekday === openHours.weekday) {
									dates[date.weekday] = {
										...date,
										times: [
											{
												startAt: openHours.startAt,
												endAt: openHours.endAt,
											},
										],
									};
								}
							}
						}
					}

					setDates(partnerOpenHoursData);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchPartnerDetail();
		}
	}, [partnerId, location]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(partnerId ? 'Edit' : 'Add') + ' Clinic'}
				subtitle="Manage your partner data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="partnerForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={partnerId ? partner : initialPartner}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Clinic Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Accepted image type are jpg, jpeg and png. Max file size is 1 Mb.',
							},
						]}
					>
						<Form.Item>
							<Space
								style={{
									width: '100%',
									background: '#f2f2f2',
									justifyContent: 'center',
								}}
							>
								<Image
									preview={false}
									width="100%"
									height={250}
									src={imageUrl || '/images/select-image.jpg'}
									fallback={'/images/blur-image.jpeg'}
									style={{ objectFit: 'cover' }}
									placeholder={
										<Image
											preview={false}
											src="/images/blur-image.jpeg"
											width="100%"
											height={200}
											style={{ objectFit: 'cover' }}
										/>
									}
								/>
							</Space>

							<CustomSpace
								style={{
									paddingTop: 10,
									paddingBottom: 10,
									width: '100%',
								}}
								wrap
							>
								<CustomUpload
									accept=".jpg,.jpeg,.png"
									onChange={async (file) => {
										console.log(file);
										const url = await readFile(file.file);
										console.info('url', url);
										setImageUrl(url);
										setImage(file.file);
									}}
									showUploadList={false}
									multiple={false}
								>
									<Button
										type="primary"
										danger
										icon={<UploadOutlined />}
										style={{ width: '100%' }}
									>
										Upload Image
									</Button>
								</CustomUpload>
							</CustomSpace>
						</Form.Item>

						<Form.Item
							label="Name"
							name="partnerName"
							rules={generateFormRules('Partner Name', ['required'])}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Email"
							name="email"
							rules={generateFormRules('Email', ['required'])}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Address"
							name="address"
							rules={generateFormRules('Address')}
						>
							<TextArea
								rows={4}
								value={partner.address}
								onChange={(e) =>
									setPartner({
										...partner,
										address: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item
									label="Latitude"
									name="latitude"
									rules={generateFormRules('Latitude')}
								>
									<Input
										defaultValue={partner.geolocation?.latitude}
										onChange={(e) =>
											setPartner({
												...partner,
												geolocation: partner.geolocation
													? {
															...partner.geolocation,
															latitude: Number(e.target.value),
													  }
													: {
															latitude: Number(e.target.value),
															longitude: 0,
													  },
											})
										}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="Longitude"
									name="longitude"
									rules={generateFormRules('Longitude')}
								>
									<Input
										defaultValue={partner.geolocation?.longitude}
										onChange={(e) =>
											setPartner({
												...partner,
												geolocation: partner.geolocation
													? {
															...partner.geolocation,
															longitude: Number(e.target.value),
													  }
													: {
															longitude: Number(e.target.value),
															latitude: 0,
													  },
											})
										}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Form.Item
							label="Phone"
							name="phone"
							rules={generateFormRules('Phone')}
						>
							<Input
								defaultValue={partnerId && partner.phone ? partner.phone : ''}
								placeholder="Phone Number"
								onChange={function (e) {
									if (e.target.value.length <= 0) {
										setUserNumber(null);
									} else {
										setUserNumber(e.target.value);
									}
								}}
							/>
						</Form.Item>

						<Row gutter={24}>
							<Col span={12}>
								<Form.Item
									style={{
										paddingTop: '2%',
									}}
									label="Published Status"
									name="isPublished"
								>
									<Radio.Group
										defaultValue={partner.isPublished ? true : false}
									>
										<Radio value={true}>Published</Radio>
										<Radio value={false}>Unpublished</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									style={{
										paddingTop: '2%',
									}}
									label="Verified Status"
									name="isConfirmedPartner"
								>
									<Radio.Group
										defaultValue={partner.isConfirmedPartner ? true : false}
									>
										<Radio value={true}>Verified</Radio>
										<Radio value={false}>Unverified</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
					</SectionContent>

					<SectionContent groupTitle="Clinic Schedule">
						<Row gutter={[24, 16]}>
							{dates.map((item) => {
								return (
									<Col span={6} key={item.weekday}>
										<Text>{item.day}</Text>
										{item.times.map((time, index) => {
											return (
												<Form.Item key={index} name={item.day}>
													<TimePicker.RangePicker
														defaultValue={
															partnerId && (time.startAt || time.endAt)
																? [moment(time.startAt), moment(time.endAt)]
																: undefined
														}
														style={{
															width: '80%',
															marginRight: '2%',
														}}
														order={false}
														format="HH:mm"
														onChange={(value) => {
															dates[item.weekday].times[index].startAt =
																value?.[0]?.toDate();
															dates[item.weekday].times[index].endAt =
																value?.[1]?.toDate();
														}}
													/>
												</Form.Item>
											);
										})}
									</Col>
								);
							})}
						</Row>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

const CustomSpace = styled(Space)`
	width: 100%;
	.ant-space-item {
		width: 100%;
	}
`;

const CustomUpload = styled(Upload)`
	width: 100%;
	.ant-upload {
		width: 100%;
	}
`;

export default PartnerEdit;
