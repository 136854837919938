import { MailOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppVersion from '../../components/AppVersion';
import AuthHeaderCard from '../../components/AuthHeaderCard';
import AuthLayout from '../layout/AuthLayout';
import styled from 'styled-components';

const ForgotPassword: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
	const [isInvalid, setInvalid] = React.useState<
		'empty' | 'invalid' | undefined
	>();
	const [message, setMessage] = React.useState<string>('');

	const submit = async ({ email }: { email: string }) => {
		try {
			setIsLoading(true);
			await axios.post(
				process.env.REACT_APP_BASE_URL +
					'/auth/forgot-password/confirmation-link',
				{ email },
			);
			setIsSuccess(true);
			setIsLoading(false);
		} catch (error) {
			setIsSuccess(false);
			setIsLoading(false);
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const onSubmit = ({ email }: { email: string }) => {
		const rule =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email || email === '') {
			setInvalid('empty');
		} else if (!rule.test(email)) {
			setInvalid('invalid');
		} else {
			setInvalid(undefined);
			submit({ email });
		}
	};

	React.useEffect(() => {
		if (isInvalid === 'empty') {
			setMessage('Email address is required');
		} else {
			setMessage('Please enter correct email format');
		}
	}, [isInvalid]);

	const handleClickTryAgain = () => {
		setIsSuccess(false);
	};

	return (
		<AuthLayout>
			<Card style={{ width: 400 }}>
				{isSuccess ? (
					<div>
						<AuthHeaderCard
							title="Check your email"
							subtitle="We have sent instructions to reset the password to your email, please follow the steps to reset password"
							status="email"
						/>
						<InfoText>
							Did not recieve the email? try check your spam inbox or <br />
							<div id="re-try" onClick={handleClickTryAgain}>
								Try another email address
							</div>
						</InfoText>
					</div>
				) : (
					<AuthHeaderCard
						title="Forgot Password"
						subtitle="If you forget your password, please enter the email used to sign in, then our system will send a link to reset your password via email"
					/>
				)}

				{!isSuccess && (
					<Form
						layout="vertical"
						name="basic"
						onFinish={onSubmit}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Form.Item
							name="email"
							label="Email address"
							validateStatus={isInvalid ? 'error' : ''}
							help={isInvalid && message}
						>
							<Input
								size="large"
								type="email"
								prefix={<MailOutlined />}
								placeholder="Email address"
							/>
						</Form.Item>

						<Form.Item>
							<AppButton
								loading={isLoading}
								size="large"
								type="primary"
								htmlType="submit"
								style={{ width: '100%' }}
							>
								Send Reset Password Instructions
							</AppButton>
						</Form.Item>

						<Link
							style={{
								display: 'block',
								textAlign: 'center',
								textDecoration: 'underline',
							}}
							to="/login"
						>
							Back to Login Page
						</Link>
					</Form>
				)}

				<AppVersion />
			</Card>
		</AuthLayout>
	);
};

const InfoText = styled.div`
	font-size: ${({ theme }) => theme.fontSize.smallText};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	color: ${({ theme }) => theme.colors.charcoal400};
	text-align: center;
	width: 100%;
	margin-top: 30px;

	#re-try {
		color: ${({ theme }) => theme.colors.primary};
		text-decoration: underline;
		cursor: pointer;
	}
`;

export default ForgotPassword;
