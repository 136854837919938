import { Row, Col, Divider, Typography, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import DetailItem from '../../../components/DetailItem';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import {
	EConsultationType,
	TransactionConsultationProps,
	TRANSACTION_STATUS_SETTINGS,
} from '../../../types/transactionConsultation.type';
import { formatDate, formatTime } from '../../../helpers/constant';
import { PaymentProps } from '../../../types/payment.type';
import { getFullName } from '../../../helpers/name';
import { getAge } from '../../../helpers/getAge';
import { replaceUnderscoreWithSpace } from '../../../helpers/replaceUnderscoreWithSpace';
import { EPaymentDetailType } from '../../../types/paymentDetail.type';
import TransactionConsultationHistory from './History';
import styled from 'styled-components';

interface IParams {
	transactionConsultationId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: TransactionConsultationProps;
}

const { Text } = Typography;
const { TabPane } = Tabs;

let formatPrice = Intl.NumberFormat('en-US');

const TransactionConsultationDetail: React.FC = () => {
	const { transactionConsultationId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [transactionConsultation, setTransactionConsultation] =
		React.useState<TransactionConsultationProps>();
	const [payment, setPayment] = React.useState<PaymentProps>();

	const [groupingSpecialist, setGroupingSpecialist] = React.useState<any[]>([]);

	React.useEffect(() => {
		const fetchTransactionConsultationDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/transaction-consults/' + transactionConsultationId,
				);
				setTransactionConsultation(res.data.payload);
				setPayment(res.data.payload.payment);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchTransactionConsultationDetail();
	}, [transactionConsultationId]);

	useEffect(() => {
		let newGroupSpecialists =
			transactionConsultation?.metaDoctor?.specialists?.reduce(function (
				storage: any,
				item: any,
			) {
				const data = {
					specialistId: item.specialistId || '',
					specialistName: item.specialistName || '',
					subSpecialists:
						item.subSpecialistId || item.subSpecialistName
							? [
									{
										subSpecialistId: item.subSpecialistId || '',
										subSpecialistName: item.subSpecialistName || '',
									},
							  ]
							: [],
				};

				let group = item['specialistId'];
				if (!group) {
					group = item['specialistName'];
				}

				storage[group] = storage[group] || {};
				const storageLength = Object.keys(storage[group]).length;
				if (storageLength > 0) {
					storage[group] = {
						...storage[group],
						subSpecialists: [
							...(storage[group].subSpecialists || []),
							...(data.subSpecialists || []),
						],
					};
				} else {
					storage[group] = data;
				}
				return storage;
			}, {});

		setGroupingSpecialist(newGroupSpecialists);
	}, [transactionConsultation?.metaDoctor?.specialists]);

	const refundPayment =
		payment &&
		payment.paymentDetails &&
		payment?.paymentDetails.find(
			(pay) => pay.paymentType == 'PATIENT_REFUND_RECEIVED',
		);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Transaction Detail"
				subtitle="Detail of your transaction"
			/>
			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<CustomTabs
					centered={true}
					size={'large'}
					tabBarStyle={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<TabPane tab="Detail" key={'1'}>
						<AppCard loading={isLoading}>
							<SectionContent groupTitle="Status">
								<div
									style={
										transactionConsultation?.transactionStatus
											? {
													border:
														'2px solid ' +
														TRANSACTION_STATUS_SETTINGS[
															transactionConsultation?.transactionStatus
														]?.color,
													color:
														TRANSACTION_STATUS_SETTINGS[
															transactionConsultation?.transactionStatus
														]?.color,
													borderRadius: '0',
													display: 'inline',
													padding: '0 10px',
											  }
											: {}
									}
								>
									{transactionConsultation?.transactionStatus
										? TRANSACTION_STATUS_SETTINGS[
												transactionConsultation?.transactionStatus
										  ]?.label
										: '-'}
								</div>
							</SectionContent>

							<SectionContent groupTitle="Consultation Info">
								<Row>
									<Col span={8}>
										<DetailItem
											label="Consultation Type"
											content={
												transactionConsultation?.consultationType ===
												EConsultationType.CONSULT_NOW_CHAT_CONSULTATION
													? 'Consult Now (Chat Only)'
													: transactionConsultation?.consultationType ===
													  EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
													? 'Consult Now (Video & Chat)'
													: transactionConsultation?.consultationType ===
													  EConsultationType.FACE_TO_FACE_CONSULTATION
													? 'In Person'
													: transactionConsultation?.consultationType ===
													  EConsultationType.TELEMEDICINE_CHAT_CONSULTATION
													? 'Telemedicine (Chat Only)'
													: 'Telemedicine (Video & Chat)'
											}
										/>
									</Col>
									<Col span={8}>
										<DetailItem
											label="Schedule"
											content={
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
													}}
												>
													<h4>
														{formatDate(
															transactionConsultation?.scheduledStartAt,
														)}
													</h4>
													<div
														style={{
															width: '5px',
															height: '5px',
															borderRadius: '50px',
															backgroundColor: 'grey',
															margin: '8px 8px 0',
														}}
													></div>
													<h4>
														{formatTime(
															transactionConsultation?.scheduledStartAt,
														)}{' '}
														-{' '}
														{formatTime(
															transactionConsultation?.scheduledEndAt,
														)}
													</h4>
												</div>
											}
										/>
									</Col>
								</Row>
							</SectionContent>

							<Divider />

							<SectionContent groupTitle="Patient">
								<Row>
									<Col span={8}>
										<DetailItem
											label="Name"
											content={getFullName(
												transactionConsultation?.metaPatient,
											)}
										/>
									</Col>
									<Col span={8}>
										<DetailItem
											label="Age"
											content={
												transactionConsultation?.metaPatient?.birthdate
													? getAge(
															transactionConsultation?.metaPatient?.birthdate,
													  ) + ' y.o'
													: undefined
											}
										/>
									</Col>
									<Col span={8}>
										<DetailItem
											label="Gender"
											content={`${transactionConsultation?.metaPatient?.gender
												?.charAt(0)
												.toUpperCase()}${transactionConsultation?.metaPatient?.gender?.slice(
												1,
											)}`}
										/>
									</Col>
								</Row>
								<DetailItem
									label="Reason Of Consultation"
									content={
										transactionConsultation?.medicalRecord?.reasonOfConsultation
									}
								/>
							</SectionContent>

							<Divider />

							<SectionContent groupTitle="Doctor">
								<DetailItem
									label="Name"
									content={transactionConsultation?.metaDoctor?.nameOfSignature}
								/>
								<Row>
									{(transactionConsultation?.metaDoctor?.specialists || [])
										.length > 0 ? (
										(
											(groupingSpecialist && Object.keys(groupingSpecialist)) ||
											[]
										)?.map((specialistId: any, index: number) => {
											const specialist = groupingSpecialist?.[specialistId];

											return (
												<Col span={8}>
													<DetailItem
														label={'Specialization'}
														content={
															<>
																<Col>{specialist.specialistName}</Col>
																{specialist.subSpecialists?.map((item: any) => (
																	<Col
																		style={{
																			marginLeft: 25,
																		}}
																	>
																		{item.subSpecialistName}
																	</Col>
																))}
															</>
														}
													/>
												</Col>
											);
										})
									) : (
										<DetailItem label={'Specialization'} content={''} />
									)}
								</Row>
							</SectionContent>

							<Divider />

							{transactionConsultation?.transactionStatus ==
								'CANCELED_BY_USER' ||
							transactionConsultation?.transactionStatus == 'REJECTED' ? (
								<>
									<SectionContent groupTitle="Refund Detail">
										<Row>
											<Col span={16}>
												<h4>Cancelation Fee (0%)</h4>
											</Col>
											<Col span={8} style={{ textAlign: 'right' }}>
												<Text style={{ fontWeight: 'bold' }}>
													₱{refundPayment?.totalPay}
												</Text>
											</Col>
										</Row>
										<Divider />
										<Row>
											<Col span={16}>
												<h4>Total</h4>
											</Col>
											<Col span={8} style={{ textAlign: 'right' }}>
												<Text style={{ fontWeight: 'bold' }}>
													₱{refundPayment?.totalPay}
												</Text>
											</Col>
										</Row>
									</SectionContent>
									<Divider />
								</>
							) : null}

							<SectionContent groupTitle="Payment Detail">
								{payment &&
									payment.paymentDetails &&
									payment.paymentDetails
										?.filter(
											(item: any) => item.paymentType !== 'PAYMENT_GATEWAY',
										)
										?.map((item) => {
											return (
												<Row key={item.paymentDetailId}>
													<Col span={16}>
														<h4>
															{item?.paymentType !==
																'PATIENT_REFUND_RECEIVED' &&
																replaceUnderscoreWithSpace(item.paymentType)}
														</h4>
													</Col>
													<Col span={8} style={{ textAlign: 'right' }}>
														{item.type === EPaymentDetailType.PAYMENT ? (
															<Text style={{ fontWeight: 'bold' }}>
																{item.paymentType !==
																	'PATIENT_REFUND_RECEIVED' &&
																	'₱' + formatPrice.format(item.totalPay)}
															</Text>
														) : (
															<Text style={{ color: 'red' }}>
																-₱{formatPrice.format(item.totalPay)}
															</Text>
														)}
													</Col>
												</Row>
											);
										})}

								<Divider />

								<Row>
									<Col span={16}>
										<Text>Total</Text>
									</Col>
									<Col span={8} style={{ textAlign: 'right' }}>
										{payment ? (
											<Text style={{ fontWeight: 'bold' }}>
												₱{formatPrice.format(payment.totalNeedToPay)}
											</Text>
										) : (
											<Text>No Payment Has Been Made</Text>
										)}
									</Col>
								</Row>
							</SectionContent>

							<Divider />
						</AppCard>
					</TabPane>
					<TabPane
						tab="History"
						key={'2'}
						children={
							<TransactionConsultationHistory
								data={transactionConsultation}
								payment={payment}
							/>
						}
					/>
				</CustomTabs>
			</div>
		</div>
	);
};
export const CustomTabs = styled(Tabs)`
	.ant-tabs-nav-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.ant-tabs-tab {
		width: 50%;
		justify-content: center;
	}
`;
export default TransactionConsultationDetail;
