import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
	Button,
	Input,
	Pagination,
	Table,
	Tag,
	Image,
	Modal,
	Switch,
} from 'antd';
import { ContainerFilter } from '../../screens/contentArticle/article';
import useFetchList from '../../hooks/useFetchList';
import { formatDate } from '../../helpers/constant';
import { EventProps } from '../../types/event.type';
import { EventTagProps } from '../../types/eventTag.type';

type IProps = {
	setIsVisible: (visible: boolean) => any;
	isVisible: boolean;
	onSelect: (article: EventProps) => any;
};

export default function ModalSelectMedicalEvent(props: IProps) {
	const { isLoading, data, pagination, setSearch, changePage } =
		useFetchList<EventProps>({
			endpoint: 'events',
			initialQuery: {},
			limit: 10,
		});

	let numberTagShow: number = 4;

	function sortTags(tags: EventTagProps[]) {
		tags.sort((a, b) => a.tagName.localeCompare(b.tagName));
		return tags;
	}

	const columns = [
		{
			title: 'Image',
			dataIndex: 'imageUrls',
			key: 'imageUrls',
			render: (imageUrls: EventProps['imageUrls']) => {
				return (
					<Image
						width={120}
						height={100}
						src={
							imageUrls && imageUrls.length > 0
								? imageUrls[0] || '/images/blur-image.jpeg'
								: '/images/blur-image.jpeg'
						}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				);
			},
		},
		{
			title: 'Headline',
			dataIndex: 'title',
			key: 'title',
			render: (text: string, record: EventProps) => {
				return <div>{text}</div>;
			},
		},
		{
			title: 'SPECIALITIES',
			key: 'specialities',
			dataIndex: 'specialities',
			render: (specialities: any, record: EventProps) => (
				<>
					{record.specialists
						? record.specialists.length <= 3
							? record.specialists
									.slice()
									.sort((a: any, b: any) => a.level - b.level)
									.map((el, index) => (
										<Tag
											style={{
												border: '2px solid #D81F64',
												color: '#D81F64',
												marginBottom: '10px',
											}}
											key={el.specialistId}
										>
											{el.specialistName}
										</Tag>
									))
							: record.specialists
									.slice(0, 3)
									.sort((a: any, b: any) => a.level - b.level)
									.map((el, index) => (
										<Tag
											style={{
												border: '2px solid #D81F64',
												color: '#D81F64',
												marginBottom: '10px',
											}}
											key={el.specialistId}
										>
											{el.specialistName}
										</Tag>
									))
						: []}
					{record.specialists
						? record.specialists.length > 3 && (
								<Tag
									style={{
										border: '2px solid #D81F64',
										color: '#D81F64',
										marginBottom: '10px',
									}}
								>
									+{record.specialists.slice(3).length} More
								</Tag>
						  )
						: []}
				</>
			),
		},

		{
			title: 'TAGS',
			key: 'tags',
			dataIndex: 'tags',
			render: (tags: any, record: EventProps) => (
				<>
					{record.tags
						? record.tags.length <= numberTagShow
							? sortTags(record.tags).map((element, idx) => {
									return (
										<Tag>
											{element.tagName}
											{idx < record.tags.length - 1 && ', '}
										</Tag>
									);
							  })
							: sortTags(record.tags)
									.slice(0, numberTagShow)
									.map((element, idx) => {
										return (
											<Tag>
												{element.tagName}
												{idx < record.tags.length && ', '}
											</Tag>
										);
									})
						: []}
					{record.tags.length > numberTagShow &&
						`+${record.tags.slice(numberTagShow).length} More`}
				</>
			),
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: EventProps) => {
				return (
					<>
						<Switch checked={isPublished} />
					</>
				);
			},
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: EventProps) => (
				// <Dropdown overlay={() => menu(record)} placement="bottomRight">
				//     <MoreOutlined style={{ cursor: 'pointer' }} />
				// </Dropdown>

				<Button
					onClick={() => {
						props.setIsVisible(false);
						props.onSelect(record);
					}}
				>
					Select
				</Button>
			),
		},
	];

	return (
		<Modal
			title={'Select Medical Event'}
			visible={props.isVisible}
			width="90%"
			onCancel={() => {
				props.setIsVisible(false);
			}}
			footer={null}
		>
			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by headline"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			<Pagination
				current={pagination.page}
				total={pagination.totalData}
				defaultPageSize={pagination.perPage}
				pageSize={pagination.perPage}
				pageSizeOptions={['10', '20', '30']}
				showSizeChanger={true}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				onChange={changePage}
			/>
		</Modal>
	);
}
