import {
	AppstoreAddOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
	Typography,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { replaceUnderscoreWithSpace } from '../../helpers/replaceUnderscoreWithSpace';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import {
	initialPackageService,
	PackageServiceProps,
} from '../../types/packageService.type';

const { Text } = Typography;
const { Option } = Select;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PackageServiceProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}
const PackageService = () => {
	const history = useHistory();
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		useState<boolean>(false);
	const [tmpData, setTmpData] = useState<PackageServiceProps>(
		initialPackageService,
	);
	const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		changePage,
		setQuery,
	} = useFetchList<PackageServiceProps>({
		endpoint: 'health-service/packages',
		limit: 15,
	});

	const handleCreateService = () => {
		history.push('/medical-service-package/add');
	};

	const handleClickDetail = (e: PackageServiceProps) => {
		history.push(`/medical-service-package/${e.healthServiceId}/detail`);
	};

	const handleClickDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.healthServiceId === tmpData.healthServiceId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);
			const res = await httpRequest.delete<DeleteResponseProps>(
				'/health-service/packages/' + tmpData.healthServiceId,
			);

			newData = data.filter(
				(item) => item.healthServiceId !== tmpData.healthServiceId,
			);
			setData(newData);

			message.success('Success delete ' + tmpData.healthServiceName);

			setIsLoadingDelete(false);
			setIsModalDeleteVisible(false);
			setTmpData(initialPackageService);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialPackageService);
			setIsLoadingDelete(false);
		}
	};

	const handleClickAction = (props: PackageServiceProps, key: string) => {
		if (key === 'detail') {
			history.push(`/medical-service-package/${props.healthServiceId}/detail`);
		} else if (key === 'edit') {
			history.push(`/medical-service-package/${props.healthServiceId}/edit`);
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		}
	};

	const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
		}
	};

	const handleStatusChange = async (record: PackageServiceProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.healthServiceId === record.healthServiceId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/health-service/packages/' + record.healthServiceId,
				{
					healthServiceId: record.healthServiceId,
					isPublished: !record.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.healthServiceId === res.data.payload.healthServiceId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success(
				'Success change ' + record.healthServiceName + ' status.',
			);
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const columns = [
		{
			title: 'Package Name',
			dataIndex: 'healthServiceName',
			key: 'healthServiceName',
			render: (text: string, record: PackageServiceProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			render: (text: string, record: PackageServiceProps) => {
				return record.group.split(/[ ,_]+/).length > 1 ? (
					<Text>{replaceUnderscoreWithSpace(record.group)}</Text>
				) : (
					<Text>{record.group}</Text>
				);
			},
		},
		{
			title: 'Available Clinic',
			dataIndex: 'clinic',
			key: 'clinic',
			render: (text: string, record: PackageServiceProps) => {
				return record.partnerPackageHealthServices.length > 0 ? (
					record.partnerPackageHealthServices
						.map((item) => {
							return item.partner.partnerName;
						})
						.join(',')
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				);
			},
		},
		{
			title: 'Status',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: PackageServiceProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: PackageServiceProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: PackageServiceProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Package Service</Menu.Item>
			<Menu.Item key="detail">Detail Package Service</Menu.Item>
			<Menu.Item key="delete">Delete Package Service</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Package"
				subtitle="Manage your Package Service"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateService}>
							Add Service
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search service name or group"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Inactive</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}
			<Modal
				title="Delete Health Service Package"
				visible={isModalDeleteVisible}
				onOk={handleClickDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialPackageService);
				}}
				okText="Yes"
				confirmLoading={isLoadingDelete}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete <b>{tmpData.healthServiceName}</b>?
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default PackageService;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
