import { MoreOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Dropdown,
	Image,
	Menu,
	message,
	Modal,
	Row,
	Space,
	Switch,
	Table,
	Tabs,
	TimePicker,
	Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import KycTag from '../../components/KycTag';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { PartnerIndividualHealthServicesProps } from '../../types/individualService.type';
import { PartnerPackageHealthServicesProps } from '../../types/packageService.type';
import { initialPartner, PartnerProps } from '../../types/partner.type';
import { PartnerDoctorProps } from '../../types/partnerDoctor.type';
import { TopicProps } from '../../types/topic.type';

interface IParams {
	partnerId: string;
}

interface FetchPartnerResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface FetchPartnerIndividualResponseProps extends BaseResponseProps {
	payload: {
		results: PartnerIndividualHealthServicesProps[];
	};
}

interface FetchPartnerPackageResponseProps extends BaseResponseProps {
	payload: {
		results: PartnerPackageHealthServicesProps[];
	};
}

interface SchedulesProps {
	id: string;
	weekday: number;
	day: string;
	times: Array<{
		startAt?: Date;
		endAt?: Date;
	}>;
}

const { Text } = Typography;
const { TabPane } = Tabs;

const PartnerDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { partnerId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [tabs, setTabs] = React.useState<string>('1');

	const [partner, setPartner] = React.useState<PartnerProps>({
		...initialPartner,
	});
	const [individualServicePartner, setIndividualServicePartner] =
		React.useState<PartnerIndividualHealthServicesProps[]>([]);
	const [PackageServicePartner, setPackageServicePartner] = React.useState<
		PartnerPackageHealthServicesProps[]
	>([]);
	const [doctorSecretaryList, setDoctorSecretaryList] = React.useState<
		PartnerDoctorProps[]
	>([]);

	const [imageUrl, setImageUrl] = React.useState<string | undefined>();
	const [tmpData, setTmpData] = React.useState<any>({});
	const [type, setType] = React.useState<string>('');

	const [isModalHomeServiceVisible, setIsModalHomeServiceVisible] =
		React.useState<boolean>(false);
	const [isModalDoctorStatusVisible, setIsModalDoctorStatusVisible] =
		React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);

	const [dates, setDates] = React.useState<Array<SchedulesProps>>([
		{
			id: '',
			weekday: 0,
			day: 'Sunday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 1,
			day: 'Monday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 2,
			day: 'Tuesday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 3,
			day: 'Wednesday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 4,
			day: 'Thursday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 5,
			day: 'Friday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
		{
			id: '',
			weekday: 6,
			day: 'Saturday',
			times: [
				{
					startAt: undefined,
					endAt: undefined,
				},
			],
		},
	]);

	const fetchIndividualPartnerService = async () => {
		const res = await httpRequest.get<FetchPartnerIndividualResponseProps>(
			'/partner-individual-health-services',
			{
				params: {
					partnerId: partnerId,
				},
			},
		);
		setIndividualServicePartner(res.data.payload.results);
	};

	const fetchPackagePartnerService = async () => {
		const res = await httpRequest.get<FetchPartnerPackageResponseProps>(
			'/partner-package-health-services',
			{
				params: {
					partnerId: partnerId,
				},
			},
		);
		setPackageServicePartner(res.data.payload.results);
	};

	const fetchPartnerDetail = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<FetchPartnerResponseProps>(
				'/partners/' + partnerId,
			);
			setPartner(res.data.payload);
			setDoctorSecretaryList(res.data.payload.doctors || []);
			setImageUrl(res.data.payload.imageCoverURL);

			const partnerOpenHoursData = dates;
			if (res.data.payload.openHours) {
				for (const date of partnerOpenHoursData) {
					for (const openHours of res.data.payload.openHours) {
						if (date.weekday === openHours.weekday) {
							dates[date.weekday] = {
								...date,
								times: [
									{
										startAt: openHours.startAt,
										endAt: openHours.endAt,
									},
								],
							};
						}
					}
				}
			}

			setDates(partnerOpenHoursData);

			setIsLoading(false);

			const bcDetails = [
				{
					field: 'partnerId',
					value: partnerId,
					label: res.data.payload.partnerName,
				},
			];
			setBreadcrumbDetails(bcDetails);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchPartnerDetail();
		fetchIndividualPartnerService();
		fetchPackagePartnerService();
	}, [partnerId]);

	const handleStatusHomeService = async () => {
		try {
			setIsLoading(true);
			let newData = [];
			if (type === 'Individual') {
				const res = await httpRequest.patch<any>(
					`/partner-individual-health-services/${tmpData.healthServiceId}/${tmpData.partnerId}`,
					{
						healthServiceId: tmpData.healthServiceId,
						partnerId: tmpData.partnerId,
						setting: {
							availableForHomeService: !tmpData.setting.availableForHomeService,
						},
					},
				);

				newData = individualServicePartner.map((item) => {
					if (item.healthServiceId === res.data.payload.healthServiceId) {
						return {
							...item,
							setting: {
								availableForHomeService:
									!tmpData.setting.availableForHomeService,
							},
						};
					}
					return item;
				});
				message.success(
					'Success change ' +
						tmpData.individualHealthService.healthServiceName +
						' status.',
				);

				setIndividualServicePartner(newData);
			} else {
				const res = await httpRequest.patch<any>(
					`/partner-package-health-services/${tmpData.packageHealthServiceId}/${tmpData.partnerId}`,
					{
						packageHealthServiceId: tmpData.packageHealthServiceId,
						partnerId: tmpData.isPublished,
						setting: {
							availableForHomeService: !tmpData.setting.availableForHomeService,
						},
					},
				);

				newData = PackageServicePartner.map((item) => {
					if (
						item.packageHealthServiceId ===
						res.data.payload.packageHealthServiceId
					) {
						return {
							...item,
							setting: {
								availableForHomeService:
									!tmpData.setting.availableForHomeService,
							},
						};
					}
					return item;
				});
				message.success(
					'Success change ' +
						tmpData.packageHealthServices.healthServiceName +
						' status.',
				);

				setPackageServicePartner(newData);
			}

			setIsLoading(false);
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleDoctorStatusChange = async () => {
		try {
			setIsLoading(true);
			let newData = [];

			const newStatus =
				tmpData.doctor.user?.status === 'active' ? 'inactive' : 'active';

			const res = await httpRequest.patch<any>('/user/' + tmpData.doctorId, {
				doctorId: tmpData.doctor.doctorId,
				status: newStatus,
			});
			console.log(res, 'res');

			newData = doctorSecretaryList.map((item) => {
				if (item.doctorId === res.data.payload.userId) {
					return {
						...item,
						doctor: {
							...item.doctor,
							doctorId: item.doctorId,
							user: {
								...item.doctor?.user,
								status: res.data.payload.status,
							},
						},
					};
				}
				return item;
			});
			console.log('newData', newData);
			setDoctorSecretaryList(newData);

			message.success(
				'Success change ' + tmpData.doctor?.user?.name + ' status.',
			);
			setIsModalDoctorStatusVisible(false);
			setTmpData({});
			setIsLoading(false);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDoctorStatusVisible(false);
			setTmpData({});
			setIsLoading(false);
		}
	};

	const handleClickDelete = async () => {
		try {
			setIsLoading(true);

			if (type === 'Individual') {
				const resDel = await httpRequest.delete(
					`/partner-individual-health-services/${tmpData.healthServiceId}/by-health-service`,
				);
				await fetchIndividualPartnerService();
				message.success(
					'Success update ' +
						tmpData.individualHealthService?.healthServiceName +
						' data',
				);
			} else {
				const resDel = await httpRequest.delete(
					`/partner-package-health-services/${tmpData.packageHealthServiceId}/by-health-service`,
				);
				await fetchPackagePartnerService();
				message.success(
					'Success update ' +
						tmpData.packageHealthServices.healthServiceName +
						' data',
				);
			}

			setTabs('2');
			setTmpData({});
			setIsModalDeleteVisible(false);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleClickAction = (props: any, key: string, type: string) => {
		if (key === 'edit') {
			if (type === 'Individual') {
				history.push(
					`/clinic/${props.partnerId}/${type}/${props.healthServiceId}/edit`,
				);
			} else {
				history.push(
					`/clinic/${props.partnerId}/${type}/${props.packageHealthServiceId}/edit`,
				);
			}
		} else if (key === 'detail') {
			if (type === 'Individual') {
				history.push(
					`/medical-service-individual/${props.healthServiceId}/detail`,
				);
			} else {
				history.push(
					`/medical-service-package/${props.packageHealthServiceId}/detail`,
				);
			}
		} else if (key === 'delete') {
			setTmpData(props);
			setType(type);
			setIsModalDeleteVisible(true);
		}
	};

	const menu = (record: any, type: string) => (
		<Menu onClick={(e) => handleClickAction(record, e.key, type)}>
			<Menu.Item key="edit">Edit Service</Menu.Item>
			<Menu.Item key="detail">Detail Service</Menu.Item>
			<Menu.Item key="delete">Delete Service</Menu.Item>
		</Menu>
	);

	const individualColumns = [
		{
			title: 'SERVICE NAME',
			dataIndex: 'healthServiceName',
			key: 'healthServiceName',
			render: (text: string, record: any) => {
				return (
					<div
						className="table-link"
						onClick={() =>
							history.push(
								`/medical-service-individual/${record.healthServiceId}/detail`,
							)
						}
					>
						{record.individualHealthService?.healthServiceName}
					</div>
				);
			},
		},
		{
			title: 'CATEGORY',
			dataIndex: 'category',
			key: 'category',
			render: (text: string, record: PartnerIndividualHealthServicesProps) => {
				return (
					<>
						{record.individualHealthService?.category?.categoryName ? (
							<Text>
								{record.individualHealthService.category.categoryName}
							</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'PRICE',
			dataIndex: 'price',
			key: 'price',
			render: (text: string, record: PartnerIndividualHealthServicesProps) => {
				return <Text>₱{record.price ? record.price : 0}</Text>;
			},
		},
		{
			title: 'HOME SERVICE',
			key: 'availableForHomeService',
			dataIndex: 'availableForHomeService',
			render: (
				availableForHomeService: any,
				record: PartnerIndividualHealthServicesProps,
			) => {
				return (
					<Switch
						checked={record.setting?.availableForHomeService}
						onChange={() => {
							setIsModalHomeServiceVisible(true);
							setTmpData(record);
							setType('Individual');
						}}
					/>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: PartnerIndividualHealthServicesProps) => (
				<Dropdown
					overlay={() => menu(record, 'Individual')}
					placement="bottomRight"
				>
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const packageColumns = [
		{
			title: 'SERVICE NAME',
			dataIndex: 'healthServiceName',
			key: 'healthServiceName',
			render: (text: string, record: PartnerPackageHealthServicesProps) => {
				return (
					<div
						className="table-link"
						onClick={() =>
							history.push(
								`/medical-service-package/${record.packageHealthServiceId}/detail`,
							)
						}
					>
						{record.packageHealthServices.healthServiceName}
					</div>
				);
			},
		},
		{
			title: 'CATEGORY',
			dataIndex: 'group',
			key: 'group',
			render: (text: string, record: PartnerPackageHealthServicesProps) => {
				return (
					<>
						{record.packageHealthServices?.group ? (
							<Text>{record.packageHealthServices.group}</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'PRICE',
			dataIndex: 'price',
			key: 'price',
			render: (text: string, record: PartnerPackageHealthServicesProps) => {
				return <Text>₱{record.price ? record.price : 0}</Text>;
			},
		},
		{
			title: 'HOME SERVICE',
			key: 'availableForHomeService',
			dataIndex: 'availableForHomeService',
			render: (
				availableForHomeServicete: any,
				record: PartnerPackageHealthServicesProps,
			) => {
				return (
					<>
						<Switch
							checked={record.setting?.availableForHomeServicete}
							onChange={() => {
								setIsModalHomeServiceVisible(true);
								setTmpData(record);
								setType('Package');
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: PartnerPackageHealthServicesProps) => (
				<Dropdown
					overlay={() => menu(record, 'Package')}
					placement="bottomRight"
				>
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const doctorColumns = [
		{
			title: 'FULL NAME',
			dataIndex: 'doctorName',
			key: 'doctorName',
			render: (text: string, record: PartnerDoctorProps) => {
				console.log('doctor', record);
				return (
					<div
						className="table-link"
						onClick={() =>
							history.push({
								pathname: `/doctor/${record.doctor?.doctorId}`,
							})
						}
					>
						{record.doctor?.user?.name}
					</div>
				);
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phone',
			key: 'phone',
			render: (text: string, record: PartnerDoctorProps) => {
				return (
					<>
						{record.doctor?.user?.phone ? (
							<Text>{record.doctor.user.phone}</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			key: 'gender',
			render: (text: string, record: PartnerDoctorProps) => {
				return (
					<>
						{record.doctor?.user?.gender ? (
							<Text>{`${record.doctor.user.gender
								.charAt(0)
								.toUpperCase()}${record.doctor.user.gender.slice(1)}`}</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'BIRTHDATE',
			dataIndex: 'birthdate',
			key: 'birtdate',
			render: (text: string, record: PartnerDoctorProps) => {
				return (
					<>
						{record.doctor?.user?.birthdate ? (
							<Text>
								{moment(record.doctor.user.birthdate).format('DD MMM YYYY')}
							</Text>
						) : (
							<Text type="secondary" italic>
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'KYC STATUS',
			key: 'kycStatus',
			dataIndex: 'kycStatus',
			render: (text: string, record: PartnerDoctorProps) => (
				<KycTag>{record.doctor?.kycStatus}</KycTag>
			),
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: PartnerDoctorProps) => (
				<>
					<Switch
						checked={record.doctor?.user?.status == 'active' ? true : false}
						onChange={() => {
							setIsModalDoctorStatusVisible(true);
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: string) => (
				<span>{moment(text).format('MMMM DD, YYYY')}</span>
			),
		},
	];

	return (
		<div>
			<HeaderSection
				icon="back"
				title={partner.partnerName}
				subtitle="Manage your clinic data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => history.push('/clinic/' + partnerId + '/edit')}
						>
							Edit Clinic
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Tabs
					type="line"
					activeKey={tabs}
					centered
					size="large"
					onChange={(activeKey) => setTabs(activeKey)}
				>
					<TabPane tab="Detail" key="1">
						<SectionContent groupTitle="Clinic Data">
							<Space
								style={{
									width: '100%',
									background: '#f2f2f2',
									justifyContent: 'center',
								}}
							>
								<Image
									preview={false}
									width="100%"
									height={250}
									src={imageUrl || '/images/select-image.jpg'}
									fallback={'/images/blur-image.jpeg'}
									style={{ objectFit: 'cover' }}
									placeholder={
										<Image
											preview={false}
											src="/images/blur-image.jpeg"
											width="100%"
											height={200}
											style={{ objectFit: 'cover' }}
										/>
									}
								/>
							</Space>

							<DetailItem label="Clinic Name" content={partner.partnerName} />

							<DetailItem label="Email " content={partner.email} />

							<DetailItem
								label="Phone"
								content={partner.phone ? partner.phone : undefined}
							/>

							<DetailItem label="Address" content={partner.address} />

							<Row>
								<Col span={6}>
									<DetailItem
										label="Latitude"
										content={partner.geolocation?.latitude}
									/>
								</Col>
								<Col span={6}>
									<DetailItem
										label="Longitude"
										content={partner.geolocation?.longitude}
									/>
								</Col>
							</Row>

							<Row>
								<Col span={6}>
									<DetailItem
										label="Published Status"
										content={partner.isPublished ? 'Published' : 'Unpublished'}
									/>
								</Col>
								<Col span={6}>
									<DetailItem
										label="Verified Status"
										content={
											partner.isConfirmedPartner ? 'Verified' : 'Unverified'
										}
									/>
								</Col>
							</Row>
						</SectionContent>

						<SectionContent groupTitle="Clinic Schedule">
							<Row gutter={[24, 16]}>
								{dates.map((item) => {
									return (
										<Col key={item.weekday} span={6}>
											<Row style={{ marginBottom: '2%' }}>
												<Text>{item.day}</Text>
											</Row>
											<Row>
												{item.times.map((time) => {
													return time.startAt && time.endAt ? (
														<TimePicker.RangePicker
															defaultValue={[
																moment(time.startAt),
																moment(time.endAt),
															]}
															style={{
																width: '100%',
																marginBottom: '2%',
															}}
															order={false}
															format="HH:mm"
															disabled
														/>
													) : (
														<Text
															style={{
																paddingBottom: '2%',
															}}
															italic
															type="secondary"
														>
															Not set
														</Text>
													);
												})}
											</Row>
										</Col>
									);
								})}
							</Row>
						</SectionContent>
					</TabPane>
					<TabPane tab="Medical Service List" key="2">
						<Tabs type="line">
							<TabPane tab="Individual" key="1">
								<Table
									loading={isLoading}
									columns={individualColumns}
									dataSource={individualServicePartner}
									pagination={false}
								/>
								<Button
									style={{
										color: '#D81F64',
										borderColor: '#D81F64',
										width: '100%',
										marginTop: '2%',
									}}
									onClick={() => {
										history.push(`/clinic/${partnerId}/Indiviual/add`);
									}}
								>
									Add New Individual Service
								</Button>
							</TabPane>
							<TabPane tab="Package" key="2">
								<Table
									loading={isLoading}
									columns={packageColumns}
									dataSource={PackageServicePartner}
									pagination={false}
								/>
								<Button
									style={{
										color: '#D81F64',
										borderColor: '#D81F64',
										width: '100%',
									}}
									onClick={() => {
										history.push(`/clinic/${partnerId}/Package/add`);
									}}
								>
									Add Package Service
								</Button>
							</TabPane>
						</Tabs>
					</TabPane>
					<TabPane tab="Doctor List" key="3">
						<Table
							loading={isLoading}
							columns={doctorColumns}
							dataSource={doctorSecretaryList}
							pagination={false}
						/>
					</TabPane>
				</Tabs>
			</AppCard>
			<Modal
				title="Update home service confirmation"
				visible={isModalHomeServiceVisible}
				onOk={handleStatusHomeService}
				onCancel={() => {
					setIsModalHomeServiceVisible(false);
					setTmpData({});
				}}
				okText="Yes"
				confirmLoading={isLoading}
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to change home service status?</p>
			</Modal>

			<Modal
				title="Delete partner confirmation"
				visible={isModalDeleteVisible}
				onOk={handleClickDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData({});
				}}
				okText="Yes"
				confirmLoading={isLoading}
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to delete this service?</p>
			</Modal>

			<Modal
				title="Update doctor status confirmation"
				visible={isModalDoctorStatusVisible}
				onOk={handleDoctorStatusChange}
				onCancel={() => {
					setIsModalDoctorStatusVisible(false);
					setTmpData({});
				}}
				okText="Yes"
				confirmLoading={isLoading}
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to change doctor status?</p>
			</Modal>
		</div>
	);
};

export default PartnerDetail;

function sortTopics(topics: TopicProps[]) {
	topics.sort((a, b) => a.topicName.localeCompare(b.topicName));
	return topics;
}
