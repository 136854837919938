import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import styled from 'styled-components';
import { PartnerProps, initialPartner } from '../../types/partner.type';
import { useDebounce } from 'use-debounce';
import useFetchList from '../../hooks/useFetchList';

interface IParams {
	partnerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

const AddEditPharmacy: React.FC = () => {
	const { Text } = Typography;
	const history = useHistory();
	const { partnerId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<PartnerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [partner, setPartner] = React.useState<PartnerProps>(initialPartner);
	const [pharmacyName, setPharmacyName] = React.useState<string>('');
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [showWarning, setShowWarning] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(pharmacyName, 500);

	const { data } = useFetchList<PartnerProps>({
		endpoint: 'partners',
		initialQuery: {
			includePartnerTypes: 'PHARMACY',
		},
	});

	React.useEffect(() => {
		const res: any = data.filter(
			(item) =>
				partnerId !== item.partnerId && searchValue === item.partnerName,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	const createPharmacy = async (
		props: Omit<
			PartnerProps,
			'createdAt' | 'updatedAt' | 'partnerId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			console.log('masuk ke createPharmacy function....');
			await httpRequest.post('/partners', {
				...props,
				partnerType: 'PHARMACY',
			});
			message.success('Success create ' + props.partnerName);
			history.push('/pharmacy');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updatePharmacy = async (
		props: Omit<PartnerProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);
			const res = await httpRequest.patch(`/partners/${partnerId}`, props);
			message.success('Success update ' + props.partnerName + ' data');
			history.push('/pharmacy');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<PartnerProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (partnerId) {
			updatePharmacy(values);
		} else {
			createPharmacy(values);
		}
	};

	const fetchPharmacy = async () => {
		try {
			setIsLoading(true);
			const res = await httpRequest.get<ResponseProps>(
				'/partners/' + partnerId,
			);
			setPartner(res.data.payload);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchPharmacy();
	}, []);

	const handlePharmacyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setPharmacyName(value);
		setShowWarning(value.length > 100);
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(partnerId ? 'Edit' : 'Add') + ' Pharmacy'}
				subtitle="Manage your pharmacy data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							disabled={isDuplicate}
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={partnerId ? partner : initialPartner}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Pharmacy Name"
							name="partnerName"
							rules={generateFormRules('Pharmacy Name', ['required'])}
						>
							<Input onChange={handlePharmacyNameChange} value={pharmacyName} />
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Pharmacy Name Already Exist!
							</Text>
						)}
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Pharmacy Name should not exceed 100 characters!
							</Text>
						)}

						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={partner?.isPublished}>
								<CustomRadio value={true}>Published</CustomRadio>
								<CustomRadio value={false}>Unpublished</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default AddEditPharmacy;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
