export enum EPointCategory {
	REGISTER = 'REGISTER',
	REFERRAL_UPLINE = 'REFERRAL_UPLINE',
	REFERRAL_DOWNLINE = 'REFERRAL_DOWNLINE',

	DAILY_CHECK_IN = 'DAILY_CHECK_IN',

	RX_PRESCRIPTION = 'RX_PRESCRIPTION',
	MEDICAL_SERVICE = 'MEDICAL_SERVICE',
	TELEMEDICINE = 'TELEMEDICINE',
	CONSULT_NOW = 'CONSULT_NOW',
	IN_PERSON_CONSULTATION = 'IN_PERSON_CONSULTATION',
	WITHDRAWAL = 'WITHDRAWAL',
	REFERRAL = 'REFERRAL',
	DOCTOR_DOCUMENT = 'DOCTOR_DOCUMENT',
	READ_ARTICLE = 'READ_ARTICLE',
	SHARE_ARTICLE = 'SHARE_ARTICLE',
	READ_EVENT = 'READ_EVENT',
	REGISTER_EVENT = 'REGISTER_EVENT',
	REFUND = 'REFUND',

	ORDER_CONSULTATION_TELEMEDICINE = 'ORDER_CONSULTATION_TELEMEDICINE',
	EXTEND_CONSULTATION_TELEMEDICINE = 'EXTEND_CONSULTATION_TELEMEDICINE',
	ORDER_CONSULTATION_IN_PERSON = 'ORDER_CONSULTATION_IN_PERSON',

	ORDER_MEDICAL_SERVICE = 'ORDER_MEDICAL_SERVICE',

	INCREASE_POINT = 'INCREASE_POINT',
	DECREASE_POINT = 'DECREASE_POINT',
}

export enum EHistoryType {
	INCOME = 'INCOME',
	OUTCOME = 'OUTCOME',
}

export enum EStatus {
	PENDING = 'PENDING',
	EXPIRED = 'EXPIRED',
	AVAILABLE = 'AVAILABLE',
}

export type PointHistoryProps = {
	historyId: string;
	pointId: string;
	userId: string;
	type?: EHistoryType;
	metaUser?: any;
	amount: number;
	status: EStatus;
	amountBefore: number;
	amountAfter: number;
	transactionId?: string;
	paymentId?: string;
	metaTransaction?: {};
	note?: string;
	category?: EPointCategory;
	createdAt: Date;
	updatedAt: Date;
};

export type PointProps = {
	pointId: string;
	userId: string;
	currentAmount: number;
	pendingAmount: number;
	createdAt?: Date;
	updatedAt?: Date;
	pointHistory: PointHistoryProps[];
};
