import {
	AppstoreAddOutlined,
	CloudUploadOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	DatePicker,
	Image,
	Input,
	message,
	Modal,
	Pagination,
	Select,
	Table,
	Tabs,
	Typography,
	Form,
	Badge,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../../components/HeaderSection';
import { getFullName } from '../../../helpers/name';
import useFetchList from '../../../hooks/useFetchList';
import type { UploadProps } from 'antd';
import AppButton from '../../../components/AppButton';
import Dragger from 'antd/lib/upload/Dragger';
import { httpRequest } from '../../../helpers/api';
import useApproval from '../../../hooks/useApproval';
import {
	TransactionEbookProps,
	ETransactionStatusType,
	TRANSACTION_STATUS_SETTINGS,
	SoldEbookProps,
} from '../../../types/ebookTransaction.type';
import { EbookProps } from '../../../types/ebook.type';
import { getUser } from '../../../helpers/auth';
import { IUser } from '../../../types/user.type';

let formatPrice = Intl.NumberFormat('en-US');

interface IParams {
	transactionEbookId: string;
}

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const TransactionEbook = (props: { isTest?: boolean }) => {
	const history = useHistory();
	const user: IUser = getUser();
	const { transactionEbookId } = useParams<IParams>();
	const [activeKey, setActiveKey] = useState<string>('1');
	const { refundLength, setRefundLength } = useApproval();

	const [tmpData, setTmpData] = useState<EbookProps | null>();
	const [uploadedImage, setUploadedImage] = useState<any>('');

	const [reason, setReason] = useState<string>('');

	const { isLoading, data, pagination, setSearch, setQuery, changePage } =
		useFetchList<TransactionEbookProps>({
			endpoint: 'transaction-ebooks',
			limit: 10,
			initialQuery: {
				isTestAccount: props.isTest,
				partnerId: user?.partnerAdmin?.partnerId,
			},
		});

	const {
		isLoading: isLoadingSold,
		data: soldBooks,
		pagination: paginationSold,
		setSearch: setSearchSold,
		setQuery: setQuerySold,
		changePage: changePageSold,
	} = useFetchList<TransactionEbookProps>({
		endpoint: `transaction-ebooks/find-by-status`,
		limit: 10,
		initialQuery: {
			transactionStatus: 'COMPLETED',
			partnerId: user?.partnerAdmin?.partnerId,
		},
	});

	useEffect(() => {
		setRefundLength(paginationSold.totalData);
	}, [paginationSold]);

	const handleChangeTab = async (key: string) => {
		setActiveKey(key);
	};

	const handleFilterByDate = (date: any) => {
		if (date) {
			setQuery((oldVal) => ({ ...oldVal, filterDate: date }));
			setQuerySold((oldVal) => ({ ...oldVal, filterDate: date }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterDate: undefined }));
			setQuerySold((oldVal) => ({ ...oldVal, filterDate: undefined }));
		}
	};

	const handleFilterByType = (type: string) => {
		if (type !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, filterConsultationTypes: type }));
			setQuerySold((oldVal) => ({
				...oldVal,
				filterConsultationTypes: type,
			}));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterConsultationTypes: undefined }));
			setQuerySold((oldVal) => ({
				...oldVal,
				filterConsultationTypes: undefined,
			}));
		}
	};

	const handleFilterByStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, transactionStatus: status }));
			setQuerySold((oldVal) => ({
				...oldVal,
				transactionStatus: status,
			}));
		} else {
			setQuery((oldVal) => ({
				...oldVal,
				transactionStatus: undefined,
			}));
		}
	};

	const handleSearch = (e: any) => {
		setSearch(e);
		setSearchSold(e);
	};

	const handleClickDetail = (e: TransactionEbookProps) => {
		history.push(`/transaction-ebooks/${e.transactionEbookId}`);
		console.log(e);
	};

	const handleClickDetailEbook = (e: TransactionEbookProps) => {
		history.push(`/transaction-ebooks/${e.transactionEbookId}/detail-ebook`);
	};

	const filterBooks = (books: TransactionEbookProps[]) => {
		const seen = new Set();
		return books.reduce((acc: TransactionEbookProps[], book) => {
			const uniqueKey =
				book.transactionEbookItems
					?.map((item) => item.ebook?.ebookId)
					.join('-') || '';
			if (!seen.has(uniqueKey)) {
				seen.add(uniqueKey);
				acc.push(book);
			}
			return acc;
		}, []);
	};

	const filterSoldBooks = filterBooks(soldBooks);

	const columns = [
		{
			title: 'INVOICE ID',
			key: 'transactionConsultationId',
			width: 100,
			render: (_: string, record: TransactionEbookProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.payment ? (
							record.payment.invoiceId
						) : (
							<Text italic type="secondary">
								Payment Not Made Yet
							</Text>
						)}
					</div>
				);
			},
		},
		{
			title: 'BUYER',
			key: 'userId',
			dataIndex: 'userId',
			render: (user: any, record: TransactionEbookProps) => {
				return (
					<Text>{record.metaUser?.name || getFullName(record.metaUser)}</Text>
				);
			},
		},
		{
			title: 'E-BOOK',
			key: 'title',
			render: (user: any, record: TransactionEbookProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						<Text>{record.transactionEbookItems?.[0]?.ebook?.title}</Text>
					</div>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'STATUS',
			key: 'transactionStatus',
			dataIndex: 'transactionStatus',
			width: 200,
			render: (transactionStatus: ETransactionStatusType) => (
				<div
					style={
						transactionStatus
							? {
									border:
										'2px solid ' +
										TRANSACTION_STATUS_SETTINGS[transactionStatus]?.color,
									color: TRANSACTION_STATUS_SETTINGS[transactionStatus]?.color,
									borderRadius: '0',
									display: 'inline',
									padding: '0 10px',
									fontSize: 11,
							  }
							: {}
					}
				>
					{transactionStatus
						? TRANSACTION_STATUS_SETTINGS[transactionStatus]?.label
						: '-'}
				</div>
			),
		},
		{
			title: 'TOTAL PAY',
			key: 'totalNeedToPay',
			dataIndex: 'totalNeedToPay',
			render: (_: string, record: TransactionEbookProps) => (
				<>
					₱{' '}
					{record.payment
						? formatPrice.format(record.payment.totalNeedToPay)
						: 0}
				</>
			),
		},
		// {
		// 	title: '',
		// 	key: 'action',
		// 	render: (_: any, record: TransactionEbookProps) => (
		// 		<Dropdown overlay={() => menu(record)} placement="bottomRight">
		// 			<MoreOutlined style={{ cursor: 'pointer' }} />
		// 		</Dropdown>
		// 	),
		// },
	];

	const columnSold = [
		{
			title: 'EBOOK NUMBER',
			dataIndex: 'bookNumber',
			key: 'bookNumber',
			render: (user: any, record: TransactionEbookProps) => {
				return (
					<>
						{record.transactionEbookItems?.map((item, index) => (
							<Text key={index}>{item.ebook?.bookNumber}</Text>
						))}
					</>
				);
			},
		},
		{
			title: 'COVER',
			dataIndex: 'image',
			key: 'image',
			render: (url: string, record: TransactionEbookProps) => (
				<>
					{record.transactionEbookItems?.map((item, index) => (
						<Image
							key={index}
							width={125}
							height={115}
							style={{ borderRadius: '7%', marginBottom: '5px' }}
							src={url || item.ebook?.imageUrl}
						/>
					))}
				</>
			),
		},
		{
			title: 'TITLE',
			dataIndex: 'title',
			key: 'title',
			width: 240,
			render: (user: any, record: TransactionEbookProps) => {
				return (
					<>
						<div
							className="table-link"
							onClick={() => handleClickDetailEbook(record)}
						>
							{record.transactionEbookItems?.map((item, index) => (
								<Text key={index}>{item.ebook?.title}</Text>
							))}
						</div>
					</>
				);
			},
		},
		{
			title: 'TOTAL BUY',
			dataIndex: 'totalBuy',
			key: 'totalBuy',
			render: (user: any, record: TransactionEbookProps) => {
				return <Text>{record.totalBuy}</Text>;
			},
		},
		{
			title: 'TOTAL INCOME',
			dataIndex: 'totalIncome',
			key: 'totalIncome',
			render: (user: any, record: TransactionEbookProps) => {
				return <Text>₱ {record.totalIncome}</Text>;
			},
		},
	];

	// const menu = (record: TransactionEbookProps) => (
	// 	<Menu onClick={(e) => handleClickAction(record, e.key)}>
	// 		<Menu.Item key="detail">Detail Consultation</Menu.Item>
	// 	</Menu>
	// );

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Transaction EBook"
				subtitle="Manage your transaction ebook"
			/>

			<ContainerFilter>
				<Input
					style={{ width: activeKey === '1' ? 540 : 800 }}
					size="large"
					placeholder="Search ebook"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => handleSearch(e.target.value)}
				/>
				<RangePicker
					size="large"
					allowEmpty={[true, true]}
					style={{ width: activeKey === '1' ? 270 : 400 }}
					onCalendarChange={handleFilterByDate}
					showHour={false}
					showMinute={false}
					showSecond={false}
					showTime={false}
				/>
				{activeKey === '1' && (
					<Select
						size="large"
						allowClear
						style={{ width: 240 }}
						onChange={handleFilterByStatus}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value={ETransactionStatusType.WAITING_PAYMENT}>
							Waiting for Payment
						</Option>
						<Option value={ETransactionStatusType.WAITING_APPROVAL}>
							Waiting for Approval
						</Option>
						<Option value={ETransactionStatusType.APPROVED}>Approved</Option>
						<Option value={ETransactionStatusType.REJECTED}>Rejected</Option>
						<Option value={ETransactionStatusType.COMPLETED}>Completed</Option>
						<Option value={ETransactionStatusType.EXPIRED}>Expired</Option>
						<Option value={ETransactionStatusType.CANCELED_BY_USER}>
							Cancel - By User
						</Option>
						<Option value={ETransactionStatusType.CANCELED_BY_SYSTEM}>
							Cancel - By Medeasy
						</Option>
					</Select>
				)}
			</ContainerFilter>
			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					onChange={(e) => handleChangeTab(e)}
				>
					<TabPane tab="Transaction List" key={'1'}>
						<Table
							loading={isLoading}
							columns={columns}
							dataSource={data}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<CustomPagination
								current={pagination.page}
								total={pagination.totalData}
								defaultPageSize={pagination.perPage}
								pageSizeOptions={['25', '50', '100']}
								showSizeChanger={true}
								showTotal={(total, range) =>
									`${range[0]} - ${range[1]} of ${total}`
								}
								onChange={changePage}
								locale={{ items_per_page: '' }}
								responsive={true}
							/>
						) : null}

						<PaginationText>
							<h4>Per Page</h4>
						</PaginationText>
					</TabPane>
					<TabPane tab={<>Ebook Sold</>} key={'2'}>
						<Table
							loading={isLoadingSold}
							columns={columnSold}
							dataSource={filterSoldBooks}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<CustomPagination
								current={paginationSold.page}
								total={paginationSold.totalData}
								defaultPageSize={paginationSold.perPage}
								pageSizeOptions={['25', '50', '100']}
								showSizeChanger={true}
								showTotal={(total, range) =>
									`${range[0]} - ${range[1]} of ${total}`
								}
								onChange={changePageSold}
								locale={{ items_per_page: '' }}
								responsive={true}
							/>
						) : null}

						<PaginationText>
							<h4>Per Page</h4>
						</PaginationText>
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default TransactionEbook;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const CustomModal = styled(Modal)`
	.ant-modal,
	.ant-modal-content {
		min-width: 630px;
	}
	.ant-modal-title {
		font-size: 20px;
		font-weight: 600;
		color: #556575;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
	}
	.ant-typography {
		font-weight: 400;
		letter-spacing: 0.02em;
		line-height: 150%;
	}
	span.ant-typography {
		font-size: 12px;
		color: #556575;
		text-transform: capitalize;
	}
	div.ant-typography {
		font-size: 14px;
		color: #1d2b36;
		margin-bottom: 0;
		/* font-weight: 600; */
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
