import { BaseResponseProps } from './config.type';
import { PatientProps } from './patient.type';

export enum UserTypes {
	SET_USER = 'SET_USER',
	SET_LOADING_USER = 'SET_LOADING_USER',
	SET_LOADING_ACTION_USER = 'SET_LOADING_ACTION_USER',
}

export interface CustomerState {
	customer: CustomerProps;
	userFetchLoading: boolean;
	userActionLoading: boolean;
}

export interface CustomerProps {
	userId?: string;
	name: string;
	email: string;
	birthdate: string;
	phone: string;
	gender: string;
	province: string;
	city: string;
	status: 'active' | 'inactive';
	userType: string;
	profilePic?: string;
	updatedAt?: Date | string;
	createdAt?: Date | string;
	statusLoading?: boolean;

	firstName?: string;
	middleName?: string;
	lastName?: string;

	isEmailVerified?: boolean;
	isPhoneVerified?: boolean;

	patient?: PatientProps;
	password?: string;
}

export interface FetchAllCustomerResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: CustomerProps[];
	};
}

export const initialCustomer: CustomerProps = {
	userId: '',
	name: '',
	email: '',
	birthdate: '',
	phone: '',
	gender: '',
	province: '',
	city: '',
	status: 'inactive',
	userType: 'customer',
	firstName: '',
	middleName: '',
	lastName: '',
};

export const initialAdmin: CustomerProps = {
	userId: '',
	name: '',
	email: '',
	birthdate: '',
	phone: '',
	gender: '',
	province: '',
	city: '',
	status: 'inactive',
	userType: 'admin',
	firstName: '',
	middleName: '',
	lastName: '',
	password: '',
};

export const initialUserFetchAll: FetchAllCustomerResponse = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialCustomer],
	},
};
