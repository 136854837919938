import {
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Row,
	Select,
	Space,
	TimePicker,
	Upload,
} from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { generateFormRules } from '../../helpers/formRules';
import { generateQueryString } from '../../helpers/generateQueryString';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { EventProps, initialEvent } from '../../types/event.type';
import {
	EventTagProps,
	FetchAllEventTagResponse,
} from '../../types/eventTag.type';
import {
	FetchAllSpecialistResponse,
	SpecialistProps,
} from '../../types/specialist.type';
import { UploadInput } from '@qlibs/react-components';
interface IParams {
	eventId: string;
}

interface ILocation {
	eventId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EventProps, 'createdAt' | 'updatedAt'>;
}

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const { Option } = Select;
const { RangePicker } = DatePicker;

const EventEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { eventId } = useParams<IParams>();
	const formRef =
		React.useRef<
			FormInstance<Omit<EventProps, 'createdAt' | 'updatedAt' | 'participant'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [event, setEvent] = React.useState<EventProps>(initialEvent);
	console.log(event);
	const [eventTags, setEventTags] = React.useState<Array<EventTagProps>>([]);
	const [eventSpecialists, setEventSpecialists] = React.useState<
		Array<SpecialistProps>
	>([]);
	// const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
	// 	url: '',
	// 	uid: '',
	// 	name: '',
	// });
	// const [images, setImages] = React.useState<
	// 	UploadChangeParam<UploadFile<any>>['fileList']
	// >([]);
	const [willBeDeletedImage, setWillBeDeletedImage] =
		React.useState<UploadFile<any>>();
	const [isLoadingDeleteImage, setIsLoadingDeleteImage] = React.useState(false);

	const [eventDate, setEventDate] = React.useState<moment.Moment>(moment());
	const [eventTime, setEventTime] = React.useState<{
		startAt: moment.Moment;
		endAt: moment.Moment;
	}>({
		startAt: moment(),
		endAt: moment(),
	});

	const [image, setImage] = React.useState<File | any | undefined>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();

	const createEvent = async (
		props: Omit<
			EventProps,
			'createdAt' | 'updatedAt' | 'eventId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			const res: any = await httpRequest.post(
				'/events',
				{
					...props,
					price: 0,
					maxParticipant: 0,
					eventStartAt: event.eventStartAt,
					eventEndAt: event.eventEndAt,
					registerStartAt: event.registerStartAt,
					registerEndAt: event.registerEndAt,
					registerUrl: event.registerUrl,
					tags: event.tags,
					specialists: event.specialists,
					image: image,
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			await uploadNewImage(res.data.payload.eventId);

			message.success('Success create ' + props.title);
			history.push('/medical-event');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateEvent = async (
		props: Omit<EventProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			// const images = [imageUrl];

			const dataToBeSent = {
				...props,
				tags: event.tags,
				specialists: event.specialists,
				eventStartAt: event.eventStartAt,
				eventEndAt: event.eventEndAt,
				registerStartAt: event.registerStartAt,
				registerEndAt: event.registerEndAt,
				registerUrl: event.registerUrl,
				image: image,
			};
			if (eventId) {
				await Promise.all([
					httpRequest.patch('/events/' + eventId, dataToBeSent),
					uploadNewImage(eventId),
				]);
			}

			message.success('Success update ' + props.title + ' data');
			history.push('/medical-event');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const uploadNewImage = async (eventId: string) => {
		try {
			if (image) {
				setIsLoadingAction(true);

				let formData = new FormData();
				formData.append('image', image);

				await httpRequest.put(
					'/events/' + eventId + '/upload-image',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
				setIsLoadingAction(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleSubmit = async (
		values: Omit<EventProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (eventId) {
			updateEvent(values);
		} else {
			createEvent(values);
		}
	};

	const handleChangeTag = (value: any) => {
		console.log(value);
		const selectedTags = value.map((selectedTagId: string) => {
			const foundTag = eventTags.find((tag) => tag.tagId === selectedTagId);

			return foundTag;
		});

		setEvent((oldVal) => {
			return {
				...oldVal,
				tags: selectedTags,
			};
		});
	};
	const handleChangeSpecialist = (value: any) => {
		const selectedSpecialist = value.map((selectedSpecialistId: string) => {
			const foundSpecialist = eventSpecialists.find(
				(specialist) => specialist.specialistId === selectedSpecialistId,
			);

			return foundSpecialist;
		});

		setEvent((oldVal) => {
			return {
				...oldVal,
				specialists: selectedSpecialist,
			};
		});
	};

	const handleChangeEventStartedTime = (val: moment.Moment) => {
		if (val) {
			const hourStartAt = val ? val.get('hour') : moment().get('hour');
			const minuteStartAt = val ? val.get('minute') : moment().get('minute');

			setEventTime({ ...eventTime, startAt: moment(val) });

			const startAt = moment().hour(hourStartAt).minute(minuteStartAt);

			console.log('time ' + startAt.toDate());
			const newEvent = {
				...event,
				registerStartAt: startAt.toDate(),
			};

			setEvent(newEvent);
		}
	};
	const handleChangeEventEndedTime = (val: moment.Moment) => {
		if (val) {
			const hourEndAt = val ? val.get('hour') : moment().get('hour');
			const minuteEndAt = val ? val.get('minute') : moment().get('minute');

			setEventTime({ ...eventTime, endAt: moment(val) });

			const endAt = moment().hour(hourEndAt).minute(minuteEndAt);

			const newEvent = {
				...event,
				registerEndAt: endAt.toDate(),
			};

			setEvent(newEvent);
		}
	};

	const handleChangeEventStartedDate = (val: moment.Moment) => {
		if (val) {
			const day = val.get('date');
			const month = val.get('month');
			const year = val.get('year');
			const hourStartAt = eventTime.startAt.get('hour');

			setEventDate(val);

			const startAt = moment()
				.year(year)
				.month(month)
				.date(day)
				.hour(hourStartAt);

			const newEvent = {
				...event,
				eventStartAt: startAt.toDate(),
			};

			setEvent(newEvent);
		}
	};
	const handleChangeEventEndedDate = (val: moment.Moment) => {
		if (val) {
			const day = val.get('date');
			const month = val.get('month');
			const year = val.get('year');
			const hourEndAt = eventTime.endAt.get('hour');

			setEventDate(val);

			const endAt = moment().year(year).month(month).date(day).hour(hourEndAt);

			const newEvent = {
				...event,
				eventEndAt: endAt.toDate(),
			};

			setEvent(newEvent);
		}
	};

	const readFile = (file: UploadFile<any>): Promise<string | undefined> => {
		return new Promise((resolve, reject) => {
			if (file.originFileObj) {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onloadend = function (event) {
					if (event.target && event.target.result) {
						const url =
							event && event.target && event.target.result
								? (event.target.result as any)
								: undefined;
						resolve(url);
					} else {
						resolve(undefined);
					}
				};
			} else {
				resolve(undefined);
			}
		});
	};

	// const handleChangeImages = async (e: UploadChangeParam<UploadFile<any>>) => {
	// 	e.fileList.forEach((file) => {
	// 		if (!file || (file && !file.size)) return;
	// 		const isLtMaxSize = (file?.size || 0) / 1024 / 1024 < 1;
	// 		if (!isLtMaxSize) {
	// 			message.error(`Image must smaller than ${1}MB!`);
	// 		}
	// 	});

	// 	// setImages(e.fileList);

	// 	const tmpListImage: Array<UploadFile<any> & { url?: string }> = [];
	// 	for (const file of e.fileList) {
	// 		if (file.originFileObj) {
	// 			const url = await readFile(file);
	// 			console.info('url', url);
	// 			tmpListImage.push({
	// 				...file,
	// 				url: url || '',
	// 			});
	// 		}
	// 	}

	// 	setImages(tmpListImage);
	// 	if (!selectedImage || (selectedImage && !selectedImage.uid)) {
	// 		setSelectedImage(tmpListImage[0]);
	// 	}
	// };

	React.useEffect(() => {
		if (eventId) {
			const fetchEventDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/events/' + eventId,
					);

					setEvent(res.data.payload);

					if (
						res.data.payload.imageUrls &&
						res.data.payload.imageUrls.length > 0
					) {
						setImageUrl(res.data.payload.imageUrls[0]);
					}
					setEventTime({
						startAt: moment(res.data.payload.eventStartAt),
						endAt: moment(res.data.payload.eventEndAt),
					});
					setEventDate(moment(res.data.payload.eventStartAt));

					const bcDetails = [
						{
							field: 'eventId',
							value: eventId,
							label: res.data.payload.title,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchEventDetail();
		}

		const getTagData = async () => {
			const tagRes = await httpRequest.get<FetchAllEventTagResponse>(
				`/event-tags${generateQueryString({ isPublished: true })}`,
			);

			setEventTags(tagRes.data.payload.results);
		};

		getTagData();

		const getSpecialistsData = async () => {
			const specialistRes = await httpRequest.get<FetchAllSpecialistResponse>(
				`/specialists${generateQueryString({ sort: 'specialistName:ASC' })}`,
			);

			setEventSpecialists(specialistRes.data.payload.results);
		};
		getSpecialistsData();
	}, [eventId, location]);
	console.log(event);

	function disabledDate(current: any) {
		const startDate = moment(event?.eventStartAt);
		return current && current <= startDate;
	}

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(eventId ? 'Edit' : 'Add') + ' Medical Event'}
				subtitle="Manage your event data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="eventForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={
						eventId
							? { ...event, file: imageUrl ? [{ url: imageUrl }] : [] }
							: initialEvent
					}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Medical Event Information"
						helpers={[
							{
								title: 'Information',
								content: (
									<div
										style={{
											maxWidth: '80%',
											wordWrap: 'break-word',
											overflow: 'hidden',
											whiteSpace: 'normal',
										}}
									>
										Accepted image type are jpg, jpeg and png. Max file size is
										1 Mb.
									</div>
								),
							},
						]}
					>
						<Form.Item
							name="file"
							label="Upload Image"
							rules={generateFormRules('Image', ['required'])}
						>
							{/* <FormUploadImage
								mode={'single-large'}
								maxSizeInMB={1}
								imageUrl={
									event.imageUrls
										? event.imageUrls[event.imageUrls.length - 1]
										: '/images/select-image.jpg'
								}
								onChange={(file) => {
									setImage(file);
								}}
								onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
							/> */}
							<UploadInput
								additionalHint={imageUrl}
								maxFileSize={1}
								onChange={(file) => {
									setImage(file);
								}}
								allowedExtension={['jpg', 'png']}
								enableAutoResizeForImage={true}
								configAutoResizeForImage={{
									maxFileWidth: 350,
								}}
							/>
						</Form.Item>

						<Form.Item
							label="Headline"
							name="title"
							rules={generateFormRules('Headline', ['required'])}
						>
							<Input
								value={event.title}
								onChange={(e) =>
									setEvent({
										...event,
										title: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item
							label="Detail"
							name="description"
							rules={generateFormRules('Detail', ['required'])}
						>
							<ReactQuill
								theme="snow"
								value={event.description}
								onChange={(val) =>
									setEvent({
										...event,
										description: val,
									})
								}
								modules={quillModules}
								formats={quillFormats}
							/>
						</Form.Item>

						<Form.Item
							label="Event Organizer"
							name="eventOrganizer"
							rules={generateFormRules('Event Organizer', ['required'])}
						>
							<Input
								placeholder="Input event organizer name"
								value={event.eventOrganizer}
								onChange={(e) =>
									setEvent({
										...event,
										eventOrganizer: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="CPD Point"
							name="isCPDAvailable"
						>
							<Radio.Group value={event.isCPDAvailable}>
								<CustomRadio value={true}>Yes</CustomRadio>
								<CustomRadio value={false}>No</CustomRadio>
							</Radio.Group>
						</Form.Item>

						<Form.Item
							style={{ marginTop: 10 }}
							label="Start"
							name="dateAndTime"
						>
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<DatePicker
										style={{
											width: '100%',
										}}
										defaultValue={
											eventId
												? moment(event.eventStartAt, 'YYYY/MM/DD')
												: moment()
										}
										onChange={(val) =>
											val
												? handleChangeEventStartedDate(val)
												: setEventDate(moment())
										}
									/>
								</Col>
								<Col span={12}>
									<TimePicker
										format={'HH:mm'}
										style={{
											width: '100%',
										}}
										defaultValue={
											eventId ? moment(event.registerStartAt) : moment()
										}
										onChange={(val) =>
											val
												? handleChangeEventStartedTime(val)
												: setEventTime({ startAt: moment(), endAt: moment() })
										}
									/>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item style={{ marginTop: 10 }} label="End" name="dateAndTime">
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<DatePicker
										style={{
											width: '100%',
										}}
										value={
											moment(event.eventEndAt).isBefore(event.eventStartAt)
												? moment(event.eventStartAt, 'YYYY/MM/DD')
												: moment(event.eventEndAt, 'YYYY/MM/DD')
										}
										onChange={(val) =>
											val
												? handleChangeEventEndedDate(val)
												: setEventDate(moment())
										}
										disabledDate={disabledDate}
									/>
								</Col>
								<Col span={12}>
									<TimePicker
										format={'HH:mm'}
										style={{
											width: '100%',
										}}
										defaultValue={
											eventId ? moment(event.registerEndAt) : moment()
										}
										onChange={(val) =>
											val
												? handleChangeEventEndedTime(val)
												: setEventTime({ startAt: moment(), endAt: moment() })
										}
										disabledHours={() => {
											const disabledHoursArray = [];
											const end = moment(event.eventEndAt).startOf('day');
											const startDateTime = moment(event.registerStartAt);

											if (end.isSame(startDateTime, 'day')) {
												for (let i = 0; i < startDateTime.hours(); i++) {
													disabledHoursArray.push(i);
												}
											}

											return disabledHoursArray;
										}}
									/>
								</Col>
							</Row>
						</Form.Item>

						<Form.Item>
							<h4>Specialities</h4>
							<Select
								mode="multiple"
								allowClear
								style={{ width: '100%' }}
								placeholder="Input Specialities"
								onChange={handleChangeSpecialist}
								key={'specialitySelect'}
								value={
									event.specialists
										? event.specialists.map((item) => item.specialistId || '')
										: []
								}
							>
								{eventSpecialists.map((speciality, index) => {
									return (
										<Option
											key={`specialist${index}`}
											value={String(speciality.specialistId)}
										>
											{speciality.specialistName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							// name="tags"
							label="Tags"
							rules={[
								{
									required: true,
									message: 'Please select tag',
									type: 'array',
								},
							]}
						>
							<Select
								mode="multiple"
								style={{ width: '100%' }}
								placeholder="Input tags"
								onChange={handleChangeTag}
								key={'tagSelect'}
								value={
									event.tags ? event.tags.map((item) => item.tagId || '') : []
								}
							>
								{eventTags.map((tag, index) => {
									return (
										<Option key={`tag${index}`} value={String(tag.tagId)}>
											{tag.tagName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item label="Webinar Register Link" name="registerUrl">
							<Input
								placeholder="Input webinar register link page"
								value={event.registerUrl}
								onChange={(e) =>
									setEvent({
										...event,
										registerUrl: e.target.value,
									})
								}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
						>
							<Radio.Group value={event.isPublished}>
								<CustomRadio value={true}>Published</CustomRadio>
								<CustomRadio value={false}>Unpublished</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

const CustomSpace = styled(Space)`
	width: 100%;
	.ant-space-item {
		width: 100%;
	}
`;

const CustomUpload = styled(Upload)`
	width: 100%;
	.ant-upload {
		width: 100%;
	}
`;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: #d81f64;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: #d81f64;
	}
`;

export default EventEdit;
