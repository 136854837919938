import { BaseResponseProps } from './config.type';

export interface GenreProps {
    genreId: string;
    genreName: string;
    isPublished: boolean;
	imageUrl?: string;
    createdAt?: Date | string;
	updatedAt?: Date | string;
	statusLoading?: boolean;  
}

export interface FetchAllGenreResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: GenreProps[];
	};
}
export const initialGenre: GenreProps = {
    genreId: '',
    genreName: '',
	imageUrl:'',
    isPublished: true,
}