import { BaseResponseProps } from './config.type';

export interface FeesProps {
	key: string;
	value: string;
}
export interface ReferralFeesProps {
	uplinePoint: string;
	downlinePoint: string;
	type: string;
}

export interface FetchAllFeesResponse extends BaseResponseProps {
	payload: FeesProps[];
}

export const initialFees: FeesProps = {
	key: '',
	value: '',
};

export const initialReferralFees: ReferralFeesProps = {
	uplinePoint: '',
	downlinePoint: '',
	type: '',
};

export interface FeesHistoryProps {
	historyId: string;
	key: string;
	newData: FeesProps;
	createdByUserId: string;
	metaCreatedByUser: any;
	createdAt?: string;
	updatedAt?: string;
}
