import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StarOutlined, EditOutlined } from '@ant-design/icons';
import {
	Table,
	Typography,
	Row,
	Col,
	Divider,
	Modal,
	InputNumber,
	Button,
	message,
	Form,
	FormInstance,
} from 'antd';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import HeaderSection from '../../components/HeaderSection';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import moment from 'moment';
import {
	ERewardPointPatientDoctor,
	RewardPoint,
	RewardProps,
} from '../../types/rewardPointSettings.type';

const { Text } = Typography;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<RewardProps, 'createdAt' | 'updatedAt'>;
}

const RewardPointSettings = () => {
	const history = useHistory();
	const formRefPatient = React.useRef<FormInstance<RewardProps>>(null);
	const formRefDoctor = React.useRef<FormInstance<RewardProps>>(null);

	const { isLoading, setIsLoading, data, fetchList } =
		useFetchList<RewardProps>({
			endpoint: 'app-configs',
			limit: Object.keys(ERewardPointPatientDoctor).length,
			initialQuery: { keys: Object.keys(ERewardPointPatientDoctor).join(',') },
		});

	const [isDataSorted, setIsDataSorted] = useState<boolean>(false);
	const [user, setUser] = useState<RewardPoint[]>([]);
	const [referral, setReferral] = useState<RewardPoint[]>([]);
	const [rxPrescription, setRxPrescription] = useState<RewardPoint[]>([]);
	const [rewards, setRewards] = useState<RewardPoint[]>([]);

	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [modalData, setModalData] = useState<RewardPoint>();
	const [loadingModalData, setLoadingModalData] = useState<boolean>(true);
	const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
	const [isUpdateSuccessful, setIsUpdateSuccessful] = useState<string>('');
	const [isPatientUpdated, setIsPatientUpdated] = useState<boolean>(false);
	const [isDoctorUpdated, setIsDoctorUpdated] = useState<boolean>(false);

	const showModal = (data: RewardPoint) => {
		setLoadingModalData(true);
		setIsModalVisible(true);
		setModalData(data);
		setLoadingModalData(false);
	};

	const closeModal = () => {
		setIsModalVisible(false);
		setLoadingModalData(true);
	};

	const editKeyName = (keyName: string) => {
		let arr: string[] = keyName.split('_');
		let edited = '';
		for (let word of arr) {
			edited =
				edited +
				word.charAt(0).toUpperCase() +
				word.slice(1).toLowerCase() +
				' ';
		}
		return edited.slice(12);
	};

	const editKey = (keyName: string) => {
		let arr: string[] = keyName.split('_');
		let edited = '';
		for (let word of arr) {
			edited = edited + '-' + word.toLowerCase();
		}
		return edited.slice(1);
	};

	const afterEditRewardPoint = () => {
		fetchList();
		closeModal();
		setIsLoadingAction(false);
	};

	const editRewardPoint = async (
		props: Omit<RewardProps, 'createdAt' | 'updatedAt'>,
		type: string,
	) => {
		try {
			setIsLoadingAction(true);
			if (type === 'Doctor') {
				if (isDoctorUpdated === true) {
					const res = await httpRequest.patch<ResponseProps>(
						`/app-configs/${modalData!.key}_DOCTOR`,
						{
							...props,
						},
					);
					setIsUpdateSuccessful('Yes');
					setIsDoctorUpdated(false);
					afterEditRewardPoint();
				}
			} else {
				if (isPatientUpdated === true) {
					const res = await httpRequest.patch<ResponseProps>(
						`/app-configs/${modalData!.key}_PATIENT`,
						{
							...props,
						},
					);
					setIsUpdateSuccessful('Yes');
					setIsPatientUpdated(false);
					afterEditRewardPoint();
				}
			}
		} catch (error) {
			message.error(`ERROR: Point value cannot be updated!`);
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async () => {
		await formRefPatient?.current?.submit();
		await formRefDoctor?.current?.submit();
	};

	const handleSubmitDoctor = async (
		values: Omit<RewardProps, 'createdAt' | 'updatedAt'>,
	) => {
		editRewardPoint(values, 'Doctor');
	};

	const handleSubmitPatient = async (
		values: Omit<RewardProps, 'createdAt' | 'updatedAt'>,
	) => {
		editRewardPoint(values, 'Patient');
	};

	let sortedData: RewardPoint[] = [
		{
			configId: 'REWARD_POINT_REGISTERED_NEW_USER',
			key: 'REWARD_POINT_REGISTERED_NEW_USER',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_REFERRAL_UPLINE',
			key: 'REWARD_POINT_REFERRAL_UPLINE',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_REFERRAL_DOWNLINE',
			key: 'REWARD_POINT_REFERRAL_DOWNLINE',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY',
			key: 'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE',
			key: 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE',
			key: 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId:
				'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP',
			key: 'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId:
				'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED',
			key: 'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_DAILY_CHECK_IN',
			key: 'REWARD_POINT_DAILY_CHECK_IN',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		// {
		// 	configId: 'REWARD_POINT_CREATE_RX_PRESCRIPTIONS',
		// 	key: 'REWARD_POINT_CREATE_RX_PRESCRIPTIONS',
		// 	value: {
		// 		doctor: '',
		// 		patient: '',
		// 	},
		// 	createdAt: '',
		// 	updatedAt: '',
		// },
		{
			configId: 'REWARD_POINT_USING_CHAT_CONSULTATION',
			key: 'REWARD_POINT_USING_CHAT_CONSULTATION',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION',
			key: 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION',
			key: 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_USING_IN_PERSON_CONSULTATION',
			key: 'REWARD_POINT_USING_IN_PERSON_CONSULTATION',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_MEDICAL_SERVICE_LAB',
			key: 'REWARD_POINT_MEDICAL_SERVICE_LAB',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		// {
		// 	configId: 'REWARD_POINT_POST_ARTICLES',
		// 	key: 'REWARD_POINT_POST_ARTICLES',
		// 	value: {
		// 		doctor: '',
		// 		patient: '',
		// 	},
		// 	createdAt: '',
		// 	updatedAt: '',
		// },
		{
			configId: 'REWARD_POINT_READ_ARTICLES',
			key: 'REWARD_POINT_READ_ARTICLES',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY',
			key: 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_SHARE_ARTICLES',
			key: 'REWARD_POINT_SHARE_ARTICLES',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY',
			key: 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_READ_EVENT',
			key: 'REWARD_POINT_READ_EVENT',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_MAX_READ_EVENT_PER_DAY',
			key: 'REWARD_POINT_MAX_READ_EVENT_PER_DAY',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
		{
			configId: 'REWARD_POINT_REGISTER_WEBINAR',
			key: 'REWARD_POINT_REGISTER_WEBINAR',
			value: {
				doctor: '',
				patient: '',
			},
			createdAt: '',
			updatedAt: '',
		},
	];

	let userSection: RewardPoint[] = [];
	let referralSection: RewardPoint[] = [];
	let rxPrescriptionSection: RewardPoint[] = [];
	let rewardsSection: RewardPoint[] = [];

	const sortData = (data: RewardProps[]) => {
		if (data.length === 0) {
			setIsLoading(true);
			setIsDataSorted(false);
			return;
		}

		if (isUpdateSuccessful === 'Yes') {
			message.success(`Point value has been updated successfully!`);
			setIsUpdateSuccessful('');
		}

		setIsLoading(true);
		setIsDataSorted(false);

		for (let obj of sortedData) {
			let patient: RewardProps = data.find(
				(e) => e.key === obj.key + '_PATIENT',
			)!;
			let doctor: RewardProps = data.find(
				(e) => e.key === obj.key + '_DOCTOR',
			)!;
			let createdAt = [patient?.createdAt, doctor?.createdAt];
			let updatedAt = [patient?.updatedAt, doctor?.updatedAt];
			obj.value.patient = patient?.value;
			obj.value.doctor = doctor?.value;
			obj.createdAt = createdAt.sort(
				(a, b) => Date.parse(b!) - Date.parse(a!),
			)[1]!;
			obj.updatedAt = updatedAt.sort(
				(a, b) => Date.parse(b!) - Date.parse(a!),
			)[0]!;

			if (obj.key === 'REWARD_POINT_REGISTERED_NEW_USER') {
				userSection.push(obj);
			} else if (
				obj.key === 'REWARD_POINT_REFERRAL_UPLINE' ||
				obj.key === 'REWARD_POINT_REFERRAL_DOWNLINE'
			) {
				referralSection.push(obj);
			} else if (
				obj.key === 'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY' ||
				obj.key === 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE' ||
				obj.key === 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE' ||
				obj.key ===
					'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP' ||
				obj.key ===
					'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED'
			) {
				rxPrescriptionSection.push(obj);
			} else {
				rewardsSection.push(obj);
			}
		}
		setUser(userSection);
		setReferral(referralSection);
		setRxPrescription(rxPrescriptionSection);
		setRewards(rewardsSection);
		setIsDataSorted(true);
		setIsLoading(false);
	};

	useEffect(() => {
		sortData(data);
	}, [data]);

	const columns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: RewardPoint) => {
				return (
					<Text
						underline
						onClick={() => {
							history.push({
								pathname: `/points/${editKey(record.key)}`,
								state: { ...record },
							});
						}}
						style={{ cursor: 'pointer' }}
					>
						{editKeyName(record.key)}
					</Text>
				);
			},
		},
		{
			title: 'DOCTOR',
			dataIndex: 'doctor',
			key: 'doctor',
			render: (text: string, record: RewardPoint) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<p
							style={{
								// width: 22,
								margin: 'auto',
								padding: 0,
								// paddingRight: 10,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							{record.key !== 'REWARD_POINT_USING_CHAT_CONSULTATION' &&
							record.key !== 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION' &&
							record.key !== 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION' &&
							record.key !== 'REWARD_POINT_USING_IN_PERSON_CONSULTATION'
								? record.value.doctor
								: '-'}
						</p>
						{record.key !== 'REWARD_POINT_USING_CHAT_CONSULTATION' &&
							record.key !== 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION' &&
							record.key !== 'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION' &&
							record.key !== 'REWARD_POINT_USING_IN_PERSON_CONSULTATION' && (
								<Text style={{ margin: 0, padding: 0 }} type="secondary">
									{record.key === 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY' ||
									record.key === 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY' ||
									record.key === 'REWARD_POINT_MAX_READ_EVENT_PER_DAY'
										? 'Times'
										: record.key !==
												'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP' &&
										  record.key !==
												'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED'
										? 'Points'
										: '% Of Total Point'}
								</Text>
							)}
					</div>
				);
			},
		},
		{
			title: 'PATIENT',
			dataIndex: 'patient',
			key: 'patient',
			render: (text: string, record: RewardPoint) => {
				return (
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<p
							style={{
								// width: 22,
								margin: 'auto',
								padding: 0,
								// paddingRight: 10,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							{record.key !== 'REWARD_POINT_READ_EVENT' &&
							record.key !== 'REWARD_POINT_MAX_READ_EVENT_PER_DAY' &&
							record.key !== 'REWARD_POINT_REGISTER_WEBINAR' &&
							record.key !==
								'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY' &&
							record.key !== 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE' &&
							record.key !== 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE' &&
							record.key !==
								'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP' &&
							record.key !==
								'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED'
								? record.value.patient
								: '-'}
						</p>
						{record.key !== 'REWARD_POINT_READ_EVENT' &&
							record.key !== 'REWARD_POINT_MAX_READ_EVENT_PER_DAY' &&
							record.key !== 'REWARD_POINT_REGISTER_WEBINAR' &&
							record.key !==
								'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY' &&
							record.key !== 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE' &&
							record.key !== 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE' &&
							record.key !==
								'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP' &&
							record.key !==
								'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED' && (
								<Text style={{ margin: 0, padding: 0 }} type="secondary">
									{record.key === 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY' ||
									record.key === 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY'
										? 'Times'
										: 'Points'}
								</Text>
							)}
					</div>
				);
			},
		},
		{
			title: 'LAST MODIFIED',
			dataIndex: 'lastModified',
			key: 'lastModified',
			render: (text: string, record: RewardPoint) => {
				return moment(record.updatedAt).format('MMMM DD, YYYY - HH:mm');
			},
		},
		{
			title: 'ACTION',
			key: 'action',
			render: (_: any, record: RewardPoint) => (
				<EditOutlined
					onClick={() => {
						showModal(record);
					}}
					style={{ color: '#A5B2BD', fontSize: '17px' }}
				/>
			),
		},
	];

	return (
		<div>
			<HeaderSection
				icon={<StarOutlined style={{ color: '#A5B2BD', fontSize: '17px' }} />}
				title="Points"
				subtitle="Manage your points"
			/>
			{isDataSorted === true && !isLoading && (
				<>
					<BackgroundDiv>
						<Row>
							<Col flex={'180px'}>
								<p style={{ fontWeight: 500 }}>User</p>
							</Col>
							<Col flex={'auto'}>
								<Table
									bordered
									loading={isLoading}
									columns={columns}
									dataSource={user}
									pagination={false}
								/>
							</Col>
						</Row>
						<Divider />
						<Row>
							<Col flex={'180px'}>
								<p style={{ fontWeight: 500 }}>Referral</p>
							</Col>
							<Col flex={'auto'}>
								<Table
									bordered
									loading={isLoading}
									columns={columns}
									dataSource={referral}
									pagination={false}
								/>
							</Col>
						</Row>
						<Divider />
						<Row>
							<Col flex={'180px'}>
								<p style={{ fontWeight: 500 }}>Rx Prescription</p>
							</Col>
							<Col flex={'auto'}>
								<Table
									bordered
									loading={isLoading}
									columns={columns}
									dataSource={rxPrescription}
									pagination={false}
								/>
							</Col>
						</Row>
						<Divider />
						<Row>
							<Col flex={'180px'}>
								<p style={{ fontWeight: 500 }}>Rewards</p>
							</Col>
							<Col flex={'auto'}>
								<Table
									bordered
									loading={isLoading}
									columns={columns}
									dataSource={rewards}
									pagination={false}
								/>
							</Col>
						</Row>
					</BackgroundDiv>
				</>
			)}

			{!loadingModalData && (
				<Modal
					title={editKeyName((modalData as RewardPoint).key)}
					visible={isModalVisible}
					onCancel={closeModal}
					width={550}
					keyboard={true}
					maskClosable={true}
					footer={[
						<Button key="back" onClick={closeModal}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={() => {
								handleSubmit();
							}}
							style={{ backgroundColor: '#D81F64', borderColor: '#D81F64' }}
						>
							Save
						</Button>,
					]}
				>
					{modalData!.key !== 'REWARD_POINT_USING_CHAT_CONSULTATION' &&
						modalData!.key !== 'REWARD_POINT_USING_TELEMEDICINE_CONSULTATION' &&
						modalData!.key !==
							'REWARD_POINT_EXTEND_TELEMEDICINE_CONSULTATION' &&
						modalData!.key !== 'REWARD_POINT_USING_IN_PERSON_CONSULTATION' && (
							<Form
								ref={formRefDoctor}
								name="rewardPointForm"
								layout="vertical"
								onFinish={handleSubmitDoctor}
								initialValues={modalData}
								autoComplete="off"
								onValuesChange={() => {
									setIsDoctorUpdated(true);
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSubmit();
									}
								}}
							>
								<Form.Item label="Doctor" name="value">
									{modalData!.key === 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY' ||
									modalData!.key === 'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY' ||
									modalData!.key === 'REWARD_POINT_MAX_READ_EVENT_PER_DAY' ? (
										<InputNumber
											addonAfter="Times"
											min={0}
											defaultValue={+(modalData as RewardPoint).value.doctor}
										/>
									) : modalData!.key !==
											'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP' &&
									  modalData!.key !==
											'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED' ? (
										<InputNumber
											addonAfter="Points"
											min={0}
											defaultValue={+(modalData as RewardPoint).value.doctor}
										/>
									) : (
										<InputNumber
											addonAfter="% Of Total Point"
											min={0}
											defaultValue={+(modalData as RewardPoint).value.doctor}
										/>
									)}
								</Form.Item>
							</Form>
						)}
					{modalData!.key !== 'REWARD_POINT_READ_EVENT' &&
						modalData!.key !== 'REWARD_POINT_MAX_READ_EVENT_PER_DAY' &&
						modalData!.key !== 'REWARD_POINT_REGISTER_WEBINAR' &&
						modalData!.key !==
							'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY' &&
						modalData!.key !== 'REWARD_POINT_MAX_POINT_GENERIC_MEDICINE' &&
						modalData!.key !== 'REWARD_POINT_MAX_POINT_PHARMACY_MEDICINE' &&
						modalData!.key !==
							'REWARD_POINT_RECEIVED_PENDING_POINT_IF_PATIENT_NOT_REGISTERED_IN_MEDEASY_APP' &&
						modalData!.key !==
							'REWARD_POINT_RECEIVED_POINT_IF_PATIENT_CONSULT_WITHOUT_MEDEASY_APP_BUT_ALREADY_REGISTERED' && (
							<Form
								ref={formRefPatient}
								name="rewardPointForm"
								layout="vertical"
								onFinish={handleSubmitPatient}
								initialValues={modalData}
								autoComplete="off"
								onValuesChange={() => {
									setIsPatientUpdated(true);
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSubmit();
									}
								}}
							>
								<Form.Item label="Patient" name="value">
									{modalData!.key === 'REWARD_POINT_MAX_READ_ARTICLE_PER_DAY' ||
									modalData!.key ===
										'REWARD_POINT_MAX_SHARE_ARTICLE_PER_DAY' ? (
										<InputNumber
											addonAfter="Times"
											min={0}
											defaultValue={+(modalData as RewardPoint).value.doctor}
										/>
									) : (
										<InputNumber
											addonAfter="Points"
											min={0}
											defaultValue={+(modalData as RewardPoint).value.patient}
										/>
									)}
								</Form.Item>
							</Form>
						)}
				</Modal>
			)}
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

const BackgroundDiv = styled.div`
	margin: 10px 20px 40px 20px;
	padding: 20px;
	border-radius: 5px;
	gap: 25px;
	background-color: white;
`;

export default RewardPointSettings;
