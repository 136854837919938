import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import { generateFormRules } from '../../../helpers/formRules';
import { initialTopic, TopicProps } from '../../../types/topic.type';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import useFetchList from '../../../hooks/useFetchList';

interface IParams {
	topicId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<TopicProps, 'createdAt' | 'updatedAt'>;
}

const TopicEdit: React.FC = () => {
	const { Text } = Typography;
	const history = useHistory();
	const { topicId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<TopicProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [topic, setTopic] = React.useState<TopicProps>(initialTopic);
	const [topicName, setTopicName] = React.useState<string>('');
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [showWarning, setShowWarning] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(topicName, 500);

	const { data } = useFetchList<TopicProps>({
		endpoint: 'topics',
		limit: 10,
	});

	React.useEffect(() => {
		const res: any = data.filter(
			(item) => topicId !== item.topicId && searchValue === item.topicName,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	const createTopic = async (
		props: Omit<
			TopicProps,
			'createdAt' | 'updatedAt' | 'topicId' | 'statusLoading'
		>,
	) => {
		try {
			console.log(props);

			setIsLoadingAction(true);
			await httpRequest.post('/topics', {
				topicName: props.topicName,
				isPublished: props.isPublished.toString(),
			});
			message.success('Success create ' + props.topicName);
			history.push('/topic');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateTopic = async (
		props: Omit<TopicProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/topics/' + topicId, {
				topicName: props.topicName,
				isPublished: props.isPublished.toString(),
			});
			message.success('Success update ' + props.topicName + ' data');
			history.push('/topic');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<TopicProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (topicId) {
			updateTopic(values);
		} else {
			createTopic(values);
		}
	};

	React.useEffect(() => {
		if (topicId) {
			const fetchTopic = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/topics/' + topicId,
					);
					setTopic(res.data.payload);
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchTopic();
		}
	}, [topicId]);

	const handleArticleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setTopicName(value);
		setShowWarning(value.length > 100);
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(topicId ? 'Edit' : 'Add') + ' Article Tag'}
				subtitle="Manage your article tag data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							disabled={isDuplicate}
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={topicId ? topic : initialTopic}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Article Tag"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Tag Name"
							name="topicName"
							rules={generateFormRules('Tag Name', ['required'])}
						>
							<Input
								onChange={handleArticleNameChange}
								value={topicName}
								placeholder="Name"
							/>
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Topic Name Already Exist!
							</Text>
						)}
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Topic Name should not exceed 100 characters!
							</Text>
						)}

						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={topic.isPublished}>
								<CustomRadio value={true}>Published</CustomRadio>
								<CustomRadio value={false}>Unpublished</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default TopicEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
