import { Row, Col, Divider, Typography, Tabs, Space } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import DetailItem from '../../../components/DetailItem';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import { formatDate, formatTime } from '../../../helpers/constant';
import { PaymentProps } from '../../../types/payment.type';
import { getFullName } from '../../../helpers/name';
import { getAge } from '../../../helpers/getAge';
import { replaceUnderscoreWithSpace } from '../../../helpers/replaceUnderscoreWithSpace';
import { EPaymentDetailType } from '../../../types/paymentDetail.type';
import styled from 'styled-components';
import { GenreProps } from '../../../types/genre.type';
import {
	TransactionEbookProps,
	TRANSACTION_STATUS_SETTINGS,
} from '../../../types/ebookTransaction.type';
import { EbookProps, initialEbooks } from '../../../types/ebook.type';
import HTMLPreview from '../../../components/HTMLPreview';
import TransactionEbookHistory from './History';

interface IParams {
	transactionEbookId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: TransactionEbookProps;
}

const { Text } = Typography;
const { TabPane } = Tabs;

let formatPrice = Intl.NumberFormat('en-US');

const TransactionEbookDetail: React.FC = () => {
	const { transactionEbookId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [transactionConsultation, setTransactionConsultation] =
		React.useState<TransactionEbookProps>();
	const [payment, setPayment] = React.useState<PaymentProps>();

	const [ebook, setEbook] = React.useState<EbookProps>(initialEbooks);

	React.useEffect(() => {
		const fetchTransactionConsultationDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/transaction-ebooks/' + transactionEbookId,
				);
				setTransactionConsultation(res.data.payload);
				setPayment(res.data.payload.payment);
				// setEbook(res.data.payload?.transactionEbookItems?.[0]?.ebook);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchTransactionConsultationDetail();
	}, [transactionEbookId]);

	const refundPayment =
		payment &&
		payment.paymentDetails &&
		payment?.paymentDetails.find(
			(pay) => pay.paymentType == 'PATIENT_REFUND_RECEIVED',
		);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Transaction Detail"
				subtitle="Detail of your transaction"
			/>
			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<CustomTabs
					centered={true}
					size={'large'}
					tabBarStyle={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<TabPane tab="Detail" key={'1'}>
						<AppCard loading={isLoading}>
							<SectionContent groupTitle="Status">
								<div
									style={
										transactionConsultation?.transactionStatus
											? {
													border:
														'2px solid ' +
														TRANSACTION_STATUS_SETTINGS[
															transactionConsultation?.transactionStatus
														]?.color,
													color:
														TRANSACTION_STATUS_SETTINGS[
															transactionConsultation?.transactionStatus
														]?.color,
													borderRadius: '0',
													display: 'inline',
													padding: '0 10px',
											  }
											: {}
									}
								>
									{transactionConsultation?.transactionStatus
										? TRANSACTION_STATUS_SETTINGS[
												transactionConsultation?.transactionStatus
										  ]?.label
										: '-'}
								</div>
							</SectionContent>
							<Divider />
							<SectionContent groupTitle="E-Book Information">
								<Row gutter={[16, 16]}>
									<Col span={8}>
										<Space
											style={{
												width: '100%',
												background: 'grey',
												justifyContent: 'center',
												marginBottom: '5px',
											}}
										>
											<DetailItem
												type="image"
												label="Cover Ebook"
												content={
													transactionConsultation?.transactionEbookItems?.[0]
														?.ebook?.imageUrl || ''
												}
											/>
										</Space>
									</Col>
									<Col span={16}>
										<DetailItem
											label="Title"
											content={
												transactionConsultation?.transactionEbookItems?.[0]
													?.ebook?.title
											}
										/>
										{/* <DetailItem
											label="Genre"
											content={sortTags(
												transactionConsultation?.transactionEbookItems?.[0]
													?.ebook?.genres || [],
											)
												.map((item) => item.genreName)
												.join(', ')}
										/> */}
										<Row gutter={[16, 16]}>
											<Col span={8}>
												<DetailItem
													label="Author"
													content={
														transactionConsultation?.transactionEbookItems?.[0]
															?.ebook?.author
													}
												/>
											</Col>
											<Col span={8}>
												<DetailItem
													label="Published"
													content={formatDate(
														transactionConsultation?.transactionEbookItems?.[0]
															?.ebook?.publicationDate,
													)}
												/>
											</Col>
											<Col span={8}>
												<DetailItem
													label="ISBN"
													content={
														transactionConsultation?.transactionEbookItems?.[0]
															?.ebook?.bookNumber
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<DetailItem
									label="Description"
									content={
										<HTMLPreview
											html={
												transactionConsultation?.transactionEbookItems?.[0]
													?.ebook?.description ?? ''
											}
										/>
									}
								/>
							</SectionContent>

							<Divider />

							{transactionConsultation?.transactionStatus ==
								'CANCELED_BY_USER' ||
							transactionConsultation?.transactionStatus == 'REJECTED' ? (
								<>
									<SectionContent groupTitle="Refund Detail">
										<Row>
											<Col span={16}>
												<h4>Cancelation Fee (0%)</h4>
											</Col>
											<Col span={8} style={{ textAlign: 'right' }}>
												<Text style={{ fontWeight: 'bold' }}>
													₱{refundPayment?.totalPay}
												</Text>
											</Col>
										</Row>
										<Divider />
										<Row>
											<Col span={16}>
												<h4>Total</h4>
											</Col>
											<Col span={8} style={{ textAlign: 'right' }}>
												<Text style={{ fontWeight: 'bold' }}>
													₱{refundPayment?.totalPay}
												</Text>
											</Col>
										</Row>
									</SectionContent>
									<Divider />
								</>
							) : null}

							<SectionContent groupTitle="Buyer">
								<DetailItem
									label="Name"
									content={
										transactionConsultation?.metaUser?.name ||
										getFullName(transactionConsultation?.metaUser)
									}
								/>
							</SectionContent>
							<Divider />
							<SectionContent groupTitle="Payment Detail">
								{payment &&
									payment.paymentDetails &&
									payment.paymentDetails
										?.filter(
											(item: any) => item.paymentType !== 'PAYMENT_GATEWAY',
										)
										?.map((item) => {
											return (
												<Row key={item.paymentDetailId}>
													<Col span={16}>
														<h4>
															{item?.paymentType !==
																'PATIENT_REFUND_RECEIVED' &&
																replaceUnderscoreWithSpace(item.paymentType)}
														</h4>
													</Col>
													<Col span={8} style={{ textAlign: 'right' }}>
														{item.type === EPaymentDetailType.PAYMENT ? (
															<Text style={{ fontWeight: 'bold' }}>
																{item.paymentType !==
																	'PATIENT_REFUND_RECEIVED' &&
																	'₱' + formatPrice.format(item.totalPay)}
															</Text>
														) : (
															<Text style={{ color: 'red' }}>
																-₱{formatPrice.format(item.totalPay)}
															</Text>
														)}
													</Col>
												</Row>
											);
										})}

								<Divider />

								<Row>
									<Col span={16}>
										<Text>Total</Text>
									</Col>
									<Col span={8} style={{ textAlign: 'right' }}>
										{payment ? (
											<Text style={{ fontWeight: 'bold' }}>
												₱{formatPrice.format(payment.totalNeedToPay)}
											</Text>
										) : (
											<Text>No Payment Has Been Made</Text>
										)}
									</Col>
								</Row>
							</SectionContent>

							<Divider />
						</AppCard>
					</TabPane>
					<TabPane
						tab="History"
						key={'2'}
						children={
							<TransactionEbookHistory
								data={transactionConsultation}
								payment={payment}
							/>
						}
					/>
				</CustomTabs>
			</div>
		</div>
	);
};
export const CustomTabs = styled(Tabs)`
	.ant-tabs-nav-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.ant-tabs-tab {
		width: 50%;
		justify-content: center;
	}
`;
export default TransactionEbookDetail;
function sortTags(tags: GenreProps[]) {
	tags.sort((a, b) => a.genreName.localeCompare(b.genreName));
	return tags;
}
