import {
	Col,
	Row,
	Image,
	Tag,
	Modal,
	Table,
	Divider,
	Button,
	Typography,
	message,
	List,
	InputNumber,
} from 'antd';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import {
	BaseResponsePaginationProps,
	BaseResponseProps,
} from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import DetailItem from '../../components/DetailItem';
import { DoctorProps, initialDoctor } from '../../types/doctor.type';
import {
	EKycStatus,
	initialKycHistory,
	KycHistoryProps,
} from '../../types/kycHistory.type';
import { generateQueryString } from '../../helpers/generateQueryString';
import styled from 'styled-components';
import { theme } from '../../assets/theme';
import KycTag from '../../components/KycTag';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { getFullName, getNickName } from '../../helpers/name';
import {
	EHistoryType,
	EPointCategory,
	EStatus,
	PointHistoryProps,
	PointProps,
} from '../../types/point.type';
import AppButton from '../../components/AppButton';
import ModalTransactionFilter from '../../components/PointHistory';
import useAuth from '../../hooks/useAuth';

interface IParams {
	doctorId: string;
	submissionId?: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<DoctorProps, 'updatedAt'>;
}

interface PointResponseProps extends BaseResponseProps {
	payload: Omit<PointProps, 'updatedAt'>;
}

enum Nav {
	detail,
	verificationDetail,
	pointHistory,
}

const { Text } = Typography;

const DoctorDetail: React.FC = () => {
	const history = useHistory();
	const { user } = useAuth();
	const { doctorId, submissionId } = useParams<IParams>();

	const [nav, setNav] = React.useState(Nav.detail);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState(false);

	const [doctor, setDoctor] = React.useState<DoctorProps>(initialDoctor);

	const [selectedKycStatus, setSelectedKycStatus] = React.useState(
		doctor.kycStatus,
	);
	const [kycHistories, setKycHistories] = React.useState<
		Array<KycHistoryProps>
	>([]);
	const [kycHistory, setKycHistory] =
		React.useState<KycHistoryProps>(initialKycHistory);
	const [kycHistoryDetail, setKycHistoryDetail] =
		React.useState<KycHistoryProps>();

	const [point, setPoint] = React.useState<PointProps>();
	const [pointHistories, setPointHistories] = React.useState<
		PointHistoryProps[]
	>([]);

	const [isModalRejectVisible, setIsModalRejectVisible] = React.useState(false);
	// const [isModalApprovedVisible, setIsModalApprovedVisible] = React.useState(false);
	const [rejectReason, setRejectReason] = React.useState('');

	const [groupingSpecialist, setGroupingSpecialist] = React.useState<any[]>([]);

	const [isModalEditPoint, setIsModalEditPoint] = React.useState(false);
	const [editPointMode, setEditPointMode] = React.useState<string>();
	const [amountEditPoint, setAmountEditPoint] = React.useState<number>(0);
	const [noteEditPoint, setNoteEditPoint] = React.useState<string>();

	const [isModalFilterPoint, setIsModalFilterPoint] = React.useState(false);
	const [filterList, setFilterList] = React.useState<any>({});

	React.useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const res = await httpRequest.get<ResponseProps>(
					'/doctors/' + doctorId,
				);
				setSelectedKycStatus(res.data.payload.kycStatus);
				setDoctor(res.data.payload);

				if (!submissionId) {
					const resKyc = await httpRequest.get<
						BaseResponsePaginationProps<KycHistoryProps>
					>(`/kyc-histories${generateQueryString({ doctorId })}`);
					setKycHistories(resKyc.data.payload.results);
				} else {
					setNav(Nav.detail);
					const resKyc = await httpRequest.get<any>(
						`/kyc-histories/detail/${submissionId}`,
					);
					setKycHistoryDetail(resKyc.data.payload);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [doctorId, submissionId, selectedKycStatus]);

	const fetchCurrentPoint = async () => {
		try {
			setIsLoading(true);

			const resPoint = await httpRequest.get<PointResponseProps>(
				`/points/${doctorId}`,
			);
			if (resPoint && resPoint.data.payload) {
				setPoint(resPoint.data.payload);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchCurrentPoint();
	}, [doctorId]);

	const fetchPointHistory = async (filterList?: any) => {
		try {
			setIsLoading(true);
			console.log(filterList, 'filterList');

			const resPointHistory = await httpRequest.get<
				BaseResponsePaginationProps<PointHistoryProps>
			>(
				`/points/${doctorId}/history${generateQueryString({
					...filterList,
					limit: 1000000,
				})}`,
			);
			if (resPointHistory && resPointHistory.data.payload) {
				setPointHistories(resPointHistory.data.payload.results);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchPointHistory();
	}, [doctorId]);

	React.useEffect(() => {
		console.log(filterList, 'filterList');
		fetchPointHistory(filterList);
	}, [filterList]);

	useEffect(() => {
		let newGroupSpecialists = doctor.specialists?.reduce(function (
			storage: any,
			item: any,
		) {
			const data = {
				specialistId: item.specialistId || '',
				specialistName: item.specialistName || '',
				subSpecialists:
					item.subSpecialistId || item.subSpecialistName
						? [
								{
									subSpecialistId: item.subSpecialistId || '',
									subSpecialistName: item.subSpecialistName || '',
								},
						  ]
						: [],
			};

			let group = item['specialistId'];
			if (!group) {
				group = item['specialistName'];
			}

			storage[group] = storage[group] || {};
			const storageLength = Object.keys(storage[group]).length;
			if (storageLength > 0) {
				storage[group] = {
					...storage[group],
					subSpecialists: [
						...(storage[group].subSpecialists || []),
						...(data.subSpecialists || []),
					],
				};
			} else {
				storage[group] = data;
			}
			return storage;
		}, {});

		setGroupingSpecialist(newGroupSpecialists);
	}, [doctor.specialists]);

	const handleChangeStatus = (status: any) => {
		Modal.confirm({
			title: 'Confirmation',
			content: 'Are you sure want to change kyc status?',
			onOk: async () => {
				setSelectedKycStatus(status);
				await httpRequest.patch(`/doctors/${doctorId}`, {
					...doctor,
					kycStatus: status,
					note: status == 'REJECTED' ? rejectReason : '',
				});
				message.success('Success change status');
				setRejectReason('');
				Modal.destroyAll();
			},
			okText: 'Update Status',
			okType: 'primary',
		});
	};

	const kycColumns = [
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: string, record: KycHistoryProps) => (
				<Link
					to={`/doctor/${doctorId}/submisson/${record.id}`}
					type="button"
					className="table-link"
				>
					{moment(text).format('DD MMM YYYY, hh:mm:ss')}
				</Link>
			),
		},
		{
			title: 'STATUS',
			dataIndex: 'kycStatus',
			key: 'kycStatus',
			render: (text: string) => <KycTag>{text}</KycTag>,
		},
		{
			title: 'VERIFICATOR',
			key: 'updatedByAdminDetail',
			dataIndex: 'updatedByAdminDetail',
			render: (text: string, record: KycHistoryProps) => {
				return <Tag>{record.updatedByAdminDetail.name}</Tag>;
			},
		},
		{
			title: 'REASON',
			key: 'reason',
			dataIndex: 'reason',
			render: (text: string, record: KycHistoryProps) => {
				return record.kycStatus == 'REJECTED' ? <p>{record.note}</p> : '';
			},
		},
	];

	const pointHistoryColumns = [
		{
			title: 'CATEGORY',
			dataIndex: 'category',
			width: '20%',
			key: 'category',
			render: (text: string, record: PointHistoryProps) => (
				<Text>
					{record.category ? (
						record.category?.replace(/_/g, ' ')
					) : (
						<Text italic type="secondary">
							Not set
						</Text>
					)}
				</Text>
			),
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			width: '15%',
			key: 'status',
			render: (text: string, record: PointHistoryProps) => {
				const statusColor = () => {
					switch (record.status) {
						case 'PENDING':
							return {
								bgColor: '#FCF1E3',
								color: '#DA7B11',
							};
						case 'EXPIRED':
							return {
								bgColor: '#FCF1E3',
								color: '#DA7B11',
							};
						case 'AVAILABLE':
							return {
								bgColor: '#D7F4EB',
								color: '#268E6C',
							};
					}
				};
				return (
					<Text
						style={{
							backgroundColor: statusColor()?.bgColor,
							color: statusColor()?.color,
							borderColor: statusColor()?.color,
							padding: 4,
							borderRadius: 5,
							borderWidth: 1,
							borderStyle: 'solid',
						}}
					>
						{record.status ? (
							record.status
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'AMOUNT',
			dataIndex: 'amount',
			width: '10%',
			key: 'amount',
			render: (text: string, record: PointHistoryProps) => {
				return (
					<Text
						style={{
							color: record.amount < 0 ? '#D82148' : '#00C897',
						}}
					>
						{record.amount < 0 ? '' : '+'} {record.amount}
					</Text>
				);
			},
		},
		{
			title: 'NOTE',
			dataIndex: 'note',
			width: '40%',
			key: 'note',
			render: (text: string, record: PointHistoryProps) => {
				return (
					<Text>
						{record.note ? (
							record.note
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			width: '15%',
			key: 'createdAt',
			render: (text: string) => (
				<span>{moment(text).format('MMMM DD, YYYY')}</span>
			),
		},
	];

	function handleClickEdit() {
		history.push('/doctor/' + doctorId + '/edit');
	}

	function handleClickReject() {
		setRejectReason('');
		setIsModalRejectVisible(true);
	}

	function handleClickApprove() {
		handleChangeStatus('APPROVED');
	}

	function handleConfirmReject() {
		setIsModalRejectVisible(false);
		handleChangeStatus('REJECTED');
	}

	async function handleConfirmEditPoint() {
		try {
			setIsLoadingAction(true);

			const resEditPoint = await httpRequest.post<PointResponseProps>(
				`/points/${doctorId}/history`,
				{
					bulk: [
						{
							type:
								editPointMode === 'increase'
									? EHistoryType.INCOME
									: EHistoryType.OUTCOME,
							category:
								editPointMode === 'increase'
									? EPointCategory.INCREASE_POINT
									: EPointCategory.DECREASE_POINT,
							metaUser: user,
							amount:
								editPointMode === 'increase'
									? amountEditPoint
									: -amountEditPoint,
							status: EStatus.AVAILABLE,
							note: noteEditPoint,
						},
					],
				},
			);
			if (resEditPoint && resEditPoint.data.payload) {
				message.success(
					`Success ${
						editPointMode === 'increase' ? 'increase' : 'decrease'
					} doctor point`,
				);
			}
		} catch (error) {
			console.error(error);
		} finally {
			fetchCurrentPoint();
			fetchPointHistory();
			setAmountEditPoint(0);
			setNoteEditPoint(undefined);
			setEditPointMode(undefined);
			setIsModalEditPoint(false);
			setIsLoadingAction(false);
		}
	}

	function handleCancelReject() {
		setIsModalRejectVisible(false);
		setRejectReason('');
	}

	return (
		<>
			<div>
				<HeaderSection
					icon="back"
					title={`${doctor.title} - ${
						getFullName(doctor.user) +
						' ' +
						(doctor.user ? '(' + getNickName(doctor.user) + ')' : '')
					}`}
					// subtitle=""
					rightAction={
						!submissionId &&
						nav === Nav.detail && (
							<>
								{doctor.kycStatus === EKycStatus.WAITING_APPROVAL && (
									<Button
										onClick={handleClickReject}
										size="large"
										style={{ margin: '0px 5px' }}
									>
										Reject
									</Button>
								)}
								{doctor.kycStatus === EKycStatus.WAITING_APPROVAL && (
									<Button
										onClick={handleClickApprove}
										size="large"
										style={{
											margin: '0px 5px',
											backgroundColor: theme.colors.primary,
											color: theme.colors.white,
										}}
									>
										Approve
									</Button>
								)}
								<Button
									onClick={handleClickEdit}
									size="large"
									style={{
										marginLeft: 25,
										backgroundColor: theme.colors.primary,
										color: theme.colors.white,
									}}
								>
									Edit
								</Button>
							</>
						)
					}
				/>
				{!submissionId ? (
					<SectionNav>
						<SectionNavItem
							onClick={() => setNav(Nav.detail)}
							style={
								nav === Nav.detail
									? {
											borderBottomStyle: 'solid',
									  }
									: { marginBottom: 3 }
							}
						>
							Detail
						</SectionNavItem>
						<SectionNavItem
							onClick={() => setNav(Nav.verificationDetail)}
							style={
								nav === Nav.verificationDetail
									? {
											borderBottomStyle: 'solid',
									  }
									: { marginBottom: 3 }
							}
						>
							Verification History
						</SectionNavItem>
						<SectionNavItem
							onClick={() => setNav(Nav.pointHistory)}
							style={
								nav === Nav.pointHistory
									? {
											borderBottomStyle: 'solid',
									  }
									: { marginBottom: 3 }
							}
						>
							Point
						</SectionNavItem>
					</SectionNav>
				) : undefined}
				{nav === Nav.detail && (
					<AppCard loading={isLoading}>
						{submissionId && kycHistoryDetail ? (
							<>
								<SectionContent
									groupTitle="Verification Detail"
									helpers={[
										{
											title: 'Information',
											content:
												'Tag name must be unique, system will reject if tag has been created with the same name',
										},
									]}
								>
									<DetailItem
										label="Date"
										content={moment(kycHistoryDetail.updatedAt).format(
											'DD MMM YYYY, hh:mm:ss',
										)}
									/>

									<DetailItem
										label="Result"
										content={<KycTag>{kycHistoryDetail.kycStatus}</KycTag>}
									/>
									<DetailItem
										label="Verificator"
										content={kycHistoryDetail.updatedByAdminDetail.name}
									/>
									<DetailItem label="Reason" content={kycHistoryDetail.note} />
								</SectionContent>
								<Divider />
							</>
						) : (
							<SectionContent groupTitle="Status">
								<KycTag>{selectedKycStatus}</KycTag>
							</SectionContent>
						)}
						<Divider />
						<SectionContent
							groupTitle="Account Information"
							helpers={[
								{
									title: 'Information',
									content:
										"Once you Approved the KycStatus or Cancel you can't change it again",
								},
							]}
						>
							<Row>
								<Col span={11}>
									<DetailItem label="Name" content={getFullName(doctor.user)} />
								</Col>
								<Col span={11}>
									<DetailItem
										label="Nickname"
										content={doctor.user?.nickName}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={11}>
									<DetailItem
										label="Phone Number"
										content={doctor.user?.phone}
									/>
								</Col>
								<Col span={11}>
									<DetailItem
										label="Gender"
										content={
											doctor.user?.gender
												? (doctor.user?.gender[0] as string).toUpperCase() +
												  (doctor.user?.gender as string).slice(1)
												: undefined
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={11}>
									<DetailItem
										label="Birthday"
										content={doctor.user?.birthdate}
									/>
								</Col>
								<Col span={11}>
									<DetailItem
										label="Email Address"
										content={doctor.user?.email}
									/>
								</Col>
							</Row>
						</SectionContent>
						<Divider />
						<SectionContent groupTitle="Doctor Information">
							<Row>
								<Col span={8}>
									<DetailItem label="Doctor Title" content={doctor.title} />
								</Col>
								<Col span={8}>
									<DetailItem
										label="Experience"
										content={
											doctor.experience
												? doctor.experience + ' Years'
												: undefined
										}
									/>
								</Col>
							</Row>
							<Row>
								{(doctor.specialists || []).length > 0 ? (
									(Object.keys(groupingSpecialist) || [])?.map(
										(specialistId: any, index: number) => {
											const specialist = groupingSpecialist[specialistId];

											return (
												<Col span={8}>
													<DetailItem
														label={'Specialization'}
														content={
															<>
																<Col>{specialist.specialistName}</Col>
																{specialist.subSpecialists?.map((item: any) => (
																	<Col
																		style={{
																			marginLeft: 25,
																		}}
																	>
																		{item.subSpecialistName}
																	</Col>
																))}
															</>
														}
													/>
												</Col>
											);
										},
									)
								) : (
									<DetailItem label={'Specialization'} content={''} />
								)}
							</Row>

							<DetailItem
								label="Other sub specialization"
								content={doctor.otherSpecialist}
							/>
						</SectionContent>
						<Divider />
						<SectionContent groupTitle="Document Verification">
							<Row>
								<Col span={8}>
									<DetailItem label="PRC Number" content={doctor.prcNumber} />
								</Col>
								<Col span={8}>
									<DetailItem label="PTR Number" content={doctor.ptrNumber} />
								</Col>
							</Row>
							<Row>
								<Col span={8}>
									<DetailItem
										label="Name of Signature"
										content={doctor.nameOfSignature}
									/>
								</Col>
								<Col span={8}>
									<DetailItem
										label="Tax Identification Number (TIN)"
										content={doctor.tinNumber}
									/>
								</Col>
							</Row>

							<DetailItem
								label="Signature"
								content={
									doctor.signatureImageUrl ? (
										<Image
											width={150}
											height={90}
											src={doctor.signatureImageUrl || ''}
											fallback={'/images/blur-image.jpeg'}
											style={{ objectFit: 'cover' }}
											placeholder={
												<Image
													preview={false}
													src="/images/blur-image.jpeg"
													width={150}
													height={90}
													style={{
														objectFit: 'cover',
													}}
												/>
											}
										/>
									) : undefined
								}
							/>
							<DetailItem
								label="Educations"
								content={
									doctor.educations && doctor.educations.length > 0 ? (
										<List
											style={{
												display: 'flex',
												alignItems: 'flex-start',
											}}
											dataSource={doctor.educations}
											renderItem={(item) => (
												<List.Item>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
														}}
													>
														<Text>{item.stage}</Text>
														<Text type="secondary">{item.institutionName}</Text>
														<Text
															type="secondary"
															style={{
																fontSize: 12,
															}}
														>{`${item.startYear} - ${item.endYear}`}</Text>
													</div>
												</List.Item>
											)}
										/>
									) : undefined
								}
							/>
							<DetailItem
								label="Certificates"
								content={
									doctor.certificates && doctor.certificates.length > 0
										? doctor.certificates.map((certificate) => (
												<div
													style={{
														marginBottom: 10,
													}}
												>
													<Image
														width={150}
														height={90}
														src={certificate.url || ''}
														fallback={'/images/blur-image.jpeg'}
														style={{
															objectFit: 'cover',
															borderRadius: 5,
														}}
														placeholder={
															<Image
																preview={false}
																src="/images/blur-image.jpeg"
																width={150}
																height={90}
																style={{
																	objectFit: 'cover',
																}}
															/>
														}
													/>
													<Text
														style={{
															marginLeft: 10,
														}}
													>
														{certificate.name}
													</Text>
												</div>
										  ))
										: undefined
								}
							/>
						</SectionContent>
					</AppCard>
				)}
				{nav === Nav.verificationDetail && (
					<Table
						loading={isLoading}
						columns={kycColumns}
						dataSource={kycHistories}
						pagination={false}
					/>
				)}
				{nav === Nav.pointHistory && (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Row style={{ justifyContent: 'space-between', margin: '10px 0' }}>
							<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
								<Col style={{ alignItems: 'center', marginRight: 20 }}>
									<Text
										style={{
											marginTop: 10,
											marginBottom: 10,
										}}
									>
										Medeasy Point
									</Text>
									<div>
										<Text
											style={{
												fontWeight: 'bold',
												marginTop: 10,
												marginBottom: 10,
												fontSize: 18,
											}}
										>
											{`${point?.currentAmount.toFixed(2)}`}
										</Text>
										{point && point.pendingAmount > 0 && (
											<Text
												style={{
													marginTop: 10,
													marginBottom: 10,
												}}
											>
												{` + ${point.pendingAmount.toFixed(2)} (Pending)`}
											</Text>
										)}
									</div>
								</Col>
								<Col>
									<AppButton
										type="primary"
										style={{
											marginTop: 10,
											marginBottom: 10,
										}}
										onClick={() => {
											setAmountEditPoint(0);
											setNoteEditPoint(undefined);
											setEditPointMode('increase');
											setIsModalEditPoint(true);
										}}
									>
										Increase Point
									</AppButton>
								</Col>
								<Col>
									<AppButton
										type="primary"
										style={{
											marginTop: 10,
											marginBottom: 10,
										}}
										onClick={() => {
											setAmountEditPoint(0);
											setNoteEditPoint(undefined);
											setEditPointMode('decrease');
											setIsModalEditPoint(true);
										}}
									>
										Decrease Point
									</AppButton>
								</Col>
							</Row>
							<AppButton
								type="primary"
								style={{
									marginTop: 10,
									marginBottom: 10,
								}}
								onClick={() => setIsModalFilterPoint(true)}
							>
								Filter
							</AppButton>
						</Row>
						<Table
							loading={isLoading}
							columns={pointHistoryColumns}
							dataSource={pointHistories}
							pagination={false}
						/>
						<ModalTransactionFilter
							visible={isModalFilterPoint}
							setVisible={setIsModalFilterPoint}
							filterList={filterList}
							setFilterList={setFilterList}
						/>
					</div>
				)}
			</div>
			<Modal
				title="Reject"
				visible={isModalRejectVisible}
				onOk={handleConfirmReject}
				onCancel={handleCancelReject}
			>
				<TextAreaTitle>Reason</TextAreaTitle>
				<TextArea
					rows={4}
					value={rejectReason !== null ? rejectReason : undefined}
					onChange={(e) => setRejectReason(e.target.value)}
					placeholder="Input the reason of rejection"
				/>
			</Modal>
			<Modal
				title={
					editPointMode === 'increase' ? 'Increase Point' : 'Decrease Point'
				}
				visible={isModalEditPoint}
				onOk={() => {
					if (!amountEditPoint) {
						message.error('Amount is required');
					} else if (!noteEditPoint) {
						message.error('Note is required');
					} else {
						handleConfirmEditPoint();
					}
				}}
				confirmLoading={isLoadingAction}
				onCancel={() => {
					setEditPointMode(undefined);
					setAmountEditPoint(0);
					setNoteEditPoint(undefined);
					setIsModalEditPoint(false);
				}}
			>
				<div style={{ marginBottom: 20 }}>
					<Text>
						{editPointMode === 'increase' ? 'Increase' : 'Decrease'} Amount
					</Text>
					<InputNumber
						style={{
							width: '100%',
						}}
						value={amountEditPoint}
						onChange={(e: any) => setAmountEditPoint(e)}
					/>
				</div>
				<div>
					<Text>Note</Text>
					<TextArea
						rows={4}
						value={noteEditPoint}
						onChange={(e) => setNoteEditPoint(e.target.value)}
						placeholder={`Input note / reason ${
							editPointMode === 'increase' ? 'increase' : 'decrease'
						} point`}
					/>
				</div>
			</Modal>
		</>
	);
};

const SectionNav = styled.div`
	display: flex;
	background-color: ${(props) => props.theme.colors.white};
	border-radius: 5px;
`;

const SectionNavItem = styled.button`
	padding: 10px;
	flex: 1;
	border-bottom-color: ${(props) => props.theme.colors.primary};
`;

const TextAreaTitle = styled.button`
	font-size: 12px;
	color: ${(props) => props.theme.colors.charcoal400};
`;

export default DoctorDetail;
