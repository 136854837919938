import { Space, Typography } from 'antd';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { IPagination } from '../../helpers/pagination';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { CategoryProps } from '../../types/category.type';
import { BaseResponseProps } from '../../types/config.type';
import { DrugMedicineProps, initialMedicine } from '../../types/medicine.type';
import { FetchOnePartnerResponse } from '../../types/partner.type';
import useFetchList from '../../hooks/useFetchList';
import {
	ERewardPointPatientDoctor,
	RewardProps,
} from '../../types/rewardPointSettings.type';

interface IParams {
	drugMedicineId: string;
}

const { Text } = Typography;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<DrugMedicineProps, 'createdAt' | 'updatedAt'>;
}

interface ILocation {
	pagination: IPagination;
}

const MedicineDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();

	const { drugMedicineId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [medicine, setMedicine] =
		React.useState<DrugMedicineProps>(initialMedicine);
	const [pharmacyName, setPharmacyName] = React.useState<string>('');
	const [genericPoint, setGenericPoint] = React.useState(0);

	React.useEffect(() => {
		const fetchMedicineDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/drug-medicines/' + drugMedicineId,
				);
				setMedicine(res.data.payload);
				// setIsLoading(false);

				if (res.data.payload.partnerId) {
					const resPharmacy = await httpRequest.get<FetchOnePartnerResponse>(
						'/partners/' + res.data.payload.partnerId,
					);
					setPharmacyName(resPharmacy.data.payload.partnerName);
				}
				setIsLoading(false);

				const bcDetails = [
					{
						field: 'drugMedicineId',
						value: drugMedicineId,
						label: res.data.payload.drugMedicineName
							? res.data.payload.drugMedicineName
							: drugMedicineId,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetchMedicineDetail();
	}, [drugMedicineId]);

	const { data: dataReward } = useFetchList<RewardProps>({
		endpoint: 'app-configs',
		limit: Object.keys(ERewardPointPatientDoctor).length,
		initialQuery: { keys: Object.keys(ERewardPointPatientDoctor).join(',') },
	});

	React.useEffect(() => {
		dataReward.map((item) => {
			if (
				item.configId ===
				'REWARD_POINT_POINT_GENERIC_MEDICINE_PER_QUANTITY_DOCTOR'
			) {
				setGenericPoint(Number(item.value));
			}
		});
	}, [dataReward]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Medicine"
				subtitle="Manage your medicine data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => {
								history.push({
									pathname: '/medicine/' + drugMedicineId + '/edit',
									state: { pagination: location.state.pagination },
								});
							}}
						>
							Edit
						</AppButton>
						<AppButton
							type="primary"
							onClick={() => {
								history.push(`/medicine/${drugMedicineId}/history/`);
							}}
						>
							History
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="Medicine Data"
					helpers={[
						{
							title: 'Information',
							content:
								'Tag name must be unique, system will reject if tag has been created with the same name',
						},
					]}
				>
					<DetailItem label="Name" content={medicine.drugMedicineName} />

					<DetailItem
						label="Dosage"
						content={
							medicine.doses && medicine.doses?.length > 0 ? (
								medicine.doses?.map((item, index) => <div>{item.dose}</div>)
							) : (
								<Text italic type="secondary">
									Not set
								</Text>
							)
						}
					/>

					<DetailItem label="Dose" content={medicine.dose} />

					<DetailItem label="Dose Adjustment" content={medicine.doseNote} />

					<DetailItem
						label="Medicine Tag"
						content={sortCategory(medicine.categories || [])
							.map((item) => item.categoryName)
							.join(', ')}
					/>

					<DetailItem
						label="Pharmacy"
						content={
							pharmacyName ? (
								pharmacyName
							) : (
								<Text italic type="secondary">
									Not set
								</Text>
							)
						}
					/>

					<DetailItem
						label="Points"
						content={
							pharmacyName ? (
								medicine.points ? (
									medicine.points
								) : (
									<Text italic type="secondary">
										Not set
									</Text>
								)
							) : (
								genericPoint
							)
						}
					/>

					<DetailItem label="Formulary" content={medicine.compositions} />

					<DetailItem label="Indications" content={medicine.indications} />

					<DetailItem
						label="Contra Indications"
						content={medicine.contraIndications}
					/>

					<DetailItem
						label="Adverse Drug Reactions"
						content={medicine.adverseDrugReactions}
					/>

					<DetailItem
						label="Administrations"
						content={medicine.administration}
					/>

					<DetailItem
						label="Pregnancy Category"
						content={medicine.pregnancyCategory}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default MedicineDetail;

function sortCategory(categories: CategoryProps[]) {
	categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
	return categories;
}
