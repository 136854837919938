import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import {
	TransactionConsultationProps,
	TRANSACTION_STATUS_SETTINGS,
} from '../../../types/transactionConsultation.type';

interface historyProps {
	data?: TransactionConsultationProps;
	payment: any;
}
interface status {
	newData: {
		transactionStatus: string;
	};
}

const TransactionConsultationHistory: React.FC<historyProps> = ({
	data,
	payment,
}) => {
	let formatPrice = Intl.NumberFormat('en-US');

	const historyColumns = [
		{
			title: 'PARTICIPANTS',

			render: () => (
				<div>
					{data?.metaPatient?.name}
					<br />
					<p>{data?.metaDoctor?.nameOfSignature}</p>
				</div>
			),
		},
		{
			title: 'CREATED AT',
			key: 'historyId',
			render: (record: TransactionConsultationProps) => (
				<p> {moment(record.updatedAt).format('DD MMM YYYY, HH:mm:ss')}</p>
			),
		},
		{
			title: 'STATUS',

			render: (record: status) => {
				return (
					<div
						style={
							record.newData.transactionStatus
								? {
										border:
											'2px solid ' +
											TRANSACTION_STATUS_SETTINGS[
												record.newData.transactionStatus
											]?.color,
										color:
											TRANSACTION_STATUS_SETTINGS[
												record.newData.transactionStatus
											]?.color,
										borderRadius: '0',
										display: 'inline',
										padding: '0 10px',
								  }
								: {}
						}
					>
						{record.newData.transactionStatus}
					</div>
				);
			},
		},
		{
			title: 'TOTAL PAY',

			render: () => <p>₱{formatPrice.format(payment.totalNeedToPay)}</p>,
		},
	];

	return (
		<Table
			columns={historyColumns}
			dataSource={data?.history?.slice().reverse()}
			pagination={false}
		/>
	);
};

export default TransactionConsultationHistory;
