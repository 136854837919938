import { BaseResponseProps } from './config.type';
import { CustomerProps } from './customer.type';

export enum AuthTypes {
	AUTH_SIGNIN = 'AUTH_SIGNIN',
	AUTH_SIGNOUT = 'AUTH_SIGNOUT',
	AUTH_LOADING = 'AUTH_LOADING',
	USER_LOADING = 'USER_LOADING',
	USER_LOADING_ACTION = 'USER_LOADING_ACTION',
	SET_USER_DATA = 'SET_USER_DATA',
}

export interface ILoginData {
	email: string;
	password: string;
}

export type AuthState = {
	user: CustomerProps;
	isLoading: boolean;
	isLoggedIn: boolean;
	isLoadingUser: boolean;
	isLoadingUserAction: boolean;
};

export interface SignInProps {
	email: string;
	password: string;
}

export interface ForgotPasswordProps {
	email: string;
}

export interface ResetPasswordProps {
	newPassword: string;
}

export interface SignInResponseProps extends BaseResponseProps {
	payload: {
		token: string;
	};
}

export interface ForgotPasswordResponseProps extends BaseResponseProps {
	payload: {
		email: string;
		isSuccess: boolean;
	};
}

export interface ResetPasswordResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}
