import { AppstoreAddOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Col,
	Form,
	Input,
	Modal,
	Pagination,
	Radio,
	Row,
	Table,
	Typography,
	message,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { IPagination, IQuery } from '../../helpers/pagination';
import useFetchList from '../../hooks/useFetchList';
import { useForm } from 'antd/es/form/Form';
import { generateFormRules } from '../../helpers/formRules';
import {
	NotificationHistoriesProps,
	initialNotificationHistories,
} from '../../types/notification.type';
import { httpRequest } from '../../helpers/api';
import { getFullName } from '../../helpers/name';

const { Text } = Typography;

interface ILocation {
	pagination: IPagination;
}

const Notification = () => {
	const location = useLocation<ILocation>();

	const [form] = useForm();

	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const [isShowModalSendNotification, setIsShowModalSendNotification] =
		React.useState<boolean>(false);
	const [
		isShowModalConfirmSendNotification,
		setIsShowModalConfirmSendNotification,
	] = React.useState<boolean>(false);

	const [search, setSearch] = React.useState<string>();
	const [searchValue] = useDebounce(search, 500);

	const { isLoading, data, pagination, setQuery, changePage, fetchList } =
		useFetchList<NotificationHistoriesProps>({
			endpoint: 'notifications/histories',
			limit: 10,
		});

	React.useEffect(() => {
		if (location.state?.pagination) {
			changePage(
				location.state.pagination.page,
				location.state.pagination.size,
			);
		}
	}, [location]);

	React.useEffect(() => {
		setQuery((e: IQuery) => {
			return {
				...e,
				search: searchValue as string,
			};
		});
	}, [searchValue]);

	const handleSendNotification = async (
		values: Omit<NotificationHistoriesProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.post('/notifications/custom-send-notification', {
				title: values.title,
				message: values.message,
				target: values.target,
			});
			message.success(`Success send notification to ${values.target}`);
		} catch (error) {
			console.log(error);
			message.error(`Failed send notification to ${values.target}`);
		} finally {
			setIsLoadingAction(false);
			setIsShowModalSendNotification(false);
			fetchList();
		}
	};

	const columns = [
		{
			title: 'TITLE',
			dataIndex: 'title',
			key: 'title',
			render: (title: string) => {
				return (
					<>
						{title ? (
							<Text>{title}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'MESSAGE',
			dataIndex: 'message',
			key: 'message',
			render: (message: string) => {
				return (
					<>
						{message ? (
							<Text>{message}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'CREATED BY USER',
			dataIndex: 'metaCreatedByUser',
			key: 'metaCreatedByUser',
			render: (metaCreatedByUser: any) => {
				return (
					<>
						{metaCreatedByUser ? (
							<Text>{getFullName(metaCreatedByUser)}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'TARGET',
			dataIndex: 'target',
			key: 'target',
			render: (target: string) => {
				return (
					<>
						{target ? (
							<Text>{target}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'TOTAL SEND USER',
			dataIndex: 'meta',
			key: 'meta',
			render: (meta: any) => {
				return (
					<>
						{meta ? (
							<Text>{meta?.totalSendUser}</Text>
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
	];

	return (
		<div>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						icon={<AppstoreAddOutlined />}
						title="Notifications"
						subtitle="Manage your Notifications"
					/>
				</Col>
				<Col flex={1}>
					<AppButton
						type="primary"
						onClick={() => setIsShowModalSendNotification(true)}
					>
						Send Notification To All User
					</AppButton>
				</Col>
			</Row>

			<ContainerFilter>
				<Input
					size="large"
					placeholder={`Search...`}
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}

			<Modal
				title="Send Notification"
				visible={isShowModalSendNotification}
				onOk={async () => {
					const notError = await form.validateFields();
					if (notError) {
						setIsShowModalConfirmSendNotification(true);
					}
				}}
				onCancel={() => {
					form.resetFields();
					setIsShowModalSendNotification(false);
				}}
				confirmLoading={isLoadingAction}
				okText="Yes"
				okButtonProps={{ type: 'primary' }}
			>
				<div>
					<Form
						form={form}
						name="notificationForm"
						layout="vertical"
						onFinish={handleSendNotification}
						initialValues={initialNotificationHistories}
						autoComplete="off"
					>
						<Form.Item
							rules={generateFormRules('Title', ['required'])}
							label="Title"
							name="title"
						>
							<Input placeholder="Input title" />
						</Form.Item>

						<Form.Item
							rules={generateFormRules('Message', ['required'])}
							label="Message"
							name="message"
						>
							<Input placeholder="Input message" name="message" />
						</Form.Item>

						<Form.Item
							rules={generateFormRules('Target', ['required'])}
							label="Target"
							name="target"
						>
							<Radio.Group>
								<Radio value="All"> All </Radio>
								<Radio value="Doctor"> Doctor </Radio>
								<Radio value="Secretary"> Secretary </Radio>
								<Radio value="Patient"> Patient </Radio>
							</Radio.Group>
						</Form.Item>
					</Form>
				</div>
			</Modal>

			<Modal
				title="Confirmation"
				visible={isShowModalConfirmSendNotification}
				onOk={() => {
					setIsShowModalConfirmSendNotification(false);
					form.submit();
				}}
				onCancel={() => {
					setIsShowModalConfirmSendNotification(false);
				}}
				okText="Yes"
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to send this notification to all user ?</p>
			</Modal>
		</div>
	);
};

export default Notification;

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
