import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	CreditCardOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Pagination,
	Table,
	Modal,
	message,
	Input,
	Typography,
	Row,
	Col,
	Button,
	Tabs,
	Dropdown,
	Menu,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import { formatDate } from '../../helpers/constant';
import {
	BankAccountProps,
	BankListProps,
	EBankStatus,
	FetchAllBankListResponse,
	initialBankAccount,
} from '../../types/bank.type';
import TextArea from 'antd/lib/input/TextArea';
import { IUser } from '../../types/user.type';

const { Text } = Typography;
const { TabPane } = Tabs;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<BankAccountProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const BankApproval = () => {
	const history = useHistory();
	const [paginationLimit, setPaginationLimit] = React.useState<number>(25);
	const [isShowModalApprove, setIsShowModalApprove] =
		React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);
	const [tmpData, setTmpData] =
		React.useState<BankAccountProps>(initialBankAccount);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		changePage,
		setQuery,
		fetchList,
	} = useFetchList<BankAccountProps>({
		endpoint: 'user-bank-accounts',
		initialQuery: {
			status: EBankStatus.WAITING_FOR_APPROVAL,
		},
		limit: paginationLimit,
	});

	const [isShowModalReject, setIsShowModalReject] =
		React.useState<boolean>(false);
	const [rejectBankAccount, setRejectBankAccount] =
		React.useState<BankAccountProps>(initialBankAccount);
	const [bankList, setBankList] = React.useState<Array<BankListProps>>([]);

	React.useEffect(() => {
		const getBankData = async () => {
			const banklistRes =
				await httpRequest.get<FetchAllBankListResponse>(`/banks`);
			setBankList(banklistRes.data.payload.results);
		};

		getBankData();
	}, []);

	const handleApprove = async (record: BankAccountProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.userBankAccountId === record.userBankAccountId) {
					return {
						...item,
						status: EBankStatus.APPROVED,
					};
				}
				return item;
			});
			setData(newData);

			await httpRequest.patch<ResponseProps>(
				'/user-bank-accounts/' + record.userBankAccountId + '/approve',
				{
					status: EBankStatus.APPROVED,
				},
			);

			setQuery((oldVal) => ({
				...oldVal,
				status: EBankStatus.WAITING_FOR_APPROVAL,
			}));

			message.success(
				'Success change ' + record.bankAccountNumber + ' status.',
			);
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const getBankProps = (bankCode: string) => {
		if (bankList) {
			const bank = bankList.find((list) => list.bankId === bankCode);
			console.log(bankCode);
			return bank ? bank.bankName : '';
		}
	};

	const getFullName = (user: IUser) => {
		if (user) {
			return `${user.firstName} ${user.middleName} ${user.lastName}`;
		}
	};

	const handleUpdateReject = async (record: BankAccountProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.userBankAccountId === record.userBankAccountId) {
					return {
						...item,
						status: EBankStatus.REJECTED,
					};
				}
				return item;
			});
			setData(newData);

			await httpRequest.patch<ResponseProps>(
				'/user-bank-accounts/' + record.userBankAccountId + '/reject',
				{
					rejectReason: rejectBankAccount.rejectReason,
				},
			);

			setQuery((oldVal) => ({
				...oldVal,
				status: EBankStatus.WAITING_FOR_APPROVAL,
			}));
			setIsShowModalReject(false);
			message.success(
				'Success change ' + record.bankAccountNumber + ' status.',
			);
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleChangeTab = async (key: string) => {
		setQuery((oldVal) => ({ ...oldVal, status: key }));
		changePage(pagination.page);
	};

	const handleClickAction = (props: BankAccountProps, key: string) => {
		if (key === 'edit') {
			history.push(`/bank-approval/${props.userBankAccountId}/edit`);
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		}
	};

	const handleDelete = async () => {
		try {
			await httpRequest.delete<DeleteResponseProps>(
				'/user-bank-accounts/' + tmpData.userBankAccountId,
			);

			message.success(
				'Success delete bank account ' + tmpData.bankAccountOwner,
			);

			fetchList();
			setIsModalDeleteVisible(false);
			setTmpData(initialBankAccount);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialBankAccount);
		}
	};

	const menu = (record: BankAccountProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Bank Approval</Menu.Item>
			<Menu.Item key="delete">Delete Bank Approval</Menu.Item>
		</Menu>
	);

	const waitingApprovalColumns = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.user.name}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.user.phone}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankName',
			key: 'bankName',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{getBankProps(record.bankCode)}</Text>;
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'bankNumber',
			key: 'bankNumber',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'bankAccountOwner',
			key: 'bankAccountOwner',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'ACTION',
			key: 'action',
			width: '20%',
			render: (_: any, record: BankAccountProps) => (
				<Row>
					<Col>
						<Button
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								width: '84px',
								height: '36px',
								color: 'white',
								backgroundColor: '#D81F64',
								borderRadius: '5px',
								marginRight: 10,
							}}
							onClick={() => {
								setIsShowModalApprove(true);
								setTmpData(record);
							}}
						>
							Approve
						</Button>
					</Col>
					<Col>
						<Button
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								width: '84px',
								height: '36px',
								color: 'black',
								borderRadius: '5px',
								border: '1px solid #D5DCE1',
							}}
							onClick={() => {
								setRejectBankAccount(record);
								setIsShowModalReject(true);
							}}
						>
							Reject
						</Button>
					</Col>
				</Row>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: 'ACTION',
			key: 'action',
			render: (_: any, record: BankAccountProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const approvedColumn = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{getFullName(record.user)}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.user.phone}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankName',
			key: 'bankName',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{getBankProps(record.bankCode)}</Text>;
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'bankNumber',
			key: 'bankNumber',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'bankAccountOwner',
			key: 'bankAccountOwner',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'APPROVED BY',
			dataIndex: 'approvedOrRejectedBy',
			key: 'approvedOrRejectedBy',
			render: (text: string, record: BankAccountProps) => (
				<Text>
					{record.logs && record.logs.length > 0
						? record.logs[0].updatedByName
						: ''}
				</Text>
			),
		},
		{
			title: 'APPROVAL DATE',
			dataIndex: 'approvedOrRejectedDate',
			key: 'approvedOrRejectedDate',
			render: (text: string, record: BankAccountProps) => (
				<div>
					{record.logs && record.logs.length > 0
						? formatDate(record.logs[0].createdAt)
						: ''}
				</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: BankAccountProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const rejectedColumn = [
		{
			title: 'NAME',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{getFullName(record.user)}</Text>;
			},
		},
		{
			title: 'PHONE NUMBER',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.user.phone}</Text>;
			},
		},
		{
			title: 'BANK NAME',
			dataIndex: 'bankName',
			key: 'bankName',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{getBankProps(record.bankCode)}</Text>;
			},
		},
		{
			title: 'ACCOUNT NUMBER',
			dataIndex: 'bankNumber',
			key: 'bankNumber',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.bankAccountNumber}</Text>;
			},
		},
		{
			title: 'NAME IN BANK ACCOUNT',
			dataIndex: 'bankAccountOwner',
			key: 'bankAccountOwner',
			render: (text: string, record: BankAccountProps) => {
				return <Text>{record.bankAccountOwner}</Text>;
			},
		},
		{
			title: 'REJECTED BY',
			dataIndex: 'approvedOrRejectedBy',
			key: 'approvedOrRejectedBy',
			render: (text: string, record: BankAccountProps) => (
				<Text>
					{record.logs && record.logs.length > 0
						? record.logs[0].updatedByName
						: ''}
				</Text>
			),
		},
		{
			title: 'REJECTED DATE',
			dataIndex: 'approvedOrRejectedDate',
			key: 'approvedOrRejectedDate',
			render: (text: string, record: BankAccountProps) => (
				<div>
					{record.logs && record.logs.length > 0
						? formatDate(record.logs[0].createdAt)
						: ''}
				</div>
			),
		},
		{
			title: 'REJECTED REASON',
			dataIndex: 'rejectReason',
			key: 'rejectReason',
			render: (text: string, record: BankAccountProps) => (
				<div>
					{record.logs && record.logs.length > 0
						? record.logs[0].newData?.rejectReason
						: ''}
				</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: BankAccountProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	return (
		<div>
			<HeaderSection
				icon={
					<CreditCardOutlined style={{ fontSize: '20px', color: 'grey' }} />
				}
				title="Bank Account Approval"
				subtitle="Manage your User Bank Account"
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search name, phone number"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
			</ContainerFilter>

			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<Tabs
					defaultActiveKey={'WAITING_FOR_APPROVAL'}
					onChange={(e) => handleChangeTab(e)}
				>
					<TabPane tab="Waiting Approval" key={'WAITING_FOR_APPROVAL'}>
						<Table
							loading={isLoading}
							columns={waitingApprovalColumns}
							dataSource={data}
							pagination={false}
						/>
					</TabPane>
					<TabPane tab="Approved" key={'APPROVED'}>
						<Table
							loading={isLoading}
							columns={approvedColumn}
							dataSource={data}
							pagination={false}
						/>
					</TabPane>
					<TabPane tab="Rejected" key={'REJECTED'}>
						<Table
							loading={isLoading}
							columns={rejectedColumn}
							dataSource={data}
							pagination={false}
						/>
					</TabPane>
				</Tabs>
			</div>
			{data.length != 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={paginationLimit}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
						onShowSizeChange={(_, size) => {
							setPaginationLimit(size);
						}}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}

			<Modal
				width={'528px'}
				title="Reject"
				visible={isShowModalReject}
				onOk={() => handleUpdateReject(rejectBankAccount)}
				onCancel={() => {
					setIsShowModalReject(false);
				}}
				okText="Reject"
				cancelText="Cancel"
				okButtonProps={{
					type: 'primary',
					style: {
						borderColor: '#D81F64',
						backgroundColor: '#D81F64',
						borderRadius: '5px',
						color: 'white',
						width: '107px',
						height: '36px',
					},
				}}
				cancelButtonProps={{
					style: {
						backgroundColor: '#FFFFFF',
						border: '1px solid #D5DCE1',
						borderRadius: '5px',
						width: '107px',
						height: '36px',
					},
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
						fontSize: '12px',
						lineHeight: '150%',
						color: '#556575',
					}}
				>
					Reason
				</Text>
				<TextArea
					rows={6}
					style={{
						resize: 'none',
					}}
					placeholder="Input the reason of rejection"
					onChange={(e) => {
						setRejectBankAccount({
							...rejectBankAccount,
							rejectReason: e.target.value,
						});
					}}
				></TextArea>
			</Modal>
			<Modal
				title="Delete bank approval confirmation"
				visible={isModalDeleteVisible}
				onOk={handleDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialBankAccount);
				}}
				okText="Yes"
				confirmLoading={isLoading}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete medicine <b>{tmpData.bankAccountOwner}</b>
					?
				</p>
			</Modal>
			<Modal
				title="Approve confirmation"
				visible={isShowModalApprove}
				onOk={() => {
					setIsShowModalApprove(false);
					handleApprove(tmpData);
				}}
				onCancel={() => {
					setIsShowModalApprove(false);
				}}
				okText="Yes"
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to approve this {tmpData.bankAccountNumber} bank
					account number ?
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default BankApproval;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
