import { Divider, Table, Typography, Button, Image } from 'antd';
import React, { useCallback } from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useParams } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import {
	BaseResponsePaginationProps,
	BaseResponseProps,
} from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import { CustomerProps, initialCustomer } from '../../types/customer.type';
import moment from 'moment';
import { generateQueryString } from '../../helpers/generateQueryString';
import { PatientInRelationProps } from '../../types/patientInRelation.type';
import { getFullName } from '../../helpers/name';
import PwIdTag from '../../components/PwIdTag';
import { capitalizeFirstLetter } from '../../helpers/text';
import ModalApprovalPwId from '../../components/ModalApprovalPwId';
import { EPWIdStatus } from '../../types/patient.type';

interface ILocation {
	userId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const { Text } = Typography;

const CustomerDetail = () => {
	const { userId } = useParams<ILocation>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [customer, setCustomer] = React.useState<CustomerProps>({
		...initialCustomer,
	});
	const [relatives, setRelatives] = React.useState<PatientInRelationProps[]>(
		[],
	);
	const [selectedData, setSelectedData] = React.useState<
		CustomerProps | PatientInRelationProps
	>();

	const [isModalApprovalPwIdVisible, setIsModalApprovalPwIdVisible] =
		React.useState<boolean>(false);

	const fetchList = useCallback(async () => {
		setIsLoading(true);
		try {
			const res = await httpRequest.get<ResponseProps>('/user/' + userId);

			setCustomer(res.data.payload);
		} catch (error: any) {
			console.log('error fetchlist patients', error);
		}

		try {
			const res = await httpRequest.get<
				BaseResponsePaginationProps<PatientInRelationProps>
			>(
				'/patient-in-relations' +
					generateQueryString({ patientId: userId, limit: 100000 }),
			);

			setRelatives(res.data.payload.results);
		} catch (error: any) {
			console.log('error fetchlist patient in relations', error);
		}
		setIsLoading(false);
	}, [userId]);

	React.useEffect(() => {
		fetchList();
	}, [fetchList]);

	const relativeColumns = [
		{
			title: 'Name',
			render: (record: PatientInRelationProps) => {
				return <div>{getFullName(record)}</div>;
			},
		},
		{
			title: 'Relationship',
			dataIndex: 'relation',
			key: 'relation',
			render: (relation: string) => (
				<Text>{capitalizeFirstLetter(relation)}</Text>
			),
		},
		{
			title: 'Remark',
			dataIndex: 'reason',
			key: 'reason',
			render: (reason: string) => (
				<Text>
					{reason || (
						<Text italic type="secondary">
							Not set
						</Text>
					)}
				</Text>
			),
		},
		{
			title: 'PWD Status',
			dataIndex: 'pwIdStatus',
			key: 'pwIdStatus',
			render: (pwIdStatus: string) =>
				pwIdStatus ? (
					<PwIdTag>{pwIdStatus}</PwIdTag>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				),
		},
		{
			title: 'Action',
			render: (record: PatientInRelationProps) => (
				<Button
					disabled={!record.pwIdUrl}
					style={{
						backgroundColor: record.pwIdUrl ? '#D81F64' : '#93A1B0',
						border: 'none',
						color: 'white',
					}}
					onClick={() => {
						setSelectedData(record);
						setIsModalApprovalPwIdVisible(true);
					}}
				>
					Edit Status
				</Button>
			),
		},
	];

	return (
		<div>
			<HeaderSection
				icon="back"
				title={customer.name}
				subtitle="Manage your patient data"
			/>
			<AppCard loading={isLoading} type="detail">
				<SectionContent groupTitle="User Account">
					<DetailItem label="Name" content={customer.firstName} />
					<DetailItem label="Middle Name" content={customer.middleName} />
					<DetailItem label="Last Name" content={customer.lastName} />
					<DetailItem label="Phone Number" content={customer.phone} />
					<DetailItem
						label="Gender"
						content={
							customer.gender.charAt(0).toUpperCase() + customer.gender.slice(1)
						}
					/>
					<DetailItem
						label="Birthday"
						content={moment(customer.birthdate).format('DD MMM YYYY')}
					/>
					<DetailItem label="Email Address" content={customer.email} />
					<DetailItem
						label="PWD ID"
						content={
							customer.patient?.pwIdUrl ? (
								<div
									style={{
										borderWidth: 2,
										borderStyle: 'solid',
										padding: 8,
										borderColor: '#D5DCE1',
										width: 400,
									}}
								>
									<Image
										preview={true}
										width={'100%'}
										height={220}
										src={customer.patient?.pwIdUrl}
										fallback={'/images/blur-image.jpeg'}
										style={{ objectFit: 'contain' }}
										placeholder={
											<Image
												preview={false}
												src="/images/blur-image.jpeg"
												width="100%"
												height={220}
												style={{ objectFit: 'contain' }}
											/>
										}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											marginTop: 12,
										}}
									>
										<Text style={{ fontSize: 14, color: '#556575' }}>
											Status
										</Text>
										<div>
											<PwIdTag>{customer.patient.pwIdStatus}</PwIdTag>
											<Button
												onClick={() => {
													setSelectedData(customer);
													setIsModalApprovalPwIdVisible(true);
												}}
												style={{ border: 'none' }}
											>
												Edit Status
											</Button>
										</div>
									</div>
									{customer.patient?.pwIdStatus === EPWIdStatus.REJECTED && (
										<>
											<Divider style={{ margin: 0, marginTop: 12 }} />
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													marginTop: 12,
												}}
											>
												<Text style={{ fontSize: 14, color: '#556575' }}>
													Remark
												</Text>
												<Text style={{ marginRight: 14 }}>
													{customer.patient.reason}
												</Text>
											</div>
										</>
									)}
								</div>
							) : null
						}
					/>
				</SectionContent>

				<Divider />

				<SectionContent groupTitle="Relatives">
					<Table
						loading={isLoading}
						columns={relativeColumns}
						dataSource={relatives}
						pagination={false}
					/>
				</SectionContent>

				<ModalApprovalPwId
					isVisible={isModalApprovalPwIdVisible}
					setIsVisible={setIsModalApprovalPwIdVisible}
					data={selectedData}
					setSelectedData={setSelectedData}
					fetchList={fetchList}
				/>
			</AppCard>
		</div>
	);
};

export default CustomerDetail;
