export enum EEmployeePermissions {
	VIEW = 'W004',
	ADD = 'W005',
	EDIT = 'W006',
	DELETE = 'W007',
	DOWNLOAD = 'W008',
}

export enum EBPOMAccessPermissions {
	VIEW = 'W009',
	ADD = 'W010',
	EDIT = 'W011',
	DELETE = 'W012',
	DOWNLOAD = 'W013',
}

export enum ECompanyPermissions {
	VIEW = 'W014',
	ADD = 'W015',
	EDIT = 'W016',
	DELETE = 'W017',
	DOWNLOAD = 'W018',
}

export enum EProductPermissions {
	VIEW = 'W019',
	ADD = 'W020',
	EDIT = 'W021',
	DELETE = 'W022',
	DOWNLOAD = 'W023',
}

export enum EBatchPermissions {
	VIEW = 'W024',
	ADD = 'W025',
	EDIT = 'W026',
	APPROVE = 'W027',
	DELETE = 'W028',
	DOWNLOAD = 'W029',
}

export enum EWorkorderPermissions {
	VIEW = 'W030',
	ADD = 'W031',
	EDIT = 'W032',
	APPROVE = 'W033',
	DELETE = 'W034',
	DOWNLOAD = 'W035',
}

export enum EShipmentPermissions {
	VIEW = 'W036',
	ADD = 'W037',
	EDIT = 'W038',
	APPROVE = 'W039',
	DELETE = 'W040',
	DOWNLOAD = 'W041',
}

export enum EReturnPermissions {
	VIEW = 'W042',
	ADD = 'W043',
	EDIT = 'W044',
	APPROVE = 'W045',
	DELETE = 'W046',
	DOWNLOAD = 'W047',
}

export enum EQAReleasePermissions {
	VIEW = 'W048',
	ADD = 'W049',
	EDIT = 'W050',
	APPROVE = 'W051',
	DELETE = 'W052',
	DOWNLOAD = 'W053',
}
