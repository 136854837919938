import { SafetyOutlined } from '@ant-design/icons';
import { Form, FormInstance, message, Space } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import {
	initialPrivacyPolicy,
	PrivacyPolicyProps,
} from '../../types/privacyPolicy.type';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { generateFormRules } from '../../helpers/formRules';

interface ILocation {}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PrivacyPolicyProps, 'createdAt' | 'updatedAt'>;
}

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const PrivacyPolicyEdit: React.FC = () => {
	const history = useHistory();
	const location = useLocation<ILocation>();
	const formRef =
		React.useRef<
			FormInstance<Omit<PrivacyPolicyProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [privacyPolicy, setPrivacyPolicy] =
		React.useState<PrivacyPolicyProps>(initialPrivacyPolicy);

	const updatePrivacyPolicy = async (
		props: Omit<
			PrivacyPolicyProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/privacy-policy', props);
			message.success('Success update privacy policy');

			setIsLoadingAction(false);

			fetchPrivacyPolicyDetail();
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			PrivacyPolicyProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		updatePrivacyPolicy(values);
	};

	const fetchPrivacyPolicyDetail = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<ResponseProps>('/privacy-policy');
			setPrivacyPolicy(res.data.payload);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchPrivacyPolicyDetail();
	}, [location]);

	console.info(privacyPolicy);

	return (
		<div>
			<HeaderSection
				icon={<SafetyOutlined />}
				title={'Edit Privacy Policy'}
				subtitle="Manage your Privacy Policy data"
				rightAction={
					<Space>
						{/* <AppButton onClick={() => history.goBack()}>Cancel</AppButton> */}
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="privacyPolicyForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={privacyPolicy}
					autoComplete="off"
				>
					<Form.Item
						name="content"
						rules={generateFormRules('Privacy Policy', ['required'])}
					>
						<ReactQuill
							theme="snow"
							value={privacyPolicy.content}
							onChange={(content) =>
								setPrivacyPolicy({
									...privacyPolicy,
									content: content,
								})
							}
							modules={quillModules}
							formats={quillFormats}
						/>
					</Form.Item>
				</Form>
			</AppCard>
		</div>
	);
};

export default PrivacyPolicyEdit;
