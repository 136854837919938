import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import { Form, FormInstance, Input, message, Space } from 'antd';
import React from 'react';
import AppCard from '../../components/AppCard';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { IChangePassword } from '../../types/user.type';
import { getErrorMessage } from '../../helpers/errorHandler';
import { httpRequest } from '../../helpers/api';

type IParams = {
	userId: string;
};

const ChangePasswordAdmin: React.FC = () => {
	const history = useHistory();
	const { userId } = useParams<IParams>();
	const formRef = React.useRef<FormInstance<IChangePassword>>(null);

	const [isLoading, setIsLoading] = React.useState(false);

	const handleSave = (values: IChangePassword) => {
		if (values.newPassword !== values.retypePassword) {
			message.error(
				'New Password and Retype Password is not same. Please check!',
			);
		} else if (values.oldPassword === values.newPassword) {
			message.error(
				'New Password cannot be the same as the Old Password. Please choose a different password.'
			);
		} else {
			setIsLoading(true);
			httpRequest
				.patch('/auth/' + userId + '/change-password', {
					newPassword: values.newPassword,
					oldPassword: values.oldPassword,
				})
				.then(() => {
					message.success('Password updated succesfully.');
					history.goBack();
				})
				.catch((err) => {
					message.error('Failed. ' + getErrorMessage(err));
					formRef.current?.resetFields();
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};
	const handleCancel = () => {
		history.goBack();
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Change Password"
				subtitle="Manage password"
				rightAction={
					<Space>
						<AppButton onClick={handleCancel}>Cancel</AppButton>
						<AppButton
							loading={isLoading}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>

			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="passwordForm"
					layout="vertical"
					onFinish={handleSave}
					initialValues={{
						newPassword: '',
						retypePassword: '',
					}}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Password Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, eveniet soluta delectus error tempore iure accusantium explicabo',
							},
						]}
					>
						<Form.Item
							label="Old Password"
							name="oldPassword"
							rules={generateFormRules('Old Password', ['required'])}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label="New Password"
							name="newPassword"
							rules={generateFormRules('New Password', [
								'required',
								'password',
							])}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label="Retype Password"
							name="retypePassword"
							rules={generateFormRules('Retype Password', [
								'required',
								'password',
							])}
						>
							<Input.Password />
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default ChangePasswordAdmin;
