import { Divider, Space } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import { initialVoucher, VoucherProps } from '../../types/voucher.type';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import HTMLPreview from '../../components/HTMLPreview';

interface IParams {
	voucherId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: VoucherProps;
}

const VoucherDetail = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { voucherId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [voucher, setVoucher] = React.useState<VoucherProps>(initialVoucher);

	const dateOptions = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	} as const;

	const timeOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	} as const;
	const fromDate =
		voucher && voucher.validStartAt
			? new Date(voucher.validStartAt).toLocaleDateString(
					'en-US',
					dateOptions,
			  ) +
			  ', ' +
			  new Date(voucher.validStartAt).toLocaleTimeString(
					navigator.language,
					timeOptions,
			  )
			: '';

	const toDate =
		voucher && voucher.validEndAt
			? new Date(voucher.validEndAt).toLocaleDateString('en-US', dateOptions) +
			  ', ' +
			  new Date(voucher.validEndAt).toLocaleTimeString(
					navigator.language,
					timeOptions,
			  )
			: '';

	const handleClickEdit = () => {
		history.push(`/voucher/${voucher.voucherId}/edit`);
	};

	React.useEffect(() => {
		const fetchDetailVoucher = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/vouchers/' + voucherId,
				);

				setVoucher(res.data.payload);

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'voucherCode',
						value: res.data.payload.voucherCode,
						label: res.data.payload.voucherName || '',
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailVoucher();
	}, [voucherId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Vouchers"
				subtitle="Manage your Vouchers data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				{/* <SectionContent groupTitle="Voucher Preview">
          <HeaderBannerItem rightImageUrl={banner.bannerImageUrl || ''} title={banner.title || 'title'} content={banner.content || ''} />
        </SectionContent> */}
				<SectionContent
					groupTitle="Information"
					helpers={[
						{
							title: 'Information',
							content:
								'Accepted image type are jpg, jpeg and png. Max file size is 1 Mb.',
						},
					]}
				>
					<DetailItem
						type="image"
						label="Vouchers image"
						content={voucher.imageUrl || ''}
					/>
					<DetailItem label="Headline" content={voucher.voucherName || ''} />
					<DetailItem
						label="Detail Vouchers"
						content={voucher.description || ''}
					/>
					<DetailItem
						label="Term and Condition"
						content={<HTMLPreview html={voucher.termAndCondition || ''} />}
					/>
					<DetailItem
						label="Status"
						content={voucher.isPublished ? 'Published' : 'Unpublished' || ''}
					/>
				</SectionContent>
				<Divider style={{ width: '100%' }} />
				<SectionContent
					groupTitle="Discount Setting"
					helpers={[
						{
							title: 'Information',
							content:
								'Tag name must be unique, system will reject if tag has been created with the same name.',
						},
					]}
				>
					<DetailItem
						label="Discount Code"
						content={voucher.voucherCode || ''}
					/>
					<DetailItem
						label="Discount Type"
						content={voucher.discountType || ''}
					/>
					<DetailItem
						label="Discount Value"
						content={
							voucher.discountType == 'percentage'
								? voucher.discountValue + ' %' || ''
								: '₱ ' + voucher.discountValue || ''
						}
					/>
					{voucher.discountType == 'percentage' ? (
						<DetailItem
							label="Max Discount"
							content={'₱ ' + voucher.maxDiscountValue || ''}
						/>
					) : (
						''
					)}
					<DetailItem
						label="Usage Limit Per User"
						content={voucher.limitMaxUsePerPerson + ' Usage' || ''}
					/>
					<DetailItem
						label="Minimum Transaction"
						content={'₱ ' + voucher.requirement || ''}
					/>
					<DetailItem
						label="From"
						content={fromDate.replace('AM', '').replace('PM', '') || ''}
					/>
					<DetailItem
						label="To"
						content={toDate.replace('AM', '').replace('PM', '') || ''}
					/>
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default VoucherDetail;
