import { Modal, Space, message } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';
import { useParams } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import AppButton from '../../components/AppButton';
import { useHistory } from 'react-router-dom';
import {
	PublisherProps,
	initialPublisher,
} from '../../types/adminPublisher.type';
import KycTag from '../../components/KycTag';
import { EKycStatus } from '../../types/kycHistory.type';
import { theme } from '../../assets/theme';

interface IParams {
	userId: string;
	partnerId: string;
}


interface ResponseProps extends BaseResponseProps {
	payload: Omit<PublisherProps, 'createdAt' | 'updatedAt'>;
}

const AdminPublisherDetail = () => {
	const history = useHistory();
	const { userId, partnerId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [adminPublisher, setAdminPublisher] = React.useState<PublisherProps>({
		...initialPublisher,
	});
	const [selectedKycStatus, setSelectedKycStatus] = React.useState(
		adminPublisher.kycStatus,
	);

	React.useEffect(() => {
		const fetchUser = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/partner-admins/' + userId,
				);

				setAdminPublisher(res.data.payload);
			} catch (error: any) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchUser();
	}, [userId, selectedKycStatus]);

	const handleChangeStatus = (status: any) => {
		Modal.confirm({
			title: 'Confirmation',
			content: 'Are you sure want to change kyc status?',
			onOk: async () => {
				setSelectedKycStatus(status);
				await httpRequest.patch(`/partner-admins/${userId}`, {
					...adminPublisher,
					kycStatus: status,
					// note: status == 'REJECTED' ? rejectReason : '',
					note: '',
				});
				message.success('Success change status');
				// setRejectReason('');
				Modal.destroyAll();
			},
			okText: 'Update Status',
			okType: 'primary',
		});
	};

	const handleClickEdit = () => {
		history.push(`/publisher/${partnerId}/detail/admin-publisher/${userId}/edit`);
	};

	// function handleClickReject() {
	// 	// setRejectReason('');
	// 	// setIsModalRejectVisible(true);
	// }

	// function handleClickApprove() {
	// 	handleChangeStatus('APPROVED');
	// }

	// function handleConfirmReject() {
	// 	// setIsModalRejectVisible(false);
	// 	handleChangeStatus('REJECTED');
	// }

	return (
		<div>
			<HeaderSection
				icon="back"
				title={adminPublisher.user.name || ''}
				subtitle="Manage your admin data"
				rightAction={
					<Space>
						{
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton> }
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="User Data"
					helpers={[
						{
							title: 'Information',
							content:
								'Tag name must be unique, system will reject if tag has been created with the same name.',
						},
					]}
				>
					<DetailItem label="Name" content={adminPublisher.user.name} />
					{/* <DetailItem
						label="Phone Number"
						content={
							adminPublisher.user.phone
								? '+' + adminPublisher.user.phone
								: adminPublisher.user.phone
						}
					/> */}
					<DetailItem
						label="Email Address"
						content={adminPublisher.user.email}
					/>
					<DetailItem
						label="Publisher"
						content={adminPublisher.partners?.partnerName}
					/>
				</SectionContent>

				{/* <SectionContent
					groupTitle="User Settings"
					helpers={[
						{
							title: 'Information',
							content:
								'Tag name must be unique, system will reject if tag has been created with the same name.',
						},
					]}
				>
					<DetailItem
						label="Status"
						content={<KycTag>{adminPublisher.kycStatus}</KycTag>}
					/>
				</SectionContent> */}
			</AppCard>
		</div>
	);
};

export default AdminPublisherDetail;
