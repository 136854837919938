import { useHistory } from 'react-router-dom';
import { MoreOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import {
	Pagination,
	Space,
	Table,
	Switch,
	Dropdown,
	Menu,
	message,
	Input,
	Select,
	Modal,
} from 'antd';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import { ContainerFilter } from '../dashboard';
import useFetchList from '../../hooks/useFetchList';
import {
	HealthServiceCategoryProps,
	initialHealthServiceCategory,
} from '../../types/healthServiceCategory.type';
import styled from 'styled-components';
import React from 'react';
import moment from 'moment';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<HealthServiceCategoryProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const HealthServiceCategory = () => {
	const history = useHistory();

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<HealthServiceCategoryProps>({
		apiRequest: httpRequest,
		endpoint: 'health-service/categories',
		initialQuery: {
			limit: 10,
			offset: 0,
		},
		limit: 10,
	});

	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<HealthServiceCategoryProps>(
		initialHealthServiceCategory,
	);
	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);

	const handleStatusChange = async () => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.categoryId === tmpData.categoryId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/health-service/categories/' + tmpData.categoryId,
				{
					categoryId: tmpData.categoryId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.categoryId === res.data.payload.categoryId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.categoryName + ' status.');
			setTmpData(initialHealthServiceCategory);
			setIsModalVisible(false);
		} catch (error: any) {
			message.error(error.data.message);
			setTmpData(initialHealthServiceCategory);
			setIsModalVisible(false);
		}
	};

	const handleCreateHealthServiceCategory = () => {
		history.push({ pathname: '/medical-service-category/add' });
	};

	const handleClickDetail = (e: HealthServiceCategoryProps) => {
		history.push({
			pathname: `/medical-service-category/${e.categoryId}/detail`,
		});
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
		}
	};

	const handleClickEdit = (e: HealthServiceCategoryProps) => {
		history.push({
			pathname: `/medical-service-category/${e.categoryId}/edit`,
		});
	};

	const handleClickDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.categoryId === tmpData.categoryId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);
			const res = await httpRequest.delete<DeleteResponseProps>(
				'/health-service/categories/' + tmpData.categoryId,
			);

			newData = data.filter((item) => item.categoryId !== tmpData.categoryId);
			setData(newData);

			message.success('Success delete ' + tmpData.categoryName);

			setIsLoadingDelete(false);
			setIsModalDeleteVisible(false);
			setTmpData(initialHealthServiceCategory);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialHealthServiceCategory);
			setIsLoadingDelete(false);
		}
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'categoryName',
			key: 'categoryName',
			render: (text: string, record: HealthServiceCategoryProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'Status',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: HealthServiceCategoryProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								setIsModalVisible(true);
								setTmpData(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => {
				return <div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>;
			},
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: HealthServiceCategoryProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: HealthServiceCategoryProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'detail') {
					handleClickDetail(record);
				} else if (key === 'edit') {
					handleClickEdit(record);
				} else if (key === 'delete') {
					setTmpData(record);
					setIsModalDeleteVisible(true);
				}
			}}
		>
			<Menu.Item key="edit">Edit Service Category</Menu.Item>
			<Menu.Item key="detail">Detail Service Category</Menu.Item>
			<Menu.Item key="delete">Delete Service Category</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<TagOutlined />}
				title="Category"
				subtitle="Manage your category data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={handleCreateHealthServiceCategory}
						>
							Add
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by category name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Inactive</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 && (
				<div>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</div>
			)}

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialHealthServiceCategory);
				}}
				okText="Yes"
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.categoryName}"</b> status to{' '}
					<b>"{tmpData.isPublished ? 'Inactive' : 'Active'}"</b>?.{' '}
					{tmpData.isPublished
						? 'If this Category status is changed to "Inactive", then this Category cannot be used on other medical service'
						: 'If this Category status is changed to "Inactive", then this Category can be used on other medical service'}
				</p>
			</Modal>

			<Modal
				title="Delete Health Service Category"
				visible={isModalDeleteVisible}
				onOk={handleClickDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialHealthServiceCategory);
				}}
				okText="Yes"
				confirmLoading={isLoadingDelete}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete <b>{tmpData.categoryName}</b>?
				</p>
			</Modal>
		</div>
	);
};

export default HealthServiceCategory;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
