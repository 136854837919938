import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	MoreOutlined,
	SearchOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import {
	Pagination,
	Space,
	Table,
	Switch,
	Dropdown,
	Menu,
	message,
	Input,
	Select,
	Modal,
	Checkbox,
} from 'antd';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import { BaseResponseProps } from '../../types/config.type';
import { EFAQType, FAQProps, initialFAQ } from '../../types/faq.type';
import { ContainerFilter } from '../admin';
import useFetchList from '../../hooks/useFetchList';
import styled from 'styled-components';

export const MAPPING_FAQ: { [key: string]: { path: string; title: string } } = {
	GENERAL: {
		path: 'faq',
		title: 'Frequently Ask Question',
	},
	BALANCE: {
		path: 'balance-information',
		title: 'Balance Information',
	},
	POINT: {
		path: 'point-information',
		title: 'Point Information',
	},
};

interface ResponseProps extends BaseResponseProps {
	payload: Omit<FAQProps, 'createdAt' | 'updatedAt'>;
}

type Props = {
	type?: EFAQType;
};
const FAQ = (props: Props) => {
	const type: EFAQType = props.type ? props.type : EFAQType.GENERAL;
	const history = useHistory();

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
		fetchList,
	} = useFetchList<FAQProps>({
		endpoint: 'faqs',
		initialQuery: {
			types: [type],
			isPublished: undefined,
		},
		limit: 10,
	});

	const READERS_OPTION =
		props.type === EFAQType.BALANCE
			? ['DOCTOR']
			: props.type === EFAQType.POINT
			? ['DOCTOR', 'PATIENT']
			: ['DOCTOR', 'PATIENT', 'SECRETARY'];

	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<FAQProps>(initialFAQ);
	const [isLoadingUpdateIsPublished, setIsLoadingUpdateIsPublished] =
		React.useState(false);
	const [isLoadingAction, setIsLoadingAction] = useState(false);
	const [willBeDeleted, setWillBeDeleted] = useState<FAQProps>();

	const handleCreateFAQ = () => {
		history.push(`/${MAPPING_FAQ[type].path}/add`);
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: '1' | '0' | string) => {
		if (status === 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		}
	};

	const handleClickEdit = (e: FAQProps) => {
		history.push(`/${MAPPING_FAQ[type].path}/${e.faqId}/edit`);
	};

	const handleClickDelete = async () => {
		if (willBeDeleted) {
			setIsLoadingAction(true);
			await httpRequest.delete('/faqs/' + willBeDeleted.faqId);
			setWillBeDeleted(undefined);
			setIsLoadingAction(false);

			fetchList();
		}
	};

	const handleChangePublishFor = (publish: string) => {
		if (publish !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, targetReaders: publish }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, targetReaders: undefined }));
		}
	};

	const handleIsPublishedChange = async () => {
		try {
			setIsLoadingUpdateIsPublished(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.faqId === tmpData.faqId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const newIsPublished = tmpData.isPublished ? false : true;

			const res = await httpRequest.patch<ResponseProps>(
				'/faqs/' + tmpData.faqId,
				{
					faqId: tmpData.faqId,
					isPublished: newIsPublished,
				},
			);

			newData = data.map((item) => {
				if (item.faqId === res.data.payload.faqId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Changed successfully');
		} catch (error: any) {
			message.error(error.data.message);
		} finally {
			setIsLoadingUpdateIsPublished(false);
			setIsModalVisible(false);
			setTmpData(initialFAQ);
		}
	};

	const columns = [
		{
			title: 'QUESTION',
			dataIndex: 'question',
			key: 'question',
			render: (text: string, record: FAQProps) => {
				return (
					<div className="table-link" onClick={() => handleClickEdit(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'ANSWER',
			key: 'answer',
			dataIndex: 'answer',
			render: (text: string) => {
				return <div>{text}</div>;
			},
		},
		{
			title: 'PUBLISHED FOR',
			key: 'targetReaders',
			dataIndex: 'targetReaders',
			width: 150,
			render: (targetReaders: string[]) => {
				return targetReaders?.length === READERS_OPTION?.length ? (
					<div>All</div>
				) : (
					<CustomCheckboxGroup options={READERS_OPTION} value={targetReaders} />
				);
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: any, record: FAQProps) => (
				<>
					<Switch
						loading={isLoadingUpdateIsPublished}
						checked={isPublished}
						onChange={() => {
							setIsModalVisible(true);
							setTmpData(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: FAQProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: FAQProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'edit') {
					handleClickEdit(record);
				} else if (key === 'delete') {
					setWillBeDeleted(record);
				}
			}}
		>
			<Menu.Item key="edit">Edit FAQ</Menu.Item>
			<Menu.Item key="delete">Delete FAQ</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<QuestionCircleOutlined />}
				title={MAPPING_FAQ[type].title}
				subtitle={`Manage your ${MAPPING_FAQ[type].title} data`}
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateFAQ}>
							Add {MAPPING_FAQ[type].title}
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by question"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				{props.type !== 'BALANCE' && (
					<Select
						size="large"
						allowClear
						style={{ width: 160 }}
						onChange={handleChangePublishFor}
						placeholder="Publish For"
					>
						{props.type === 'POINT' ? (
							<>
								<Option value="all">All</Option>
								<Option value="DOCTOR">Doctor</Option>
								<Option value="PATIENT">Patient</Option>
							</>
						) : (
							<>
								<Option value="all">All</Option>
								<Option value="DOCTOR">Doctor</Option>
								<Option value="PATIENT">Patient</Option>
								<Option value="SECRETARY">Secretary</Option>
							</>
						)}
					</Select>
				)}
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="1">Published</Option>
					<Option value="0">Unpublished</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{pagination.totalData !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={PAGE_SIZE_OPTIONS}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : (
				false
			)}

			<Modal
				title="Confirmation"
				visible={isModalVisible}
				onOk={handleIsPublishedChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialFAQ);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateIsPublished}
				okButtonProps={{ type: 'primary' }}
			>
				<div style={{ background: '#F2F2F2', padding: 10 }}>
					{tmpData.question}
				</div>
				<p>
					Are you sure want to change to be{' '}
					<b>"{tmpData.isPublished ? 'Unpublished' : 'Published'}"</b>?.{' '}
				</p>
			</Modal>

			<Modal
				title="Confirmation"
				visible={!!willBeDeleted}
				onOk={handleClickDelete}
				onCancel={() => {
					setWillBeDeleted(undefined);
				}}
				okText="Yes"
				confirmLoading={isLoadingAction}
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to change to delete this data?</p>
			</Modal>
		</div>
	);
};

export default FAQ;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

const CustomCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: red;
		background-color: red;
	}
`;
