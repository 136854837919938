import {
	Col,
	Divider,
	Input,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Table,
	Tag,
} from 'antd';
import React from 'react';

import {
	CaretDownOutlined,
	ExclamationCircleOutlined,
	SearchOutlined,
	TeamOutlined,
} from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import {
	CustomerFeedbackProps,
	EStatus,
	initialCustomerFeedback,
} from '../../types/customerFeedback.type';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerFeedbackProps, 'createdAt' | 'updatedAt'>;
}

const CustomerFeedback = () => {
	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<CustomerFeedbackProps>(
		initialCustomerFeedback,
	);

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<CustomerFeedbackProps>({
		endpoint: 'customer-feedbacks',
		limit: 10,
	});
	console.log(tmpData.meta);
	const handleStatusChange = async (status: EStatus) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: `Update status for ${tmpData.name} ?`,
			async onOk() {
				try {
					setIsLoadingUpdateStatus(true);
					let newData = [];
					newData = data.map((item) => {
						if (item.customerFeedbackId === tmpData.customerFeedbackId) {
							return {
								...item,
								statusLoading: true,
							};
						}
						return item;
					});
					setData(newData);

					const res = await httpRequest.patch<ResponseProps>(
						`/customer-feedbacks/${tmpData.customerFeedbackId}`,
						{
							status: status,
						},
					);

					newData = data.map((item) => {
						if (
							item.customerFeedbackId === res.data.payload.customerFeedbackId
						) {
							return {
								...item,
								status: res.data.payload.status,
								statusLoading: false,
							};
						}
						return item;
					});
					setData(newData);

					message.success('Success change status for ' + tmpData.name);

					setIsLoadingUpdateStatus(false);
					setIsModalVisible(false);
					setTmpData(initialCustomerFeedback);
				} catch (error: any) {
					message.error(error.data.message);
					setIsModalVisible(false);
					setTmpData(initialCustomerFeedback);
					setIsLoadingUpdateStatus(false);
				}
			},
			onCancel() {
				console.log('cancel');
			},
		});
	};

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		console.log(status);
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, status: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, status: undefined }));
		}
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '12%',
			render: (text: string, record: CustomerFeedbackProps) => {
				return (
					<Text
						className="table-link"
						onClick={() => {
							setTmpData(record);
							setIsModalVisible(true);
						}}
					>
						{text}
					</Text>
				);
			},
		},
		{
			title: 'Email',
			key: 'email',
			dataIndex: 'email',
			render: (email: any, record: CustomerFeedbackProps) =>
				record.email ? (
					<Text>{record.email}</Text>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				),
		},
		{
			title: 'Phone',
			key: 'phone',
			dataIndex: 'phone',
			render: (phone: any, record: CustomerFeedbackProps) =>
				record.phone ? (
					<Text>{record.phone}</Text>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				),
		},
		{
			title: 'Feedback',
			key: 'feedback',
			dataIndex: 'feedback',
			width: '33%',
			render: (feedback: any, record: CustomerFeedbackProps) => (
				<Text>
					{record.feedback.length > 147
						? record.feedback.slice(0, 147) + '...'
						: record.feedback}
				</Text>
			),
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: CustomerFeedbackProps) => (
				<Tag
					style={{ cursor: 'pointer' }}
					color={record.status === EStatus.RESPONDED ? 'success' : 'warning'}
					onClick={() => {
						setTmpData(record);
						setIsModalVisible(true);
					}}
				>
					{record.status} <CaretDownOutlined />
				</Tag>
			),
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <Text>{formatDate(createdAt)}</Text>,
		},
	];

	return (
		<div>
			<HeaderSection
				icon={<TeamOutlined />}
				title="Customer Feedback"
				subtitle="Manage your Customer Feedback"
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search name, email or phone number"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status - All"
				>
					<Option value="all">All</Option>
					<Option value={EStatus.RESPONDED}>Responded</Option>
					<Option value={EStatus.WAITING_FOR_RESPONSE}>Waiting</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{pagination.totalData !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : (
				false
			)}

			<CustomModal
				title="Detail Feedback"
				centered
				visible={isModalVisible}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialCustomerFeedback);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
				footer={null}
			>
				<Row>
					<Col span={24}>
						<Text>Status</Text>
						<Select
							value={tmpData.status}
							style={{ width: '100%' }}
							onChange={handleStatusChange}
						>
							<Option value={EStatus.WAITING_FOR_RESPONSE}>Waiting</Option>
							<Option value={EStatus.RESPONDED}>Responded</Option>
						</Select>
					</Col>
				</Row>

				<Row>
					<Col span={12}>
						<Text>Name</Text>
						<Paragraph>{tmpData.name}</Paragraph>
					</Col>
					<Col span={12}>
						{' '}
						<Text>Phone</Text>
						{tmpData.phone ? (
							<Paragraph>{tmpData.phone}</Paragraph>
						) : (
							<Paragraph italic type="secondary">
								Not set
							</Paragraph>
						)}
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Text>Email</Text>
						{tmpData.email ? (
							<Paragraph>{tmpData.email}</Paragraph>
						) : (
							<Paragraph italic type="secondary">
								Not set
							</Paragraph>
						)}
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<Text>Feedback</Text>
						<Paragraph>{tmpData.feedback}</Paragraph>
					</Col>
				</Row>
				<Divider />
				<Row>
					{Object.keys(tmpData.meta).map((props) => (
						<Col span={8}>
							<Text>{props}</Text>
							<Paragraph>
								{tmpData.meta[props] ? tmpData.meta[props] : 'Unknown'}
							</Paragraph>
						</Col>
					))}
				</Row>
			</CustomModal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

const CustomModal = styled(Modal)`
	.ant-modal,
	.ant-modal-content {
		min-width: 630px;
	}
	.ant-modal-title {
		font-size: 20px;
		font-weight: 600;
		color: #556575;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
	}
	.ant-typography {
		font-weight: 400;
		letter-spacing: 0.02em;
		line-height: 150%;
	}
	span.ant-typography {
		font-size: 12px;
		color: #556575;
		text-transform: capitalize;
	}
	div.ant-typography {
		font-size: 14px;
		color: #1d2b36;
		margin-bottom: 0;
		/* font-weight: 600; */
	}
`;

export default CustomerFeedback;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
