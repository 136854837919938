import {
	AppstoreAddOutlined,
	CheckCircleFilled,
	MoreOutlined,
	SearchOutlined,
	ReadOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
	Tooltip,
	Typography,
	Button,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import { initialPartner, PartnerProps } from '../../types/partner.type';
import {
	PublisherProps,
	initialPublisher,
} from '../../types/adminPublisher.type';
import { getFullName } from '../../helpers/name';
import { IUser, initialUser } from '../../types/user.type';
import { getUser } from '../../helpers/auth';

interface IParams {
	partnerId: string;
}
interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface FetchAllPublisherResponse extends BaseResponseProps {
	payload: {
		results: PublisherProps[];
	};
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const { Text } = Typography;

const Publisher = () => {
	const userData = getUser();
	const history = useHistory();
	const { partnerId } = useParams<IParams>();
	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [isModalVisibleConfirmed, setIsModalVisibleConfirmed] =
		React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<PartnerProps>(initialPartner);
	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);
	const [adminPublisher, setAdminPublisher] = React.useState<PublisherProps[]>(
		[],
	);
	const [inputValue, setInputValue] = React.useState('');

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<PartnerProps>({
		endpoint: 'partners',
		limit: 0,
		initialQuery: {
			includePartnerTypes: 'PUBLISHER',
			userId: userData?.userType === 'customer' ? userData?.userId : undefined,
		},
	});

	const handleUpdateIsPublished = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.partnerId === tmpData.partnerId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/partners/' + tmpData.partnerId,
				{
					partnerId: tmpData.partnerId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.partnerId === res.data.payload.partnerId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.partnerName + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialPartner);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialPartner);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleUpdateIsConfirmedPartner = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.partnerId === tmpData.partnerId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/partners/' + tmpData.partnerId,
				{
					partnerId: tmpData.partnerId,
					isConfirmedPartner: !tmpData.isConfirmedPartner,
				},
			);

			newData = data.map((item) => {
				if (item.partnerId === res.data.payload.partnerId) {
					return {
						...item,
						isConfirmedPartner: res.data.payload.isConfirmedPartner,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.partnerName + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisibleConfirmed(false);
			setTmpData(initialPartner);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisibleConfirmed(false);
			setTmpData(initialPartner);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleCreatePartner = () => {
		history.push('/publisher/add');
	};

	const handlePublisherTrash = () => {
		history.push('/publisher/publisher-trash');
	};

	const { Option } = Select;

	const handleChangeStatusPublished = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleChangeStatusVerified = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isConfirmedPartner: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isConfirmedPartner: undefined }));
		}
	};

	const handleClickDetail = (e: PartnerProps) => {
		history.push(`/publisher/${e.partnerId}/detail`);
	};

	const handleClickAction = (props: PartnerProps, key: string) => {
		if (key === 'detail') {
			history.push(`/publisher/${props.partnerId}/detail`);
		} else if (key === 'edit') {
			history.push(`/publisher/${props.partnerId}/edit`);
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		}
	};

	const handleDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.partnerId === tmpData.partnerId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			await httpRequest.delete<DeleteResponseProps>(
				'/partners/' + tmpData.partnerId,
			);

			newData = data.filter((item) => item.partnerId !== tmpData.partnerId);
			setData(newData);

			message.success('Success delete ' + tmpData.partnerName);

			setIsLoadingDelete(false);
			setIsModalDeleteVisible(false);
			setTmpData(initialPartner);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalDeleteVisible(false);
			setTmpData(initialPartner);
			setIsLoadingDelete(false);
		}
	};

	React.useEffect(() => {
		if (isModalDeleteVisible) {
			setInputValue('');
		}
	}, [isModalDeleteVisible]);

	const CheckAdmin = adminPublisher.some(
		(admin) =>
			admin.userId === userData?.userId && admin.role === 'publisher_admin',
	);

	const isPublisherOwner = adminPublisher.some(
		(admin) =>
			admin.userId === userData?.userId && admin.role === 'publisher_owner',
	);

	React.useEffect(() => {
		const fetchAdminList = async () => {
			const res = await httpRequest.get<FetchAllPublisherResponse>(
				'/partner-admins',
				{
					params: {
						partnerId: partnerId,
					},
				},
			);
			const allAdmins = res.data.payload.results;

			console.log('All Admins:', allAdmins);
			setAdminPublisher(allAdmins);
		};

		fetchAdminList();
	}, [partnerId]);

	const getOwner = (partnerId: string) => {
		const admin = adminPublisher.find(
			(admin) =>
				admin.partnerId === partnerId && admin.role === 'publisher_owner',
		);
		return admin ? admin.user : null;
	};

	const columns = [
		{
			title: 'Publisher Name',
			dataIndex: 'partnerName',
			key: 'partnerName',
			render: (text: string, record: PartnerProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}{' '}
						{record.isConfirmedPartner && (
							<Tooltip placement="top" title="Verified Publihsher">
								<CheckCircleFilled style={{ color: 'green', fontSize: 14 }} />
							</Tooltip>
						)}
					</div>
				);
			},
		},
		{
			title: 'Contact Email',
			dataIndex: 'email',
			key: 'email',
			render: (text: string, record: PartnerProps) => {
				return (
					<Text>
						{record.email ? (
							record.email
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		{
			title: 'Phone Number',
			dataIndex: 'phone',
			key: 'phone',
			render: (text: string, record: PartnerProps) => {
				return (
					<Text>
						{record.phone ? (
							record.phone
						) : (
							<Text italic type="secondary">
								Not set
							</Text>
						)}
					</Text>
				);
			},
		},
		// {
		// 	title: 'Address',
		// 	dataIndex: 'address',
		// 	key: 'address',
		// 	render: (text: string, record: PartnerProps) => {
		// 		return (
		// 			<Text>
		// 				{record.address ? (
		// 					record.address
		// 				) : (
		// 					<Text italic type="secondary">
		// 						Not set
		// 					</Text>
		// 				)}
		// 			</Text>
		// 		);
		// 	},
		// },
		{
			title: 'OWNER',
			key: 'owner',
			render: (record: PartnerProps) => {
				const owner = getOwner(record.partnerId);
				return owner ? (
					<div>
						<div>{owner.name}</div>
						<div style={{ fontSize: '0.8em', color: 'rgba(0, 0, 0, 0.45)' }}>
							{owner.email}
						</div>
					</div>
				) : (
					<Text italic type="secondary">
						Not set
					</Text>
				);
			},
		},

		{
			title: 'Is Published',
			key: 'isPublished',
			width: 70,
			dataIndex: 'isPublished',
			render: (isPublished: any, record: PartnerProps) => (
				<>
					<Switch
						loading={record.statusLoading}
						checked={isPublished}
						onChange={() => {
							if (!CheckAdmin) {
								setIsModalVisible(true);
								setTmpData(record);
							}
						}}
						disabled={CheckAdmin}
					/>
				</>
			),
		},

		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: PartnerProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: PartnerProps) => {
		let actions: {} | undefined = [];

		if (isPublisherOwner) {
			actions = [
				<Menu.Item key="edit">Edit Publisher</Menu.Item>,
				<Menu.Item key="detail">Detail Publisher</Menu.Item>,
			];
		} else if (CheckAdmin) {
			actions = [<Menu.Item key="detail">Detail Publisher</Menu.Item>];
		} else {
			actions = [
				<Menu.Item key="edit">Edit Publisher</Menu.Item>,
				<Menu.Item key="detail">Detail Publisher</Menu.Item>,
				<Menu.Item key="delete">Delete Publisher</Menu.Item>,
			];
		}

		return (
			<Menu onClick={(e) => handleClickAction(record, e.key)}>{actions}</Menu>
		);
	};

	return (
		<div>
			<HeaderSection
				icon={<ReadOutlined />}
				title="Publisher"
				subtitle="Manage your Publishers"
				rightAction={
					userData?.userType !== 'customer' && (
						<Space>
							<AppButton
								type="default"
								onClick={handlePublisherTrash}
								icon={<DeleteOutlined />}
							>
								Trash
							</AppButton>
							<AppButton type="primary" onClick={handleCreatePartner}>
								Add Publisher
							</AppButton>
						</Space>
					)
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by Publisher Name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Input
					size="large"
					placeholder="Search by Owner"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) =>
						setQuery((prevQuery) => ({
							...prevQuery,
							searchByOwner: e.target.value,
						}))
					}
				/>
				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeStatusVerified}
					placeholder="Status Verification"
				>
					<Option value="all">All</Option>
					<Option value="1">Verified</Option>
					<Option value="0">Unverified</Option>
				</Select>

				<Select
					size="large"
					style={{ width: 160 }}
					onChange={handleChangeStatusPublished}
					placeholder="Status Publish"
				>
					<Option value="all">All</Option>
					<Option value="1">Published</Option>
					<Option value="0">Unpublished</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length > 0 && (
				<>
					<CustomPagination
						current={pagination.page}
						total={data.length}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger
						showTotal={(total, [start, end]) => {
							return `${start}-${end} of ${total}`;
						}}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			)}

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleUpdateIsPublished}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialPartner);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.partnerName}"</b> status to{' '}
					<b>"{!tmpData.isPublished ? 'Published' : 'Unpublished'}"</b>
					?. {!tmpData.isPublished}
				</p>
			</Modal>

			<Modal
				title="Update status confirmation"
				visible={isModalVisibleConfirmed}
				onOk={handleUpdateIsConfirmedPartner}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialPartner);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.partnerName}"</b> status to{' '}
					<b>"{!tmpData.isConfirmedPartner ? 'Confirmed' : 'Unconfirmed'}"</b>
					?. {!tmpData.isConfirmedPartner}
				</p>
			</Modal>

			<Modal
				title="Delete publisher confirmation"
				visible={isModalDeleteVisible}
				onOk={handleDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialPartner);
					setInputValue('');
				}}
				okText="Yes,delete publisher"
				cancelText="No,I changed my mind"
				confirmLoading={isLoadingDelete}
				okButtonProps={{
					style: {
						backgroundColor:
							inputValue === tmpData.partnerName ? '#d81f64' : '#d3d3d3',
						borderColor:
							inputValue === tmpData.partnerName ? '#d81f64' : '#d3d3d3',
						color: inputValue === tmpData.partnerName ? 'white' : 'gray',
					},
					disabled: inputValue !== tmpData.partnerName,
				}}
				cancelButtonProps={{
					style: {
						backgroundColor: 'white',
						borderColor: '#d81f64',
						color: '#d81f64',
					},
				}}
			>
				<p style={{ fontSize: '16px' }}>
					You will delete publisher <b>{tmpData.partnerName}</b>
				</p>
				<p style={{ fontSize: '12px' }}>
					This cannot be undone. This will permanently delete{' '}
					<b>{tmpData.partnerName}</b> publisher, and remove all collaboration
					associations in E-Book List, and Admin List.
				</p>

				<Input
					placeholder={`Type "${tmpData.partnerName}" to confirm`}
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
				/>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Publisher;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const CustomTable = styled(Table)`
	th.ant-table-cell {
		text-align: center; // Align header text to center
	}

	td.ant-table-cell {
		text-align: center; // Align cell text to center
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
