import { BaseResponseProps } from './config.type';
import { IndividualServiceProps } from './individualService.type';

export interface HealthServiceCategoryProps {
	categoryId?: string;
	categoryName: string;
	description?: string;
	isSpecial: boolean;
	individualHealthServices: IndividualServiceProps[];
	icon?: string;
	isPublished: boolean;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	statusLoading?: boolean;
}

export interface FetchAllHealthServiceCategoryResponse
	extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: HealthServiceCategoryProps[];
	};
}

export const initialHealthServiceCategory: HealthServiceCategoryProps = {
	categoryId: '',
	categoryName: '',
	isPublished: true,
	isSpecial: false,
	individualHealthServices: [],
};
