import { SafetyOutlined } from '@ant-design/icons';
import { Form, message, Space } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';

import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { generateFormRules } from '../../helpers/formRules';
import { FormInstance } from 'antd/es/form/Form';

interface ILocation {}

interface ResponseProps extends BaseResponseProps {
	payload: {
		key: string;
		value: string;
	};
}

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const ReferralInformationEdit: React.FC = () => {
	const history = useHistory();
	const location = useLocation<ILocation>();

	const formRef = React.useRef<
		FormInstance<{
			key: string;
			value: string;
		}>
	>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [referralInformation, setReferralInformation] = React.useState<{
		key: string;
		value: string;
	}>();

	const updateReferralInformation = async (props: {
		key: string;
		value: string;
	}) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/app-configs/REFERRAL_INFORMATION', props);
			message.success('Success update privacy policy');

			setIsLoadingAction(false);

			fetchReferralInformationDetail();
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (values: { key: string; value: string }) => {
		updateReferralInformation(values);
	};

	const fetchReferralInformationDetail = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<ResponseProps>(
				'/app-configs/REFERRAL_INFORMATION',
			);
			setReferralInformation(res.data.payload);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchReferralInformationDetail();
	}, [location]);

	return (
		<div>
			<HeaderSection
				icon={<SafetyOutlined />}
				title={'Edit Referral Information'}
				subtitle="Manage your Referral Information data"
				rightAction={
					<Space>
						{/* <AppButton onClick={() => history.goBack()}>Cancel</AppButton> */}
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="referralInformationForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={referralInformation}
					autoComplete="off"
				>
					<Form.Item
						name="value"
						rules={generateFormRules('Referral Information', ['required'])}
					>
						<ReactQuill
							theme="snow"
							value={referralInformation?.value || ''}
							onChange={(value) => console.log(value)}
							modules={quillModules}
							formats={quillFormats}
						/>
					</Form.Item>
				</Form>
			</AppCard>
		</div>
	);
};

export default ReferralInformationEdit;
