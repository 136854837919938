export type IconProps = {
	name: string;
	label: string;
	src: string;
};

export const iconList = [
	{
		name: 'general_practitioner',
		label: 'General Practitioner (GP)',
		src: '/specialist-icons/general_practitioner.svg',
	},
	{
		name: 'dental',
		label: 'Dentist',
		src: '/specialist-icons/dental.svg',
	},
	{
		name: 'family_medicine',
		label: 'Family Medicine',
		src: '/specialist-icons/family_medicine.svg',
	},
	{
		name: 'internal_medicine',
		label: 'Internal Medicine (IM)',
		src: '/specialist-icons/internal_medicine.svg',
	},
	{
		name: 'ear_nose_throat',
		label: 'Ear, Nose & Throat (ENT)',
		src: '/specialist-icons/ear_nose_throat.svg',
	},
	{
		name: 'obstetric_gynecology',
		label: 'Obstetric & Gynecology (OB-GYN)',
		src: '/specialist-icons/obstetric_gynecology.svg',
	},
	{
		name: 'orthopedic_surgery',
		label: 'Orthopedic Surgery',
		src: '/specialist-icons/orthopedic_surgery.svg',
	},
	{
		name: 'general_surgery',
		label: 'General Surgery',
		src: '/specialist-icons/general_surgery.svg',
	},
	{
		name: 'nutrition',
		label: 'Nutrition',
		src: '/specialist-icons/nutrition.svg',
	},
	{
		name: 'radiology',
		label: 'Radiology',
		src: '/specialist-icons/radiology.svg',
	},
	{
		name: 'pediatrician',
		label: 'Pediatrician',
		src: '/specialist-icons/pediatrician.svg',
	},
	{
		name: 'dermatology',
		label: 'Dermatology',
		src: '/specialist-icons/dermatology.svg',
	},
	{
		name: 'nuclear_medicine',
		label: 'Nuclear Medicine',
		src: '/specialist-icons/nuclear_medicine.svg',
	},
	{
		name: 'opthalmology',
		label: 'Opthalmology',
		src: '/specialist-icons/opthalmology.svg',
	},
	{
		name: 'urology',
		label: 'Urology',
		src: '/specialist-icons/urology.svg',
	},
	{
		name: 'anesthesiology',
		label: 'Anesthesiology',
		src: '/specialist-icons/anesthesiology.svg',
	},
	{
		name: 'psychiatry',
		label: 'Psychiatry',
		src: '/specialist-icons/psychiatry.svg',
	},
	{
		name: 'general_clinical_pathology',
		label: 'General/Clinical Pathology',
		src: '/specialist-icons/general_clinical_pathology.svg',
	},
	{
		name: 'occupational_medicine',
		label: 'Occupational Medicine',
		src: '/specialist-icons/occupational_medicine.svg',
	},
	{
		name: 'physiotherapist',
		label: 'Physical Medicine and Rehabilitation (Physiotherapist)',
		src: '/specialist-icons/physiotherapist.svg',
	},
	{
		name: 'preventive_medicine',
		label: 'Preventive Medicine',
		src: '/specialist-icons/preventive_medicine.svg',
	},
	{
		name: 'hematology',
		label: 'Hematology',
		src: '/specialist-icons/hematology.svg',
	},
	{
		name: 'functional_medicine',
		label: 'Functional medicine',
		src: '/specialist-icons/functional_medicine.svg',
	},
	{
		name: 'infectious_disease',
		label: 'Infectious Disease',
		src: '/specialist-icons/infectious_disease.svg',
	},
	{
		name: 'nutrition_2',
		label: 'Nutrition',
		src: '/specialist-icons/nutrition_2.svg',
	},
	{
		name: 'diabetology',
		label: 'Diabetology',
		src: '/specialist-icons/diabetology.svg',
	},
	{
		name: 'rheumatology',
		label: 'Rheumatology',
		src: '/specialist-icons/rheumatology.svg',
	},
	{
		name: 'allergology',
		label: 'Allergology',
		src: '/specialist-icons/allergology.svg',
	},
	{
		name: 'cardiovascular_thoracic_surgery',
		label: 'Cardiovascular/Thoracic Surgery',
		src: '/specialist-icons/cardiovascular_thoracic_surgery.svg',
	},
	{
		name: 'critical_care_intensivist',
		label: 'Critical Care (Intensivist)',
		src: '/specialist-icons/critical_care_intensivist.svg',
	},
	{
		name: 'geriatric_medicine',
		label: 'Geriatric Medicine',
		src: '/specialist-icons/geriatric_medicine.svg',
	},
	{
		name: 'neurosurgery',
		label: 'Neurosurgery',
		src: '/specialist-icons/neurosurgery.svg',
	},
	{
		name: 'plastic_and_reconstructive_surgery',
		label: 'Plastic and Reconstructive Surgery',
		src: '/specialist-icons/plastic_and_reconstructive_surgery.svg',
	},
	{
		name: 'neonatology',
		label: 'Neonatology',
		src: '/specialist-icons/neonatology.svg',
	},
	{
		name: 'perinatology',
		label: 'Perinatology',
		src: '/specialist-icons/Perinatology.svg',
	},
	{
		name: 'vascular_surgery',
		label: 'Vascular Surgery',
		src: '/specialist-icons/vascular_surgery.svg',
	},
	{
		name: 'hepatobiliary_surgery',
		label: 'Hepatobiliary Surgery',
		src: '/specialist-icons/hepatobiliary_surgery.svg',
	},
	{
		name: 'laparoscopic_surgery',
		label: 'Laparoscopic Surgery',
		src: '/specialist-icons/laparoscopic_surgery.svg',
	},
	{
		name: 'sleep_disorder',
		label: 'Sleep Disorder',
		src: '/specialist-icons/sleep_disorder.svg',
	},
];
