import { Col, Image, Row, Tag, Typography } from 'antd';
import React from 'react';
import { formatDate } from '../../helpers/constant';

export default function SelectedVoucher({ content }: any) {
	const { Text } = Typography;

	const renderVoucherStatus = (text: string) => {
		if (text === 'Available' || text === 'available') {
			return (
				<Tag
					style={{
						color: '#56C288',
						backgroundColor: '#F5FFF9',
						border: '1px solid #56C288',
					}}
				>
					{text}
				</Tag>
			);
		} else if (text === 'Pending' || text === 'pending') {
			return (
				<Tag
					style={{
						color: '#FAB347',
						backgroundColor: '#FFF9EF',
						border: '1px solid #FAB347',
					}}
				>
					{text}
				</Tag>
			);
		} else if (text === 'Expired' || text === 'expired') {
			return (
				<Tag
					style={{
						color: '#F23459',
						backgroundColor: '#FFF9FB',
						border: '1px solid #F23459',
					}}
				>
					{text}
				</Tag>
			);
		}
	};

	return (
		<div
			style={{
				height: '120px',
			}}
		>
			<Row gutter={[16, 16]}>
				<Col>
					<Image
						width={120}
						height={100}
						src={content.imageUrl}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				</Col>

				<Col span={10}>
					<Row>
						<Text
							style={{
								fontWeight: 'bold',
								fontSize: '14px',
								marginBottom: '5px',
							}}
						>
							{content.voucherName}
						</Text>
					</Row>
					<Row>
						{renderVoucherStatus(content.voucherStatus)}
						<Text
							style={{
								fontWeight: 'bold',
								fontSize: '14px',
							}}
						>
							{content.voucherCode}
						</Text>
					</Row>
					<Row>
						{
							<div style={{ marginTop: '5px' }}>{`Expired : ${formatDate(
								content.validEndAt,
							)}`}</div>
						}
					</Row>
					<Row>
						{<div>{`Created : ${formatDate(content.validStartAt)}`}</div>}
					</Row>
				</Col>
			</Row>
		</div>
	);
}
