import { MoreOutlined, TagOutlined } from '@ant-design/icons';
import {
	Col,
	Collapse,
	Dropdown,
	Image,
	Input,
	Menu,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { iconList, IconProps } from '../../components/IconList';
import { httpRequest } from '../../helpers/api';
import useFetchList from '../../hooks/useFetchList';
import {
	initialSpecialist,
	SpecialistProps,
} from '../../types/specialist.type';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

const Specialization = () => {
	const { isLoading, data, fetchList } = useFetchList<SpecialistProps>({
		endpoint: 'specialists',
		initialQuery: {
			sort: 'specialistName:ASC',
			limit: 100000,
		},
	});

	const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
	const [tmpData, setTmpData] =
		React.useState<SpecialistProps>(initialSpecialist);
	const [selectedSpec, setSelectedSpec] =
		React.useState<SpecialistProps>(initialSpecialist);
	const [isLoadingUpdate, setIsLoadingUpdate] = React.useState<boolean>(false);
	const [selectedIcon, setSelectedIcon] = React.useState<IconProps | undefined>(
		undefined,
	);

	const handleDelete = async (specialistId: string | undefined) => {
		if (specialistId) {
			await httpRequest.delete('specialists/' + specialistId);
		}

		setIsShowModal(false);
		fetchList();
	};

	const handleUpdateCreate = async () => {
		setIsLoadingUpdate(true);
		if (tmpData.specialistId) {
			const formData = {
				specialistName: tmpData.specialistName,
				icon: selectedIcon?.name,
			};

			await httpRequest
				.patch('/specialists/' + tmpData.specialistId, formData)
				.then((res) => {
					if (res.status == 200) {
						setIsLoadingUpdate(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			const formData = {
				specialistName: tmpData.specialistName,
				icon: selectedIcon?.name,
			};

			await httpRequest
				.post('/specialists', formData)
				.then((res) => {
					if (res.status === 200) {
						setIsLoadingUpdate(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}

		setIsLoadingUpdate(false);
		setIsShowModal(false);
		fetchList();
	};

	const handleChangeIcon = (iconValue: string) => {
		const findIcon = iconList.find((icon) => icon.name === iconValue);
		if (findIcon) {
			// const url = '../../' + findIcon.src
			// setImageUrl(url)
			setSelectedIcon(findIcon);
		}
	};

	const menuBasic = (record: SpecialistProps) => (
		<Space>
			<Dropdown
				overlay={
					<Menu
						onClick={({ key }) => {
							if (key === 'edit') {
								setIsShowModal(true);
								setTmpData(record);
								setSelectedSpec(record);

								const findIcon = iconList.find(
									(icon) => icon.name === record.icon,
								);

								if (findIcon) {
									setSelectedIcon(findIcon);
								}
							} else if (key === 'delete') {
								handleDelete(record.specialistId);
							}
						}}
					>
						<Menu.Item key="edit">Edit</Menu.Item>
						<Menu.Item key="delete">Delete</Menu.Item>
					</Menu>
				}
				placement="bottomRight"
			>
				<MoreOutlined style={{ cursor: 'pointer', color: 'black' }} />
			</Dropdown>
		</Space>
	);

	const findIcon = (name: string | undefined): string => {
		const findIcon = iconList.find((icon) => icon.name === name);
		if (findIcon) {
			return findIcon.src;
		} else {
			return '/images/select-image.jpg';
		}
	};

	return (
		<div>
			<HeaderSection
				icon={<TagOutlined />}
				title="Doctor Specialization"
				subtitle="Manage your doctor specialization data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => {
								setIsShowModal(true);
								setTmpData(initialSpecialist);
								setSelectedSpec(initialSpecialist);
								setSelectedIcon(undefined);
							}}
						>
							Add Basic
						</AppButton>
					</Space>
				}
			/>

			{isLoading ? (
				<Spin
					tip="Loading..."
					style={{
						position: 'relative',
						left: '45%',
						top: '40%',
					}}
				/>
			) : (
				data.map((sepcialist, index) => (
					<CustomCollapse
						key={'sepcialist_' + index}
						expandIcon={({ isActive }) => (
							<Image
								preview={false}
								width={32}
								height={32}
								src={findIcon(sepcialist.icon) || '/images/select-image.jpg'}
								fallback={'/images/blur-image.jpeg'}
							/>
						)}
					>
						<Panel
							style={{ marginBottom: '4px' }}
							showArrow={false}
							header={
								<Row
									align="middle"
									wrap={false}
									style={{ minWidth: '32em' }}
									gutter={[16, 16]}
								>
									<Col>
										<Image
											preview={false}
											width={32}
											height={32}
											src={
												findIcon(sepcialist.icon) || '/images/select-image.jpg'
											}
											fallback={'/images/blur-image.jpeg'}
										/>
									</Col>
									<Col>
										<Text>{sepcialist.specialistName}</Text>
									</Col>
								</Row>
							}
							key={sepcialist.specialistId || index}
							extra={menuBasic(sepcialist)}
						>
							<Row>
								<Text>{`Specialist Name: `}</Text>
								<Text strong>{sepcialist.specialistName}</Text>
							</Row>
							<Row>
								<Text>{`Specialist Alias Name: `}</Text>
								<Text strong>
									{sepcialist.specialistAliasName
										? sepcialist.specialistAliasName
										: ''}
								</Text>
							</Row>
						</Panel>
					</CustomCollapse>
				))
			)}

			<Modal
				title={
					selectedSpec.specialistId
						? `Edit ${selectedSpec.specialistName}`
						: `Add Specialization`
				}
				visible={isShowModal}
				onOk={handleUpdateCreate}
				onCancel={() => {
					setIsShowModal(false);
					setTmpData(initialSpecialist);
					setSelectedSpec(initialSpecialist);
					setSelectedIcon(undefined);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdate}
				okButtonProps={{ type: 'primary' }}
			>
				<h4>Icon</h4>

				<Image
					preview={false}
					width="100%"
					height={250}
					src={selectedIcon?.src || '/images/select-image.jpg'}
					fallback={'/images/blur-image.jpeg'}
					placeholder={
						<Image
							preview={false}
							src="/images/blur-image.jpeg"
							width="100%"
							height={200}
							style={{ objectFit: 'cover' }}
						/>
					}
				/>
				<Select
					allowClear
					style={{
						width: '100%',
						paddingBottom: '2%',
						paddingTop: '2%',
					}}
					placeholder="Please select"
					onChange={(e) => handleChangeIcon(e)}
					key={'iconSelect'}
					value={selectedIcon?.name ? selectedIcon.name : ''}
				>
					{iconList.map((icon, index) => {
						return (
							<Option key={`icon${index}`} value={String(icon.name)}>
								{icon.label}
							</Option>
						);
					})}
				</Select>

				<br></br>
				<Text
					style={{
						paddingTop: '15%',
					}}
				>
					Specialist Name
				</Text>
				<Input
					value={tmpData.specialistName}
					onChange={(e) =>
						setTmpData({
							...tmpData,
							specialistName: e.target.value,
						})
					}
				/>
			</Modal>
		</div>
	);
};

export default Specialization;

const CustomCollapse = styled(Collapse)`
	align-items: center;
`;
