import { AppstoreAddOutlined, SearchOutlined } from '@ant-design/icons';
import { DatePicker, Input, Pagination, Select, Table, Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSection from '../../../components/HeaderSection';
import { getFullName } from '../../../helpers/name';
import useFetchList from '../../../hooks/useFetchList';

const TransactionPrescriptionLabRequset = (props: { isTest?: boolean }) => {
	const { Option } = Select;
	const { RangePicker } = DatePicker;
	const [filteredData, setFilteredData] = useState<any>();
	const [status, setStatus] = useState<string>('all');

	const [activeKey, setActiveKey] = useState<string>('1');

	const { TabPane } = Tabs;

	const history = useHistory();

	const { isLoading, data, pagination, setSearch, setQuery, changePage } =
		useFetchList<any>({
			endpoint: 'rx-prescriptions',
			limit: 25,
			initialQuery: {
				isTestAccount: props.isTest,
				isTemporary: 'false',
			},
		});

	const {
		isLoading: isLoadingLab,
		data: dataLab,
		pagination: paginationLab,
		setSearch: setSearchLab,
		setQuery: setQueryLab,
		changePage: changePageLab,
	} = useFetchList<any>({
		endpoint: 'lab-requests',
		limit: 25,
		initialQuery: {
			isTestAccount: props.isTest,
			isTemporary: 'false',
		},
	});

	const {
		isLoading: isLoadingDoc,
		data: dataDoc,
		pagination: paginationDoc,
		setSearch: setSearchDoc,
		setQuery: setQueryDoc,
		changePage: changePageDoc,
	} = useFetchList<any>({
		endpoint: 'doctor-documents',
		limit: 25,
		initialQuery: {
			isTestAccount: props.isTest,
			isTemporary: 'false',
		},
	});

	useEffect(() => {
		let data = dataDoc.filter((doc) => doc.payment == null);
		setFilteredData(data);
	}, [dataDoc]);

	const handleChangeTab = async (key: string) => {
		setActiveKey(key);
	};

	const handleFilterByStatus = (status: string) => {
		setStatus(status);
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, statusType: status }));
			setQueryLab((oldVal) => ({ ...oldVal, statusType: status }));
			setQueryDoc((oldVal) => ({ ...oldVal, statusType: status }));
		} else {
			setQuery((oldVal) => ({
				...oldVal,
				statusType: undefined,
			}));
			setQueryLab((oldVal) => ({
				...oldVal,
				statusType: undefined,
			}));
			setQueryDoc((oldVal) => ({
				...oldVal,
				statusType: undefined,
			}));
		}
	};

	const handleClickDetail = (e: any) => {
		history.push(
			`/transaction-prescription-labrequest/${e.transactionConsultationId}/` +
				(e.labRequestId
					? e.labRequestId
					: e.rxPrescriptionId
					? e.rxPrescriptionId
					: e.doctorDocumentId),
		);
	};

	const handleFilterByDate = (date: any) => {
		if (date) {
			setQuery((oldVal) => ({ ...oldVal, filterDate: date }));
			setQueryLab((oldVal) => ({ ...oldVal, filterDate: date }));
			setQueryDoc((oldVal) => ({ ...oldVal, filterDate: date }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterDate: undefined }));
			setQueryLab((oldVal) => ({ ...oldVal, filterDate: undefined }));
			setQueryDoc((oldVal) => ({ ...oldVal, filterDate: undefined }));
		}
	};

	const handleAllSearch = (e: any) => {
		setSearch(e);
		setSearchLab(e);
		setSearchDoc(e);
	};

	function isExpired(validUntil: Date) {
		const currentDate = new Date();
		const validUntilDate = new Date(validUntil);
		return currentDate > validUntilDate;
	}

	const columnRx = [
		{
			title: 'PARTICIPANTS',

			render: (record: any) => {
				return (
					<div
						onClick={() => handleClickDetail(record)}
						style={{ cursor: 'pointer' }}
					>
						<div>
							{record.metaPatient?.name || getFullName(record.metaPatient)}
						</div>
						<div>{record.doctor?.nameOfSignature || 'No Doctor'}</div>
					</div>
				);
			},
		},

		{
			title: 'STATUS',

			render: (record: any) => {
				if (isExpired(record.validUntil)) {
					return (
						<div
							style={{
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								padding: 2,
								textAlign: 'center',
								// borderColor: "#FFCCD7",
								border: '1px solid',
							}}
						>
							Expired
						</div>
					);
				} else {
					return (
						<div
							style={{
								color: '#39AC6E',
								backgroundColor: '#F5FFF9',
								padding: 2,
								textAlign: 'center',
								border: '1px solid',
							}}
						>
							Free
						</div>
					);
				}
			},
		},

		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'VALID UNTIL',
			dataIndex: 'validUntil',
			key: 'validUntil',
			render: (validUntil: any) => (
				<div>{moment(validUntil).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
	];

	const columnLab = [
		{
			title: 'PARTICIPANTS',

			render: (record: any) => {
				return (
					<div
						onClick={() => handleClickDetail(record)}
						style={{ cursor: 'pointer' }}
					>
						<div>
							{record.metaPatient?.name || getFullName(record.metaPatient)}
						</div>
						<div>{record.doctor?.nameOfSignature || 'No Doctor'}</div>
					</div>
				);
			},
		},

		{
			title: 'STATUS',

			render: (record: any) => {
				if (isExpired(record.validUntil)) {
					return (
						<div
							style={{
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								padding: 2,
								textAlign: 'center',
								// borderColor: "#FFCCD7",
								border: '1px solid',
							}}
						>
							Expired
						</div>
					);
				} else {
					return (
						<div
							style={{
								color: '#39AC6E',
								backgroundColor: '#F5FFF9',
								padding: 2,
								textAlign: 'center',
								border: '1px solid',
							}}
						>
							Free
						</div>
					);
				}
			},
		},

		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'VALID UNTIL',
			dataIndex: 'validUntil',
			key: 'validUntil',
			render: (validUntil: any) => (
				<div>{moment(validUntil).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
	];

	const columnDoc = [
		{
			title: 'INVOICE ID',
			width: 150,
			render: (record: any) => {
				if (record?.payment?.invoiceId) {
					return (
						<div
							onClick={() => handleClickDetail(record)}
							style={{ cursor: 'pointer' }}
						>
							{record.payment.invoiceId}
						</div>
					);
				} else {
					return (
						<div
							onClick={() => handleClickDetail(record)}
							style={{ cursor: 'pointer' }}
						>
							{record?.doctorDocumentId || '-'}
						</div>
					);
				}
			},
		},
		{
			title: 'PARTICIPANTS',

			render: (record: any) => {
				return (
					<>
						<div>
							{record.metaPatient?.name || getFullName(record.metaPatient)}
						</div>
						<div>{record.doctor?.nameOfSignature || 'No Doctor'}</div>
					</>
				);
			},
		},

		{
			title: 'STATUS',

			render: (record: any) => {
				if (isExpired(record.validUntil)) {
					return (
						<div
							style={{
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								padding: 2,
								textAlign: 'center',
								// borderColor: "#FFCCD7",
								border: '1px solid',
							}}
						>
							Expired
						</div>
					);
				} else if (record.payment) {
					return (
						<div
							style={{
								color: '#39AC6E',
								backgroundColor: '#F5FFF9',
								padding: 2,
								textAlign: 'center',
								// borderColor: "#C2F0D7",
								border: '1px solid',
							}}
						>
							Paid
						</div>
					);
				} else if (record.price == 0 || record.price == null) {
					return (
						<div
							style={{
								color: '#39AC6E',
								backgroundColor: '#F5FFF9',
								padding: 2,
								textAlign: 'center',
								// borderColor: "#C2F0D7",
								border: '1px solid',
							}}
						>
							Free
						</div>
					);
				} else {
					return (
						<div
							style={{
								color: '#E89635',
								backgroundColor: '#FFF9EF',
								padding: 2,
								textAlign: 'center',
								// borderColor: "#FFCCD7",
								border: '1px solid',
							}}
						>
							Unpaid
						</div>
					);
				}
			},
		},
		{
			title: 'TOTAL PAY',

			render: (record: any) => {
				if (record.price) {
					return <div>₱ {record.price}</div>;
				} else {
					return <div>-</div>;
				}
			},
		},

		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'VALID UNTIL',
			dataIndex: 'validUntil',
			key: 'validUntil',
			render: (validUntil: any) => (
				<div>{moment(validUntil).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
	];

	return (
		<>
			<div>
				<HeaderSection
					icon={<AppstoreAddOutlined />}
					title="Prescription,  Lab Request & Doctor Document"
					subtitle="Manage your prescription,lab Request, and doctor document"
				/>

				<ContainerFilter>
					<Input
						style={{ width: 550 }}
						size="large"
						placeholder="Search Patient name or Doctor Name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => handleAllSearch(e.target.value)}
					/>
					<RangePicker
						size="large"
						allowEmpty={[true, true]}
						onCalendarChange={handleFilterByDate}
						showHour={false}
						showMinute={false}
						showSecond={false}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 240 }}
						onChange={handleFilterByStatus}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="FREE">Free</Option>
						{activeKey == '3' && (
							<>
								<Option value="PAID_FULL">Paid</Option>
								<Option value="UNPAID">Unpaid</Option>
							</>
						)}
						<Option value="EXPIRED">Expired</Option>
					</Select>
				</ContainerFilter>
				<div style={{ backgroundColor: 'white', padding: '1%' }}>
					<Tabs
						defaultActiveKey={activeKey}
						activeKey={activeKey}
						onChange={(e) => handleChangeTab(e)}
					>
						<TabPane tab="Rx Prescription" key={'1'}>
							<Table
								loading={isLoading}
								columns={columnRx}
								dataSource={data}
								pagination={false}
							/>
							{data?.length !== 0 ? (
								<CustomPagination
									current={pagination.page}
									total={pagination.totalData}
									defaultPageSize={pagination.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]} - ${range[1]} of ${total}`
									}
									onChange={changePage}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
							) : null}

							<PaginationText>
								<h4>Per Page</h4>
							</PaginationText>
						</TabPane>
						<TabPane tab="Lab Request" key={'2'}>
							<Table
								loading={isLoadingLab}
								columns={columnLab}
								dataSource={dataLab}
								pagination={false}
							/>
							{dataLab?.length !== 0 ? (
								<CustomPagination
									current={paginationLab.page}
									total={paginationLab.totalData}
									defaultPageSize={paginationLab.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]} - ${range[1]} of ${total}`
									}
									onChange={changePageLab}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
							) : null}

							<PaginationText>
								<h4>Per Page</h4>
							</PaginationText>
						</TabPane>
						<TabPane tab="Doctor Document" key={'3'}>
							<Table
								loading={isLoadingDoc}
								columns={columnDoc}
								dataSource={status == 'UNPAID' ? filteredData : dataDoc}
								pagination={false}
							/>
							{dataDoc?.length !== 0 ? (
								<CustomPagination
									current={paginationDoc.page}
									total={paginationDoc.totalData}
									defaultPageSize={paginationDoc.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]} - ${range[1]} of ${total}`
									}
									onChange={changePageDoc}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
							) : null}

							<PaginationText>
								<h4>Per Page</h4>
							</PaginationText>
						</TabPane>
					</Tabs>
				</div>
			</div>
		</>
	);
};

export default TransactionPrescriptionLabRequset;

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
