import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import { initialUser, IUser } from '../../types/user.type';

interface ILocation {
	userId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<IUser, 'createdAt' | 'updatedAt'>;
}

const SecretaryDetail = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { userId } = useParams<ILocation>();
	const location = useLocation<ILocation>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [user, setUser] = React.useState<IUser>({ ...initialUser });
	const [secretaryList, setSecretaryList] = React.useState<any[]>([]);

	console.log(userId);

	React.useEffect(() => {
		const fetchUser = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>('/user/' + userId);

				console.log('res', res);

				setUser(res.data.payload);

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'userId',
						value: userId,
						label: res.data.payload.name || '',
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error: any) {
				console.log(error);
				setIsLoading(false);
			}
		};
		fetchUser();
	}, [userId]);

	React.useEffect(() => {
		let newGroupSpecialists = user?.secretary?.reduce(function (
			storage: any,
			item: any,
		) {
			const data = {
				doctor: item.doctor || {},
				partners: [{ partner: item.partner, status: item.status }],
			};

			let group = item['doctorId'];

			storage[group] = storage[group] || {};
			const storageLength = Object.keys(storage[group]).length;
			if (storageLength > 0) {
				storage[group] = {
					...storage[group],
					partners: [
						...(storage[group].partners || []),
						...(data.partners || []),
					],
				};
			} else {
				storage[group] = data;
			}
			return storage;
		}, {});

		setSecretaryList(newGroupSpecialists || []);
	}, [user]);

	console.log(secretaryList);
	return (
		<div>
			<HeaderSection
				icon="back"
				title={user.name || ''}
				subtitle="Manage your secretary data"
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Secretary Information">
					<DetailItem label="Name" content={user.name} />
					<DetailItem label="Middle Name" content={user.middleName} />
					<DetailItem label="Last Name" content={user.lastName} />
					<DetailItem label="Phone Number" content={user.phone} />
					<DetailItem
						label="Gender"
						content={
							user.gender &&
							user.gender.charAt(0).toUpperCase() + user.gender.slice(1)
						}
					/>
					<DetailItem
						label="Birthday"
						content={moment(user.birthdate).format('DD MMM YYYY')}
					/>
					<DetailItem label="Email Address" content={user.email} />
				</SectionContent>
				<SectionContent groupTitle="Secretary Doctor List">
					{(user.secretary || []).length > 0 ? (
						(Object.keys(secretaryList) || [])?.map(
							(specialistId: any, index: number) => {
								const secretary = secretaryList[specialistId];

								return (
									<Row>
										<Col span={5}>
											<DetailItem
												label={'Doctor Name'}
												content={secretary.doctor.nameOfSignature}
											/>
										</Col>
										<Col span={18}>
											{secretary.partners?.map((item: any) => (
												<Row gutter={[24, 16]}>
													<Col span={6}>
														<DetailItem
															label={'Partner Name'}
															content={item.partner?.partnerName}
														/>
													</Col>
													<Col span={8}>
														<DetailItem
															label={'Partner Address'}
															content={item.partner?.address}
														/>
													</Col>
													<Col span={4}>
														<DetailItem
															label={'Partner Type'}
															content={item.partner?.partnerType}
														/>
													</Col>
													<Col span={4}>
														<DetailItem
															label={'Secretary Status'}
															content={item.status}
														/>
													</Col>
												</Row>
											))}
										</Col>
									</Row>
								);
							},
						)
					) : (
						<DetailItem label={''} content={''} />
					)}
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default SecretaryDetail;
