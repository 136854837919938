import React from 'react';
import { Button, Layout, Tooltip } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import UserAvatar from '../../components/UserAvatar';
import AppMenuSidebar from '../../components/AppMenuSidebar';
import { appVersion, now, thisYear } from '../../helpers/constant';
import useConfigApp from '../../hooks/useConfigApp';
import AppLogo from '../../components/AppLogo';
import Breadcrumbs from '../../components/Breadcrumbs';

const { Header, Sider, Content } = Layout;

const AppLayout: React.FC = ({ children }) => {
	const { sidebarCollapsed, toggleSidebar } = useConfigApp();

	return (
		<Layout style={{ height: '100vh', flexDirection: 'row' }}>
			<AppSider
				onCollapse={toggleSidebar}
				trigger={null}
				collapsible
				collapsed={sidebarCollapsed}
				collapsedWidth={70}
				width={240}
			>
				<AppLogo collapsed={sidebarCollapsed} />
				<AppMenuSidebar />
			</AppSider>
			{sidebarCollapsed ? (
				<Tooltip placement="right" title={'Expand Sidebar'}>
					<Button
						style={{
							position: 'absolute',
							bottom: 0,
							background: '#1D2B36',
							height: 40,
							width: 70,
						}}
						onClick={toggleSidebar}
						type="text"
						icon={
							<RightCircleOutlined color="#FFFFFF" className="icon-collapsed" />
						}
					/>
				</Tooltip>
			) : (
				<MinimizeButton
					onClick={toggleSidebar}
					style={{
						position: 'absolute',
						bottom: 0,
						background: '#1D2B36',
						height: 40,
						width: 240,
					}}
				>
					<div>
						v{appVersion} · ©{thisYear}
					</div>
					<LeftCircleOutlined color="#FFFFFF" />
				</MinimizeButton>
			)}

			<Layout
				className="site-layout"
				style={{ height: '100vh', overflowY: 'scroll' }}
			>
				<AppHeader>
					<Breadcrumbs />
					<AppHeaderAccount>
						<DateNow>{now}</DateNow>
						<DividerTitle />
						<UserAvatar />
					</AppHeaderAccount>
				</AppHeader>
				<AppContent>{children}</AppContent>
			</Layout>
		</Layout>
	);
};

const AppContent = styled(Content)`
	height: 100%;
	padding: 10px 20px 20px 20px;
	background-color: ${({ theme }) => theme.colors.ash300};
`;

const AppSider = styled(Sider)`
	background-color: ${({ theme }) => theme.colors.charcoal800};
	overflow: auto;
	overflow-x: hidden;
`;

const AppHeader = styled(Header)`
	background-color: ${({ theme }) => theme.colors.ash300};
	position: relative;
	padding: 0 20px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DateNow = styled.div`
	color: ${({ theme }) => theme.colors.black};
	text-align: right;
`;

const AppHeaderAccount = styled.div`
	flex: 1;
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr auto auto;
	align-items: center;
	justify-content: flex-end;
`;

const DividerTitle = styled.div`
	width: 1px;
	height: 40%;
	background-color: ${({ theme }) => theme.colors.ash800};
	opacity: 0.4;
	margin: 0 10px;
`;

const MinimizeButton = styled.div`
	width: 100%;
	display: flex;
	height: 60px;
	align-items: center;
	justify-content: space-between;
	padding: 0px 16px;
	cursor: pointer;

	div {
		color: ${({ theme }) => theme.colors.white};
		font-size: 10px;
	}

	svg {
		fill: ${({ theme }) => theme.colors.white};
	}
`;

export default AppLayout;
