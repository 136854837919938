import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as HardDriveGreyIcon } from '../../assets/icons/hard_drive_grey.svg';
import {
	Pagination,
	Space,
	Table,
	Dropdown,
	Menu,
	Input,
	Typography,
	Col,
	Row,
	DatePicker,
	Switch,
	Modal,
	message,
	Tooltip,
} from 'antd';
import { httpRequest } from '../../helpers/api';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import { DrugMedicineProps, initialMedicine } from '../../types/medicine.type';
import { PartnerProps } from '../../types/partner.type';
import { PharmacyMedicineProps } from '../../types/medicine.type';
import moment from 'moment';

const { Text } = Typography;

interface IParams {
	partnerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface ResponseMedicineProps extends BaseResponseProps {
	payload: Omit<DrugMedicineProps, 'createdAt' | 'updatedAt'>;
}

const DetailPharmacy = () => {
	const history = useHistory();
	const { partnerId } = useParams<IParams>();

	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] =
		React.useState<DrugMedicineProps>(initialMedicine);

	const [partnerName, setPartnerName] = React.useState<string>('Pharmacy');

	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
		setIsLoading,
	} = useFetchList<PharmacyMedicineProps>({
		endpoint: 'drug-medicines',
		initialQuery: {
			partnerId: partnerId,
		},
	});

	console.log(data);

	const handleFilterByDate = (date: any) => {
		if (date) {
			setQuery((oldVal) => ({ ...oldVal, filterDate: date }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterDate: undefined }));
		}
	};

	const handleClickDetail = (props: PharmacyMedicineProps) => {
		history.push(`/pharmacy/${partnerId}/details/${props.drugMedicineId}`);
	};

	const handleClickAction = (props: PharmacyMedicineProps, key: string) => {
		if (key === 'detail') {
			history.push(`/pharmacy/${partnerId}/details/${props.drugMedicineId}`);
		} else if (key === 'edit') {
			history.push(`/medicine/${props.drugMedicineId}/edit`);
		}
	};

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.drugMedicineId === tmpData.drugMedicineId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseMedicineProps>(
				'/drug-medicines/' + tmpData.drugMedicineId,
				{
					drugMedicineId: tmpData.drugMedicineId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.drugMedicineId === res.data.payload.drugMedicineId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success(
				'Success change ' + tmpData.drugMedicineName + ' status.',
			);

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialMedicine);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialMedicine);
			setIsLoadingUpdateStatus(false);
		}
	};

	const fetchPharmacyName = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<ResponseProps>(
				'/partners/' + partnerId,
			);
			setPartnerName(res.data.payload.partnerName);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		if (partnerId) {
			fetchPharmacyName();
		}
	}, []);

	const columns = [
		{
			title: 'MEDICINE NAME',
			dataIndex: 'drugMedicineName',
			key: 'drugMedicineName',
			render: (text: string, record: PharmacyMedicineProps) => {
				return record.drugMedicineGenericName ? (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.drugMedicineGenericName}({record.drugMedicineName})
					</div>
				) : (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.drugMedicineName}
					</div>
				);
			},
		},
		{
			title: 'PUBLISHED',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (status: any, record: PharmacyMedicineProps) => (
				<>
					{
						<Tooltip
							title={
								record.partner && !record.partner?.isPublished
									? 'Pharmacy status is unpublished'
									: ''
							}
						>
							<Switch
								loading={record.statusLoading}
								checked={record.isPublished}
								onChange={() => {
									setIsModalVisible(true);
									setTmpData(record);
								}}
								disabled={record.partner && !record.partner?.isPublished}
							/>
						</Tooltip>
					}
				</>
			),
		},
		{
			title: 'TOTAL PRESCRIBED',
			dataIndex: 'totalPrescribed',
			key: 'totalPrescribed',
			render: (text: string, record: PharmacyMedicineProps) => {
				return <Text>{record.medicine?.totalPrescribed}</Text>;
			},
		},
		{
			title: 'TOTAL QUANTITY',
			dataIndex: 'totalPrescribedXQuantity',
			key: 'totalPrescribedXQuantity',
			render: (text: string, record: PharmacyMedicineProps) => {
				return <Text>{record.medicine?.totalPrescribedXQuantity}</Text>;
			},
		},
		{
			title: 'POINTS PER QUANTITY',
			dataIndex: 'pointPerPrescribed',
			key: 'pointPerPrescribed',
			render: (text: string, record: PharmacyMedicineProps) => {
				return <Text>{record.points || '-'}</Text>;
			},
		},
		{
			title: 'TOTAL POINTS',
			dataIndex: 'totalPoints',
			key: 'totalPoints',
			render: (text: string, record: PharmacyMedicineProps) => {
				return <Text>{record.medicine?.totalPointsXQuantity || '-'}</Text>;
			},
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: PharmacyMedicineProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: PharmacyMedicineProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit Medicine</Menu.Item>
			<Menu.Item key="detail">View Prescription History</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						icon={'back'}
						title={partnerName}
						subtitle="Manage your pharmacy data"
					/>
				</Col>
				<Col flex={1}>
					<Space>
						<AppButton
							type="primary"
							onClick={() => {
								history.push(`/pharmacy/${partnerId}/edit`);
							}}
						>
							Edit Pharmacy
						</AppButton>
					</Space>
					<Space
						style={{
							paddingLeft: '2%',
						}}
					></Space>
				</Col>
			</Row>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<CustoRangePicker
					size="large"
					allowEmpty={[true, true]}
					onCalendarChange={handleFilterByDate}
					showTime={{
						hideDisabledOptions: true,
						defaultValue: [
							moment('00:00:00', 'HH:mm:ss'),
							moment('23:59:59', 'HH:mm:ss'),
						],
					}}
					showHour={false}
					showMinute={false}
					showSecond={false}
				/>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) =>
							`${range[0]} - ${range[1]} of ${total}`
						}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}
			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialMedicine);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.drugMedicineName}"</b> status
					to <b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
					{!tmpData.isPublished}
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default DetailPharmacy;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

const CustoRangePicker = styled(DatePicker.RangePicker)`
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: 1px;
		margin-right: 10px;
		order: -1;
	}
	.ant-picker.ant-picker-range {
		display: flex;
		flex-direction: row-reverse;
	}
`;
