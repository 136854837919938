import {
	Form,
	FormInstance,
	Image,
	Input,
	message,
	Radio,
	Select,
	Space,
	Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { IconProps } from '../../components/IconList';
import { medicalServiceIconList } from '../../components/MedicalServiceIconList';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { generateFormRules } from '../../helpers/formRules';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import {
	HealthServiceCategoryProps,
	initialHealthServiceCategory,
} from '../../types/healthServiceCategory.type';

const { Option } = Select;

interface IParams {
	categoryId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<HealthServiceCategoryProps, 'createdAt' | 'updatedAt'>;
}

const HealthServiceCategoryEdit: React.FC = () => {
	const { Text } = Typography;

	const history = useHistory();
	const { categoryId } = useParams<IParams>();
	const formRef =
		React.useRef<
			FormInstance<Omit<HealthServiceCategoryProps, 'createdAt' | 'updatedAt'>>
		>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [categoryName, setCategoryName] = React.useState<string>('');
	const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
	const [searchValue] = useDebounce(categoryName, 500);
	const [showWarning, setShowWarning] = React.useState<boolean>(false);
	const [healthServiceCategory, setCategory] =
		React.useState<HealthServiceCategoryProps>(initialHealthServiceCategory);
	const [selectedIcon, setSelectedIcon] = React.useState<IconProps | undefined>(
		undefined,
	);

	const { data } = useFetchList<HealthServiceCategoryProps>({
		apiRequest: httpRequest,
		endpoint: 'health-service/categories',
		initialQuery: {
			limit: 10000,
			offset: 0,
		},
	});

	React.useEffect(() => {
		const res: any = data.filter(
			(item) =>
				categoryId !== item.categoryId && searchValue === item.categoryName,
		);
		setIsDuplicate(res.length > 0 ? true : false);
	}, [searchValue]);

	const createCategory = async (
		props: Omit<
			HealthServiceCategoryProps,
			'createdAt' | 'updatedAt' | 'categoryId' | 'statusLoading'
		>,
	) => {
		console.log(selectedIcon);
		if (selectedIcon == undefined) {
			message.error('Please Select Icon');
			return false;
		}

		try {
			setIsLoadingAction(true);
			const res: any = await httpRequest.post('/health-service/categories', {
				...props,
				isSpecial: false,
				icon: selectedIcon?.name,
			});

			if (res && res.data) {
				// await uploadNewImage(res.data.payload.categoryId);
			}

			message.success('Success create ' + props.categoryName);
			history.push('/medical-service-category');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateCategory = async (
		props: Omit<
			HealthServiceCategoryProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);

			if (categoryId) {
				await Promise.all([
					httpRequest.patch('/health-service/categories/' + categoryId, {
						...props,
						icon: selectedIcon?.name,
					}),
					// uploadNewImage(categoryId),
				]);
			}
			message.success('Success update ' + props.categoryName + ' data');
			history.push('/medical-service-category');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<
			HealthServiceCategoryProps,
			'createdAt' | 'updatedAt' | 'statusLoading'
		>,
	) => {
		if (categoryId) {
			updateCategory(values);
		} else {
			createCategory(values);
		}
	};

	const handleChangeIcon = (iconValue: string) => {
		const findIcon = medicalServiceIconList.find(
			(icon) => icon.name === iconValue,
		);
		if (findIcon) {
			// const url = '../../' + findIcon.src
			// setImageUrl(url)
			setSelectedIcon(findIcon);
		}
	};

	React.useEffect(() => {
		if (categoryId) {
			const fetchCategory = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/health-service/categories/' + categoryId,
					);

					setCategory(res.data.payload);
					if (res.data.payload.icon) {
						handleChangeIcon(res.data.payload.icon);
						setIsLoading(false);
					}
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchCategory();
		}
	}, [categoryId]);

	const handleCategoryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setCategoryName(value);
		setShowWarning(value.length > 100);
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(categoryId ? 'Edit' : 'Add') + ' Category'}
				subtitle="Manage your medical category data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							disabled={isDuplicate}
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={
						categoryId ? healthServiceCategory : initialHealthServiceCategory
					}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Category Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="Name"
							name="categoryName"
							rules={generateFormRules('Name', ['required'])}
						>
							<Input onChange={handleCategoryNameChange} value={categoryName} />
						</Form.Item>
						{isDuplicate && (
							<Text style={{ color: 'red', fontSize: 13 }}>
								Category Name Already Exist!
							</Text>
						)}
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Category Name should not exceed 100 characters!
							</Text>
						)}

						<h4>Icon</h4>
						<Image
							preview={false}
							width="100%"
							height={250}
							src={selectedIcon?.src || '/images/select-image.jpg'}
							fallback={'/images/blur-image.jpeg'}
							placeholder={
								<Image
									preview={false}
									src="/images/blur-image.jpeg"
									width="100%"
									height={200}
									style={{ objectFit: 'cover' }}
								/>
							}
						/>
						<p>Choose icon</p>
						<Select
							allowClear
							style={{
								width: '100%',
								paddingBottom: '2%',
								paddingTop: '2%',
							}}
							placeholder="Please select"
							onChange={(e) => handleChangeIcon(e)}
							key={'iconSelect'}
							value={selectedIcon?.name ? selectedIcon.name : ''}
						>
							{medicalServiceIconList.map((icon, index) => {
								return (
									<Option key={`icon${index}`} value={String(icon.name)}>
										{icon.label}
									</Option>
								);
							})}
						</Select>

						<Form.Item
							label="Description"
							name="description"
							rules={generateFormRules('Description', ['required'])}
						>
							<TextArea rows={4} />
						</Form.Item>

						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group value={healthServiceCategory.isPublished}>
								<Radio value={true}>Active</Radio>
								<Radio value={false}>Non-Active</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default HealthServiceCategoryEdit;
