export enum EBalanceWithdrawStatus {
	WAITING_APPROVAL = 'WAITING_APPROVAL',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export type BalanceWithdrawalProperties = {
	balanceWithdrawalId: string;
	userId: string;
	metaUser: any;
	userBankAccountId: string;
	metaBankAccount: any;
	amount: number;
	status: EBalanceWithdrawStatus;
	updatedAt?: Date;
	createdAt?: Date;
	approveRejectDetails?: string;
	lastUpdatedByUser?: string;
	reason?: any;
	imageLink?: any;
};
