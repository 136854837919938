import { BaseResponseProps } from './config.type';

export interface KycHistoryProps {
	id: string;
	doctorId: string;
	kycStatus: EKycStatus;
	kycDetail: any;
	updatedByAdminId?: string;
	updatedByAdminDetail?: any;
	note?: string;
	createdAt?: Date;
	updatedAt?: Date;
	// specialists: SpecialistProperties[];
	// certificates?: CertificateProperties[];
	// educations?: EducationProperties[];
	// partners?: PartnerDoctorProperties[];
}

export enum EKycStatus {
	WAITING_APPROVAL = 'WAITING_APPROVAL',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	BLOCKED = 'BLOCKED',
}

export interface FetchAllKycHistories extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: KycHistoryProps[];
	};
}

export const initialKycHistory: KycHistoryProps = {
	id: '',
	doctorId: '',
	kycStatus: EKycStatus.WAITING_APPROVAL,
	kycDetail: {},
	updatedByAdminId: '',
	updatedByAdminDetail: {},
	note: '',
};

export const initialKycFetchAll: FetchAllKycHistories = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialKycHistory],
	},
};
