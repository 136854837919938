import {
	CloseOutlined,
	DeleteOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import {
	Form,
	FormInstance,
	Input,
	message,
	Modal,
	Radio,
	Select,
	Space,
	Upload,
	Image,
	Button,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { generateFormRules } from '../../helpers/formRules';
import { generateQueryString } from '../../helpers/generateQueryString';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { getErrorMessage } from '../../helpers/errorHandler';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import {
	initialProduct,
	ProductProps,
	VariantProps,
} from '../../types/product.type';
import {
	CategoryProps,
	FetchAllCategoryResponse,
} from '../../types/category.type';

interface IParams {
	productId: string;
}

interface ILocation {
	productId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<ProductProps, 'createdAt' | 'updatedAt'>;
}

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const { Option } = Select;

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'list',
	'bullet',
];

const ProductEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { productId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<ProductProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [product, setProduct] = React.useState<ProductProps>(initialProduct);

	const [categories, setCategories] = React.useState<Array<CategoryProps>>([]);

	const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
		url: '',
		uid: '',
		name: '',
	});
	const [images, setImages] = React.useState<
		UploadChangeParam<UploadFile<any>>['fileList']
	>([]);
	const [willBeDeletedImage, setWillBeDeletedImage] =
		React.useState<UploadFile<any>>();
	const [isLoadingDeleteImage, setIsLoadingDeleteImage] = React.useState(false);

	const createProduct = async (
		props: Omit<
			ProductProps,
			'createdAt' | 'updatedAt' | 'productId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			const res: any = await httpRequest.post(
				'/products',
				{
					...props,
					categories: product.categories,
					variants: product.variants,
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			await uploadNewImage(res.data.payload.productId);

			message.success('Success create ' + props.productName);
			history.push('/product');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateProduct = async (
		props: Omit<ProductProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			const dataToBeSent = {
				...props,
				categories: product.categories,
				variants: product.variants,
			};
			if (productId) {
				await Promise.all([
					httpRequest.patch('/products/' + productId, dataToBeSent),
					uploadNewImage(productId),
				]);
			}

			message.success('Success update ' + props.productName + ' data');
			history.push('/product');
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const uploadNewImage = async (productId: string) => {
		setIsLoadingAction(true);

		const newImages = images.filter((img) => img.originFileObj);
		if (newImages.length > 0) {
			const promises = [];
			for (const img of newImages) {
				let formData = new FormData();
				formData.append('image', img.originFileObj as Blob);

				const promise = httpRequest.put(
					'/products/' + productId + '/upload-image',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
				promises.push(promise);
			}
			await Promise.all(promises);
		}

		setIsLoadingAction(false);
	};

	const handleDeleteImage = async () => {
		try {
			setIsLoadingDeleteImage(true);
			if (willBeDeletedImage && !willBeDeletedImage.originFileObj) {
				const fileId = willBeDeletedImage?.uid;
				await httpRequest.delete('/products/' + productId + '/' + fileId);
			}

			setImages(images.filter((item) => item.uid !== willBeDeletedImage?.uid));

			setIsLoadingDeleteImage(false);
			setWillBeDeletedImage(undefined);
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoadingDeleteImage(false);
		}
	};

	const handleSubmit = async (
		values: Omit<ProductProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (productId) {
			updateProduct(values);
		} else {
			createProduct(values);
		}
	};

	const handleChangeVariant = (value: string, index: number, key: string) => {
		if (key === 'pricePerItem') {
			product.variants[index].pricePerItem = parseFloat(value);
		} else {
			product.variants[index].productVariantName = value;
		}
		setProduct((oldVal) => {
			return {
				...oldVal,
			};
		});
	};

	const handleClickVariant = async (index: number, key: string) => {
		if (key === 'delete') {
			const tobeDeletedVariant = product.variants.splice(index, 1);
			if (
				index === product.variants.length &&
				tobeDeletedVariant[0].isDefaultVariant
			) {
				product.variants[index - 1].isDefaultVariant = true;
			}
			setProduct((oldVal) => {
				return {
					...oldVal,
					variants: product.variants,
				};
			});
		} else {
			product.variants.forEach((variant, idx) => {
				if (idx === index) {
					variant.isDefaultVariant = true;
				} else {
					variant.isDefaultVariant = false;
				}
				setProduct((oldVal) => {
					return {
						...oldVal,
					};
				});
			});
		}
	};

	const handleAddVariant = () => {
		const newProductVariants = product.variants.concat({
			pricePerItem: 0,
			productVariantName: '',
			isDefaultVariant: false,
		});

		setProduct((oldVal) => {
			return {
				...oldVal,
				variants: newProductVariants,
			};
		});
	};

	const handleChangeCategory = (value: any) => {
		const selectedCategories = value.map((selectedCategoryId: string) => {
			const foundCategory = categories.find(
				(category) => category.categoryId === selectedCategoryId,
			);

			return foundCategory;
		});

		setProduct((oldVal) => {
			return {
				...oldVal,
				categories: selectedCategories,
			};
		});
	};

	const readFile = (file: UploadFile<any>): Promise<string | undefined> => {
		return new Promise((resolve, reject) => {
			if (file.originFileObj) {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onloadend = function (event) {
					if (event.target && event.target.result) {
						const url =
							event && event.target && event.target.result
								? (event.target.result as any)
								: undefined;
						resolve(url);
						// setImages(
						//   e.fileList.map((item) => {
						//     if (item.uid === file.uid) {
						//       item = {
						//         ...item,
						//         url,
						//       };
						//     }
						//     return item;
						//   })
						// );
						// if (!selectedImage || (selectedImage && !selectedImage.uid)) {
						//   setSelectedImage({ ...e.fileList[0], url });
						// }
					} else {
						resolve(undefined);
					}
				};
			} else {
				resolve(undefined);
			}
		});
	};

	const handleChangeImages = async (e: UploadChangeParam<UploadFile<any>>) => {
		e.fileList.forEach((file) => {
			if (!file || (file && !file.size)) return;
			const isLtMaxSize = (file?.size || 0) / 1024 / 1024 < 1;
			if (!isLtMaxSize) {
				message.error(`Image must smaller than ${1}MB!`);
			}
		});

		setImages(e.fileList);

		const tmpListImage: Array<UploadFile<any> & { url?: string }> = [];
		for (const file of e.fileList) {
			if (file.originFileObj) {
				const url = await readFile(file);
				console.info('url', url);
				tmpListImage.push({
					...file,
					url: url || '',
				});

				// const reader = new FileReader();
				// reader.readAsDataURL(file.originFileObj);
				// reader.onloadend = function (event) {
				//   if (event.target && event.target.result) {
				//     const url =
				//       event && event.target && event.target.result
				//         ? (event.target.result as any)
				//         : undefined;

				// };
			}
		}

		setImages(tmpListImage);
		if (!selectedImage || (selectedImage && !selectedImage.uid)) {
			setSelectedImage(tmpListImage[0]);
		}
	};

	React.useEffect(() => {
		if (productId) {
			const fetchProductDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/products/' + productId,
					);
					setProduct(res.data.payload);
					const dataImages = (res.data.payload.imageUrls || []).map((item) => ({
						url: item || '',
						uid: '',
						name: '',
					}));

					setImages(dataImages as any[]);

					if (dataImages.length > 0) {
						setSelectedImage(dataImages[0]);
					}

					const bcDetails = [
						{
							field: 'productId',
							value: productId,
							label: res.data.payload.productName,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchProductDetail();
		}

		const getCategoryData = async () => {
			const categoryRes = await httpRequest.get<FetchAllCategoryResponse>(
				`/categories${generateQueryString({ isPublished: true })}`,
			);
			setCategories(categoryRes.data.payload.results);
		};

		getCategoryData();
	}, [productId, location]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(productId ? 'Edit' : 'Add') + ' Product'}
				subtitle="Manage your product data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="productForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={productId ? product : initialProduct}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Product Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Image either jpg, png or jpeg; max size 1MB; recommended resolution is 500x300px',
							},
						]}
					>
						<Form.Item>
							<Space
								style={{
									width: '100%',
									background: '#f2f2f2',
									justifyContent: 'center',
								}}
							>
								<Image
									preview={false}
									width="100%"
									height={250}
									src={selectedImage.url || '/images/select-image.jpg'}
									fallback={'/images/blur-image.jpeg'}
									style={{ objectFit: 'cover' }}
									placeholder={
										<Image
											preview={false}
											src="/images/blur-image.jpeg"
											width="100%"
											height={200}
											style={{ objectFit: 'cover' }}
										/>
									}
								/>
							</Space>
							<Space direction="horizontal">
								{images.map((img, index) => (
									<div
										style={
											selectedImage.uid === img.uid
												? {
														border: 5,
														borderStyle: 'solid',
														borderColor: '#D81F64',
														position: 'relative',
												  }
												: { position: 'relative' }
										}
									>
										<Image
											onClick={() => {
												setSelectedImage(img);
											}}
											preview={false}
											width={100}
											height={100}
											src={
												img.url || img.thumbUrl || '/images/select-image.jpg'
											}
											fallback={'/images/blur-image.jpeg'}
											style={{ objectFit: 'cover' }}
											placeholder={
												<Image
													preview={false}
													src="/images/blur-image.jpeg"
													width={100}
													height={100}
													style={{ objectFit: 'cover' }}
												/>
											}
										/>
										<div
											onClick={() => {
												setWillBeDeletedImage(img);
											}}
											style={{
												cursor: 'pointer',
												backgroundColor: 'grey',
												position: 'absolute',
												top: 5,
												right: 5,
												paddingRight: 5,
												paddingLeft: 5,
												zIndex: 100,
											}}
										>
											<CloseOutlined width={20} height={20} color="#FFFFFF" />
										</div>
									</div>
								))}
							</Space>

							<CustomSpace
								style={{ paddingTop: 10, paddingBottom: 10, width: '100%' }}
								wrap
							>
								<CustomUpload
									accept=".jpg,.jpeg,.png"
									beforeUpload={(file) => {
										// console.info('file', file);
										// const isLtMaxSize = file.size / 1024 / 1024 < 1;
										// if (!isLtMaxSize) {
										//   message.error(`Image must smaller than ${1}MB!`);
										// }
										return false;
									}}
									fileList={images}
									onChange={handleChangeImages}
									onRemove={(file) => {
										setWillBeDeletedImage(file);
										return false;
									}}
									showUploadList={false}
									multiple={true}
								>
									<Button
										type="primary"
										danger
										icon={<UploadOutlined />}
										style={{ width: '100%' }}
									>
										Upload Image
									</Button>
								</CustomUpload>
							</CustomSpace>
						</Form.Item>
						<Form.Item
							label="Product name"
							name="productName"
							rules={generateFormRules('Product name', ['required'])}
						>
							<Input
								value={product.productName}
								onChange={(e) =>
									setProduct({
										...product,
										productName: e.target.value,
									})
								}
							/>
						</Form.Item>
						<Form.Item
							label="Description"
							name="description"
							rules={generateFormRules('Description', ['required'])}
						>
							<ReactQuill
								theme="snow"
								value={product.description}
								onChange={(val) =>
									setProduct({
										...product,
										description: val,
									})
								}
								modules={quillModules}
								formats={quillFormats}
							/>
						</Form.Item>

						<ProductVariantInput
							variants={product.variants}
							onAddVariant={handleAddVariant}
							onChangeText={handleChangeVariant}
							onClickVariant={handleClickVariant}
						/>

						<h4>Categories</h4>
						<Select
							mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Please select"
							onChange={handleChangeCategory}
							key={'tagSelect'}
							value={
								product.categories
									? product.categories.map((item) => item.categoryId || '')
									: []
							}
						>
							{categories.map((category, index) => {
								return (
									<Option
										key={`category${index}`}
										value={String(category.categoryId)}
									>
										{category.categoryName}
									</Option>
								);
							})}
						</Select>

						<Form.Item
							label="Status"
							name="isPublished"
							rules={generateFormRules('Status', ['required'])}
						>
							<Radio.Group value={product.isPublished}>
								<Radio value={true}>Published</Radio>
								<Radio value={false}>Unpublished</Radio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>

			<Modal
				title="Confirmation"
				visible={!!willBeDeletedImage}
				onOk={handleDeleteImage}
				onCancel={() => {
					setWillBeDeletedImage(undefined);
				}}
				okText="Yes"
				confirmLoading={isLoadingDeleteImage}
				okButtonProps={{ type: 'primary' }}
			>
				<p>Are you sure want to delete this image?</p>
			</Modal>
		</div>
	);
};

interface ProductVariantInputProps {
	variants: Array<VariantProps>;
	onAddVariant: () => void;
	onChangeText: (
		value: string,
		index: number,
		key: 'pricePerItem' | 'productVariantName',
	) => void;
	onClickVariant: (index: number, key: 'delete' | 'default') => void;
}

const ProductVariantInput: React.FC<ProductVariantInputProps> = ({
	variants,
	onAddVariant,
	onChangeText,
	onClickVariant,
}) => {
	return (
		<Form.Item
			label="Product Variant"
			rules={[
				{
					required: true,
					message: 'The Variants is required.',
				},
			]}
		>
			{variants.map((item, index) => {
				return (
					<VariantInputContainer key={index}>
						<Input
							prefix="$"
							value={item.pricePerItem}
							name={`pricePerItem${index}`}
							key={`pricePerItem`}
							type="number"
							min={0}
							onChange={(e) =>
								onChangeText(e.target.value, index, 'pricePerItem')
							}
						/>
						<Input
							disabled={variants.length === 1}
							name={`productVariantName${index}`}
							key={`productVariantName`}
							value={item.productVariantName}
							onChange={(e) =>
								onChangeText(e.target.value, index, 'productVariantName')
							}
						/>
						<AppButton
							disabled={variants.length === 1}
							type="primary"
							icon={<DeleteOutlined />}
							onClick={() => onClickVariant(index, 'delete')}
						/>
						<AppButton
							disabled={item.isDefaultVariant}
							type="primary"
							onClick={() => onClickVariant(index, 'default')}
						>
							Set default
						</AppButton>
					</VariantInputContainer>
				);
			})}
			<AppButton type="primary" onClick={onAddVariant}>
				Add Variant
			</AppButton>
		</Form.Item>
	);
};

const VariantInputContainer = styled.div`
	display: grid;
	grid-template-columns: 200px 1fr 32px 120px;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
`;

const CustomSpace = styled(Space)`
	width: 100%;
	.ant-space-item {
		width: 100%;
	}
`;

const CustomUpload = styled(Upload)`
	width: 100%;
	.ant-upload {
		width: 100%;
	}
`;

export default ProductEdit;
