import { MoreOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	message,
	Image,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../../components/AppButton';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import useFetchList from '../../../hooks/useFetchList';
import { BaseResponseProps } from '../../../types/config.type';
import { GenreProps } from '../../../types/genre.type';
import { ContainerFilter } from '../../dashboard';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<GenreProps, 'createdAt' | 'updatedAt'>;
}

const Genre = () => {
	const history = useHistory();

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<GenreProps>({
		endpoint: 'genre',
		initialQuery: {
			limit: 10,
			offset: 0,
		},
		limit: 10,
	});

	console.log(data);

	const handleStatusChange = async (record: GenreProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.genreId === record.genreId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/genre/' + record.genreId,
				{
					genreId: record.genreId,
					isPublished: !record.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.genreId === res.data.payload.genreId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.genreName + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const handleCreateUser = () => {
		history.push({ pathname: '/genre/add' });
	};

	const handleClickDetail = (e: GenreProps) => {
		history.push({
			pathname: `/genre/${e.genreId}/detail`,
		});
	};

	const { Option } = Select;

	const handleFilterStatusChange = (status: 'active' | 'inactive' | string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: null }));
		}
	};

	const handleClickEdit = (e: GenreProps) => {
		history.push({
			pathname: `/genre/${e.genreId}/edit`,
		});
	};

	const columns = [
		// {
		// 	title: 'COVER',
		// 	dataIndex: 'imageUrl',
		// 	key: 'imageUrl',
		// 	width: 110,
		// 	render: (url: string, record: GenreProps) => {
		// 		return (
		// 			<Image
		// 				width={100}
		// 				height={110}
		// 				style={{ borderRadius: '7%' }}
		// 				src={url}
		// 			/>
		// 		);
		// 	},
		// },
		{
			title: 'GENRE',
			dataIndex: 'genreName',
			key: 'genreName',
			render: (text: string, record: GenreProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{text}
					</div>
				);
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: GenreProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'ACTION',
			key: 'action',
			render: (_: any, record: GenreProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: GenreProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'detail') {
					handleClickDetail(record);
				} else if (key === 'edit') {
					handleClickEdit(record);
				}
			}}
		>
			<Menu.Item key="detail">Detail</Menu.Item>
			<Menu.Item key="edit">Edit </Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<TagOutlined />}
				title="E-Book Genre"
				subtitle="Manage Your E-Book Genre"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateUser}>
							Add New Genre
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by genre name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleFilterStatusChange}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Active</Option>
					<Option value="inactive">Inactive</Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 && (
				<div>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>
					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</div>
			)}
		</div>
	);
};

export default Genre;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
