import { Store, createStore } from 'redux';
import { AuthState } from '../types/auth.type';
import { ApprovalState, ConfigState } from '../types/config.type';
import rootReducer from './reducers/index';

export interface AppState {
	auth: AuthState;
	configApp: ConfigState;
	approval: ApprovalState;
}

const store: Store<AppState> = createStore(rootReducer);

export default store;
