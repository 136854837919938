import {
	AppstoreAddOutlined,
	MoreOutlined,
	SearchOutlined,
	BookOutlined,
} from '@ant-design/icons';
import {
	Dropdown,
	Image,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
	Tag,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import { EbookProps, initialEbooks } from '../../types/ebook.type';
import Text from 'antd/lib/typography/Text';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EbookProps, 'createdAt' | 'updatedAt'>;
}

const Ebook = () => {
	const history = useHistory();
	const [tmpData, setTmpData] = React.useState<EbookProps>(initialEbooks);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		changePage,
		setQuery,
		fetchList,
	} = useFetchList<EbookProps>({
		endpoint: 'ebooks',
		limit: 10,
	});

	const handleUpdateIsPublished = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.ebookId === tmpData.ebookId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);
			const res = await httpRequest.patch<ResponseProps>(
				'/ebooks/' + tmpData.ebookId,
				{
					ebookId: tmpData.ebookId,
					isPublished: !tmpData.isPublished,
				},
			);
			newData = data.map((item) => {
				if (item.ebookId === res.data.payload.ebookId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.title + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialEbooks);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialEbooks);
			setIsLoadingUpdateStatus(false);
		}
	};
	const handleCreateEbook = () => {
		history.push('/ebooks/add');
	};

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};
	const handleClickDetail = (e: EbookProps) => {
		history.push(`/ebooks/${e.ebookId}/detail`);
	};

	const handleClickAction = (props: EbookProps, key: string) => {
		if (key === 'detail') {
			history.push(`/ebooks/${props.ebookId}/detail`);
		} else if (key === 'edit') {
			history.push(`/ebooks/${props.ebookId}/edit`);
		} else if (key === 'delete') {
			Modal.confirm({
				title: 'Confirmation',
				content: 'Are you sure want to delete this eBook?',
				onOk: () => {
					if (props.ebookId) {
						handleDelete(props.ebookId);
					}
				},
			});
		}
	};

	const handleDelete = async (id: string) => {
		try {
			await httpRequest.delete('ebooks/' + id);
			fetchList();
			message.success('Deleted successfully');
		} catch (err) {
			message.error('Failed to delete. ' + getErrorMessage(err));
		}
	};

	const handleStatusChange = async (record: EbookProps) => {
		try {
			let newData = [];
			newData = data.map((item) => {
				if (item.ebookId === record.ebookId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/ebooks/' + record.ebookId,
				{
					ebookId: record.ebookId,
					isPublished: !record.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.ebookId === res.data.payload.ebookId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + record.title + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		}
	};

	const columns = [
		{
			title: 'COVER',
			dataIndex: 'imageUrl',
			key: 'imageUrl',
			width: 110,
			render: (url: string, record: EbookProps) => {
				return (
					<Image
						width={100}
						height={110}
						style={{ borderRadius: '7%' }}
						src={url}
					/>
				);
			},
		},
		{
			title: 'TITLE',
			dataIndex: 'title',
			key: 'title',
			width: 250,
			render: (title: string, record: EbookProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{title}
					</div>
				);
			},
		},
		{
			title: 'PUBLISHER',
			width: 210,
			render: (record: EbookProps) => (
				<Text>{record.partners?.partnerName}</Text>
			),
		},
		{
			title: 'GENRE',
			key: 'genres',
			dataIndex: 'genres',
			render: (genres: any, record: EbookProps) => (
				<>
					{record.genres
						? record.genres.length <= 3
							? record.genres.slice().map((el, index) => (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
										key={el.genreId}
									>
										{el.genreName}
									</Tag>
							  ))
							: record.genres.slice(0, 3).map((el, index) => (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
										key={el.genreId}
									>
										{el.genreName}
									</Tag>
							  ))
						: []}
					{record.genres
						? record.genres.length > 3 && (
								<Tag
									style={{
										border: '2px solid #D81F64',
										color: '#D81F64',
										marginBottom: '10px',
									}}
								>
									+{record.genres.slice(3).length} More
								</Tag>
						  )
						: []}
				</>
			),
		},
		{
			title: 'AUTHOR',
			key: 'author',
			dataIndex: 'author',
			width: 240,
		},
		{
			title: 'PUBLISH IN',
			width: 150,
			dataIndex: 'publicationDate',
			key: 'publicationDate',
			render: (createdAt: any) => (
				<div>{moment(createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: 'PRICE',
			key: 'priceRange',
			render: (record: EbookProps) => {
				const { finalPrice, originalPrice } = record;

				if (finalPrice === originalPrice) {
					return <span>{finalPrice}</span>;
				} else {
					return (
						<span>
							{finalPrice} - {originalPrice}
						</span>
					);
				}
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (isPublished: boolean, record: EbookProps) => {
				return (
					<>
						<Switch
							loading={record.statusLoading}
							checked={isPublished}
							onChange={() => {
								handleStatusChange(record);
							}}
						/>
					</>
				);
			},
		},
		{
			title: 'UPDATED AT',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			render: (updatedAt: any) => (
				<div>{moment(updatedAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: EbookProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: EbookProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="edit">Edit E-Book</Menu.Item>
			<Menu.Item key="detail">Detail E-Book</Menu.Item>
			<Menu.Item key="delete">Delete E-Book</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<BookOutlined />}
				title="E-Book"
				subtitle="Manage Your E-Book"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateEbook}>
							Add E-Book
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search E-book Title "
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status - All"
				>
					<Select.Option value="all">All</Select.Option>
					<Select.Option value="1">Published</Select.Option>
					<Select.Option value="0">Unpublished</Select.Option>
				</Select>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			{data.length !== 0 ? (
				<>
					<CustomPagination
						current={pagination.page}
						total={pagination.totalData}
						defaultPageSize={pagination.perPage}
						pageSizeOptions={['25', '50', '100']}
						showSizeChanger={true}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
						onChange={changePage}
						locale={{ items_per_page: '' }}
						responsive={true}
					/>

					<PaginationText>
						<h4>Per Page</h4>
					</PaginationText>
				</>
			) : null}
			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleUpdateIsPublished}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialEbooks);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.title}"</b> status to{' '}
					<b>"{!tmpData.isPublished ? 'Published' : 'UnPublished'}"</b>
					?. {!tmpData.isPublished}
				</p>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Ebook;
const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
