import { message } from 'antd';

export function generateFormRules(
	formName: string,
	rules: Array<
		| 'required'
		| 'email'
		| 'phoneNumber'
		| 'letter-and-space'
		| 'password'
		| 'url'
		| 'fileRequired'
	> = [],
): Array<{
	required?: boolean;
	message: string;
	pattern?: any;
}> {
	const formRules = [];
	if (rules.includes('required')) {
		formRules.push({
			required: true,
			pattern: /^(?!\s*$).+/,
			message: `${formName} is required.`,
			validator(_: any, value: any) {
				if (typeof value === 'boolean') {
					if (!value) {
						return Promise.reject(
							new Error(`${formName} cannot be empty or unchecked.`),
						);
					}
					return Promise.resolve();
				}

				if (Array.isArray(value) && value.length === 0) {
					return Promise.reject(new Error(`${formName} cannot be empty.`));
				}

				const plainText = String(value)
					.replace(/<[^>]+>/g, '')
					.trim();
				if (!plainText && value !== 0) {
					return Promise.reject(
						new Error(`${formName} cannot be just spaces or empty.`),
					);
				}

				return Promise.resolve();
			},
		});
	}
	if (rules.includes('email')) {
		formRules.push({
			pattern:
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			message: `${formName} format is invalid.`,
		});
	}
	if (rules.includes('phoneNumber')) {
		formRules.push({
			pattern: /^[0-9+ -]+$/,
			message: `${formName} can only include numbers, dash, plus and spacing.`,
		});

		formRules.push({
			max: 13,
			message: `${formName} cannot exceed 13 characters.`,
		});
		formRules.push({
			min: 10,
			message: `Minimum ${formName} is 10 characters.`,
		});
	}
	if (rules.includes('letter-and-space')) {
		formRules.push({
			pattern: /^[a-zA-Z ']+$/,
			message: `${formName} can only include letters.`,
		});
	}
	if (rules.includes('password')) {
		formRules.push({
			pattern:
				/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`\\|=-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'<>,.?/~`\\|=-]{8,}$/,
			message: `${formName} minimum 8 characters (letter, number, capital letter, and symbol)`,
		});
	}
	if (rules.includes('url')) {
		formRules.push({
			pattern:
				/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
			message: `${formName} must be a valid URL`,
		});
	}
	if (rules.includes('fileRequired')) {
		formRules.push({
			required: true,
			pattern: /^(?!\s*$).+/,
			message: `${formName} is required.`,

			validator(_: any, value: any) {
				if (!value || value.length === 0) {
					return Promise.reject(new Error(`${formName} is required.`));
				}

				const file = value[0];

				if (file && file.size === 0) {
					return Promise.reject(
						new Error(`${formName} cannot be an empty file.`),
					);
				}
				return Promise.resolve();
			},
		});
	}

	return formRules;
}
