import { BaseResponseProps } from './config.type';
import { LogGeneralProps } from './logsGeneral.type';
import { initialUser, IUser } from './user.type';

export interface BankAccountProps {
	userBankAccountId: string;
	userId: string;
	bankCode: string;
	bankAccountNumber: string;
	bankAccountOwner: string;
	status: EBankStatus;
	isDefault?: boolean;
	updatedAt?: Date;
	createdAt?: Date;
	approvedOrRejectedBy?: string;
	approvedOrRejectedDate?: Date;
	rejectReason?: string;
	user: IUser;
	logs?: LogGeneralProps[];
}

export enum EBankStatus {
	WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED',
}

export interface FetchAllBankAccountResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: BankAccountProps[];
	};
}

export interface FetchAllBankListResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: BankListProps[];
	};
}

export const initialBankAccount: BankAccountProps = {
	userBankAccountId: '',
	userId: '',
	bankCode: '',
	bankAccountNumber: '',
	status: EBankStatus.WAITING_FOR_APPROVAL,
	rejectReason: '',
	bankAccountOwner: '',
	updatedAt: new Date(),
	createdAt: new Date(),
	user: initialUser,
};

export type BankListProps = {
	bankId: string;
	bankName: string;
};
