import { Checkbox, Col, Image, Row, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

interface PartnerCardProps {
	partner: any;
	selectedPartner: any[];
	handleChange?: (partnerData: any) => void;
}

const PartnerCard: React.FC<PartnerCardProps> = ({
	partner,
	selectedPartner,
	handleChange,
}) => {
	return (
		<div
			style={{
				border: '1px solid #D5DCE1',
				borderRadius: '5px',
				marginBottom: '2%',
			}}
		>
			<Row
				gutter={[16, 16]}
				align="middle"
				style={{
					padding: '2%',
				}}
			>
				<Col>
					<Checkbox
						checked={
							selectedPartner.find(
								(item) => item.partnerId === partner.partnerId,
							)
								? true
								: false
						}
						onChange={() => handleChange?.(partner)}
					/>
				</Col>
				<Col>
					<Image
						width={67}
						height={67}
						src={
							partner?.imageCoverURL ||
							partner?.partner?.imageCoverURL ||
							'/images/select-image.jpg'
						}
						fallback={'/images/blur-image.jpeg'}
					/>
				</Col>
				<Col>
					<Row
						style={{
							paddingBottom: '2%',
						}}
					>
						<Text
							style={{
								fontWeight: 'bold',
								fontSize: '14px',
								lineHeight: '150%',
							}}
						>
							{partner?.partnerName || partner?.partner?.partnerName}
						</Text>
					</Row>
					<Row
						style={{
							paddingTop: '2%',
						}}
					>
						<Text
							style={{
								fontWeight: 'bold',
								fontSize: '12px',
								lineHeight: '150%',
							}}
						>
							{partner?.address || partner?.partner?.address}
						</Text>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default PartnerCard;
