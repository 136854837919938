import {
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Button,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import {
	FetchAllPublisherResponse,
	initialPublisher as InitialPublisher,
	PublisherProps,
} from '../../types/adminPublisher.type';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { generateFormRules } from '../../helpers/formRules';
import { getErrorMessage } from '../../helpers/errorHandler';
import styled from 'styled-components';
import { getFullName } from '../../helpers/name';
import { CustomerProps, initialCustomer } from '../../types/customer.type';
import { PartnerProps } from '../../types/partner.type';
import useFetchList from '../../hooks/useFetchList';
import { EditOutlined } from '@ant-design/icons';
import { getUser } from '../../helpers/auth';
const { Option } = Select;

interface IParams {
	userId: string;
	partnerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<CustomerProps, 'createdAt' | 'updatedAt'>;
}

const AdminPublisherEdit = () => {
	const history = useHistory();
	const userData = getUser();
	const { userId, partnerId } = useParams<IParams>();

	const formRef =
		React.useRef<FormInstance<Omit<CustomerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const { data: publishers } = useFetchList<PartnerProps>({
		endpoint: `partners`,
		initialQuery: {
			includePartnerTypes: 'PUBLISHER',
		},
	});

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const [publisherId, setPublisherId] = React.useState('');
	const [adminPublisher, setAdminPublisher] =
		React.useState<CustomerProps>(initialCustomer);
	const [fieldsDisabled, setFieldsDisabled] = React.useState<boolean>(true);
	const [Password, setPassword] = React.useState<boolean>(true);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const type = queryParams.get('type');
	const [emailDisabled, setEmailDisabled] = React.useState<boolean>(false);
	const [emailChecked, setEmailChecked] = React.useState<boolean>(false);
	const [statusDisabled, setStatusDisabled] = React.useState<boolean>(false);
	const [adminPublishers, setAdminPublishers] = React.useState<
		PublisherProps[]
	>([]);

	const CheckAdmin = adminPublishers.some(
		(admin) =>
			admin.userId === userData?.userId &&
			(admin.role === 'publisher_owner' || admin.role === 'publisher_admin'),
	);

	const createAdmin = async (
		props: Omit<CustomerProps, 'createdAt' | 'updatedAt' | 'adminId'>,
	) => {
		try {
			setIsLoadingAction(true);

			let name = getFullName(props);

			const formData = {
				name: name,
				email: props.email,
				phone: props.phone,
				status: props.status,
				isEmailVerified: true,
				isPhoneVerified: true,

				firstName: props.firstName,
				middleName: props.middleName,
				lastName: props.lastName,
				password: props.password,
			};

			const role = type === 'owner' ? 'publisher_owner' : 'publisher_owner';

			await httpRequest.put('/partner-admins', {
				user: formData,
				partnerId,
				role: role,
			});

			message.success('Success create ' + getFullName(formData));

			history.push(`/publisher/${partnerId}/detail`);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateAdmin = async (
		props: Omit<CustomerProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			let name = getFullName(props);

			const formData = {
				name: name,
				email: props.email,
				phone: props.phone,
				status: props.status,
				isEmailVerified: true,
				isPhoneVerified: true,

				firstName: props.firstName,
				middleName: props.middleName,
				lastName: props.lastName,
			};

			await httpRequest.patch('/user/' + userId, formData);

			message.success('Success update ' + getFullName(formData));

			history.push(`/publisher/${partnerId}/detail`);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<CustomerProps, 'createdAt' | 'updatedAt'>,
	) => {
		if (values.email) {
			values.email = values.email.toLowerCase();
		}

		if (userId) {
			updateAdmin(values);
		} else {
			createAdmin(values);
		}
	};

	const handleEmailCheck = async () => {
		if (emailChecked) {
			setEmailChecked(false);
			setEmailDisabled(false);
		} else {
			try {
				const email = formRef.current?.getFieldValue('email');
				if (!email) {
					message.warning('Please enter an email address.');
					return;
				}
				const response = await httpRequest.post<ResponseProps>(
					'/partner-admins/check-email',
					{ email },
				);
				if (response.data.payload) {
					const { firstName, middleName, lastName, status } =
						response.data.payload;

					formRef.current?.setFieldsValue({
						firstName,
						middleName,
						lastName,
						status,
					});

					setAdminPublisher(response.data.payload);
					setFieldsDisabled(true);
					setPassword(false);
					setEmailDisabled(true);
					setEmailChecked(true);
					if (CheckAdmin) {
						setStatusDisabled(true);
					} else {
						setStatusDisabled(false);
					}

					message.success('User data loaded.');
				} else {
					setFieldsDisabled(false);
					setPassword(true);
					message.warning('No user found with this email.');
				}
			} catch (error: any) {
				if (
					error.data.message ===
					'Email has already been registered as a patient, doctor, or secretary'
				) {
					formRef.current?.setFieldsValue({
						email: '',
						firstName: '',
						middleName: '',
						lastName: '',
						password: '',
					});
					setFieldsDisabled(true);
					setStatusDisabled(false);
					message.error(error.data.message);
				} else {
					formRef.current?.setFieldsValue({
						firstName: '',
						middleName: '',
						lastName: '',
						password: '',
					});
					setFieldsDisabled(false);
					setPassword(true);
					setEmailDisabled(true);
					setStatusDisabled(false);
					setEmailChecked(true);
					message.success(
						'The account is not registered yet,please create a new one.',
					);
				}
			}
		}
	};

	React.useEffect(() => {
		const fetchAdminList = async () => {
			const res = await httpRequest.get<FetchAllPublisherResponse>(
				'/partner-admins',
				{
					params: {
						partnerId: partnerId,
					},
				},
			);
			const allAdmins = res.data.payload.results;

			console.log('All Admins:', allAdmins);
			setAdminPublishers(allAdmins);
		};

		fetchAdminList();
	}, [partnerId]);

	React.useEffect(() => {
		if (userId) {
			const fetchAdmin = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>('/user/' + userId);
					setAdminPublisher(res.data.payload);

					if (CheckAdmin) {
						setStatusDisabled(true);
					} else {
						setStatusDisabled(false);
					}
				} catch (error) {
					console.log(error);
				} finally {
					setIsLoading(false);
				}
			};

			fetchAdmin();
		}
	}, [userId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(userId ? 'Edit' : 'Add') + ' Admin Publisher'}
				subtitle={
					userId ? 'Manage your admin data' : 'Create new admin publisher'
				}
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="profileForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={userId ? adminPublisher : initialCustomer}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="User Data"
						helpers={[
							{
								title: 'Information',
								content:
									'Provide contact information, including email and phone number for create publisher admin',
							},
						]}
					>
						<Form.Item
							label="Email"
							name="email"
							rules={generateFormRules('Email', ['required', 'email'])}
						>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Input
									placeholder="Input email address"
									style={{ flex: 1 }}
									disabled={emailDisabled}
								/>
								<Button
									type="default"
									onClick={handleEmailCheck}
									style={{
										marginLeft: 8,
										backgroundColor: '#d81f64',
										borderColor: '#d81f64',
										color: '#ffffff',
									}}
									icon={emailChecked ? <EditOutlined /> : null}
								>
									{emailChecked ? '' : 'Check Email'}
								</Button>
							</div>
						</Form.Item>

						<Form.Item
							label="First Name"
							name="firstName"
							rules={generateFormRules('FirstName', [
								'required',
								'letter-and-space',
							])}
						>
							<Input placeholder="First Name" disabled={fieldsDisabled} />
						</Form.Item>
						<Form.Item
							label="Middle Name"
							name="middleName"
							rules={generateFormRules('MiddleName', ['letter-and-space'])}
						>
							<Input placeholder="Middle Name" disabled={fieldsDisabled} />
						</Form.Item>
						<Form.Item
							label="Last Name"
							name="lastName"
							rules={generateFormRules('LastName', ['letter-and-space'])}
						>
							<Input placeholder="Last Name" disabled={fieldsDisabled} />
						</Form.Item>

						{/* <Form.Item
							label="Phone Number"
							name="phone"
							rules={generateFormRules('Phone number', [
								// 'required',
								'phoneNumber',
							])}
						>
							<Input
								placeholder="Input number"
							/>
						</Form.Item> */}

						{Password && !userId && (
							<Form.Item
								label="Password"
								name="password"
								rules={generateFormRules('Password', ['required', 'password'])}
							>
								<Input.Password
									placeholder="Input password"
									disabled={fieldsDisabled}
								/>
							</Form.Item>
						)}
					</SectionContent>

					<Divider />

					<SectionContent
						groupTitle="User Settings"
						helpers={[
							{
								title: 'Information',
								content:
									'Select The one of status to make the user active or non active',
							},
						]}
					>
						<Form.Item
							label="Status"
							name="status"
							rules={[
								{
									message: 'The Status is required.',
								},
							]}
						>
							<Radio.Group
								value={adminPublisher.status || 'active'}
								disabled={statusDisabled}
							>
								<CustomRadio value="active">Active</CustomRadio>
								<CustomRadio value="inactive">Non Active</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default AdminPublisherEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
