import { BaseResponseProps } from './config.type';

export interface EventTagProps {
	tagId?: string;
	tagName: string;
	isPublished: boolean;
	createdAt?: Date | string;
	updatedAt?: Date | string;
	statusLoading?: boolean;
}

export interface FetchAllEventTagResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: EventTagProps[];
	};
}

export const initialEventTag: EventTagProps = {
	tagId: '',
	tagName: '',
	isPublished: true,
};
