import { BaseResponseProps } from './config.type';

export interface DrugMedicineDosesProps {
	drugMedicineId: string;
	dose: string;
	description?: string;
	updatedAt?: Date;
	createdAt?: Date;
}

export interface FetchAllMedicineDosesResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: DrugMedicineDosesProps[];
	};
}

export const initialMedicine: DrugMedicineDosesProps = {
	drugMedicineId: '',
	dose: '',
	description: '',
};
