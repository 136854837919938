import { Space } from 'antd';
import React from 'react';
import HeaderSection from '../../components/HeaderSection';

import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import SectionContent from '../../components/SectionContent';
import { formatDate } from '../../helpers/constant';
import { initialTag, TagProps } from '../../types/tag.type';

interface IParams {
	tagId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<TagProps, 'createdAt' | 'updatedAt'>;
}

const TagDetail = () => {
	const history = useHistory();
	const { tagId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [tag, setTag] = React.useState<TagProps>(initialTag);

	const handleClickEdit = () => {
		history.push({
			pathname: `/tag/${tag.tagId}/edit`,
			state: {
				tagId: tag.tagId,
			},
		});
	};

	React.useEffect(() => {
		const fetchDetailTag = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>('/tags/' + tagId);

				setTag(res.data.payload);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchDetailTag();
	}, [tagId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Tag Detail"
				subtitle="Manage your tag data"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleClickEdit}>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent groupTitle="Tag Data">
					<DetailItem label="Name" content={tag.name} />
					<DetailItem
						label="Status"
						content={tag.isPublished ? 'Active' : 'Inactive'}
					/>
					<DetailItem label="Created at" content={formatDate(tag.createdAt)} />
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default TagDetail;
