import {
	Divider,
	Button,
	Form,
	FormInstance,
	message,
	Input,
	Select,
	Result,
	Typography,
	Row,
	Col,
	DatePicker,
	Modal,
} from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import {
	BaseResponsePaginationProps,
	BaseResponseProps,
} from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import { DoctorProps, initialDoctor } from '../../types/doctor.type';
import styled from 'styled-components';
import { theme } from '../../assets/theme';
import KycTag from '../../components/KycTag';
import { getErrorMessage } from '../../helpers/errorHandler';
import { generateQueryString } from '../../helpers/generateQueryString';
import ModalSpecialization from '../../components/ModalSpecialization';
import { ReactComponent as IconDeleteOutline } from '../../assets/icons/icon-delete-outline.svg';
import ModalSubSpecialization from '../../components/ModalSubSpecialization';
import { generateFormRules } from '../../helpers/formRules';
import { SpecialistProps } from '../../types/specialist.type';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

interface IParams {
	doctorId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<DoctorProps, 'updatedAt'>;
}

const { Text } = Typography;

const DoctorEdit: React.FC = () => {
	const history = useHistory();
	const { doctorId } = useParams<IParams>();

	const formRef =
		React.useRef<FormInstance<Omit<DoctorProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

	const [doctor, setDoctor] = React.useState<DoctorProps>(initialDoctor);

	const [showSpecializationModal, setShowSpecializationModal] =
		React.useState<boolean>(false);
	const [showSubSpecializationModal, setShowSubSpecializationModal] =
		React.useState<any>({});
	const [specializationList, setSpecializationList] = React.useState<
		SpecialistProps[]
	>([]);
	const [groupingSpecialist, setGroupingSpecialist] = React.useState<any>([]);
	const [rejectReason, setRejectReason] = React.useState('');
	const [isModalRejectVisible, setIsModalRejectVisible] = React.useState(false);
	const [selectedKycStatus, setSelectedKycStatus] = React.useState(
		doctor.kycStatus,
	);

	const createDoctor = async (
		props: Omit<DoctorProps, 'createdAt' | 'updatedAt' | 'adminId'>,
	) => {
		try {
			setIsLoadingAction(true);
			// TODO
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateDoctor = async (
		props: Omit<DoctorProps, 'createdAt' | 'updatedAt' | 'adminId'>,
	) => {
		try {
			setIsLoadingAction(true);
			let name = '';
			if (doctor.user?.firstName) {
				name = doctor.user.firstName;
			}
			if (doctor.user?.middleName) {
				name = name + ' ' + doctor.user.middleName;
			}
			if (doctor.user?.lastName) {
				name = name + ' ' + doctor.user.lastName;
			}

			const signatureName = `${
				doctor.user?.firstName ? doctor.user.firstName + ' ' : ''
			}${doctor.user?.middleName ? doctor.user.middleName + ' ' : ''}${
				doctor.user?.lastName ? doctor.user.lastName + ' ' : ''
			}${doctor.title || ''}`;

			const resUser = await httpRequest.patch(
				`${process.env.REACT_APP_BASE_URL}/user/${doctorId}`,
				{
					...doctor.user,
					name: name,
				},
			);
			console.log('ini resUser::', resUser);

			const resDoctor = await httpRequest.patch(
				`${process.env.REACT_APP_BASE_URL}/doctors/${doctorId}`,
				{
					...doctor,
					nameOfSignature: signatureName,
				},
			);
			console.log('ini resDoctor::', resDoctor);

			history.goBack();
		} catch (error) {
			message.error(getErrorMessage(error));
		} finally {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<DoctorProps, 'createdAt' | 'updatedAt'>,
	) => {
		if (doctor.specialists?.length === 0) {
			return message.error('Specialization is required');
		}
		if (doctorId) {
			updateDoctor(values);
		} else {
			createDoctor(values);
		}
	};

	const fetchDoctor = async () => {
		setIsLoading(true);

		try {
			const res = await httpRequest.get<ResponseProps>('/doctors/' + doctorId);

			if (res && res.data) {
				setDoctor(res.data.payload);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		setSelectedKycStatus(doctor.kycStatus);
	}, [doctor]);

	React.useEffect(() => {
		fetchDoctor();
	}, [doctorId]);

	const fetchSpecialization = async (query: any) => {
		try {
			const res = await httpRequest.get<
				BaseResponsePaginationProps<SpecialistProps>
			>('/specialists/' + generateQueryString(query));
			if (res.data.payload.results) {
				setSpecializationList(res.data.payload.results);
			}
		} catch (error) {
			console.info('error fetch specialization ::: ', error);
		}
	};

	useEffect(() => {
		fetchSpecialization({
			sort: 'specialistName:ASC',
		});
	}, []);

	useEffect(() => {
		let newGroupSpecialists = doctor.specialists?.reduce(function (
			storage: any,
			item: any,
		) {
			const data = {
				specialistId: item.specialistId || '',
				specialistName: item.specialistName || '',
				subSpecialists:
					item.subSpecialistId || item.subSpecialistName
						? [
								{
									subSpecialistId: item.subSpecialistId || '',
									subSpecialistName: item.subSpecialistName || '',
								},
						  ]
						: [],
			};

			let group = item['specialistId'];
			if (!group) {
				group = item['specialistName'];
			}

			storage[group] = storage[group] || {};
			const storageLength = Object.keys(storage[group]).length;
			if (storageLength > 0) {
				storage[group] = {
					...storage[group],
					subSpecialists: [
						...(storage[group].subSpecialists || []),
						...(data.subSpecialists || []),
					],
				};
			} else {
				storage[group] = data;
			}
			return storage;
		}, {});

		setGroupingSpecialist(newGroupSpecialists);
	}, [doctor.specialists]);

	const handleConfirmSpecialization = (items: SpecialistProps[]) => {
		const selectedData = items.map((item) => ({
			specialistId: item.specialistId || '',
			specialistName: item.specialistName || '',
		}));

		setDoctor((old) => ({
			...old,
			specialists: [...(old.specialists || []), ...selectedData],
		}));

		setShowSpecializationModal(false);
	};

	const handleConfirmSubSpecialization = (
		items: SpecialistProps[],
		parent: SpecialistProps,
		index: number,
	) => {
		const selectedData = items.map((item) => ({
			specialistId: parent.specialistId || '',
			specialistName: parent.specialistName || '',
			subSpecialistId: item.specialistId || '',
			subSpecialistName: item.specialistName || '',
		}));

		setDoctor((old) => ({
			...old,
			specialists: [...(old.specialists || []), ...selectedData],
		}));

		setShowSubSpecializationModal((prev: any) => ({
			...prev,
			[parent.specialistName]: false,
		}));
	};

	const handleRemoveItemSpecialization = (name: string) => {
		const newSpecialitites = doctor.specialists?.filter(
			(specialist) =>
				specialist.specialistName !== name &&
				specialist.subSpecialistName !== name,
		);
		setDoctor((old) => ({
			...old,
			specialists: newSpecialitites,
		}));
	};

	const handleChangeStatus = (status: any) => {
		Modal.confirm({
			title: 'Confirmation',
			content: 'Are you sure want to change kyc status?',
			onOk: async () => {
				setSelectedKycStatus(status);
				await httpRequest.patch(`/doctors/${doctorId}`, {
					...doctor,
					kycStatus: status,
					note: status == 'REJECTED' ? rejectReason : '',
				});
				message.success('Success change status');
				setRejectReason('');
				Modal.destroyAll();
			},
			okText: 'Update Status',
			okType: 'primary',
		});
	};

	function handleClickReject() {
		setRejectReason('');
		setIsModalRejectVisible(true);
	}

	function handleClickApprove() {
		handleChangeStatus('APPROVED');
	}

	function handleConfirmReject() {
		setIsModalRejectVisible(false);
		handleChangeStatus('REJECTED');
	}

	function handleCancelReject() {
		setIsModalRejectVisible(false);
		setRejectReason('');
	}

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Edit"
				subtitle="Edit Doctor Data"
				rightAction={
					<>
						<Button
							loading={isLoadingAction}
							onClick={() => history.goBack()}
							size="large"
							style={{ margin: '0px 5px' }}
						>
							Cancel
						</Button>
						<Button
							loading={isLoadingAction}
							onClick={() => formRef?.current?.submit()}
							size="large"
							style={{
								margin: '0px 5px',
								backgroundColor: theme.colors.primary,
								color: theme.colors.white,
							}}
						>
							Save
						</Button>
					</>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="doctorForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={doctorId ? doctor : initialDoctor}
					autoComplete="off"
				>
					<SectionContent groupTitle="Status">
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<KycTag>{selectedKycStatus}</KycTag>
							{selectedKycStatus !== 'WAITING_APPROVAL' && (
								<Button
									onClick={
										selectedKycStatus == 'APPROVED'
											? handleClickReject
											: handleClickApprove
									}
									size="middle"
									style={{
										marginLeft: 0,
										backgroundColor: theme.colors.primary,
										color: theme.colors.white,
									}}
								>
									Edit Status
								</Button>
							)}
						</div>
					</SectionContent>
					<Divider />
					<SectionContent
						groupTitle="Account Information"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Row gutter={[16, 0]}>
							<Col span={8}>
								<Form.Item
									label="First Name"
									rules={generateFormRules('First Name', [
										'required',
										'letter-and-space',
									])}
									name={['user', 'firstName']}
								>
									<Input
										placeholder="First Name"
										value={doctor.user?.firstName}
										onChange={(val) =>
											setDoctor({
												...doctor,
												user: {
													...doctor.user,
													firstName: val.target.value,
												},
											})
										}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Middle Name"
									name={['user', 'middleName']}
									rules={generateFormRules('Middle Name', ['letter-and-space'])}
								>
									<Input
										placeholder="Middle Name"
										value={doctor.user?.middleName}
										onChange={(val) =>
											setDoctor({
												...doctor,
												user: {
													...doctor.user,
													middleName: val.target.value,
												},
											})
										}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Last Name"
									name={['user', 'lastName']}
									rules={generateFormRules('Last Name', ['letter-and-space'])}
								>
									<Input
										placeholder="Last Name"
										value={doctor.user?.lastName}
										onChange={(val) =>
											setDoctor({
												...doctor,
												user: {
													...doctor.user,
													lastName: val.target.value,
												},
											})
										}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							label="Nickname"
							rules={generateFormRules('Nickname', ['required'])}
							name={['user', 'nickName']}
						>
							<Input
								placeholder="Nickname"
								value={doctor.user?.nickName}
								onChange={(e) =>
									setDoctor({
										...doctor,
										user: {
											...doctor.user,
											nickName: e.target.value,
										},
									})
								}
							/>
						</Form.Item>
						<Form.Item
							label="Phone Number"
							rules={generateFormRules('Phone Number', ['required'])}
							name={['user', 'phone']}
						>
							<Input
								disabled
								placeholder="Phone number"
								value={doctor.user?.phone}
								type="number"
								onChange={(e) =>
									setDoctor({
										...doctor,
										user: {
											...doctor.user,
											phone: e.target.value,
										},
									})
								}
							/>
						</Form.Item>
						<Form.Item
							label="Gender"
							rules={generateFormRules('Gender', ['required'])}
							name={['user', 'gender']}
						>
							<Select
								style={{ width: '100%' }}
								value={doctor.user?.gender}
								onChange={(val) =>
									setDoctor({
										...doctor,
										user: { ...doctor.user, gender: val },
									})
								}
							>
								<Select.Option value="male">Male</Select.Option>
								<Select.Option value="female">Female</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item label="Birthday">
							<DatePicker
								style={{ width: '100%' }}
								placeholder="Birthday"
								value={moment(doctor.user?.birthdate)}
								disabledDate={(current) => {
									return moment().add(-1, 'days') <= current;
								}}
								onChange={(date: any, dateString: string) =>
									setDoctor({
										...doctor,
										user: {
											...doctor.user,
											birthdate: dateString,
										},
									})
								}
							/>
						</Form.Item>
						<Form.Item label="Email Address" name={['user', 'email']}>
							<Input
								placeholder="Email Address"
								value={doctor.user?.email}
								type="email"
								onChange={(e) =>
									setDoctor({
										...doctor,
										user: {
											...doctor.user,
											email: e.target.value,
										},
									})
								}
							/>
						</Form.Item>
					</SectionContent>
					<Divider />
					<SectionContent
						groupTitle="Doctor Information"
						helpers={[
							{
								title: 'Information',
								content:
									"Once you Approved the KycStatus or Cancel you can't change it again",
							},
						]}
					>
						<Form.Item
							label="Doctor Title"
							rules={generateFormRules('Doctor Title', ['required'])}
							name={'title'}
						>
							<Input
								placeholder="Doctor Title"
								value={doctor.title}
								onChange={(e) =>
									setDoctor({
										...doctor,
										title: e.target.value,
									})
								}
							/>
						</Form.Item>
						<Form.Item label="Experience" name={'experience'}>
							<Input
								placeholder="Experience"
								value={doctor.experience}
								type="number"
								onChange={(e) =>
									setDoctor({
										...doctor,
										experience: Number(e.target.value),
									})
								}
								suffix="Years"
							/>
						</Form.Item>
						<Form.Item
							label="Specialization"
							rules={generateFormRules('Specialization', ['required'])}
							name={'specialists'}
						>
							{doctor.specialists?.length === 0 ? (
								<Result
									style={{ padding: 0 }}
									title="Specialization Empty"
									subTitle="The specialization you enter will appear here."
									extra={
										<Button
											type="primary"
											ghost
											onClick={() => {
												setShowSpecializationModal(true);
											}}
										>
											Choose
										</Button>
									}
								></Result>
							) : (
								<>
									<div>
										{Object.keys(groupingSpecialist)?.map(
											(specialistId: any, index) => {
												const specialist = groupingSpecialist[specialistId];

												return (
													<div key={index}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'space-between',
															}}
														>
															<Text>{specialist.specialistName}</Text>
															<Button
																style={{
																	display: 'flex',
																	alignItems: 'center',
																}}
																onClick={() =>
																	handleRemoveItemSpecialization(
																		specialist.specialistName,
																	)
																}
															>
																<IconDeleteOutline width={20} />
															</Button>
														</div>
														<Divider style={{ margin: '10px 0' }} />

														<div
															style={{
																paddingLeft: 25,
															}}
														>
															{specialist?.subSpecialists?.map((sub: any) => (
																<>
																	<div
																		style={{
																			display: 'flex',
																			justifyContent: 'space-between',
																			alignItems: 'center',
																		}}
																	>
																		<Text>{sub?.subSpecialistName}</Text>
																		<Button
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																			}}
																			onClick={() =>
																				handleRemoveItemSpecialization(
																					sub.subSpecialistName,
																				)
																			}
																		>
																			<IconDeleteOutline width={20} />
																		</Button>
																	</div>
																	<Divider style={{ margin: '10px 0' }} />
																</>
															))}
															<Button
																type="ghost"
																onClick={() =>
																	setShowSubSpecializationModal({
																		...showSubSpecializationModal,
																		[specialist.specialistName]: true,
																	})
																}
															>
																<Text>Add Sub Specialization</Text>
															</Button>
															<Divider style={{ margin: '10px 0' }} />
														</div>
														<ModalSubSpecialization
															subSpecializaionList={specializationList}
															data={doctor.specialists || []}
															onConfirm={(data: SpecialistProps[]) =>
																handleConfirmSubSpecialization(
																	data,
																	specialist,
																	index,
																)
															}
															showModal={
																showSubSpecializationModal[
																	specialist.specialistName
																]
															}
															closeModal={() =>
																setShowSubSpecializationModal((prev: any) => ({
																	...prev,
																	[specialist.specialistName]: false,
																}))
															}
														/>
													</div>
												);
											},
										)}
										<Button
											type="ghost"
											style={{
												width: '100%',
											}}
											onClick={() => setShowSpecializationModal(true)}
										>
											<Text style={{ fontSize: 14 }}>Add Specialization</Text>
										</Button>
									</div>
								</>
							)}
						</Form.Item>

						<Form.Item
							label="Other sub specialization"
							name={'otherSpecialist'}
						>
							<Input
								placeholder="Other sub specialization"
								value={''}
								onChange={(e) =>
									setDoctor({
										...doctor,
										otherSpecialist: e.target.value,
									})
								}
							/>
						</Form.Item>
					</SectionContent>
					<Divider />
					<SectionContent
						groupTitle="Document Verification"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name',
							},
						]}
					>
						<Form.Item
							label="PRC Number"
							name={'prcNumber'}
							rules={generateFormRules('PRC Number', ['required'])}
						>
							<Input
								placeholder="PRC Number"
								value={doctor.prcNumber}
								onChange={(e) =>
									setDoctor({
										...doctor,
										prcNumber: e.target.value,
									})
								}
							/>
						</Form.Item>
						<Form.Item
							label="PTR Number"
							name={'ptrNumber'}
							rules={generateFormRules('PTR Number', ['required'])}
						>
							<Input
								placeholder="PTR Number"
								value={doctor.ptrNumber}
								onChange={(e) =>
									setDoctor({
										...doctor,
										ptrNumber: e.target.value,
									})
								}
							/>
						</Form.Item>
						<Form.Item
							label="Tax Identification Number (TIN)"
							name={'tinNumber'}
							rules={generateFormRules('TIN Number', ['required'])}
						>
							<Input
								placeholder="Tax Identification Number (TIN)"
								value={doctor.tinNumber}
								onChange={(e) =>
									setDoctor({
										...doctor,
										tinNumber: e.target.value,
									})
								}
							/>
						</Form.Item>
						<Form.Item label="Name of Signature">
							<Input
								disabled
								placeholder="Name of Signature"
								value={`${
									doctor.user?.firstName ? doctor.user.firstName + ' ' : ''
								}${
									doctor.user?.middleName ? doctor.user.middleName + ' ' : ''
								}${doctor.user?.lastName ? doctor.user.lastName + ' ' : ''}${
									doctor.title || ''
								}`}
							/>
						</Form.Item>
						{/* <DetailItem
							label='Signature'
							content={
								<FormUploadImage
									mode='single-medium'
									imageUrl={doctor.signatureImageUrl || ''}
									onChange={(file) => setSignatureImage(file)}
									onPreviewChange={(previewUrl) => setSignatureImageUrl(previewUrl)}
								/>
							}
						/>
						<DetailItem
							label='Certificates'
							// content={certificate.name}
							content={
								certificateImageUrls && certificateImageUrls.length > 0 ? (
									certificateImageUrls?.map((certUrl) => (
										<FormUploadImage
											imageUrl={certUrl || ''}
											onChange={(file) =>
												setCertificateImages(
													certificateImages ? [...certificateImages, file] : [file]
												)
											}
											onPreviewChange={(previewUrl) =>
												setCertificateImageUrls([...certificateImageUrls, previewUrl])
											}
										/>
									))
								) : (
									<FormUploadImage
										imageUrl=''
										onChange={(file) => setCertificateImages([file])}
										onPreviewChange={(previewUrl) => setCertificateImageUrls([previewUrl])}
									/>
								)
							}
						/> */}
					</SectionContent>
				</Form>
				<ModalSpecialization
					specializaionList={specializationList}
					data={doctor.specialists || []}
					onConfirm={(data: SpecialistProps[]) =>
						handleConfirmSpecialization(data)
					}
					showModal={showSpecializationModal}
					setShowModal={setShowSpecializationModal}
				/>
				<Modal
					title="Reject"
					visible={isModalRejectVisible}
					onOk={handleConfirmReject}
					onCancel={handleCancelReject}
				>
					<TextAreaTitle>Reason</TextAreaTitle>
					<TextArea
						rows={4}
						value={rejectReason !== null ? rejectReason : undefined}
						onChange={(e) => setRejectReason(e.target.value)}
						placeholder="Input the reason of rejection"
					/>
				</Modal>
			</AppCard>
		</div>
	);
};
const TextAreaTitle = styled.button`
	font-size: 12px;
	color: ${(props) => props.theme.colors.charcoal400};
`;

const SectionNav = styled.div`
	display: flex;
	background-color: ${(props) => props.theme.colors.white};
	border-radius: 5px;
`;

const SectionNavItem = styled.button`
	padding: 10px;
	flex: 1;
	border-bottom-color: ${(props) => props.theme.colors.primary};
`;

export default DoctorEdit;
