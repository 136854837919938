import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Table, Image, Modal, Switch } from 'antd';
import { ContainerFilter } from '../../screens/voucher';
import useFetchList from '../../hooks/useFetchList';
import { formatDate } from '../../helpers/constant';
import { VoucherProps } from '../../types/voucher.type';

type IProps = {
	setIsVisible: (visible: boolean) => any;
	isVisible: boolean;
	onSelect: (voucher: VoucherProps) => any;
};

export default function ModalSelectVoucher(props: IProps) {
	const { isLoading, data, pagination, setSearch, changePage } =
		useFetchList<VoucherProps>({
			endpoint: 'vouchers',
			initialQuery: {
				isPublished: true,
			},
			limit: 10,
		});

	const columns = [
		{
			title: 'Image',
			dataIndex: 'imageUrl',
			key: 'imageUrl',
			render: (url: string, record: VoucherProps) => {
				return (
					<Image
						width={120}
						height={100}
						src={url}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'voucherName',
			key: 'voucherName',
			render: (voucherName: string, record: VoucherProps) => {
				return <div>{voucherName}</div>;
			},
		},
		{
			title: 'PROMO STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			width: 150,
			render: (isActive: any, record: VoucherProps) => (
				<>
					<Switch checked={isActive} />
				</>
			),
		},
		{
			title: 'VOUCHER STATUS',
			key: 'voucherStatus',
			dataIndex: 'voucherStatus',
			width: 200,
			render: (text: string, record: VoucherProps) => {
				if (text === 'Available' || text === 'available') {
					return (
						<div
							style={{
								color: '#56C288',
								backgroundColor: '#F5FFF9',
								padding: '5px 50px',
								borderRadius: '7px',
								border: '1px solid #56C288',
								display: 'inline',
							}}
						>
							{text}
						</div>
					);
				} else if (text === 'Pending' || text === 'pending') {
					return (
						<div
							style={{
								color: '#FAB347',
								backgroundColor: '#FFF9EF',
								padding: '5px 50px',
								borderRadius: '7px',
								border: '1px solid #FAB347',
								display: 'inline',
							}}
						>
							{text}
						</div>
					);
				} else if (text === 'Expired' || text === 'expired') {
					return (
						<div
							style={{
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								padding: '5px 50px',
								borderRadius: '7px',
								border: '1px solid #F23459',
								display: 'inline',
							}}
						>
							{text}
						</div>
					);
				}
			},
		},
		{
			title: 'EXPIRED',
			dataIndex: 'validEndAt',
			key: 'validEndAt',
			render: (validEndAt: any) => <div>{formatDate(validEndAt)}</div>,
		},
		{
			title: 'CREATED',
			dataIndex: 'validStartAt',
			key: 'validStartAt',
			render: (validStartAt: any) => <div>{formatDate(validStartAt)}</div>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: VoucherProps) => (
				// <Dropdown overlay={() => menu(record)} placement="bottomRight">
				//     <MoreOutlined style={{ cursor: 'pointer' }} />
				// </Dropdown>

				<Button
					onClick={() => {
						props.setIsVisible(false);
						props.onSelect(record);
					}}
				>
					Select
				</Button>
			),
		},
	];

	return (
		<Modal
			title={'Select Voucher'}
			visible={props.isVisible}
			width="90%"
			onCancel={() => {
				props.setIsVisible(false);
			}}
			footer={null}
		>
			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search by name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
			</ContainerFilter>

			<Table
				loading={isLoading}
				columns={columns}
				dataSource={data}
				pagination={false}
			/>

			<Pagination
				current={pagination.page}
				total={pagination.totalData}
				defaultPageSize={pagination.perPage}
				pageSize={pagination.perPage}
				pageSizeOptions={['10', '20', '30']}
				showSizeChanger={true}
				showTotal={(total, range) =>
					`${range[0]}-${range[1]} of ${total} items`
				}
				onChange={changePage}
			/>
		</Modal>
	);
}
