import { useEffect } from 'react';
import { getToken, removeToken, saveToken, saveUser } from '../helpers/auth';
import { createGlobalState } from 'react-hooks-global-state';
import { IUser, initialUser } from '../types/user.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import axios from 'axios';
import { PartnerProps } from '../types/partner.type';
import { isPublisherMode, isSuperAdminMode } from '../helpers/cmsMode';

type IAuthData = {
	isLoading?: boolean;
	token: string;
	user: IUser;
	lastFetched?: Date | null;
};

const initialState: IAuthData = {
	isLoading: true,
	token: '',
	user: { ...initialUser },
	lastFetched: null,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useAuth() {
	const [isLoading, setIsLoading] = useGlobalState('isLoading');
	const [token, setToken] = useGlobalState('token');
	const [user, setUser] = useGlobalState('user');
	console.info('isLoading', isLoading);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const tokenString = await getToken();
		if (tokenString) {
			setToken(tokenString);
		}
		setIsLoading(false);
	};

	const getProfile = async () => {
		try {
			setIsLoading(true);
			const resultUser =
				await httpRequest.get<IHttpResponse<IUser>>('/user/me');
			setUser(resultUser.data.payload);
			setIsLoading(false);
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoading(false);
		}
	};

	const saveProfile = async (data: IUser) => {
		setIsLoading(true);

		const formData = {
			name: data.name,
			email: data.email,
			phone: data.phone,
			userType: data.userType,
			status: data.status,

			firstName: data.firstName,
			middleName: data.middleName,
			lastName: data.lastName,
		};

		const imageData = new FormData();
		if (data.image) imageData.append('image', data.image);

		try {
			const resultUpdate = await httpRequest.patch<IHttpResponse<IUser>>(
				'/user/' + user.userId,
				formData,
			);
			if (data.image) {
				await httpRequest.put('/user/' + user.userId + '/photo', imageData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
			}
			setUser(resultUpdate.data.payload);
			setIsLoading(false);
			message.success('Update profile successfully');
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoading(false);
		}
	};

	const login = async (data: { email: string; password: string }) => {
		try {
			const resultAuthLogin = await axios.post<
				IHttpResponse<{
					token: string;
				}>
			>(process.env.REACT_APP_BASE_URL + '/auth/signin', data);

			const resultUser = await axios.get<IHttpResponse<IUser>>(
				process.env.REACT_APP_BASE_URL + '/user/me',
				{
					headers: {
						Authorization: 'Bearer ' + resultAuthLogin.data.payload.token,
					},
				},
			);

			if (isSuperAdminMode() && resultUser.data.payload.userType === 'admin') {
				handleAfterLogin({
					token: resultAuthLogin.data.payload.token,
					user: resultUser.data.payload,
				});
				message.success('Login successfully.');
				// message.success(`Hi, ${resultUser.data.payload.name}.`);
				setIsLoading(false);
			} else if (isPublisherMode()) {
				const resultCustomer = await axios.get<IHttpResponse<any>>(
					process.env.REACT_APP_BASE_URL +
						'/partner-admins/' +
						resultUser.data.payload.userId,
					{
						headers: {
							Authorization: 'Bearer ' + resultAuthLogin.data.payload.token,
						},
					},
				);
				const publisherOwner = resultCustomer.data.payload;

				if (publisherOwner) {
					handleAfterLogin({
						token: resultAuthLogin.data.payload.token,
						user: {
							...resultUser.data.payload,
							partnerAdmin: resultCustomer.data.payload,
						},
					});
					message.success('Login successfully.');
					setIsLoading(false);
					window.location.href = '/dashboard';
				} else {
					logout();
					message.error('Please login with a valid publisher owner account.');
					setIsLoading(false);
				}
			} else {
				logout();
				message.error('Please login with available admin account.');
				setIsLoading(false);
			}
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoading(false);
		}
	};

	const handleAfterLogin = (data: IAuthData) => {
		saveToken(data.token);
		setToken(data.token);
		saveUser(data.user);
		setUser(data.user);
	};

	const logout = () => {
		removeToken();
		setToken('');
		setUser({ ...initialUser });

		// message.success('Logout successfully');
	};

	return {
		isLoading,
		isLoggedIn: token ? true : false,
		token,
		user,
		handleAfterLogin,
		login,
		logout,
		getProfile,
		saveProfile,
	};
}
