import {
	Checkbox,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Space,
} from 'antd';
import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { initialFAQ, FAQProps, EFAQType } from '../../types/faq.type';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import TextArea from 'antd/lib/input/TextArea';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { MAPPING_FAQ } from '.';
import styled from 'styled-components';

interface IParams {
	faqId: string;
}

interface ILocation {
	faqId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<FAQProps, 'createdAt' | 'updatedAt'>;
}

type Props = {
	type?: EFAQType;
};

const FAQEdit: React.FC<Props> = (props) => {
	const type: EFAQType = props.type ? props.type : EFAQType.GENERAL;
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { faqId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<FAQProps, 'createdAt' | 'updatedAt'>>>(null);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [faq, setFAQ] = React.useState<FAQProps>(initialFAQ);

	const READERS_OPTION =
		props.type === EFAQType.BALANCE
			? ['DOCTOR']
			: props.type === EFAQType.POINT
			? ['DOCTOR', 'PATIENT']
			: ['DOCTOR', 'PATIENT', 'SECRETARY'];

	const createFAQ = async (
		props: Omit<FAQProps, 'createdAt' | 'updatedAt' | 'adminId'>,
	) => {
		try {
			setIsLoadingAction(true);
			await httpRequest.post('/faqs', {
				...props,
				type,
				targetReaders:
					type === EFAQType.BALANCE ? ['DOCTOR'] : props.targetReaders,
			});
			message.success('Created successfully');
			history.push(`/${MAPPING_FAQ[type].path}`);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateFAQ = async (
		props: Omit<FAQProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			await httpRequest.patch('/faqs/' + faqId, {
				...props,
				type,
				targetReaders:
					type === EFAQType.BALANCE ? ['DOCTOR'] : props.targetReaders,
			});
			message.success('Updated successfully');
			history.push(`/${MAPPING_FAQ[type].path}`);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<FAQProps, 'createdAt' | 'updatedAt'>,
	) => {
		if (faqId) {
			updateFAQ(values);
		} else {
			createFAQ(values);
		}
	};

	React.useEffect(() => {
		if (faqId) {
			const fetchFAQ = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>('/faqs/' + faqId);
					setFAQ(res.data.payload);
					setIsLoading(false);

					const bcDetails = [
						{
							field: 'faqId',
							value: faqId,
							label: res.data.payload.question,
						},
					];
					setBreadcrumbDetails(bcDetails);
				} catch (error) {
					setIsLoading(false);
				}
			};

			fetchFAQ();
		}
	}, [faqId, location]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(faqId ? 'Edit ' : 'Add ') + MAPPING_FAQ[type].title}
				subtitle={`Manage your ${MAPPING_FAQ[type].title} data`}
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="faqForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={faqId ? faq : initialFAQ}
					autoComplete="off"
				>
					<SectionContent
						groupTitle={`${MAPPING_FAQ[type].title} Data`}
						helpers={[
							{
								title: 'Information',
								content: 'Highlight will show the question in Home Page',
							},
						]}
					>
						<Form.Item
							label="Question"
							name="question"
							rules={[
								{
									required: true,
									message: 'The question is required.',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Status"
							name="isPublished"
							rules={[
								{
									required: true,
									message: 'The status is required.',
								},
							]}
						>
							<Radio.Group value={faq.isPublished || false}>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>

						{props.type !== 'BALANCE' && (
							<Form.Item
								label="Published For"
								name="targetReaders"
								rules={[
									{
										required: true,
										message: 'The published for is required.',
									},
								]}
							>
								<CustomCheckboxGroup
									options={READERS_OPTION}
									value={faq.targetReaders}
								/>
							</Form.Item>
						)}

						<Form.Item
							label="Answer"
							name="answer"
							rules={[
								{
									required: true,
									message: 'The answer is required.',
								},
							]}
						>
							<TextArea />
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default FAQEdit;

const CustomCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: red;
		background-color: red;
	}

	.ant-checkbox-group-item {
		margin-right: 5.6rem;
	}
`;
