import {
	Button,
	Col,
	Dropdown,
	Image,
	Menu,
	message,
	Modal,
	Row,
	Space,
	Switch,
	Tag,
	Table,
	Tabs,
	TimePicker,
	Typography,
} from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import moment from 'moment';
import React from 'react';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { MoreOutlined } from '@ant-design/icons';
import { BaseResponseProps } from '../../types/config.type';
import { PartnerProps, initialPartner } from '../../types/partner.type';
import { formatDate } from '../../helpers/constant';
import AppButton from '../../components/AppButton';
import AdminEdit from './../admin/Edit';
import { getErrorMessage } from '../../helpers/errorHandler';
import { CustomerProps, initialCustomer } from '../../types/customer.type';
import {
	PublisherProps,
	initialPublisher,
} from '../../types/adminPublisher.type';
import { getFullName } from '../../helpers/name';
import KycTag from '../../components/KycTag';
import { EbookProps, initialEbooks } from '../../types/ebook.type';
import { getUser } from '../../helpers/auth';
interface IParams {
	partnerId: string;
}

interface FetchPartnerResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface FetchAllPublisherResponse extends BaseResponseProps {
	payload: {
		results: PublisherProps[];
	};
}

interface FetchAllEbookResponse extends BaseResponseProps {
	payload: {
		results: EbookProps[];
	};
}

const { Text } = Typography;
const { TabPane } = Tabs;

const PublisherDetail: React.FC = () => {
	const userData = getUser();
	const history = useHistory();
	const { partnerId } = useParams<IParams>();
	const [tabs, setTabs] = React.useState<string>('1');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [partner, setPartner] = React.useState<PartnerProps>(initialPartner);
	const [adminPublisher, setAdminPublisher] = useState<PublisherProps[]>([]);
	const [ebook, setEbook] = useState<EbookProps[]>([]);
	const [tmpData, setTmpData] =
		React.useState<PublisherProps>(initialPublisher);
	const [isOwnerExists, setIsOwnerExists] = useState<boolean>(false);

	const handleClickDetail = (e: PublisherProps) => {
		history.push(
			`/publisher/${partnerId}/detail/admin-publisher/${e.userId}/detail`,
		);
	};
	const handleClickDetailEbook = (e: EbookProps) => {
		history.push(`/publisher/${partnerId}/detail/ebooks/${e.ebookId}/detail`);
	};

	React.useEffect(() => {
		const fetchPartnerDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<FetchPartnerResponseProps>(
					'/partners/' + partnerId,
				);

				setPartner(res.data.payload);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchPartnerDetail();
	}, [partnerId]);

	const fetchAdminList = useCallback(async () => {
		const res = await httpRequest.get<FetchAllPublisherResponse>(
			'/partner-admins',
			{
				params: {
					partnerId: partnerId,
				},
			},
		);
		
		const filteredAdmins = res.data.payload.results.filter(
			(admin) => admin.partners?.partnerId === partnerId,
		);

		const sortedAdmins = filteredAdmins.sort((a, b) => {
			if (a.role === 'publisher_owner') return -1;
			if (b.role === 'publisher_owner') return 1;
			return 0;
		});

		setAdminPublisher(sortedAdmins);
		setIsOwnerExists(sortedAdmins.some(admin => admin.role === 'publisher_owner')); 
	}, [partnerId]);

	const handleDelete = async (id: string) => {
		try {
			await httpRequest.delete('partner-admins/' + id);
			fetchAdminList();
			message.success('Deleted successfully');
		} catch (err) {
			message.error('Failed to delete. ' + getErrorMessage(err));
		}
	};

	useEffect(() => {
		fetchAdminList();
	}, [fetchAdminList]);

	React.useEffect(() => {
		const fetchBookList = async () => {
			const res = await httpRequest.get<FetchAllEbookResponse>('/ebooks', {
				params: {
					partnerId: partnerId,
				},
			});
			const filtereEbook = res.data.payload.results.filter(
				(ebook) => ebook.partners?.partnerId === partnerId,
			);
			setEbook(filtereEbook);
		};

		fetchBookList();
	}, [partnerId]);

	const CheckAdmin = adminPublisher.some(
		(admin) =>
			admin.userId === userData?.userId && admin.role === 'publisher_admin',
	);

	const isPublisherOwner = adminPublisher.some(
		(admin) =>
			admin.userId === userData?.userId && admin.role === 'publisher_owner',
	);
	const bookColumns = [
		{
			title: 'TITLE',
			dataIndex: 'title',
			key: 'title',
			render: (title: string, record: EbookProps) => {
				return (
					<div
						className="table-link"
						onClick={() => handleClickDetailEbook(record)}
					>
						{title}
					</div>
				);
			},
		},
		{
			title: 'GENRE',
			key: 'genres',
			dataIndex: 'genres',
			render: (genres: any, record: EbookProps) => (
				<>
					{record.genres
						? record.genres.length <= 3
							? record.genres.slice().map((el, index) => (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
										key={el.genreId}
									>
										{el.genreName}
									</Tag>
							  ))
							: record.genres.slice(0, 3).map((el, index) => (
									<Tag
										style={{
											border: '2px solid #D81F64',
											color: '#D81F64',
											marginBottom: '10px',
										}}
										key={el.genreId}
									>
										{el.genreName}
									</Tag>
							  ))
						: []}
					{record.genres
						? record.genres.length > 3 && (
								<Tag
									style={{
										border: '2px solid #D81F64',
										color: '#D81F64',
										marginBottom: '10px',
									}}
								>
									+{record.genres.slice(3).length} More
								</Tag>
						  )
						: []}
				</>
			),
		},
		{
			title: 'PUBLISHER',
			render: (record: EbookProps) => (
				<Text>{record.partners?.partnerName}</Text>
			),
		},
		{
			title: 'PRICE',
			dataIndex: 'originalPrice',
			key: 'originalPrice',
		},
		{
			title: 'CREATED',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: string) => (
				<span>{moment(text).format('MMMM DD, YYYY')}</span>
			),
		},
	];

	const adminColumns = [
		{
			title: 'FULL NAME',
			render: (record: PublisherProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{getFullName(record.user)}
					</div>
				);
			},
		},
		// {
		// 	title: 'PHONE NUMBER',
		// 	render: (record: PublisherProps) =>
		// 		record.user?.phone ? (
		// 			<Text>+{record.user?.phone}</Text>
		// 		) : (
		// 			<Text italic type="secondary">
		// 				Not set
		// 			</Text>
		// 		),
		// },
		{
			title: 'EMAIL ADDRESS',
			render: (record: PublisherProps) => <Text>{record.user?.email}</Text>,
		},
		// {
		// 	title: 'PUBLISHER',
		// 	render: (record: PublisherProps) => (
		// 		<Text>{record.partners?.partnerName}</Text>
		// 	),
		// },
		{
			title: 'Role',
			render: (record: PublisherProps) => {
				let mappedRole;

				switch (record.role) {
					case 'publisher_owner':
						mappedRole = 'OWNER';
						break;
					case 'publisher_admin':
						mappedRole = 'ADMIN';
						break;
					default:
						mappedRole = record.role;
				}

				return <Text>{mappedRole}</Text>;
			},
		},
		{
			title: 'CREATED',
			render: (record: PublisherProps) => (
				<div>{moment(record.createdAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: PublisherProps) => {
				if (CheckAdmin) {
					return null;
				}
				return !isPublisherOwner || record.userId !== userData?.userId ? (
					<Dropdown overlay={() => menu(record)} placement="bottomRight">
						<MoreOutlined style={{ cursor: 'pointer' }} />
					</Dropdown>
				) : null;
			},
		},
	];

	const menu = (record: PublisherProps) => (
		<Menu onClick={(e) => handleClick(e.key, record)}>
			<Menu.Item key="delete">Delete </Menu.Item>
		</Menu>
	);

	const handleClick = (key: string, record: PublisherProps) => {
		if (key === 'delete') {
			Modal.confirm({
				title: 'Confirmation',
				content: 'Are you sure want to delete this admin?',
				onOk: () => {
					if (record.userId) {
						handleDelete(record.userId);
					}
				},
			});
		}
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={partner.partnerName}
				subtitle="Manage your publisher data"
				rightAction={
					!CheckAdmin && (
						<AppButton
							type="primary"
							onClick={() => history.push(`/publisher/${partnerId}/edit`)}
						>
							Edit Publisher
						</AppButton>
					)
				}
			/>
			<AppCard loading={isLoading}>
				<Tabs
					type="line"
					activeKey={tabs}
					centered
					size="large"
					onChange={(activeKey) => setTabs(activeKey)}
				>
					<TabPane tab="Detail" key="1">
						<SectionContent groupTitle="Publisher Data">
							<DetailItem
								label="Publisher Name"
								content={partner.partnerName}
							/>

							<DetailItem label="Contact Email " content={partner.email} />

							<DetailItem
								label="Contact Phone"
								content={partner.phone ? partner.phone : undefined}
							/>

							<DetailItem label="Address" content={partner.address} />

							<DetailItem
								label="Published Status"
								content={partner.isPublished ? 'Published' : 'Unpublished'}
							/>

							<DetailItem
								label="Verified Status"
								content={partner.isConfirmedPartner ? 'Verified' : 'Unverified'}
							/>
						</SectionContent>
					</TabPane>
					<TabPane tab="Book List" key="2">
						<Table
							loading={isLoading}
							columns={bookColumns}
							dataSource={ebook}
							pagination={false}
						/>
						<Button
							style={{
								color: '#D81F64',
								borderColor: '#D81F64',
								width: '100%',
								marginTop: '2%',
							}}
							onClick={() => {
								history.push(`/publisher/${partnerId}/detail/ebooks/add`);
							}}
						>
							Add New E-Book
						</Button>
					</TabPane>
					<TabPane tab="Admin List" key="3">
						<Table
							loading={isLoading}
							columns={adminColumns}
							dataSource={adminPublisher}
							pagination={false}
						/>
						<Button
							style={{
								color: '#D81F64',
								borderColor: '#D81F64',
								width: '100%',
								marginTop: '2%',
							}}
							onClick={() => {
								history.push(
									`/publisher/${partnerId}/detail/admin-publisher/add?type=${isOwnerExists ? 'admin' : 'owner'}`
								);
							}}
						>
							{isOwnerExists ? 'Add New Admin' : 'Add New Owner'}
						</Button>
					</TabPane>
				</Tabs>
			</AppCard>
		</div>
	);
};

export default PublisherDetail;
